import loadable from '@loadable/component'
import { Flex, OverflowAuto } from 'cdk'
import styled from 'styled-components'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'

const Fallback = styled.div`
  width: 200px;
  height: 200px;
`

const LazyPaymentSuccessImg = loadable(() => import('./PaymentSuccessImg.lazy'), {
  resolveComponent: (components) => components.PaymentSuccessImg,
  fallback: <Fallback />,
})

type Props = {
  onClose: () => void
}

export const TriggerPaymentSuccess = ({ onClose }: Props) => (
  <>
    <OverflowAuto $align="center">
      <LazyPaymentSuccessImg />
      <Text colorName="black" fontWeight="600" kind="h2">
        <Translation id="triggerPaymentModal.success.title" />
      </Text>
      <Flex $pt={16}>
        <Text colorName="black" kind="paragraph" textAlign="center">
          <Translation id="triggerPaymentModal.success.body" />
        </Text>
      </Flex>
    </OverflowAuto>
    <Flex $pt={32}>
      <Button kind="primary" onClick={onClose}>
        <Translation id="actions.close" />
      </Button>
    </Flex>
  </>
)
