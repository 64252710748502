import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'

type Event = {
  end: Date
  start: Date
}

export const groupCalendarEventsByDay = (events: Event[]) =>
  Object.entries(
    events.reduce(
      (accumulator, { start, end }) => {
        const startAtEvent = pipe(start, format(FormatDateEnum.DAY_MONTH_YEAR))
        const endAtEvent = pipe(end, format(FormatDateEnum.DAY_MONTH_YEAR))

        return {
          ...accumulator,
          [startAtEvent]: [...(accumulator[startAtEvent] || []), start],
          [endAtEvent]: [...(accumulator[endAtEvent] || []), end],
        }
      },
      {} as Record<string, Date[]>,
    ),
  ).map(([, value]) => value)
