import { Flex, MaxWidth1260px, Pressable } from 'cdk'
import { ReactNode, useCallback } from 'react'
import { AppBarLogo } from '~/components/AppBar/components/AppBarLogo'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useWindowLocation } from '~/hooks/useWindowLocation'
import { getEnv } from '~/utils/getEnv'
import { APP_BAR_HEIGHT } from '../AppBar/constants'

type Props = {
  children?: ReactNode
}

export const FlowAppBar = ({ children }: Props) => {
  const { isAuthenticated } = useAuthState()

  const location = useWindowLocation()

  const onClick = useCallback(() => {
    location.replace(getEnv('SERENIS_WEBSITE_URL'))
  }, [location])

  return (
    <Flex $align="center" $basis={APP_BAR_HEIGHT} $justify="center" $p={16} as="header">
      <MaxWidth1260px $align="center" $direction="row" $justify="space-between">
        <Flex $align="flex-start">
          <Pressable disabled={isAuthenticated} onClick={onClick}>
            <AppBarLogo />
          </Pressable>
        </Flex>
        <Flex $align="center" $direction="row" $justify="flex-end">
          {children}
        </Flex>
      </MaxWidth1260px>
    </Flex>
  )
}
