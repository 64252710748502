import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'

export const useIsGenericBundleModuleVisible = () => {
  const { therapies, loading } = usePatientTherapies()

  return (
    !loading &&
    therapies.some(
      (therapy) =>
        [
          // NOTE: intentionally checking ids and not types to hide bundles for patients with old pricing
          'tpp_jy9fl3wv3n9td7jv', // 'COUPLES_PSYCHOTHERAPY'
          'tpp_utqytx1nedovxt6p', // 'MYSELF_PSYCHOTHERAPY'
          'tpp_y55p30afehj6znkx', // 'PATH_COACHING'
          'tpp_jpwsoi7zcd2czetw', // 'PATH_SEXOLOGY'
          'tpp_ep19vxz5avfxebk2', // 'UNDERAGE_PSYCHOTHERAPY'
        ].includes(therapy.therapyPath.id) && !therapy.withTherapyBundle,
    )
  )
}
