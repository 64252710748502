import { Flex } from 'cdk'
import styled from 'styled-components'

/** @deprecated Use this component until the new Card from `ui` is ready */
export const CardBoxNoStroke = styled(Flex).attrs((attrs) => ({
  $backgroundColorName: 'lighter',
  $borderRadius: 24,
  $elevationName: 'xxs',
  $overflow: 'hidden',
  $p: 24,
  ...attrs,
}))`
  /* stylelint-disable-next-line no-empty-source */
`
