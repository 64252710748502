import { convertBase64ToBlob } from '~/utils/convertBase64ToBlob'

type downloadBlobProps = { base64: string; fileName: string }

export const downloadCalendarEvent = ({ base64, fileName }: downloadBlobProps) => {
  const link = document.createElement('a')
  const blob = convertBase64ToBlob({ base64, mimeType: 'text/calendar' })

  link.href = URL.createObjectURL(blob)
  link.download = fileName

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  URL.revokeObjectURL(link.href)
}
