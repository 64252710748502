import { Chip } from 'ui'
import { Translation } from '~/components/Translation'
import { AppointmentDetails } from '../components/AppointmentDetails'
import { ContentSkeleton } from '../components/ContentSkeleton'
import { Price } from '../components/Price'

export const MissingPaymentCancelledAppointmentContent = () => (
  <ContentSkeleton
    topEndChildren={<Price />}
    topStartChildren={
      <Chip kind="error">
        <Translation id="therapySession.patient.missingPaymentCancelled.statusValues" />
      </Chip>
    }
  >
    <AppointmentDetails canManage />
  </ContentSkeleton>
)
