import { gql, useMutation } from '@apollo/client'
import { JournalingLogShareMutation, MutationJournalingLogShareArgs } from '~/types/graphql'

const MUTATION = gql`
  mutation journalingLogShare($input: JournalingLogShareInput!) {
    journalingLogShare(input: $input) {
      ok
    }
  }
`

export const useJournalingLogShare = () =>
  useMutation<JournalingLogShareMutation, MutationJournalingLogShareArgs>(MUTATION)
