import { useEffect, useMemo } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { ReviewThankYou } from '~/domains/product-reviews/detail/components/ReviewThankYou'
import { getRoute } from '~/utils/getRoute'
import { NegativeReview } from './components/NegativeReview'
import { PositiveReview } from './components/PositiveReview'
import { useUpdateProductReviewScore } from './hooks/useUpdateProductReviewScore'
import { useUserProductReview } from './hooks/useUserProductReview'

type Param = {
  score: string
}

export const ProductReviewDetail = () => {
  const { score } = useParams<Param>()

  const { error, loading, productReview } = useUserProductReview()
  const [updateProductReviewScore] = useUpdateProductReviewScore()

  const scoreIntegerValue = useMemo(() => {
    const value = Number(score)

    if (Number.isNaN(value)) {
      return null
    }

    if (value > 5) {
      return 5
    }

    if (value < 1) {
      return 1
    }

    return value
  }, [score])

  useEffect(() => {
    if (error || loading || !productReview || productReview.body || !scoreIntegerValue) {
      return
    }

    const run = async () => {
      await updateProductReviewScore({
        variables: {
          input: {
            id: productReview.id,
            score: scoreIntegerValue,
          },
        },
      })
    }

    run()
  }, [error, productReview, loading, scoreIntegerValue, updateProductReviewScore])

  if (loading) {
    return <CenteredLoader />
  }

  if (error || !scoreIntegerValue || !productReview) {
    return <Redirect to={getRoute('/')} />
  }

  return (
    <>
      {!!productReview.body && <ReviewThankYou />}
      {!productReview.body && scoreIntegerValue < 4 && <NegativeReview id={productReview.id} />}
      {!productReview.body && scoreIntegerValue >= 4 && <PositiveReview score={scoreIntegerValue} />}
    </>
  )
}
