import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertTherapist } from '~/domains/auth/components/AssertTherapist'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

const LazyProfileTechniquesPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.ProfileTechniquesPageView,
})

export const ProfileTechniquesPage: Page = {
  exact: true,
  id: 'settings.techniques',
  paths: ['/profile/techniques'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'settings.techniques',
    title: 'settings.techniques.title',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertTherapist target="/">{children}</AssertTherapist>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyProfileTechniquesPageView />,
}
