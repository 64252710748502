import { RouterLinkButton } from '~/components/RouterLinkButton'

type Props = {
  therapyId: string
}

export const BookTherapySessionFromTherapy = ({ therapyId }: Props) => (
  <RouterLinkButton
    kind="primary"
    size="sm"
    to={`/therapy-session/booking/${therapyId}`}
    translationId="actions.bookTherapySession"
  />
)
