import { Flex } from 'cdk'
import Lottie from 'lottie-react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import animation from './trigger-payment-lottie.json'

type Props = {
  onLoopCompleted: () => void
}

const Container = styled(Flex)`
  width: 200px;
  height: 200px;
`

export const TriggerPaymentLoading = ({ onLoopCompleted }: Props) => (
  <Flex $align="center" $py={64}>
    <Container>
      <Lottie animationData={animation} onLoopComplete={onLoopCompleted} />
    </Container>
    <Text colorName="black" fontWeight="600" kind="h2">
      <Translation id="triggerPaymentModal.loading" />
    </Text>
  </Flex>
)
