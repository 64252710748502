import { Flex, MountOn, PositionSticky } from 'cdk'
import { memo, useMemo } from 'react'
import { useAvailableTimeSlotsByDay } from '../../hooks/useTimeSlots'
import { getAvailabilityDayHeaderId } from '../../utils/getAvailabilityDayHeaderId'
import { AvailabilityDayHeader, AvailabilityDayHeaderOnMultipleLines } from './AvailabilityDayHeader'
import { BookingAvailabilitiesSkeleton } from './BookingLoaderSkeleton'
import { SlotsByDay } from './SlotsByDay'

export const SingleSuggestionAvailabilities = memo(
  ({
    onTimeSlotSelected,
    filterByTimeInterval,
  }: {
    onTimeSlotSelected: (timeSlot: Date, therapistId: string) => void
    filterByTimeInterval: boolean
  }) => {
    const { loading, slotsByDay, slotsByDayFilteredByTimeInterval } = useAvailableTimeSlotsByDay()

    const slots = useMemo(
      () => (filterByTimeInterval ? slotsByDayFilteredByTimeInterval : slotsByDay),
      [filterByTimeInterval, slotsByDay, slotsByDayFilteredByTimeInterval],
    )

    if (loading) {
      return (
        <Flex $pt={32}>
          <BookingAvailabilitiesSkeleton />
        </Flex>
      )
    }

    return (
      <Flex $pt={32}>
        {slots.map(([day, slotsByTherapist]) => (
          <Flex key={day} $mdDirection="row" id={getAvailabilityDayHeaderId(day)}>
            <PositionSticky $backgroundColorName="lighter" $mdBasis={120} $top={0}>
              <MountOn mediaQuery="sm">
                <AvailabilityDayHeader day={day} pt={16} />
              </MountOn>
              <MountOn mediaQuery="gt-sm">
                <AvailabilityDayHeaderOnMultipleLines day={day} pt={16} />
              </MountOn>
            </PositionSticky>
            {slotsByTherapist.map(([therapistId, slots]) => (
              <SlotsByDay
                key={therapistId}
                onTimeSlotSelected={onTimeSlotSelected}
                py={16}
                slots={slots}
                therapistId={therapistId}
              />
            ))}
          </Flex>
        ))}
      </Flex>
    )
  },
)
