import { Flex } from 'cdk'
import { Link } from 'react-router-dom'
import { Suggestion, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { getRoute } from '~/utils/getRoute'

const to = getRoute('/gesto/onboarding/internal')

export const SaasActivateGesto = () => (
  <Suggestion kind="info">
    <Text fontWeight="600" kind="paragraph">
      <Translation id="saas.onboarding.internal.suggestion.title" /> 💜
    </Text>

    <Flex $pt={8}>
      <TranslationMarkdown id="saas.onboarding.internal.suggestion.description" kind="paragraph" />
    </Flex>

    <Flex $align="flex-start" $pt={8}>
      <Link to={to}>
        <Text colorName="primary" fontWeight="600" kind="paragraph" textDecoration="underline">
          <Translation id="saas.onboarding.internal.suggestion.complete" />
        </Text>
      </Link>
    </Flex>
  </Suggestion>
)
