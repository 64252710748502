import { ReactNode } from 'react'
import { Redirect } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { getRoute, Route } from '~/utils/getRoute'
import { useGetLegalGuardians } from '../hooks/useGetLegalGuardians'

type Props = {
  children: ReactNode
  target?: Route
}

export const AssertWithoutLegalPartners = ({ children, target = '/' }: Props) => {
  const { loading, legalGuardians } = useGetLegalGuardians()

  if (loading) {
    return <CenteredLoader />
  }

  if (!!legalGuardians?.length) {
    return <Redirect to={getRoute(target)} />
  }

  return <>{children}</>
}
