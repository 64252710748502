import { Flex, OverflowAuto } from 'cdk'
import { Icon } from 'icons'
import { memo, useMemo } from 'react'
import { Chip, Text } from 'ui'
import { Price } from '~/components/Price'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { TherapyPathKey } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathCard'
import { StartTherapyPathIcon } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathIcon'
import {
  colorByTherapyPathName,
  StartTherapyPathName,
} from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathName'
import { useTranslationVariablesTherapySessionCosts } from '~/domains/auth/hooks/useTranslationVariablesTherapySessionCosts'
import { Intent } from '~/domains/auth/types/intent'
import { validFirstTherapySessionFree } from '~/domains/therapies/constants'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { ClinicalDirectorQuoteCard } from '../ClinicalDirectorQuote/ClinicalDirectorQuoteCard'

type TabItemContentProps = {
  kind: 'myself' | 'couples' | 'coaching' | 'psychiatry' | 'sexology' | 'dca' | 'nutritionist'
}

const kindToDictionaryKeys = (kind: TabItemContentProps['kind']): Exclude<Intent, 'call' | 'journaling'> => {
  switch (kind) {
    case 'coaching':
      return 'coaching'
    case 'couples':
      return 'therapyCouple'
    case 'myself':
      return 'therapyMyself'
    case 'psychiatry':
      return 'psychiatry'
    case 'sexology':
      return 'sexology'
    case 'dca':
      return 'dca'
    case 'nutritionist':
      return 'nutritionist'
  }
}

const therapyPathKindToKey = (kind: TabItemContentProps['kind']): TherapyPathKey => {
  switch (kind) {
    case 'coaching':
      return 'PATH_COACHING'
    case 'couples':
      return 'COUPLES_PSYCHOTHERAPY'
    case 'myself':
      return 'MYSELF_PSYCHOTHERAPY'
    case 'psychiatry':
      return 'MYSELF_PSYCHIATRY'
    case 'sexology':
      return 'PATH_SEXOLOGY'
    case 'dca':
      return 'PATH_NUTRITION_DCA'
    case 'nutritionist':
      return 'PATH_NUTRITION_WEIGHT_LOSS'
  }
}

const FirstSessionFree = () => (
  <Flex $align="center" $direction="row" $gap={8}>
    <Icon colorName="black" name="gift" size={24} />
    <Chip kind="black">
      <Translation id="formFlow.firstTherapySessionFree.label" />
    </Chip>
  </Flex>
)

const TherapySessionDuration = ({ kind }: TabItemContentProps) => (
  <Flex $align="center" $direction="row" $gap={8}>
    <Icon colorName="black" name="timer" size={24} />
    <TranslationMarkdown id={`startTherapyModal.${kindToDictionaryKeys(kind)}.sessionDuration`} kind="paragraph" />
  </Flex>
)

const TherapySessionTalkWith = ({ kind }: TabItemContentProps) => (
  <Flex $align="center" $direction="row" $gap={8}>
    <Icon colorName="black" name="user-badge-check" size={24} />
    <TranslationMarkdown id={`startTherapyModal.${kindToDictionaryKeys(kind)}.talkWith`} kind="paragraph" />
  </Flex>
)

export const TabItemContent = memo(({ kind }: TabItemContentProps) => {
  const therapyPathType = therapyPathKindToKey(kind)
  const kindToDictionaryKey = kindToDictionaryKeys(kind)

  const costs = useTranslationVariablesTherapySessionCosts(kindToDictionaryKey)

  const leftDetailTranslationId = useTranslation(`paymentPlan.${kindToDictionaryKey}.left.detail`)
  const rightDetailTranslationId = useTranslation(`paymentPlan.${kindToDictionaryKey}.right.detail`)

  const showChip = useMemo(() => validFirstTherapySessionFree.includes(therapyPathType), [therapyPathType])

  return (
    <OverflowAuto $gap={16}>
      <Flex $align="center" $direction="row" $gap={12}>
        <StartTherapyPathIcon size={48} therapyPathType={therapyPathType} />
        <Flex $shrink={1}>
          <StartTherapyPathName kind="h2" therapyPathType={therapyPathType} />
        </Flex>
      </Flex>
      <ClinicalDirectorQuoteCard
        backgroundColorName="grey-20"
        quote={`formFlow.forWhom.pathChoice.steps.options.${kind}.quote`}
      />

      <Flex $gap={16}>
        {showChip && <FirstSessionFree />}
        <TherapySessionDuration kind={kind} />
        <TherapySessionTalkWith kind={kind} />
        <Flex $direction="row" $pt={16}>
          <Flex $borderColorName="grey-50" $borderSizeRight={1} $grow={1}>
            <Price
              amount={costs.firstTherapySessionCost ?? costs.therapySessionCost}
              detail={leftDetailTranslationId}
            />
            <Text kind="paragraph">
              <Translation id={`paymentPlan.${kindToDictionaryKey}.left.planName`} />
            </Text>
          </Flex>
          <Flex $grow={1} $pl={16}>
            <Price
              $color={colorByTherapyPathName[therapyPathType]}
              amount={costs.bundleTherapySessionCost ?? costs.therapySessionCost}
              detail={rightDetailTranslationId}
            />
            <Text kind="paragraph">
              <Translation id={`paymentPlan.${kindToDictionaryKey}.right.planName`} />
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </OverflowAuto>
  )
})
