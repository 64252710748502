import { useTrackEventView } from '~/domains/analytics/hooks/useTrackEventView'
import { Page } from '~/routes/types'

type Props = {
  id: NonNullable<Page['id']>
}

export const PageTrackEffect = ({ id }: Props) => {
  useTrackEventView(id)

  return null
}
