import { Flex } from 'cdk'
import { COLOR_DARKER, FONT_SIZE_48, LINE_HEIGHT_48 } from 'design-tokens'
import styled, { CSSProperties } from 'styled-components'
import { Text } from 'ui'

type PriceProps = {
  amount: string
  $color?: CSSProperties['background']
  detail?: string
}

const Text48 = styled(Text)`
  font-size: ${FONT_SIZE_48};
  line-height: ${LINE_HEIGHT_48};
`

const PriceWrapper = styled(Flex).attrs({
  $direction: 'row',
})<{ $color?: PriceProps['$color'] }>`
  background: ${({ $color }) => $color ?? COLOR_DARKER};
  background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const Price = ({ amount, $color, detail }: PriceProps) => {
  const [whole] = amount.replace(' €', '').split(',')

  return (
    <PriceWrapper $align="center" $color={$color} $gap={4}>
      <Text48 fontWeight="600" kind="h1">
        {whole}
      </Text48>
      <Flex>
        <Flex $direction="row">
          <Text fontWeight="600" kind="h2">
            €
          </Text>
        </Flex>
        {detail !== ' ' && (
          <Text fontWeight="600" kind="h3">
            {detail}
          </Text>
        )}
      </Flex>
    </PriceWrapper>
  )
}
