import { gql, useMutation } from '@apollo/client'
import { TriggerPaymentMutation, TriggerPaymentMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation triggerPayment($input: TriggerPaymentInput!) {
    triggerPayment(input: $input) {
      ok
    }
  }
`

export const useTriggerPayment = () => useMutation<TriggerPaymentMutation, TriggerPaymentMutationVariables>(MUTATION)
