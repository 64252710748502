import { gql, useMutation } from '@apollo/client'
import {
  DeleteRecurrencyForTherapistEmergencyMutation,
  DeleteRecurrencyForTherapistEmergencyMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation deleteRecurrencyForTherapistEmergency($input: DeleteRecurrencyForTherapistEmergencyInput!) {
    deleteRecurrencyForTherapistEmergency(input: $input) {
      id
    }
  }
`

export const useDeleteRecurrencyForTherapistEmergency = () =>
  useMutation<DeleteRecurrencyForTherapistEmergencyMutation, DeleteRecurrencyForTherapistEmergencyMutationVariables>(
    MUTATION,
  )
