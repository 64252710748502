import { Flex, media } from 'cdk'
import { COLOR_PRIMARY } from 'design-tokens'
import styled from 'styled-components'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { DashedLine } from '~/domains/referral/components/DashedLine'

const Name = styled(Flex)`
  border-top: 1px solid ${COLOR_PRIMARY};
  border-bottom: 1px solid ${COLOR_PRIMARY};
  border-left: 1px solid ${COLOR_PRIMARY};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  ${media.gtSm`
    min-width: 160px;
  `}
`

const Description = styled(Flex)`
  border-top: 1px solid ${COLOR_PRIMARY};
  border-right: 1px solid ${COLOR_PRIMARY};
  border-bottom: 1px solid ${COLOR_PRIMARY};
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
`

export type ReferralCodeStatus = 'REQUESTED' | 'ACTIVE'
type Props = {
  status: ReferralCodeStatus
}

export const ReferralCode = ({ status }: Props) => (
  <Flex $backgroundColorName={status === 'REQUESTED' ? 'primary-10' : 'primary'} $borderRadius={16} $direction="row">
    <Name $justify="center" $px={16} $shrink={1}>
      <Text colorName={status === 'REQUESTED' ? 'primary' : 'white'} fontWeight="600" kind="caption" textAlign="center">
        <Translation id="referralCode.name.variant" />
      </Text>
    </Name>

    <DashedLine status={status} />

    <Description $grow={1} $justify="center" $px={16} $shrink={1}>
      <Text colorName={status === 'REQUESTED' ? 'black' : 'white'} kind="caption">
        <Translation id="referralCode.description.variant" />
      </Text>
    </Description>
  </Flex>
)
