import { Icon } from 'icons'
import { useCallback } from 'react'
import { Text, Tile } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { useTrackEvent } from '~/domains/analytics/hooks/useTrackEvent'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useToasts } from '~/hooks/useToasts'
import { Translation } from '../Translation'

const url = 'https://serenis.typeform.com/to/saulZaDK'

export const ServiceInfoCompanyReferral = () => {
  const trackClick = useTrackEvent('CLICK')
  const { addToast } = useToasts()
  const { id: userId } = useCurrentUser()

  const onClick = useCallback(async () => {
    try {
      trackClick({
        name: 'clicks.home.request-company-referral',
        payload: { userId },
      })

      window.open(url, '_blank')
    } catch (error) {
      addToast({
        translationId: 'generic.error.404',
        type: 'alert',
      })
    }
  }, [addToast, trackClick, userId])

  return (
    <Flex pt={16}>
      <Tile>
        <Flex mdAlign="center" mdDirection="row">
          <Flex align="flex-start" direction="row" grow={1} justify="center" mdAlign="center" shrink={1}>
            <Flex pr={8}>
              <Icon colorName="grey-80" name="badge-check" size={32} />
            </Flex>

            <Flex grow={1} shrink={1}>
              <Text fontWeight="600" kind="paragraph">
                <Translation id="home.patient.serviceInfo.companyReferral.title" />
              </Text>

              <Text fontWeight="400" kind="paragraph">
                <Translation id="home.patient.serviceInfo.companyReferral.description" />
              </Text>

              <Flex lgHide>
                <Pressable align="flex-start" colorName="purple08" onClick={onClick}>
                  <Text fontWeight="600" kind="paragraph" textDecoration="underline">
                    <Translation id="home.patient.serviceInfo.companyReferral.action" />
                  </Text>
                </Pressable>
              </Flex>
            </Flex>
          </Flex>

          <Flex hide lgDirection="row" lgHide={false}>
            <Flex lgGrow={1} lgShrink={1} pt={4}>
              <Text colorName="black" fontWeight="600" kind="paragraph">
                <Pressable colorName="purple08" onClick={onClick}>
                  <Text fontWeight="600" kind="paragraph" textDecoration="underline">
                    <Translation id="home.patient.serviceInfo.companyReferral.action" />
                  </Text>
                </Pressable>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Tile>
    </Flex>
  )
}
