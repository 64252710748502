import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useTherapyIdLocationParam } from '~/domains/changeTherapist/hooks/useTherapyIdLocationParam'
import {
  TherapySessionReassignByAlgoResolverMutation,
  TherapySessionReassignByAlgoResolverMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation therapySessionReassignByAlgoResolver($input: ReassignTherapySessionByAlgoInput!) {
    therapySessionReassignByAlgoResolver(input: $input) {
      ok
    }
  }
`

type Param = {
  startAt: Date
  therapistId: string
}

export const useReassignTherapySession = () => {
  const [reassignTherapySession] = useMutation<
    TherapySessionReassignByAlgoResolverMutation,
    TherapySessionReassignByAlgoResolverMutationVariables
  >(MUTATION)
  const therapyId = useTherapyIdLocationParam()

  return useCallback(
    ({ startAt, therapistId }: Param) => {
      if (!therapyId) {
        return
      }

      return reassignTherapySession({
        variables: {
          input: {
            churnReason: 'PENDING_CHANGE_THERAPIST',
            startAt,
            therapistId,
            therapyId,
          },
        },
      })
    },
    [reassignTherapySession, therapyId],
  )
}
