import { useEffect } from 'react'
import { useNavigation } from '../hooks/useNavigation'

const useNavigationHideNotificationsInboxEffect = () => {
  const { hideNotificationsInbox } = useNavigation()

  useEffect(() => {
    hideNotificationsInbox()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const NavigationHideNotificationsInboxEffect = () => {
  useNavigationHideNotificationsInboxEffect()

  return null
}
