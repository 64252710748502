import { Icon } from 'icons'
import { useCallback } from 'react'
import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { TherapistCalendarProvider } from '~/domains/availabilities/calendar/hooks/useTherapistCalendar'
import { useModals } from '~/domains/modals'
import { RescheduleTherapySessionModals } from '~/domains/therapy-session/components/RescheduleTherapySessionModals'
import { isBeforeCancelationPolicyLimit } from '~/utils/dates/isBeforeCancelationPolicyLimit'
import { isTherapySessionSuccessfullyPaid } from '~/utils/dates/isTherapySessionSuccessfullyPaid'

type Props = Pick<
  TherapistAgendaTherapySession,
  'duration' | 'id' | 'patient' | 'recurrency' | 'startAt' | 'status' | 'therapyPathType' | 'typeCost'
>

export const RescheduleTherapySession = ({
  duration,
  id,
  patient,
  recurrency,
  startAt,
  status,
  therapyPathType,
  typeCost,
}: Props) => {
  const { open } = useModals()

  const onClick = useCallback(() => {
    if (
      isTherapySessionSuccessfullyPaid({ status, typeCost }) ||
      (typeCost === 'FREE' && !isBeforeCancelationPolicyLimit(startAt))
    ) {
      open(`rescheduleTherapySessionWarning:${id}`)

      return
    }

    open(`rescheduleTherapySession:${id}`)
  }, [id, open, startAt, status, typeCost])

  return (
    <>
      <Pressable autoWidth onClick={onClick}>
        <Flex align="center" direction="row" justify="flex-start">
          <Flex basis="28px" pr={8}>
            <Icon colorName="black" name="calendar" size={20} />
          </Flex>

          <Text fontWeight="400" kind="caption">
            <Translation id="actions.rescheduleTherapySession" />
          </Text>
        </Flex>
      </Pressable>

      <TherapistCalendarProvider>
        <RescheduleTherapySessionModals
          duration={duration}
          id={id}
          initialStartAt={startAt}
          patientFullName={patient.fullName}
          patientId={patient.id}
          recurrency={recurrency || false}
          status={status}
          therapyPathType={therapyPathType}
          typeCost={typeCost || 'FREE'}
        />
      </TherapistCalendarProvider>
    </>
  )
}
