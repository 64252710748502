import { NavigationHideAppBarEffect } from '~/domains/navigation/components/NavigationHideAppBarEffect'
import { NavigationHideBackButtonEffect } from '~/domains/navigation/components/NavigationHideBackButtonEffect'
import { NavigationHideNotificationsInboxEffect } from '~/domains/navigation/components/NavigationHideNotificationsInboxEffect'
import { NavigationShowAppBarEffect } from '~/domains/navigation/components/NavigationShowAppBarEffect'
import { NavigationShowBackButtonEffect } from '~/domains/navigation/components/NavigationShowBackButtonEffect'
import { NavigationShowNotificationsInboxEffect } from '~/domains/navigation/components/NavigationShowNotificationsInboxEffect'
import { Page } from '~/routes/types'

type Props = Pick<Page['options'], 'isVisibleAppBar' | 'isVisibleBackButton' | 'isVisibleNotificationsInbox'>

export const PageAppBarEffect = ({ isVisibleAppBar, isVisibleBackButton, isVisibleNotificationsInbox }: Props) => (
  <>
    {isVisibleAppBar && <NavigationShowAppBarEffect />}
    {!isVisibleAppBar && <NavigationHideAppBarEffect />}

    {isVisibleBackButton && <NavigationShowBackButtonEffect />}
    {!isVisibleBackButton && <NavigationHideBackButtonEffect />}

    {isVisibleNotificationsInbox && <NavigationShowNotificationsInboxEffect />}
    {!isVisibleNotificationsInbox && <NavigationHideNotificationsInboxEffect />}
  </>
)
