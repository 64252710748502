import { PositionFixed } from 'cdk'
import styled, { keyframes } from 'styled-components'
import { TrustpilotWidgetFloating } from './TrustpilotWidgetFloating'

type Props = {
  isVisible: boolean
}

type Attrs = {
  $isVisible: boolean
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
    pointer-events: none;
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
    pointer-events: none;
  }
`

const StyledFloatingButton = styled(PositionFixed).attrs({
  $backgroundColorName: 'white',
  $bottom: 0,
  $elevationName: 'xs',
  $left: 0,
  $lgHide: true,
  $pb: 8,
  $right: 0,
  $zIndex: 1000,
  $px: 16,
})<Attrs>`
  animation: ${({ $isVisible }) => ($isVisible ? fadeIn : fadeOut)} 0.5s forwards;
`

export const FloatingButton = ({ isVisible }: Props) => (
  <StyledFloatingButton $isVisible={isVisible}>
    <TrustpilotWidgetFloating />
  </StyledFloatingButton>
)
