import { Chip } from 'ui'
import { Flex } from 'ui-deprecated'
import { ChipBonusPsicologo } from '~/components/ChipBonusPsicologo'
import { Translation } from '~/components/Translation'
import { AppointmentDetails } from '../components/AppointmentDetails'
import { ConnectToLiveButton } from '../components/ConnectToLiveButton'
import { ContentSkeleton } from '../components/ContentSkeleton'
import { Price } from '../components/Price'

type Props = {
  bonus: boolean
  isVeryNextTherapySession?: boolean
}

export const ConfirmedAppointmentContent = ({ bonus = false, isVeryNextTherapySession = false }: Props) => (
  <ContentSkeleton
    bottomChildren={<ConnectToLiveButton isVeryNextTherapySession={isVeryNextTherapySession} />}
    topEndChildren={<Price />}
    topStartChildren={
      <Flex align="center" direction="row" wrap="wrap">
        <Chip kind="success">
          <Translation id="therapySession.patient.confirmed.statusValues" />
        </Chip>
        {!!bonus && (
          <Flex pl={8}>
            <ChipBonusPsicologo />
          </Flex>
        )}
      </Flex>
    }
  >
    <AppointmentDetails canManage />
  </ContentSkeleton>
)
