import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { TertiaryButtonIconLeft } from '~/components/TertiaryButton/TertiaryButtonIconLeft'
import { Translation } from '~/components/Translation'
import { usePhoneVerificationContext } from '~/domains/phoneNumbers/hooks/usePhoneVerificationContext'
import { useReservationFlow } from '~/domains/reservation/hooks/useReservationFlow'
import { ReservationFormValues } from '../types'

export const GoPreviousButton = () => {
  const { formState } = useFormContext<ReservationFormValues>()
  const { loading, sending } = usePhoneVerificationContext()
  const { loading: reservationFlowLoading } = useReservationFlow()
  const history = useHistory()

  const onClick = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <TertiaryButtonIconLeft
      disabled={loading || reservationFlowLoading || sending || formState.isSubmitting}
      iconName="arrow-left"
      onClick={onClick}
      size="sm"
      tabIndex={-1}
    >
      <Translation id="actions.goBack" />
    </TertiaryButtonIconLeft>
  )
}
