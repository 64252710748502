import { gql, useMutation } from '@apollo/client'
import { ChangeTherapistCancellationMutation, ChangeTherapistCancellationMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation changeTherapistCancellation($input: ChangeTherapistCancellationInput!) {
    changeTherapistCancellation(input: $input) {
      ok
    }
  }
`

export const useChangeTherapistCancellation = () =>
  useMutation<ChangeTherapistCancellationMutation, ChangeTherapistCancellationMutationVariables>(MUTATION)
