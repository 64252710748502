import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { useDeleteForTherapistEmergencyOnSubmit } from '~/components/TherapySessionCardTherapist/components/DeleteForTherapistEmergency/hooks/useDeleteForTherapistEmergencyOnSubmit'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { ReactHookFormRadioField, useReactHookFormContext } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { TranslationId } from '~/i18n/types'
import { DeletedReason } from '~/types/graphql'
import { isBeforeCancelationPolicyLimit } from '~/utils/dates/isBeforeCancelationPolicyLimit'
import { DeleteTherapySessionChurnReasonField } from '../../DeleteTherapySession/components/DeleteTherapySessionChurnReasonField'
import { FormValues } from '../types/formValues'
import { getDeleteTherapySessionRoute } from '../utils/getDeleteTherapySessionRoute'
import { DateInfoBox } from './DateInfoBox'

type Props = {
  endAt: Date
  id: string
  startAt: Date
}

type OptionTranslationId = Extract<
  TranslationId,
  | 'therapySession.deleteReason.patient.churn'
  | 'therapySession.deleteReason.patient.other'
  | 'therapySession.deleteReason.patient.pendingReschedule'
  | 'therapySession.deleteReason.patient.schedulingIssue'
>

type Option = {
  id: OptionTranslationId
  value: DeletedReason
}

const deleteReasons: Option[] = [
  { id: 'therapySession.deleteReason.patient.pendingReschedule', value: 'PENDING_RESCHEDULE' },
  { id: 'therapySession.deleteReason.patient.schedulingIssue', value: 'SCHEDULING_ISSUE' },
  { id: 'therapySession.deleteReason.patient.churn', value: 'CHURN' },
  { id: 'therapySession.deleteReason.patient.other', value: 'OTHER' },
]

const Label = styled(Text)`
  line-height: 100%;
`

export const StepReason = ({ endAt, id, startAt }: Props) => {
  const { loading } = useDeleteForTherapistEmergencyOnSubmit()
  const history = useHistory()
  const form = useReactHookFormContext<FormValues>()
  const modalId = `deleteTherapySession:${id}` as const

  const {
    errors: { required },
  } = useFormTranslations()

  const { watch } = useReactHookFormContext<FormValues>()

  const deletedType = watch('deletedType')
  const deletedReason = watch('deletedReason')

  const onSubmit = useCallback(() => {
    history.replace(getDeleteTherapySessionRoute('/message'))
  }, [history])

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <ModalCloseButton id={modalId} />

      <OverflowAuto>
        <Text fontWeight="600" kind="h3">
          <Translation
            id={
              deletedType === 'DELETE_SINGLE_THERAPY_SESSION'
                ? 'therapySession.delete.therapySessionReasonModal.title'
                : 'therapySession.delete.therapySessionsReasonModal.title'
            }
          />
        </Text>

        {!isBeforeCancelationPolicyLimit(startAt) && (
          <TranslationMarkdown colorName="black" id="therapySession.delete.reason.subtitle" kind="paragraph" pt={16} />
        )}

        {deletedType === 'DELETE_SINGLE_THERAPY_SESSION' && (
          <Flex pt={32}>
            <DateInfoBox endAt={endAt} startAt={startAt} />
          </Flex>
        )}

        <Flex pt={32}>
          <Text fontWeight="600" kind="paragraph">
            <Translation id="therapySession.delete.therapySessionReasonModal.description" />
          </Text>
        </Flex>

        {deleteReasons.map(({ id, value }) => (
          <Flex key={id} as="label" pt={16}>
            <ReactHookFormRadioField name="deletedReason" rules={{ required }} value={value}>
              <Flex pt={4} shrink={1}>
                <Label fontWeight="400" kind="paragraph">
                  <Translation id={id} />
                </Label>
              </Flex>
            </ReactHookFormRadioField>

            {deletedReason === 'CHURN' && value === 'CHURN' && (
              <Flex pb={8} pt={16}>
                <DeleteTherapySessionChurnReasonField />
              </Flex>
            )}
          </Flex>
        ))}
      </OverflowAuto>

      <Flex pt={32}>
        <Button disabled={!form.formState.isValid} kind="primary" loading={loading} type="submit">
          <Translation id="therapySession.delete.proceed" />
        </Button>
      </Flex>
    </Form>
  )
}
