import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'
import { JournalingProvider } from '../journaling/hooks/useJournalingContext'

const LazyJournalingLogsPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.JournalingLogsPageView,
})

export const JournalingLogsPage: Page = {
  exact: true,
  id: 'journaling.logs',
  paths: ['/journaling/logs'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'journaling.logs',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: JournalingProvider,
  PageView: () => <LazyJournalingLogsPageView />,
}
