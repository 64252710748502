import { gql, useMutation } from '@apollo/client'
import { StopTherapySessionMutation, StopTherapySessionMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation stopTherapySession($input: StopTherapySessionInput!) {
    stopTherapySession(input: $input) {
      id
    }
  }
`

export const useStopTherapySession = () =>
  useMutation<StopTherapySessionMutation, StopTherapySessionMutationVariables>(MUTATION)
