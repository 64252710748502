import { BREAKPOINT_LG_VALUE, BREAKPOINT_MD_VALUE, BREAKPOINT_SM_VALUE, BREAKPOINT_XL_VALUE } from 'design-tokens'
import { useEffect, useState } from 'react'

const getInitialBreakpoints = () => {
  const width = window.innerWidth

  return {
    isExtraLarge: width >= parseInt(BREAKPOINT_XL_VALUE),
    isLarge: width >= parseInt(BREAKPOINT_LG_VALUE),
    isLargeOnly: width >= parseInt(BREAKPOINT_LG_VALUE) && width < parseInt(BREAKPOINT_XL_VALUE),
    isMedium: width >= parseInt(BREAKPOINT_MD_VALUE),
    isMediumOnly: width >= parseInt(BREAKPOINT_MD_VALUE) && width < parseInt(BREAKPOINT_LG_VALUE),
    isSmall: width > parseInt(BREAKPOINT_SM_VALUE),
    isSmallOnly: width > parseInt(BREAKPOINT_SM_VALUE) && width < parseInt(BREAKPOINT_MD_VALUE),
  }
}

export const useBreakpoints = () => {
  const [breakpoints, setBreakpoints] = useState(getInitialBreakpoints())

  useEffect(() => {
    const mediaQuerySmall = window.matchMedia(`(max-width: calc(${BREAKPOINT_MD_VALUE} - 1px))`)
    const mediaQueryMedium = window.matchMedia(
      `(min-width: ${BREAKPOINT_MD_VALUE}) and (max-width: calc(${BREAKPOINT_LG_VALUE} - 1px))`,
    )
    const mediaQueryLarge = window.matchMedia(
      `(min-width: ${BREAKPOINT_LG_VALUE}) and (max-width: calc(${BREAKPOINT_XL_VALUE} - 1px))`,
    )
    const mediaQueryExtraLarge = window.matchMedia(`(min-width: ${BREAKPOINT_XL_VALUE})`)

    const updateBreakpoints = () => {
      setBreakpoints({
        isSmall:
          mediaQuerySmall.matches ||
          mediaQueryMedium.matches ||
          mediaQueryLarge.matches ||
          mediaQueryExtraLarge.matches,
        isSmallOnly: mediaQuerySmall.matches,
        isMedium: mediaQueryMedium.matches || mediaQueryLarge.matches || mediaQueryExtraLarge.matches,
        isMediumOnly: mediaQueryMedium.matches,
        isLarge: mediaQueryLarge.matches || mediaQueryExtraLarge.matches,
        isLargeOnly: mediaQueryLarge.matches,
        isExtraLarge: mediaQueryExtraLarge.matches,
      })
    }

    mediaQuerySmall.addEventListener('change', updateBreakpoints)
    mediaQueryMedium.addEventListener('change', updateBreakpoints)
    mediaQueryLarge.addEventListener('change', updateBreakpoints)
    mediaQueryExtraLarge.addEventListener('change', updateBreakpoints)

    return () => {
      mediaQuerySmall.removeEventListener('change', updateBreakpoints)
      mediaQueryMedium.removeEventListener('change', updateBreakpoints)
      mediaQueryLarge.removeEventListener('change', updateBreakpoints)
      mediaQueryExtraLarge.removeEventListener('change', updateBreakpoints)
    }
  }, [])

  return breakpoints
}
