import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'

export const JournalingLogShareModalBookedFirst = () => {
  const { close } = useModals()

  const { therapy } = usePatientTherapies()

  const onCloseClick = useCallback(() => {
    close('journalingLogShareBookedFirst')
  }, [close])

  const therapistFullName = String(therapy?.therapist?.fullName)

  return (
    <Modal id="journalingLogShareBookedFirst">
      <ModalCloseButton id="journalingLogShareBookedFirst" />

      <OverflowAuto>
        <Text fontWeight="600" kind="h2">
          <Translation id="journaling.journalingLogShare.bookedFirst.title" />
        </Text>

        <TranslationMarkdown
          colorName="black"
          id="journaling.journalingLogShare.bookedFirst.text"
          kind="paragraph"
          pt={16}
          values={{ therapistFullName }}
        />
      </OverflowAuto>

      <Flex pt={16}>
        <Button kind="primary" onClick={onCloseClick}>
          <Translation id="journaling.journalingLogShare.bookedFirst.cta" />
        </Button>
      </Flex>
    </Modal>
  )
}
