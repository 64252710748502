import { isSameDay, isSunday } from 'date-fns/fp'
import { FormatDateEnum, format } from 'dates'
import { pipe } from 'fp-ts/function'
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useTrackEvent } from '~/domains/analytics/hooks/useTrackEvent'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { usePaymentMethods } from '~/domains/payments/hooks/usePaymentMethods'
import { getReservationRoutes } from '~/domains/reservation/utils/getReservationRoutes'
import { UserTherapist } from '~/domains/therapist/types'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useDataLayer } from '~/hooks/useDataLayer'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { useRootHistory, useRootLocation } from '~/hooks/useRootHistory'
import { useSuggestedTherapistsProfiles } from '~/hooks/useSuggestedTherapistsProfiles'
import { useToasts } from '~/hooks/useToasts'
import { getRoute } from '~/utils/getRoute'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'
import { hardcodedUnavailabilities } from '../constants'
import { useReservationRouteStrategy } from './useReservationRouteStrategy'
import { useTherapyPathById } from './useTherapyPathById'
import { useUserHasAttributionScoreAlertQuery } from './useUserHasAttributionScoreAlertQuery'

type Context = {
  addTherapist: () => boolean
  bookedTherapistId: string
  goBack: () => void
  goNext: () => void
  index: number
  loading: boolean
  onBookAndAssignTherapySession: (selectedTimeSlot: Date, therapistId: string | null) => Promise<void>
  onDayAndTimeSlotSelected: (selectedTimeSlot: Date) => void
  selectedTherapist?: UserTherapist
  suggestedTherapistsProfiles: UserTherapist[]
}

type Props = {
  children: ReactNode
  suggestedTherapistsIds: string[]
}

const toastId = 'booking-error'

const ReservationFlowContext = createContext<Context | null>(null)

export const ReservationFlowProvider = ({ children, suggestedTherapistsIds }: Props) => {
  const [loading, setLoading] = useState(false)

  const { id: userId, phoneNumberVerified } = useCurrentUser()
  const { fetchUserHasAttributionScoreAlert } = useUserHasAttributionScoreAlertQuery()

  const { isVariant, isVariant1 } = useFeatureFlagsByUserId()

  const shouldSkipPhoneNumberVerification = isVariant1('ff_skip_phone_number_verification')

  const { addToast } = useToasts()
  const bookOrReassignTherapySession = useReservationRouteStrategy()
  const dataLayer = useDataLayer()
  const history = useHistory()
  const rootHistory = useRootHistory()
  const trackFailure = useTrackEvent('FAILURE')

  const trackEventClick = useTrackEventClick()

  const { suggestedTherapistsProfiles, loading: suggestedTherapistsProfilesLoading } =
    useSuggestedTherapistsProfiles(suggestedTherapistsIds)

  const [index, setIndex] = useState(0)

  const [actualSuggested, setActualSuggested] = useState<UserTherapist[]>([])
  const [bookedTherapistId, setBookedTherapistId] = useState<string>('')

  useEffect(() => {
    if (!suggestedTherapistsProfilesLoading && suggestedTherapistsProfiles.length > 0 && actualSuggested.length === 0) {
      const previousSuggestedTherapistCount = pipe('suggested-therapist-count', getLocalStorage, Number) || 1

      const initialActualSuggested = suggestedTherapistsProfiles.slice(0, previousSuggestedTherapistCount)

      setActualSuggested(initialActualSuggested)
    }
  }, [actualSuggested.length, suggestedTherapistsProfiles, suggestedTherapistsProfilesLoading])

  const selectedTherapist = actualSuggested.at(index)

  const length = actualSuggested.length

  const { refetch: refetchPatientAgenda } = usePatientAgenda()

  const { items, loading: itemsLoading } = usePaymentMethods()

  const { pathname } = useRootLocation()

  const { therapyPath, loading: therapyPathLoading } = useTherapyPathById()

  const isPsychiatryPath = therapyPath.type === 'MYSELF_PSYCHIATRY'
  const isUnderagePath = therapyPath.type === 'UNDERAGE_PSYCHOTHERAPY'
  const isSexologyPath = therapyPath.type === 'PATH_SEXOLOGY'

  const isChangeTherapistBooking = !!matchPath(pathname, {
    path: getRoute('/change-therapist/:therapyId/booking'),
  })

  const onBookAndAssignTherapySession = useCallback(
    async (selectedTimeSlot: Date, therapistId: string | null) => {
      setLoading(true)

      const selectedTherapistId = therapistId ?? selectedTherapist?.id ?? null

      if (selectedTherapistId === null) {
        trackFailure({ name: 'failures.booking' })

        addToast({ id: toastId, translationId: 'reservation.checkVerification.genericError', type: 'alert' })

        return
      }

      /**
       * TODO (amin-khayam): should be therapyId instead of therapistId
       */

      try {
        setBookedTherapistId(selectedTherapistId)

        await bookOrReassignTherapySession({
          startAt: selectedTimeSlot,
          therapistId: selectedTherapistId,
        })

        dataLayer.add({ event: 'THERAPY_SESSION_BOOKED' }, 'therapy-session-booked')

        await refetchPatientAgenda()

        if (isVariant('ff_thank_you_page')) {
          history.push(getReservationRoutes('/thank-you-page'))
        } else {
          rootHistory.push(getRoute('/onboarding'))
        }
      } catch (error) {
        trackFailure({ name: 'failures.booking' })

        const { message } = error as unknown as {
          message: 'ASSIGNATION_CONFLICT' | 'THERAPY_ALREADY_EXISTENT'
        }

        switch (message) {
          case 'ASSIGNATION_CONFLICT': {
            addToast({ id: toastId, translationId: 'reservation.checkVerification.assignationConflict', type: 'alert' })

            history.push(getReservationRoutes('/book-by-suggested-therapist'))

            break
          }

          case 'THERAPY_ALREADY_EXISTENT': {
            addToast({
              id: toastId,
              translationId: 'reservation.checkVerification.therapyAlreadyExistent',
              type: 'info',
            })

            await refetchPatientAgenda()

            rootHistory.push(getRoute('/'))

            break
          }

          default:
            addToast({ id: toastId, translationId: 'reservation.checkVerification.genericError', type: 'alert' })

            break
        }
      } finally {
        setLoading(false)
      }
    },
    [
      addToast,
      bookOrReassignTherapySession,
      dataLayer,
      history,
      isVariant,
      refetchPatientAgenda,
      rootHistory,
      selectedTherapist,
      trackFailure,
    ],
  )

  const ffSkipCreditCardBookingVariant1 = isVariant1('ff_skip_credit_card_booking')
  const ffSCreditCardLeadScoringVariant1 = isVariant1('ff_credit_card_lead_scoring')

  const onDayAndTimeSlotSelected = useCallback(
    async (selectedTimeSlot: Date) => {
      if (phoneNumberVerified || shouldSkipPhoneNumberVerification) {
        if (ffSkipCreditCardBookingVariant1 || isChangeTherapistBooking) {
          history.push(getReservationRoutes('/submit'))

          return
        }

        if (!!items.length) {
          history.push(getReservationRoutes('/submit'))

          return
        }

        if (isPsychiatryPath || isUnderagePath || isSexologyPath) {
          history.push(getReservationRoutes('/insert-billing-information'))

          return
        }

        const isHardcodedUnavailability = hardcodedUnavailabilities.some((unavailableTimeSlot) =>
          isSameDay(selectedTimeSlot, unavailableTimeSlot),
        )

        const isFestive = pipe(selectedTimeSlot, isSunday)

        if (isHardcodedUnavailability || isFestive) {
          trackEventClick('reservation.hardcoded-unavailability-selected', {
            timeslot: pipe(selectedTimeSlot, format(FormatDateEnum.ATOM)),
            userId,
          })

          history.push(getReservationRoutes('/insert-billing-information'))

          return
        }

        try {
          const userHasAttributionScoreAlertResponse = await fetchUserHasAttributionScoreAlert()

          if (!!userHasAttributionScoreAlertResponse?.data?.userHasAttributionScoreAlert) {
            if (ffSCreditCardLeadScoringVariant1) {
              history.push(getReservationRoutes('/submit'))

              return
            }

            history.push(getReservationRoutes('/insert-billing-information'))

            return
          }
        } catch (error) {}

        history.push(getReservationRoutes('/submit'))

        return
      }

      history.push(getReservationRoutes('/send-verification-code'))
    },
    [
      fetchUserHasAttributionScoreAlert,
      ffSCreditCardLeadScoringVariant1,
      ffSkipCreditCardBookingVariant1,
      history,
      isChangeTherapistBooking,
      isPsychiatryPath,
      isSexologyPath,
      isUnderagePath,
      items.length,
      phoneNumberVerified,
      shouldSkipPhoneNumberVerification,
      trackEventClick,
      userId,
    ],
  )

  const goNext = useCallback(() => {
    setIndex((i) => (i + 1 > length - 1 ? 0 : i + 1))
  }, [length])

  const goBack = useCallback(() => {
    setIndex((i) => (i - 1 < 0 ? length - 1 : i - 1))
  }, [length])

  const addTherapist = useCallback(() => {
    if (suggestedTherapistsProfiles.length === actualSuggested.length) {
      return false
    }

    const nextTherapist = suggestedTherapistsProfiles.find(
      (profile) => !actualSuggested.find((therapist) => therapist.id === profile.id),
    )

    if (!nextTherapist) {
      return false
    }

    const actualSuggestedWithNext = [...actualSuggested, nextTherapist]

    setActualSuggested(actualSuggestedWithNext)

    setIndex(actualSuggestedWithNext.length - 1)

    setLocalStorage('suggested-therapist-count', actualSuggestedWithNext.length)

    return true
  }, [actualSuggested, suggestedTherapistsProfiles])

  const value = {
    addTherapist,
    bookedTherapistId,
    goBack,
    goNext,
    index,
    loading: itemsLoading || loading || suggestedTherapistsProfilesLoading || therapyPathLoading,
    onBookAndAssignTherapySession,
    onDayAndTimeSlotSelected,
    selectedTherapist,
    suggestedTherapistsProfiles: actualSuggested ?? [],
  }

  if (value.loading || (!!suggestedTherapistsProfiles.length && !value.selectedTherapist)) {
    return <CenteredLoader />
  }

  return <ReservationFlowContext.Provider value={value}>{children}</ReservationFlowContext.Provider>
}

export const useReservationFlow = () => {
  const context = useContext(ReservationFlowContext)

  if (!context) {
    throw new Error('The `useReservationFlow` should be wrapped with `ReservationFlowProvider`.')
  }

  return context
}
