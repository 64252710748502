import { useParams } from 'react-router-dom'

type Params = {
  prefilledConceptIdOrName?: string
}

export const usePrefilledConceptIdOrName = (): string | null => {
  const { prefilledConceptIdOrName } = useParams<Params>()

  return prefilledConceptIdOrName || null
}
