import styled from 'styled-components'
import { Flex, FlexProps } from 'ui-deprecated'

export const MenuVerticalWrapperFullScreen = styled(Flex)<FlexProps>`
  position: fixed;
  inset: 0 0 0 78px;
  z-index: 100;
`

MenuVerticalWrapperFullScreen.displayName = 'MenuVerticalWrapperFullScreen'
