import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertTherapist } from '~/domains/auth/components/AssertTherapist'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

const LazyProfilePageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.ProfilePageView,
})

export const ProfilePage: Page = {
  exact: true,
  id: 'profile',
  paths: ['/profile'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'settings',
    title: 'settings.title',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertTherapist target="/">{children}</AssertTherapist>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyProfilePageView />,
}
