import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { AssertPatientTypeCouples } from '~/domains/onboardingCouples/components/AssertPatientTypeCouples'
import { AssertWithoutPartner } from '~/domains/onboardingCouples/components/AssertWithoutPartner'
import { OnboardingCouplesPageView } from '~/domains/onboardingCouples/routes/OnboardingCouplesPageView'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

export const OnboardingCouplesPage: Page = {
  exact: true,
  id: 'onboarding.couples',
  paths: ['/onboarding/couples'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: false,
    seoKey: 'onboarding.couples',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">
        <PatientTherapiesProvider>
          <AssertPatientTypeCouples>
            <AssertWithoutPartner>{children}</AssertWithoutPartner>
          </AssertPatientTypeCouples>
        </PatientTherapiesProvider>
      </AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: OnboardingCouplesPageView,
}
