import { Flex, OverflowAuto, Space } from 'cdk'
import { Icon } from 'icons'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Link, Text } from 'ui'
import { TextFieldNew } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { getRoute } from '~/utils/getRoute'
import { useTimer } from '../../../hooks/useTimer'
import { useEmailVerification } from '../hooks/useEmailVerification'
import { getEmailVerificationRoute } from '../utils/getEmailVerificationRoute'

const RETRY_WAIT_TIME_SEC = 30
const CODE_LENGTH = 6

export const EmailVerificationModalDefaultRoute = () => {
  const history = useHistory()
  const { id: userId, email } = useCurrentUser()
  const { requestVerification, verifyCode, loading } = useEmailVerification()

  const { seconds: retryWaitTime, startTimer: startRetryWaitTime } = useTimer(RETRY_WAIT_TIME_SEC)
  const [invalid, setInvalid] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')

  const verifyEmail = useCallback(
    async (userId: string, code: string) => {
      const result = await verifyCode(userId, code)
      if (result === false) {
        setInvalid(true)
        return
      }

      history.push(getRoute('/booking'))
    },
    [verifyCode, history],
  )

  const onRetry = useCallback(async () => {
    startRetryWaitTime()
    await requestVerification(email)
  }, [email, requestVerification, startRetryWaitTime])

  const onEdit = useCallback(() => {
    history.push(getEmailVerificationRoute('/edit'))
  }, [history])

  const onClear = useCallback(() => {
    setVerificationCode('')
    setInvalid(false)
  }, [])

  const onChange = useCallback(
    async (code: string) => {
      if (code.length <= CODE_LENGTH) {
        setInvalid(false)
        setVerificationCode(code)
      }

      if (code.length !== CODE_LENGTH) {
        return
      }

      await verifyEmail(userId, code)
    },
    [userId, verifyEmail],
  )

  return (
    <OverflowAuto>
      <Text fontWeight="600" kind="h2">
        <Translation id="emailVerification.default.title" />
      </Text>

      <Flex $pt={16}>
        <TranslationMarkdown
          colorName="black"
          id="emailVerification.default.description1"
          kind="paragraph"
          values={{ email }}
        />
      </Flex>

      <Flex $align="center" $direction="row" $gap={10} $pt={16}>
        <Flex $grow={1} $justify="space-between">
          <Flex>
            <TextFieldNew
              autoComplete="off"
              disabled={loading}
              invalid={invalid}
              label={useTranslation('emailVerification.default.code.label')}
              name="verificationCode"
              onChange={onChange}
              placeholder={useTranslation('emailVerification.default.code.placeholder')}
              size="small"
              type="number"
              value={verificationCode}
            />
          </Flex>
        </Flex>

        {verificationCode.length > 0 ? (
          <Flex>
            <Button isRound={true} kind="secondary" onClick={onClear} size="xs">
              <Icon name="xmark" size={20} />
            </Button>
          </Flex>
        ) : (
          <Flex $maxWidth={28} $minWidth={28} />
        )}
      </Flex>

      {invalid && (
        <Flex $pt={4}>
          <Text colorName="red-80" fontWeight="400" kind="caption">
            <Translation id="emailVerification.default.code.error" />
          </Text>
        </Flex>
      )}

      <Flex $direction="row" $pt={32}>
        <Text kind="caption">
          <Translation id="emailVerification.default.retry.title" />
          <Space />
          {retryWaitTime == 0 && (
            <Link onClick={onRetry}>
              <Text kind="caption-strong" textDecoration="underline">
                <Translation id="emailVerification.default.retry.action" />
              </Text>
            </Link>
          )}
          {retryWaitTime > 0 && (
            <Text kind="caption-strong">
              <Translation id="emailVerification.default.retry.wait" values={{ count: retryWaitTime }} />
            </Text>
          )}
        </Text>
      </Flex>

      <Flex $direction="row" $pt={8}>
        <Text kind="caption">
          <Translation id="emailVerification.default.edit.title" />
          <Space />
          <Link onClick={onEdit}>
            <Text kind="caption-strong" textDecoration="underline">
              <Translation id="emailVerification.default.edit.action" />
            </Text>
          </Link>
        </Text>
      </Flex>
    </OverflowAuto>
  )
}
