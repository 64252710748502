import { isOfExactType } from 'functions'
import { useCallback, useEffect } from 'react'
import { CreateUserDeviceInput, DeleteUserDeviceInput } from '~/types/graphql'
import { useCreateUserDevice } from './useCreateUserDevice'
import { useDeleteUserDevice } from './useDeleteUserDevice'
import { useIsNativeApp } from './useIsNativeApp'
import { useIsNativeAppAndroid } from './useIsNativeAppAndroid'
import { ThirdPartyApp, useThirdPartyApp } from './useThirdPartyApp'

const PUSH_NOTIFICATION_TOKEN_REQUEST = {
  type: 'PUSH_NOTIFICATION_TOKEN_REQUEST',
}

const PUSH_NOTIFICATION_TOKEN_DELETE = {
  type: 'PUSH_NOTIFICATION_TOKEN_DELETE',
}

const PUSH_NOTIFICATION_TOKEN_RESPONSE = {
  type: 'PUSH_NOTIFICATION_TOKEN_RESPONSE',
}

const PUSH_NOTIFICATION_DELETE_TOKEN_REQUEST = {
  type: 'PUSH_NOTIFICATION_DELETE_TOKEN_REQUEST',
}

const WEBVIEW_DISMISS_AUTH_SESSION = {
  type: 'WEBVIEW_DISMISS_AUTH_SESSION',
}

const CAMERA_PERMISSIONS_REQUEST = {
  type: 'CAMERA_PERMISSIONS_REQUEST',
}

const SHARE_REQUEST = {
  type: 'SHARE_REQUEST',
}

const IS_THIRD_PARTY_APP_INSTALLED_REQUEST = {
  type: 'IS_THIRD_PARTY_APP_INSTALLED_REQUEST',
}

const IS_THIRD_PARTY_APP_INSTALLED_RESPONSE = {
  type: 'IS_THIRD_PARTY_APP_INSTALLED_RESPONSE',
}

const COPY_TO_CLIPBOARD_ANDROID = {
  type: 'COPY_TO_CLIPBOARD_ANDROID',
}

export const useReactNativePostMessage = () => {
  const isNativeApp = useIsNativeApp()

  const isNativeAppAndroid = useIsNativeAppAndroid()

  const [createUserDevice] = useCreateUserDevice()

  const [deleteUserDevice] = useDeleteUserDevice()

  const { setIsInstalled } = useThirdPartyApp()

  const postMessage = (data: string) => {
    window.ReactNativeWebView.postMessage(data)
  }

  const dismissAuthSession = useCallback(() => {
    if (!isNativeApp) {
      return
    }

    try {
      postMessage(JSON.stringify(WEBVIEW_DISMISS_AUTH_SESSION))
    } catch (error) {}
  }, [isNativeApp])

  const requestCameraPermissions = useCallback(() => {
    if (!isNativeApp) {
      return
    }

    try {
      postMessage(JSON.stringify(CAMERA_PERMISSIONS_REQUEST))
    } catch (error) {}
  }, [isNativeApp])

  const requestPushNotificationToken = useCallback(() => {
    if (!isNativeApp) {
      return
    }

    try {
      postMessage(JSON.stringify(PUSH_NOTIFICATION_TOKEN_REQUEST))
    } catch (error) {}
  }, [isNativeApp])

  const requestPushNotificationTokenDelete = useCallback(() => {
    if (!isNativeApp) {
      return
    }

    try {
      postMessage(JSON.stringify(PUSH_NOTIFICATION_TOKEN_DELETE))
    } catch (error) {}
  }, [isNativeApp])

  const requestShare = useCallback(
    (sticker: string, app: string | null) => {
      if (!isNativeApp) {
        return
      }

      try {
        postMessage(JSON.stringify({ ...SHARE_REQUEST, sticker, app }))
      } catch (error) {}
    },
    [isNativeApp],
  )

  const requestIsThirdPartyAppInstalled = useCallback(
    (app: ThirdPartyApp) => {
      if (!isNativeApp) {
        return
      }

      try {
        postMessage(JSON.stringify({ ...IS_THIRD_PARTY_APP_INSTALLED_REQUEST, app }))
      } catch (error) {}
    },
    [isNativeApp],
  )

  const copyToClipboardAndroid = useCallback(
    (text: string) => {
      if (!isNativeAppAndroid) {
        return
      }

      try {
        postMessage(JSON.stringify({ ...COPY_TO_CLIPBOARD_ANDROID, text }))
      } catch (error) {}
    },
    [isNativeAppAndroid],
  )

  const onMessage = useCallback(
    async ({ data }: any) => {
      const input = data.payload

      if (data.type === PUSH_NOTIFICATION_TOKEN_RESPONSE.type && isOfExactType<CreateUserDeviceInput>(input)) {
        try {
          await createUserDevice({
            variables: {
              input: {
                deviceId: input.deviceId,
                os: input.os,
                token: input.token,
              },
            },
          })
        } catch (error) {}

        return
      }

      if (data.type === PUSH_NOTIFICATION_DELETE_TOKEN_REQUEST.type && isOfExactType<DeleteUserDeviceInput>(input)) {
        try {
          await deleteUserDevice({
            variables: {
              input: {
                deviceId: input.deviceId,
              },
            },
          })
        } catch (error) {}

        return
      }

      if (data.type === IS_THIRD_PARTY_APP_INSTALLED_RESPONSE.type) {
        setIsInstalled(input.app, input.isInstalled)
      }
    },
    [createUserDevice, deleteUserDevice, setIsInstalled],
  )

  useEffect(() => {
    window.addEventListener('message', onMessage, true)
    document.addEventListener('message', onMessage, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    copyToClipboardAndroid,
    dismissAuthSession,
    requestCameraPermissions,
    requestPushNotificationTokenDelete,
    requestPushNotificationToken,
    requestShare,
    requestIsThirdPartyAppInstalled,
  }
}
