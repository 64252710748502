import { isWithinInterval } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'

type Param = {
  endAt: Date
  startAt: Date
}

export const isLiveTherapySession = ({ endAt, startAt }: Param) =>
  pipe(
    nowInMilliseconds(),
    isWithinInterval({
      end: endAt,
      start: startAt,
    }),
  )
