import { gql, useMutation } from '@apollo/client'
import { CreateSetupIntentMutation, CreateSetupIntentMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation createSetupIntent {
    createSetupIntent {
      client_secret
      id
    }
  }
`

export const useCreateSetupIntent = () =>
  useMutation<CreateSetupIntentMutation, CreateSetupIntentMutationVariables>(MUTATION)
