import { addSeconds, isBefore } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { getTherapySessionCancellationPolicyInSeconds } from '~/utils/getTherapySessionCancellationPolicyInSeconds'

/**
 * @description Passing `null` is intentional as the cancellation policy still remains 24 hours
 */
export const isBeforeCancelationPolicyLimit = (date: Date) =>
  pipe(nowInMilliseconds(), addSeconds(getTherapySessionCancellationPolicyInSeconds(null)), isBefore(date))
