import { Icon } from 'icons'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { MenuExternalLink } from '~/domains/navigation/TabNavigation/components/MenuExternalLink'

export const MenuItemEmergency = () => (
  <MenuExternalLink to="https://www.serenis.it/hai-una-emergenza">
    <Flex pr={12}>
      <Icon colorName="grey-60" name="warning-circle" size={24} />
    </Flex>

    <Flex grow={1} pt={2} shrink={1}>
      <Text fontWeight="400" kind="paragraph">
        <Translation id="actions.emergency" />
      </Text>
    </Flex>
  </MenuExternalLink>
)
