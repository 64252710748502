import { gql, useMutation } from '@apollo/client'
import { useIntl } from '~/i18n/hooks/useIntl'
import { CreateChildFormFlowResponseMutation, CreateChildFormFlowResponseMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation createChildFormFlowResponse($input: CreateChildFormFlowResponseInput!) {
    createChildFormFlowResponse(input: $input) {
      ok
    }
  }
`

export const useCreateChildFormFlow = () => {
  const { timeZone } = useIntl()

  return useMutation<CreateChildFormFlowResponseMutation, CreateChildFormFlowResponseMutationVariables>(MUTATION, {
    variables: {
      input: {
        timeZone,
      },
    },
  })
}
