import { useTherapistTherapies } from '~/domains/therapies/TherapistTherapiesProvider'
import { usePatientId } from '~/hooks/usePatientId'
import { TherapyTherapyPathType } from '~/types/graphql'

const enabledTherapyPathTypes: TherapyTherapyPathType[] = [
  'MYSELF_PSYCHIATRY',
  'MYSELF_PSYCHOTHERAPY',
  'UNDERAGE_PSYCHOTHERAPY',
]

export const useIsClinicalTestEnabledByTherapyPathType = (therapyPathType?: TherapyTherapyPathType | null) => {
  if (!therapyPathType) {
    return false
  }

  return enabledTherapyPathTypes.includes(therapyPathType)
}

export const useIsClinicalTestEnabledByPatientId = () => {
  const { therapies } = useTherapistTherapies()
  const patientId = usePatientId()

  const therapyPathType = therapies.find((therapy) => therapy?.patient?.id === patientId)?.therapyPath.type

  if (!therapyPathType) {
    return false
  }

  return enabledTherapyPathTypes.includes(therapyPathType)
}
