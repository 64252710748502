import { useContext } from 'react'
import { FeatureFlagsByFingerprintContext } from '~/domains/featureFlags/components/FeatureFlagsByFingerprint'
import { useFeatureFlagsClient } from '~/domains/featureFlags/hooks/useFeatureFlagsClient'

export const useFeatureFlagsByFingerprint = () => {
  const context = useContext(FeatureFlagsByFingerprintContext)

  const client = useFeatureFlagsClient(context)

  return client
}
