import { PositionAbsolute } from 'cdk'
import { COLOR_RED_60 } from 'design-tokens'
import styled from 'styled-components'

export const UnreadDot = styled(PositionAbsolute)`
  top: -2px;
  right: -2px;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-radius: 50%;
  background-color: ${COLOR_RED_60};
`
