import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import {
  FormValues,
  TherapistChurnReasonFields,
  TherapistChurnReasonFieldsProps,
} from '~/components/TherapistChurnReasonFields'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useReactHookFormContext } from '~/domains/react-hook-form'

type Props = {
  loading: boolean
  onSubmit: (values: FormValues) => Promise<void>
  patientFullName: string
}

const options: TherapistChurnReasonFieldsProps['options'] = [
  {
    availableFor: [],
    description: 'therapistChurnReasonModal.option.description.missingAuthorization',
    title: 'therapistChurnReasonModal.option.title.missingAuthorization',
    value: 'MISSING_AUTHORIZATION',
  },
  {
    availableFor: ['COACH', 'PSYCHOTHERAPIST'],
    description: 'therapistChurnReasonModal.option.description.schedulingIssue',
    title: 'therapistChurnReasonModal.option.title.schedulingIssue',
    value: 'SCHEDULING_ISSUE',
  },
  {
    availableFor: [],
    description: 'therapistChurnReasonModal.option.description.pendingReschedule',
    title: 'therapistChurnReasonModal.option.title.pendingReschedule',
    value: 'PENDING_RESCHEDULE',
  },
  {
    availableFor: ['COACH', 'PSYCHOTHERAPIST'],
    description: 'therapistChurnReasonModal.option.description.maintaining',
    title: 'therapistChurnReasonModal.option.title.maintaining',
    value: 'MAINTAINING',
  },
]

export const TherapistChurnReasonModalProceed = ({ loading = false, onSubmit, patientFullName }: Props) => {
  const form = useReactHookFormContext<FormValues>()

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <Text kind="paragraph">2 / 2</Text>
      <Flex py={8}>
        <Text fontWeight="600" kind="h2">
          <Translation id="therapistChurnReasonModal.proceed.title" />
        </Text>
      </Flex>
      <Flex pb={24}>
        <TranslationMarkdown
          colorName="black"
          id="therapistChurnReasonModal.proceed.subtitle"
          kind="paragraph"
          values={{ patientFullName }}
        />
      </Flex>

      <OverflowAuto>
        <TherapistChurnReasonFields options={options} />
      </OverflowAuto>

      <Flex pt={16}>
        <Button disabled={!form.formState.isValid} kind="primary" loading={loading} type="submit">
          <Translation id="actions.confirm" />
        </Button>
      </Flex>
    </Form>
  )
}
