import { groupByToEntries } from 'arrays'
import { addSeconds, isAfter } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { getCalendarAvailableTimeSlotFromAvailabilities } from '~/domains/availabilities/calendar/utils/getCalendarAvailableTimeSlotFromAvailabilities'
import { TherapyTherapyPathType } from '~/types/graphql'
import { getTherapySessionCancellationPolicyInSeconds } from '~/utils/getTherapySessionCancellationPolicyInSeconds'

export type Availability = {
  id: string
  endAt: Date
  startAt: Date
  userId: string
}

const groupAvailabilitiesByDayAndTherapist = (
  availabilitiesByDay: [string, Availability[]][],
): [day: string, availabilitiesByTherapist: [therapistId: string, availabilities: Availability[]][]][] =>
  availabilitiesByDay.map(([day, availabilities]) => [day, groupByToEntries(availabilities, ({ userId }) => userId)])

export const mapAvailabilitiesToSlotsByDayAndTherapist = (
  availabilitiesByDay: [string, Availability[]][],
  therapyPathType: TherapyTherapyPathType,
): [day: string, slotsByTherapist: [therapistId: string, slots: Date[]][]][] => {
  const availabilitiesByDayGroupedByTherapist = groupAvailabilitiesByDayAndTherapist(availabilitiesByDay)

  return availabilitiesByDayGroupedByTherapist
    .map(([day, availabilitiesByTherapist]) => {
      const mappedAvailabilities = availabilitiesByTherapist.map(([therapistId, availability]) => [
        therapistId,
        getCalendarAvailableTimeSlotFromAvailabilities(availability, therapyPathType).filter((date: number | Date) =>
          pipe(
            date,
            isAfter(
              pipe(nowInMilliseconds(), addSeconds(getTherapySessionCancellationPolicyInSeconds(therapyPathType))),
            ),
          ),
        ),
      ])

      return [day, mappedAvailabilities] as [day: string, slotsByTherapist: [therapistId: string, slots: Date[]][]]
    })
    .filter(([, slotsByTherapist]) => slotsByTherapist?.some(([, slots]) => !!slots.length))
}
