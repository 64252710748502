import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'

export const shouldOpenSentencesTutorial = () => {
  const sentencesTutorialViewedAt = getLocalStorage('sentences-tutorial-viewed-at')

  if (!sentencesTutorialViewedAt) {
    return true
  }

  return false
}
