import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { getRoute } from '~/utils/getRoute'
import { useGetLegalGuardians } from '../hooks/useGetLegalGuardians'
import { CreateLegalGuardiansSuggestion } from './CreateLegalGuardiansSuggestion'

export const CreateLegalGuardiansSuggestionBanner = () => {
  const { legalGuardians, loading } = useGetLegalGuardians()
  const history = useHistory()
  const { therapies } = usePatientTherapies()

  const underageTherapyId = therapies.find(
    ({ ongoing, therapyPath }) => ongoing && therapyPath?.type === 'UNDERAGE_PSYCHOTHERAPY',
  )?.id

  const onClick = useCallback(() => {
    if (!underageTherapyId) {
      history.push(getRoute('/'))

      return
    }

    history.push(getRoute(`/therapies/${underageTherapyId}/details`))
  }, [history, underageTherapyId])

  if (loading || !!legalGuardians?.length || !underageTherapyId) {
    return null
  }

  return <CreateLegalGuardiansSuggestion onClick={onClick} />
}
