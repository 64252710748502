import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { SignupHeroContentControl } from './SignupHeroContentControl'
import { SignupHeroContentVariant } from './SignupHeroContentVariant'
import { useSignupIntent } from '../hooks/useSignupIntent'
import { useConventionCodeName } from '~/domains/convention/hooks/useConventionCodeName'
import { usePartnershipQueryParam } from '~/hooks/usePartnershipQueryParam'
import { useIsUtmSource } from '~/hooks/useIsUtmSource'

export const SignupHeroContent = () => {
  const intent = useSignupIntent()
  const conventionCodeName = useConventionCodeName()
  const partnership = usePartnershipQueryParam()
  const { isVariant } = useFeatureFlagsByFingerprint()
  const isSignupMultivariantVariant = isVariant('ff_signup_multivariant')
  const isWellhubUtmSource = useIsUtmSource('wellhub-srns')

  const shouldIgnoreIntent = ['call', 'dca', 'journaling', 'nutritionist'].includes(intent)

  if (
    isSignupMultivariantVariant &&
    !shouldIgnoreIntent &&
    !conventionCodeName &&
    !partnership &&
    !isWellhubUtmSource
  ) {
    return <SignupHeroContentVariant />
  }

  return <SignupHeroContentControl />
}
