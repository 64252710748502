import { ReactNode } from 'react'
import { OverflowAuto, PageLayout } from 'ui-deprecated'

type Props = {
  children: ReactNode
}

export const StepOverflowAuto = ({ children }: Props) => (
  <OverflowAuto lgPt={40} mdPt={32} pt={24} px={16}>
    <PageLayout lgPb={40} maxWidth="640px" mdPb={32} pb={24} px={0} py={0}>
      {children}
    </PageLayout>
  </OverflowAuto>
)
