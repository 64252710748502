import { useIsNativeAppAndroid } from '~/domains/appNative/hooks/useIsNativeAppAndroid'
import { useIsNativeAppIOS } from '~/domains/appNative/hooks/useIsNativeAppIOS'
import { useNativeAppVersion } from '~/domains/appNative/hooks/useNativeAppVersion'

export const useReviewExtra = () => {
  const android = useIsNativeAppAndroid()
  const ios = useIsNativeAppIOS()
  const appVersion = useNativeAppVersion()

  return {
    android,
    ios,
    appVersion,
  }
}
