import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'

export const useAddUserToSentryEffect = () => {
  const { idTokenPayload, isAuthenticated } = useAuthState()

  useEffect(() => {
    if (isAuthenticated && idTokenPayload) {
      Sentry.setUser({ email: idTokenPayload.email, id: idTokenPayload.user_id })

      return
    }

    Sentry.setUser(null)
  }, [idTokenPayload, isAuthenticated])
}
