import { Icon } from 'icons'
import { memo } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { Badge, Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useChatUnreadCount } from '~/domains/chat/hooks/useChatUnreadCount'
import { MenuHorizontalItem } from '~/domains/navigation/TabNavigation/components/MenuHorizontalItem'
import { MenuVerticalItem } from '~/domains/navigation/TabNavigation/components/MenuVerticalItem'
import { useTabNavigationMenu } from '~/domains/navigation/TabNavigation/hooks/useTabNavigationMenu'
import { NavigationProps } from '~/domains/navigation/TabNavigation/types'
import { getRoute } from '~/utils/getRoute'

export const RouteChatMenuItem = memo(({ direction }: NavigationProps) => {
  const { unread } = useChatUnreadCount()
  const { pathname } = useLocation()
  const { open } = useTabNavigationMenu()

  const active = !!matchPath(pathname, {
    path: [getRoute('/chat')],
  })

  const Item = direction === 'horizontal' ? MenuHorizontalItem : MenuVerticalItem
  const itemActive = direction === 'horizontal' ? active && !open : active

  return (
    <Link data-test-id="tab-item-chat" to={getRoute('/chat')}>
      <Item active={itemActive}>
        <Flex basis="32px" direction="row" pb={8}>
          <Badge sup={unread}>
            <Icon colorName="white" name="chat-lines" size={24} />
          </Badge>
        </Flex>

        <Text fontWeight={active ? '600' : '400'} kind="footnote">
          <Translation id="menu.chat" />
        </Text>
      </Item>
    </Link>
  )
})

RouteChatMenuItem.displayName = 'RouteChatMenuItem'
