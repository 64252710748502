import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useToasts } from '~/hooks/useToasts'
import { getRoute } from '~/utils/getRoute'
import { useChangeTherapistCancellation } from '../hooks/useChangeTherapistCancellation'
import { useTherapyIdLocationParam } from '../hooks/useTherapyIdLocationParam'

export const ChangeTherapistLeaveModal = () => {
  const history = useRootHistory()
  const [changeTherapistCancellation] = useChangeTherapistCancellation()
  const { addToast } = useToasts()

  const therapyId = useTherapyIdLocationParam()

  const { close } = useModals()

  const handleOnClick = useCallback(async () => {
    try {
      await changeTherapistCancellation({
        variables: {
          input: {
            therapyId,
          },
        },
      })

      history.push(getRoute('/'))
    } catch {
      addToast({ type: 'alert', translationId: 'generic.error' })
    } finally {
      close('changeTherapistLeave')
    }
  }, [addToast, changeTherapistCancellation, close, history, therapyId])

  return (
    <Modal closeOnClickOutside={false} id="changeTherapistLeave">
      <ModalCloseButton id="changeTherapistLeave" />
      <Text fontWeight="600" kind="h1">
        <Translation id="leaveChangeTherapistModal.title" />
      </Text>
      <TranslationMarkdown id="leaveChangeTherapistModal.body" kind="paragraph" pt={16} />
      <Flex pt={24}>
        <Button kind="primary" onClick={handleOnClick} size="small">
          <Translation id="leaveChangeTherapistModal.cta" />
        </Button>
      </Flex>
    </Modal>
  )
}
