import { useFormState } from 'react-hook-form'
import { Button, Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { usePhoneVerificationContext } from '~/domains/phoneNumbers/hooks/usePhoneVerificationContext'
import { useReservationFlow } from '~/domains/reservation/hooks/useReservationFlow'

export const BookButton = () => {
  const formState = useFormState()
  const { loading } = usePhoneVerificationContext()
  const { loading: reservationFlowLoading } = useReservationFlow()

  return (
    <Button
      disabled={!formState.isValid}
      form="reservationFlow"
      kind="primary"
      loading={loading || reservationFlowLoading}
      size="small"
      testId="confirm-booking-cta"
      type="submit"
    >
      <Flex align="center" direction="row">
        <Translation id="actions.bookReservation" />
      </Flex>
    </Button>
  )
}
