import { Flex } from 'cdk'
import { SPACING_MD } from 'design-tokens'
import { AppBar } from '~/components/AppBar'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { Navigation } from '~/domains/navigation'
import { useNavigation } from '~/domains/navigation/hooks/useNavigation'
import { useBreakpoints } from '~/hooks/useBreakpoints'
import { ScrollRestoreProvider } from '~/hooks/useScrollRestore'
import { Pages } from '~/routes'

export const MainPageLayout = () => {
  const { isVisibleTabNavigation } = useNavigation()
  const { isVariant1 } = useFeatureFlagsByUserId()
  const useNewNavigation = isVariant1('ff_new_navigation')

  const { isLarge, isSmallOnly } = useBreakpoints()

  return (
    <Flex $direction="row" $gap={useNewNavigation && !isSmallOnly ? SPACING_MD : 0} $grow={1} $shrink={1}>
      {isVisibleTabNavigation && <Navigation direction="vertical" />}

      <Flex $grow={1} $pl={!useNewNavigation || isLarge || isSmallOnly || !isVisibleTabNavigation ? 0 : 80} $shrink={1}>
        <Flex $zIndex={1}>
          <AppBar />
        </Flex>

        <ScrollRestoreProvider grow={1} pb={0} shrink={1}>
          <Pages />
        </ScrollRestoreProvider>

        {isVisibleTabNavigation && <Navigation direction="horizontal" />}
      </Flex>
    </Flex>
  )
}
