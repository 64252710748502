import { useEffect } from 'react'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { ReservationFormValues } from '~/domains/reservation/types'

export const useRegisterSelectedTimeSlot = () => {
  const form = useReactHookFormContext<ReservationFormValues>()

  useEffect(() => {
    form.register('selectedTimeSlot', { required: true, valueAsDate: true })
    form.trigger('selectedTimeSlot')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
