import { Pressable } from 'cdk'
import { ColorName } from 'design-tokens'
import { Icon, IconProps } from 'icons'
import { useCallback } from 'react'

type Props = {
  disabled: boolean
  onRate: (value: number) => void
  selected: boolean
  size: IconProps['size']
  value: number
}

export const RatingStar = ({ disabled, onRate, selected, size, value }: Props) => {
  const onClick = useCallback(() => {
    onRate(value)
  }, [onRate, value])

  const fillColorName: Extract<ColorName, 'white' | 'yellow-40'> = selected ? 'yellow-40' : 'white'

  return (
    <Pressable data-test-id="rating-star-button" disabled={disabled} onClick={onClick}>
      <Icon colorName="yellow-20" fillColorName={fillColorName} name="star" size={size} />
    </Pressable>
  )
}
