import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { ReactHookFormRadioField, useReactHookFormContext } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { TranslationId } from '~/i18n/types'
import { TherapistEmergencyReason, TherapySessionStatus, TherapySessionTypeCost } from '~/types/graphql'
import { isBeforeCancelationPolicyLimit } from '~/utils/dates/isBeforeCancelationPolicyLimit'
import { isTherapySessionSuccessfullyPaid } from '~/utils/dates/isTherapySessionSuccessfullyPaid'
import { useDeleteForTherapistEmergencyOnSubmit } from '../hooks/useDeleteForTherapistEmergencyOnSubmit'
import { FormValues } from '../types/formValues'
import { getDeleteForTherapistEmergencyRoute } from '../utils/getDeleteForTherapistEmergencyRoute'

type Props = {
  startAt: Date
  status: TherapySessionStatus
  typeCost?: TherapySessionTypeCost | null
}

type OptionTranslationId = Extract<
  TranslationId,
  `therapySession.deleteForTherapistEmergency.step.deletion.reason.${string}`
>

type Option = {
  id: OptionTranslationId
  value: TherapistEmergencyReason
}

const emergencyReasons: Option[] = [
  { id: 'therapySession.deleteForTherapistEmergency.step.deletion.reason.family', value: 'FAMILY_ISSUE' },
  { id: 'therapySession.deleteForTherapistEmergency.step.deletion.reason.health', value: 'HEALTH_ISSUE' },
  { id: 'therapySession.deleteForTherapistEmergency.step.deletion.reason.patient', value: 'PATIENT_REQUEST' },
]

export const StepReason = ({ startAt, status, typeCost }: Props) => {
  const { loading } = useDeleteForTherapistEmergencyOnSubmit()
  const history = useHistory()
  const form = useReactHookFormContext<FormValues>()

  const {
    errors: { required },
  } = useFormTranslations()

  const onSubmit = useCallback(() => {
    if (
      (isTherapySessionSuccessfullyPaid({ status, typeCost }) || typeCost === 'FREE') &&
      !isBeforeCancelationPolicyLimit(startAt)
    ) {
      history.push(getDeleteForTherapistEmergencyRoute('/payment-warning'))

      return
    }

    history.replace(getDeleteForTherapistEmergencyRoute('/message'))
  }, [history, startAt, status, typeCost])

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <OverflowAuto>
        <Text fontWeight="600" kind="h3">
          <Translation id="therapySession.deleteForTherapistEmergency.step.deletion.title" />
        </Text>

        <Flex pt={16}>
          <Text fontWeight="400" kind="paragraph">
            <Translation id="therapySession.deleteForTherapistEmergency.step.deletion.reason.title" />
          </Text>
        </Flex>

        <Flex pt={32}>
          {emergencyReasons.map(({ id, value }) => (
            <Flex key={id} pb={16}>
              <Flex as="label" borderColor="purple04" borderRadius={16} borderSize={1} id={id} p={16}>
                <ReactHookFormRadioField name="therapistEmergencyReason" rules={{ required }} value={value}>
                  <Flex pt={2}>
                    <Text fontWeight="600" kind="caption">
                      <Translation id={id} />
                    </Text>
                  </Flex>
                </ReactHookFormRadioField>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </OverflowAuto>

      <Button kind="danger" loading={loading} type="submit">
        <Translation id="therapySession.deleteForTherapistEmergency.step.deletion.confirm" />
      </Button>
    </Form>
  )
}
