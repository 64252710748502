import { pipe } from 'fp-ts/function'
import queryString from 'query-string'
import { parsePath } from './parsePath'

export const parseQueryParams = <T>(path: string): T => {
  const query = pipe(path, parsePath, ({ search }) => queryString.parse(search))

  return Object.entries(query)
    .filter(([key]) => !['altk'].includes(key.toLowerCase()))
    .reduce(
      (accumulator, [key, value]) => ({
        ...accumulator,
        [key]: value ? String(value).trim() : '1',
      }),
      {} as T,
    )
}
