import styled from 'styled-components'
import { Toasts as UiToasts, View } from 'ui-deprecated'
import { useToasts } from '~/hooks/useToasts'

const Wrapper = styled(View)`
  z-index: 150;
`

export const Toasts = () => {
  const { items } = useToasts()

  return (
    <Wrapper>
      <UiToasts items={items} />
    </Wrapper>
  )
}
