import { Amplify } from '@aws-amplify/core'
import { useCallback } from 'react'

type AmplifyConfig = {
  Auth: {
    region: string
    userPoolId: string
    userPoolWebClientId: string
    cookieStorage: {
      domain: string
      path: string
      expires: number
      secure: boolean
    }
  }
}

export const useAmplifyMethods = () => {
  const configure = useCallback((config: AmplifyConfig) => Amplify.configure(config), [])

  return { configure }
}
