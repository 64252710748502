import { Flex } from 'cdk'
import { useCallback, useEffect, useState } from 'react'
import { ReactHookFormTextFieldNew, useReactHookFormContext } from '~/domains/react-hook-form'
import { isEmailTypo } from '~/hooks/isEmailTypo'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'
import { TextFieldNewHelperText } from './TextFieldNewHelperText'

export type FormValues = {
  email: string
  emailConfirmation: string
}

export const FieldEmailWithConfirmation = () => {
  const [warning, setWarning] = useState(false)
  const form = useReactHookFormContext<FormValues>()
  const { isEmail } = useFormValidation()

  const {
    errors: { required, emailConfirmation },
    helperTexts,
    labels,
    placeholders,
  } = useFormTranslations()

  const firstEmail = form.watch('email')

  useEffect(() => {
    if (!isEmail(firstEmail)) {
      setWarning(false)

      return
    }
    setWarning(isEmailTypo(firstEmail))

    void form.trigger('emailConfirmation')
  }, [isEmail, firstEmail, form])

  const validateEmail = useCallback(
    (value: string) => {
      if (isEmail(value) && value === firstEmail) {
        return true
      }

      return emailConfirmation
    },
    [emailConfirmation, firstEmail, isEmail],
  )

  return (
    <>
      <ReactHookFormTextFieldNew
        autoComplete="email"
        name="email"
        placeholder={placeholders.email}
        rules={{
          required,
          validate: isEmail,
        }}
        size="small"
        type="email"
        warning={warning}
      />

      <TextFieldNewHelperText helperText={helperTexts.emailSignup} />

      <Flex $pt={16}>
        <ReactHookFormTextFieldNew
          autoComplete="email"
          name="emailConfirmation"
          placeholder={placeholders.email}
          rules={{
            required,
            validate: validateEmail,
          }}
          size="small"
          type="email"
        />
      </Flex>

      <TextFieldNewHelperText helperText={labels.emailConfirmation} />
    </>
  )
}
