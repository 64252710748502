import { fold } from 'fp-ts/boolean'
import { pipe } from 'fp-ts/function'
import { useCallback } from 'react'
import { isPassword } from 'validation'
import { useFormTranslations } from './useFormTranslations'

export const useIsPassword = () => {
  const {
    errors: { password: label },
  } = useFormTranslations()

  return useCallback(
    (value: unknown) =>
      pipe(
        value,
        isPassword,
        fold<boolean | string>(
          () => label,
          () => true,
        ),
      ),
    [label],
  )
}
