import { Icon } from 'icons'
import { Text } from 'ui'
import { Flex, PageLayout } from 'ui-deprecated'
import { PageRoute } from '~/components/PageRoute'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { PageKey } from '~/hooks/useSeoMeta'
import { TranslationId } from '~/i18n/types'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { Head } from '../components/Head'

type Type = 'skipBooking' | 'skipBookingWithTherapy'

type DescriptionTranslationId<K extends '1' | '2'> = Extract<TranslationId, `reservation.${Type}.description.${K}`>
type TitleTranslationId = Extract<TranslationId, `reservation.${Type}.title`>
type SeoTitleTranslationId = Extract<PageKey, `reservation.${Type}`>

export const SkipBooking = () => {
  const { therapies } = usePatientTherapies()

  const shouldShowTranslationsWithTherapy = !!therapies.length

  const seoTitleTranslationId: SeoTitleTranslationId = shouldShowTranslationsWithTherapy
    ? 'reservation.skipBookingWithTherapy'
    : 'reservation.skipBooking'

  const titleTranslationId: TitleTranslationId = shouldShowTranslationsWithTherapy
    ? 'reservation.skipBookingWithTherapy.title'
    : 'reservation.skipBooking.title'

  const description1TranslationId: DescriptionTranslationId<'1'> = shouldShowTranslationsWithTherapy
    ? 'reservation.skipBookingWithTherapy.description.1'
    : 'reservation.skipBooking.description.1'

  const description2TranslationId: DescriptionTranslationId<'2'> = shouldShowTranslationsWithTherapy
    ? 'reservation.skipBookingWithTherapy.description.2'
    : 'reservation.skipBooking.description.2'

  return (
    <>
      <PageScrollEffect />

      <Head translationId={seoTitleTranslationId} />

      <PageRoute id="reservation.skipBooking">
        <PageLayout maxWidth="640px" pb={32} px={24}>
          <Flex pb={8}>
            <Text fontWeight="600" kind="h2">
              <Translation id={titleTranslationId} />
            </Text>
          </Flex>

          <Flex direction="row" pt={16}>
            <Flex pr={16}>
              <Icon colorName="grey-60" name="edit-pencil" size={32} />
            </Flex>
            <Flex grow={1} shrink={1}>
              <TranslationMarkdown colorName="black" fontWeight="400" id={description1TranslationId} kind="h3" />
            </Flex>
          </Flex>

          <Flex direction="row" pt={16}>
            <Flex pr={16}>
              <Icon
                colorName="grey-60"
                name={shouldShowTranslationsWithTherapy ? 'people-tag' : 'calendar'}
                size={32}
              />
            </Flex>
            <Flex grow={1} shrink={1}>
              <TranslationMarkdown colorName="black" fontWeight="400" id={description2TranslationId} kind="h3" />
            </Flex>
          </Flex>

          {!shouldShowTranslationsWithTherapy && (
            <Flex direction="row" pt={16}>
              <Flex pr={16}>
                <Icon colorName="grey-60" name="people-tag" size={32} />
              </Flex>
              <Flex grow={1} shrink={1}>
                <TranslationMarkdown
                  colorName="black"
                  fontWeight="400"
                  id="reservation.skipBooking.description.3"
                  kind="h3"
                />
              </Flex>
            </Flex>
          )}
        </PageLayout>
      </PageRoute>
    </>
  )
}
