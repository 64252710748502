import { Flex, Form, Pressable } from 'cdk'
import { Icon } from 'icons'
import { useCallback, useEffect, useMemo } from 'react'
import { matchPath, MemoryRouter, Route, Switch, useLocation } from 'react-router-dom'
import { Modal, ModalContent, ModalTrigger, Text, useModalContext } from 'ui'
import { Tooltip, TooltipContent, TooltipTrigger } from '~/components/FloatingUI/Tooltip'
import { Translation } from '~/components/Translation'
import { useTrackEventModal } from '~/domains/analytics/hooks/useTrackEventModal'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { useTherapistTherapies } from '~/domains/therapies/TherapistTherapiesProvider'
import { usePatientId } from '~/hooks/usePatientId'
import { useToasts } from '~/hooks/useToasts'
import { useSendClinicalTestToPatient } from '../hooks/useSendClinicalTestToPatient'
import { ChooseClinicalTestRoute } from './ChooseClinicalTestRoute'
import { ClinicalTestMessageRoute } from './ClinicalTestMessageRoute'
import { getAttachClinicalTestToChatRoute } from './getRoute'

export type FormValues = {
  clinicalTestId: string
  message: string | null
}

const Progress = () => {
  const { pathname } = useLocation()

  const value = useMemo(
    () => (!!matchPath(pathname, { path: getAttachClinicalTestToChatRoute('/tests') }) ? 1 : 2),
    [pathname],
  )

  return (
    <Text colorName="neutral-70" kind="caption">
      <Translation id="generic.outOf" values={{ value, max: 2 }} />
    </Text>
  )
}

const SendClinicalTestFormModalTrackEventEffect = () => {
  const trackDismiss = useTrackEventModal('dismiss')
  const trackOpen = useTrackEventModal('open')

  useEffect(() => {
    trackOpen('clinicalTestAttachClinicalTestToChat')

    return () => trackDismiss('clinicalTestAttachClinicalTestToChat')
  }, [trackDismiss, trackOpen])

  return null
}

const SendClinicalTestForm = () => {
  const { close } = useModalContext()
  const form = useReactHookForm<FormValues>()
  const [sendClinicalTestToPatient] = useSendClinicalTestToPatient()
  const patientId = usePatientId()
  const { therapies } = useTherapistTherapies()
  const therapy = therapies.find((therapy) => therapy.patient?.id === patientId)
  const { addToast } = useToasts()

  const handleSubmit = useCallback(
    ({ clinicalTestId, message }: FormValues) => {
      if (!therapy) {
        addToast({ type: 'alert', translationId: 'generic.error' })

        return
      }

      sendClinicalTestToPatient({
        variables: {
          input: {
            clinicalTestId,
            messageForThePatient: message,
            therapyId: therapy.id,
          },
        },
      })

      form.reset({ message: '' })
      form.clearErrors()
      close()
    },
    [addToast, close, form, sendClinicalTestToPatient, therapy],
  )

  return (
    <ReactHookFormProvider {...form}>
      <Form $gap={4} id="send-clinical-test" onSubmit={form.handleSubmit(handleSubmit)}>
        <MemoryRouter initialEntries={[getAttachClinicalTestToChatRoute('/tests')]}>
          <Progress />
          <Switch>
            <Route path={getAttachClinicalTestToChatRoute('/tests')}>
              <ChooseClinicalTestRoute />
            </Route>
            <Route path={getAttachClinicalTestToChatRoute('/message')}>
              <ClinicalTestMessageRoute />
            </Route>
          </Switch>
        </MemoryRouter>
      </Form>
    </ReactHookFormProvider>
  )
}

export const AttachClinicalTestToChat = () => (
  <Modal>
    <Flex
      $align="flex-start"
      $basis="calc(var(--str-chat__spacing-px) * 45)"
      $borderColorName="primary-40"
      $borderSizeTop={1}
      $justify="center"
      $minHeight="100%"
    >
      <ModalTrigger>
        <Pressable>
          <Tooltip kind="title" placement="top-end">
            <TooltipTrigger>
              <Flex $align="center">
                <Icon colorName="primary" name="paste-clipboard" size={32} />
              </Flex>
            </TooltipTrigger>
            <TooltipContent>
              <Translation id="clinicalTests.sendClinicalTest" />
            </TooltipContent>
          </Tooltip>
        </Pressable>
      </ModalTrigger>
    </Flex>
    <ModalContent>
      <SendClinicalTestFormModalTrackEventEffect />
      <SendClinicalTestForm />
    </ModalContent>
  </Modal>
)
