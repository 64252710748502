import { noop } from 'functions'
import { ComponentProps, forwardRef, useMemo } from 'react'
import Select, { PropsValue } from 'react-select'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { styles } from '~/components/SelectNew/constants/styles'
import { theme } from '~/components/SelectNew/constants/theme'
import { SelectOption, SelectSize } from '~/components/SelectNew/types'
import { Translation } from '~/components/Translation'
import { ClearIndicator } from './components/ClearIndicator'
import { DropdownIndicator } from './components/DropdownIndicator'
import { MultiValueRemove } from './components/MultiValueRemove'

type OnChange = ComponentProps<typeof Select>['onChange']

type Props = {
  defaultValue?: PropsValue<SelectOption>
  disabled?: boolean
  errorMessage?: string
  invalid?: boolean
  multiple?: boolean
  name: string
  onBlur?: () => void
  onChange?: OnChange
  options: SelectOption[]
  placeholder?: string
  searchable?: boolean
  size?: SelectSize
  value?: PropsValue<SelectOption>
}

export const SelectNew = forwardRef(
  (
    {
      defaultValue,
      disabled = false,
      errorMessage,
      invalid = false,
      multiple = false,
      name,
      onBlur = noop,
      onChange = noop,
      options,
      placeholder,
      searchable = true,
      size = 'medium',
      value,
    }: Props,
    ref: ComponentProps<typeof Select<SelectOption, boolean>>['ref'],
  ) => {
    const hasError = errorMessage && invalid && !disabled

    const selectStyles = useMemo(() => styles(Boolean(hasError), size), [hasError, size])
    const selectTheme = useMemo(() => theme(size), [size])

    return (
      <Flex>
        <Select
          ref={ref}
          components={{
            ClearIndicator,
            DropdownIndicator,
            MultiValueRemove,
          }}
          defaultValue={defaultValue}
          hideSelectedOptions={multiple}
          isDisabled={disabled}
          isMulti={multiple}
          isSearchable={searchable}
          menuPortalTarget={document.body}
          name={name}
          noOptionsMessage={() => <Translation id="forms.noOptionsMessage" />}
          onBlur={onBlur}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          styles={selectStyles}
          theme={selectTheme}
          value={value}
        />

        {hasError && (
          <Flex pt={8}>
            <Text colorName="red-80" kind="caption">
              {errorMessage}
            </Text>
          </Flex>
        )}
      </Flex>
    )
  },
)
