import { Svg } from 'ui-deprecated'

export const JournalingLogScoreIllustration4 = ({ size }: { size: number }) => (
  <Svg fill="none" height={size} style={{ maxWidth: '100%' }} viewBox="0 0 64 64" width={size}>
    <circle cx="32" cy="32" fill="#DFDAF6" r="21.33" />
    <circle cx="24.96" cy="29.87" fill="#141323" r=".64" />
    <circle cx="39.04" cy="29.87" fill="#141323" r=".64" />
    <path d="M39.67 33.44a6.4 6.4 0 1 1-12.36 3.31" stroke="#000" strokeWidth="1.28" />
    <path
      d="M4.18 34.48a7.97 7.97 0 0 0 11.23-1M48.5 34.48a7.97 7.97 0 0 0 11.23-1"
      stroke="#B5B2EA"
      strokeLinecap="round"
      strokeWidth="2.56"
    />
  </Svg>
)
