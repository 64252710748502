import {
  registerDecorator,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator'
import { countries } from './country'

export const isNationality = (value: unknown) => countries.some(({ code }) => code === value)

@ValidatorConstraint({ name: 'nationality', async: false })
export class IsNationalityConstraint implements ValidatorConstraintInterface {
  validate = isNationality

  defaultMessage = () => 'Input is not a valid nationality'
}

export function IsNationality<T extends object>(validationOptions?: ValidationOptions) {
  return function (object: T, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsNationalityConstraint,
    })
  }
}
