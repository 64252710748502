export const authenticatedUserGroups = [
  'bi',
  'coach',
  'engineering',
  'finance',
  'marketing',
  'operations',
  'patient',
  'saas-therapist',
  'sexologist',
  'therapist',
] as const
