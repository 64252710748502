import { Redirect, useParams } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useClinicalTestAnswerIsCompleted } from '~/domains/clinicalTests'
import { getRoute } from '~/utils/getRoute'
import { PageElementComponent } from '../types'

type Params = {
  clinicalTestAnswerId: string
}

export const ClinicalTestPageGuard: PageElementComponent = ({ children }) => {
  const { clinicalTestAnswerId } = useParams<Params>()
  const { isCompleted, loading } = useClinicalTestAnswerIsCompleted(clinicalTestAnswerId)

  if (loading) {
    return <CenteredLoader />
  }

  if (isCompleted) {
    return <Redirect to={getRoute('/')} />
  }

  return children
}
