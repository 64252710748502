import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { SettingsPaymentsConfirm } from '~/domains/settings/pages/SettingsPaymentsConfirm'
import { StripeElementsProvider } from '~/domains/stripe'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

export const SettingsPaymentsConfirmPage: Page = {
  exact: true,
  id: 'settings.payments.confirm',
  paths: ['/settings/payments/confirm'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'settings.payments.confirm',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => <StripeElementsProvider>{children}</StripeElementsProvider>,
  PageView: SettingsPaymentsConfirm,
}
