import { subHours } from 'date-fns/fp'
import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { Icon } from 'icons'
import { toCurrency } from 'numbers'
import { useCallback, useMemo } from 'react'
import { matchPath } from 'react-router-dom'
import styled from 'styled-components'
import { Avatar, Text } from 'ui'
import { CardBox, Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useAvailableChangeTherapistsFree } from '~/domains/changeTherapist/hooks/useAvailableChangeTherapistsFree'
import { useModals } from '~/domains/modals'
import { usePaymentMethods } from '~/domains/payments/hooks/usePaymentMethods'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { ReservationFormValues } from '~/domains/reservation/types'
import { TherapistProfileModal } from '~/domains/therapist/components/TherapistProfileModal'
import { useRootLocation } from '~/hooks/useRootHistory'
import { TherapistProvider, useTherapist } from '~/hooks/useTherapist'
import { TranslationId } from '~/i18n/types'
import { getTherapySessionDayAndMonthShort } from '~/utils/dates/getTherapySessionDayAndMonth'
import { getTherapySessionStartAt } from '~/utils/dates/getTherapySessionStartAt'
import { getRoute } from '~/utils/getRoute'
import { getTherapySessionCancellationPolicyInHours } from '~/utils/getTherapySessionCancellationPolicyInHours'
import { useReservationFlow } from '../hooks/useReservationFlow'
import { useTherapyPathById } from '../hooks/useTherapyPathById'

const AvatarWrapper = styled(Flex).attrs({
  align: 'center',
  background: 'purple06',
  borderRadius: 100,
  borderSize: 2,
  borderColor: 'purple06',
  justify: 'center',
})`
  box-shadow: 0 0 8px rgb(80 57 216 / 20%);
`

type Props = {
  timeslot: Date
}

const SummaryWithTherapist = ({ timeslot }: Props) => {
  const { open } = useModals()

  const {
    fullName,
    id,
    therapist: { profileImage },
  } = useTherapist()

  const openTherapistModal = useCallback(() => {
    open(`therapistProfile:${id}`)
  }, [id, open])

  const { therapyPath } = useTherapyPathById()

  const titleTranslationId = useMemo((): TranslationId => {
    if (therapyPath.type === 'MYSELF_PSYCHIATRY') {
      return 'reservation.submit.psychiatrist.title'
    }

    if (therapyPath.type === 'PATH_COACHING') {
      return 'reservation.submit.coach.title'
    }

    return 'reservation.submit.therapist.title'
  }, [therapyPath.type])

  return (
    <>
      <Flex pb={16}>
        <Text kind="h1">
          <Translation id="reservation.submit.title.main" />
        </Text>
      </Flex>
      <CardBox>
        <Flex align="center" direction="row">
          <Flex pr={16}>
            <AvatarWrapper>
              <Pressable autoWidth onClick={openTherapistModal}>
                <Avatar image={profileImage?.s} name={fullName} size="lg" />
              </Pressable>
            </AvatarWrapper>
          </Flex>
          <Flex grow={1} shrink={1}>
            <Flex pb={8}>
              <TranslationMarkdown
                fontWeight="400"
                id={titleTranslationId}
                kind="h3"
                values={{
                  therapistFullName: fullName,
                }}
              />
            </Flex>
            <Pressable autoWidth onClick={openTherapistModal}>
              <Text colorName="primary" fontWeight="600" kind="paragraph" textAlign="left" textDecoration="underline">
                <Translation id="suggestedTherapist.moreInfo" />
              </Text>
            </Pressable>
          </Flex>
        </Flex>
        <Flex direction="row" pt={16}>
          <Flex pr={60}>
            <Text colorName="grey-80" kind="paragraph">
              <Translation id="generic.date" />
            </Text>

            <Flex pt={4}>
              <Text colorName="black" fontWeight="600" kind="paragraph">
                {pipe(timeslot, getTherapySessionDayAndMonthShort)}
              </Text>
            </Flex>
          </Flex>

          <Flex>
            <Text colorName="grey-80" kind="paragraph">
              <Translation id="generic.hour" />
            </Text>

            <Flex pt={4}>
              <Text colorName="black" fontWeight="600" kind="paragraph">
                {pipe(timeslot, getTherapySessionStartAt)}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </CardBox>

      <TherapistProfileModal therapyPathType={therapyPath.type} />
    </>
  )
}

export const SubmitBookingContent = () => {
  const form = useReactHookFormContext<ReservationFormValues>()

  const { selectedTherapist: selectedTherapistControl, suggestedTherapistsProfiles } = useReservationFlow()

  const { therapyPath } = useTherapyPathById()

  const { selectedTimeSlot, therapistId } = form.getValues()

  const selectedTherapist = therapistId
    ? suggestedTherapistsProfiles.find(({ id }) => id === therapistId)
    : selectedTherapistControl

  const { pathname } = useRootLocation()

  const { availableChangeTherapistsFree, loading } = useAvailableChangeTherapistsFree()

  const isChangeTherapist = !!matchPath(pathname, { path: [getRoute('/change-therapist/:therapyId/booking')] })

  const costAsCurrency = toCurrency(therapyPath.cost)

  const isChangeTherapistPaid = isChangeTherapist && availableChangeTherapistsFree === 0

  const { items, loading: itemsLoading } = usePaymentMethods()

  const firstExpectationTranslationId = useMemo((): TranslationId => {
    if (therapyPath.type === 'PATH_COACHING') {
      return 'reservation.submit.coaching.expectation1'
    }

    return 'reservation.submit.expectation1'
  }, [therapyPath])

  const secondExpectationTranslationId = useMemo((): TranslationId => {
    if (therapyPath.type === 'MYSELF_PSYCHIATRY') {
      return 'reservation.submit.psychiatrist.expectation2'
    }

    if (therapyPath.type === 'PATH_COACHING') {
      return 'reservation.submit.coaching.expectation2'
    }

    return 'reservation.submit.expectation2'
  }, [therapyPath])

  const thirdExpectationTranslationId = useMemo((): TranslationId => {
    if (therapyPath.type === 'MYSELF_PSYCHIATRY') {
      return !!items.length
        ? 'reservation.submit.psychiatrist.alreadyWithPaymentMethod.expectation3'
        : 'reservation.submit.psychiatrist.expectation3'
    }

    if (therapyPath.type === 'PATH_COACHING') {
      return 'reservation.submit.coaching.expectation3'
    }

    return 'reservation.submit.expectation3'
  }, [items, therapyPath])

  if (!selectedTimeSlot || loading || itemsLoading) {
    return null
  }

  return (
    <>
      <Flex pt={32}>
        {!!selectedTherapist && (
          <TherapistProvider therapist={selectedTherapist}>
            <SummaryWithTherapist timeslot={selectedTimeSlot} />
          </TherapistProvider>
        )}
      </Flex>

      <Flex direction="row" pt={32}>
        <Flex pr={16} pt={2}>
          <Icon colorName="grey-60" name="calendar" size={24} />
        </Flex>

        {therapyPath.type === 'MYSELF_PSYCHIATRY' && (
          <Flex pt={2} shrink={1}>
            <TranslationMarkdown
              colorName="black"
              fontWeight="400"
              id="reservation.submit.psychiatrist.expectation1"
              kind="h3"
            />
          </Flex>
        )}

        {!(therapyPath.type === 'MYSELF_PSYCHIATRY') && (
          <Flex pt={2} shrink={1}>
            {!isChangeTherapistPaid && (
              <TranslationMarkdown colorName="black" fontWeight="400" id={firstExpectationTranslationId} kind="h3" />
            )}

            {isChangeTherapistPaid && (
              <TranslationMarkdown
                colorName="black"
                fontWeight="400"
                id="reservation.submit.expectation1.payed"
                kind="h3"
                values={{ cost: costAsCurrency }}
              />
            )}
          </Flex>
        )}
      </Flex>

      <Flex direction="row" pt={24}>
        <Flex pr={16} pt={2}>
          <Icon colorName="grey-60" name="edit-pencil" size={24} />
        </Flex>

        <Flex pt={2} shrink={1}>
          <TranslationMarkdown
            colorName="black"
            fontWeight="400"
            id={secondExpectationTranslationId}
            kind="h3"
            values={{
              hour: pipe(
                selectedTimeSlot,
                subHours(getTherapySessionCancellationPolicyInHours(null)),
                format(FormatDateEnum.HOURS_MINUTES),
              ),
              date: pipe(
                selectedTimeSlot,
                subHours(getTherapySessionCancellationPolicyInHours(null)),
                format(FormatDateEnum.LONG_DAY_DATE_MONTH_NAME),
              ),
            }}
          />
        </Flex>
      </Flex>

      <Flex direction="row" pt={24}>
        <Flex pr={16} pt={2}>
          <Icon colorName="grey-60" name="finder" size={24} />
        </Flex>

        <Flex pt={2} shrink={1}>
          <TranslationMarkdown colorName="black" fontWeight="400" id={thirdExpectationTranslationId} kind="h3" />
        </Flex>
      </Flex>
    </>
  )
}
