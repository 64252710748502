import { gql, useQuery } from '@apollo/client'
import { GetSentenceOfTheDayQuery } from '~/types/graphql'

const QUERY = gql`
  query getSentenceOfTheDay {
    getSentenceOfTheDay {
      author
      text
      type
    }
  }
`

export const useSentenceOfTheDay = () => {
  const { data, loading, error } = useQuery<GetSentenceOfTheDayQuery>(QUERY, { fetchPolicy: 'cache-first' })

  return {
    author: data?.getSentenceOfTheDay?.author,
    text: data?.getSentenceOfTheDay?.text,
    type: data?.getSentenceOfTheDay?.type,
    loading,
    error: !!error,
  }
}
