import { differenceInYears, parseISO } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { fold } from 'fp-ts/boolean'
import { pipe } from 'fp-ts/function'
import { useCallback } from 'react'
import { useFormTranslations } from './useFormTranslations'

const isAgeOlderThan14 = (value: Date): boolean => Math.abs(pipe(value, differenceInYears(nowInMilliseconds()))) >= 14

const isAgeYoungerThan18 = (value: Date): boolean => Math.abs(pipe(value, differenceInYears(nowInMilliseconds()))) < 18

export const useIsOlderThan14AndYoungerThan18 = () => {
  const {
    errors: { olderThan18, youngerThan14 },
  } = useFormTranslations()

  return useCallback(
    (value: any) =>
      pipe(
        value,
        parseISO,
        isAgeOlderThan14,
        fold<boolean | string>(
          () => youngerThan14,
          () =>
            pipe(
              value,
              parseISO,
              isAgeYoungerThan18,
              fold<boolean | string>(
                () => olderThan18,
                () => true,
              ),
            ),
        ),
      ),
    [olderThan18, youngerThan14],
  )
}
