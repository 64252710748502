import { ComponentProps } from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { SelectNew } from '~/components/SelectNew'
import { useLoadingState } from '~/hooks/useLoadingState'

type SelectProps = ComponentProps<typeof SelectNew>

type Props = Omit<SelectProps, 'errorMessage' | 'value'> & {
  disabled?: boolean
  rules?: RegisterOptions
  validateOnSubmit?: boolean
}

export const ReactHookFormSelectNew = ({
  defaultValue,
  disabled,
  name,
  rules,
  size = 'medium',
  validateOnSubmit = true,
  ...props
}: Props) => {
  const { control } = useFormContext()
  const { loading } = useLoadingState()

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error, invalid },
        formState: { isSubmitting, isSubmitted, isValid },
      }) => (
        <SelectNew
          {...props}
          ref={ref}
          disabled={disabled || ((isSubmitting || loading) && isValid)}
          errorMessage={error?.message}
          invalid={(invalid && !validateOnSubmit) || (invalid && isSubmitted && validateOnSubmit)}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          size={size}
          value={value}
        />
      )}
      rules={rules}
    />
  )
}
