import { Icon } from 'icons'
import { useCallback, useState } from 'react'
import { Card, Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TherapyTherapyPathType } from '~/types/graphql'

type Pros = {
  therapyPathType: TherapyTherapyPathType
}

export const AboutAddingPaymentInfoDuringSignup = ({ therapyPathType }: Pros) => {
  const [isOpen, setIsOpen] = useState(false)

  const isPsychiatryPath = therapyPathType === 'MYSELF_PSYCHIATRY'

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  return (
    <Card>
      <Pressable onClick={handleClick}>
        <Flex align="center" direction="row">
          <Flex grow={1} shrink={1}>
            <Text fontWeight="600" kind="paragraph" textAlign="left">
              <Translation id="reservation.paymentMethod.whatDoYouHaveToKnow.title" />
            </Text>
          </Flex>
          <Icon colorName="primary" name="nav-arrow-down" size={24} />
        </Flex>
      </Pressable>
      {isOpen && (
        <Flex pt={16}>
          <Flex align="center" direction="row">
            <Flex pr={16}>
              <Icon colorName="grey-60" name="credit-card" size={24} />
            </Flex>
            <Text kind="paragraph">
              <Translation
                id={
                  isPsychiatryPath
                    ? 'reservation.paymentMethod.whatDoYouHaveToKnow.psychiatry.1.description'
                    : 'reservation.paymentMethod.whatDoYouHaveToKnow.psychotherapy.1.description'
                }
              />
            </Text>
          </Flex>
          <Flex align="center" direction="row" pt={8}>
            <Flex pr={16}>
              <Icon colorName="grey-60" name="timer" size={24} />
            </Flex>
            <Text kind="paragraph">
              <Translation
                id={
                  isPsychiatryPath
                    ? 'reservation.paymentMethod.whatDoYouHaveToKnow.psychiatry.2.description'
                    : 'reservation.paymentMethod.whatDoYouHaveToKnow.psychotherapy.2.description'
                }
              />
            </Text>
          </Flex>
          <Flex align="center" direction="row" pt={8}>
            <Flex pr={16}>
              <Icon colorName="grey-60" name="credit-card-slash" size={24} />
            </Flex>
            <Text kind="paragraph">
              <Translation
                id={
                  isPsychiatryPath
                    ? 'reservation.paymentMethod.whatDoYouHaveToKnow.psychiatry.3.description'
                    : 'reservation.paymentMethod.whatDoYouHaveToKnow.psychotherapy.3.description'
                }
              />
            </Text>
          </Flex>
        </Flex>
      )}
    </Card>
  )
}
