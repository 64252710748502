import { createContext, useContext } from 'react'
import { usePopover } from './Popover'

type ContextType = ReturnType<typeof usePopover> | null

export const PopoverContext = createContext<ContextType>(null)

export const usePopoverContext = () => {
  const context = useContext(PopoverContext)

  if (context === null) {
    throw new Error('Popover components must be wrapped in <Popover />')
  }

  return context
}

export const usePopoverContextNullable = () => {
  const context = useContext(PopoverContext)

  return context
}
