import { gql, ObservableQuery, useLazyQuery } from '@apollo/client'
import { createContext, ReactNode, useCallback, useContext, useMemo, useRef } from 'react'
import { CenteredLoader } from '~/components/CenteredLoader'
import { GenericErrorContent } from '~/domains/error/GenericError'
import { MeQuery, MeQueryVariables } from '~/types/graphql'
import { useUserLastActivity } from './useUserLastActivity'

const QUERY = gql`
  query me {
    me {
      id
      activeReferral
      company {
        id
        name
      }
      createdAt
      email
      emailVerifiedAt
      firstName
      fullName
      hash
      lastName
      marketingEmails
      partnershipSource
      phoneNumber
      phoneNumberVerifiedAt
      professionTypeValues
      pushNotificationsEnabled
      referralCode
      timeZone
    }
  }
`

type CurrentUser = Omit<NonNullable<MeQuery['me']>, 'emailVerifiedAt' | 'phoneNumberVerifiedAt'> & {
  emailVerified: boolean
  phoneNumberFormatted: string
  phoneNumberVerified: boolean
}

export type CurrentUserContext = {
  loading: boolean
  onLoginCompleted: () => Promise<void>
  onLogoutCompleted: () => Promise<void>
  updateQuery: ObservableQuery<MeQuery>['updateQuery']
  user: CurrentUser | null
}

export const CurrentUserContext = createContext<CurrentUserContext | null>(null)

type Props = {
  children: ReactNode
}

export const CurrentUserProvider = ({ children }: Props) => {
  const [userLastActivity] = useUserLastActivity()
  const hasAlreadyFetchedMe = useRef(false)

  const [getMe, { data, error, loading, updateQuery }] = useLazyQuery<MeQuery, MeQueryVariables>(QUERY, {
    fetchPolicy: 'network-only',
  })

  const onLogoutCompleted = useCallback(async () => {
    await updateQuery((state) => ({
      ...state,
      me: null,
    }))

    hasAlreadyFetchedMe.current = false
  }, [updateQuery])

  const onLoginCompleted = useCallback(async () => {
    try {
      if (hasAlreadyFetchedMe.current) {
        return
      }

      hasAlreadyFetchedMe.current = true

      await getMe()

      await userLastActivity()
    } catch {}
  }, [getMe, userLastActivity])

  const value = useMemo(
    () => ({
      loading,
      onLoginCompleted,
      onLogoutCompleted,
      updateQuery,
      user: data?.me
        ? {
            activeReferral: data.me.activeReferral,
            company: data.me.company,
            createdAt: data.me.createdAt,
            email: data.me.email,
            emailVerified: !!data.me.emailVerifiedAt,
            firstName: data.me.firstName,
            fullName: data.me.fullName,
            hash: data.me.hash ?? '',
            id: data.me.id,
            lastName: data.me.lastName,
            marketingEmails: !!data.me.marketingEmails,
            partnershipSource: data.me.partnershipSource,
            phoneNumber: data.me.phoneNumber,
            phoneNumberFormatted: data.me.phoneNumber || '',
            phoneNumberVerified: !!data.me.phoneNumberVerifiedAt,
            professionTypeValues: data.me.professionTypeValues,
            pushNotificationsEnabled: data.me.pushNotificationsEnabled,
            referralCode: data.me.referralCode,
            timeZone: data.me.timeZone,
          }
        : null,
    }),
    [data?.me, loading, onLoginCompleted, onLogoutCompleted, updateQuery],
  )

  if (!data?.me && error) {
    return <GenericErrorContent />
  }

  if (loading) {
    return <CenteredLoader />
  }

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>
}

export const useCurrentUserNullable = () => {
  const context = useContext(CurrentUserContext)

  if (!context) {
    throw new Error('The `useCurrentUserNullable` should be wrapped with `CurrentUserProvider`.')
  }

  const { loading, onLoginCompleted, onLogoutCompleted, updateQuery, user } = context

  return { loading, onLoginCompleted, onLogoutCompleted, updateQuery, ...user }
}

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext)

  if (!context) {
    throw new Error('The `useCurrentUser` should be wrapped with `CurrentUserProvider`.')
  }

  if (!context.user) {
    throw new Error('Missing current user')
  }

  const { loading, onLoginCompleted, onLogoutCompleted, updateQuery, user } = context

  return { loading, onLoginCompleted, onLogoutCompleted, updateQuery, ...user }
}
