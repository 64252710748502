import { PropsWithChildren, createContext, useContext, useMemo } from 'react'
import { UserTherapist } from '~/domains/therapist/types'
import { TherapySession } from '~/types/graphql'
import { CurrentUserContext, useCurrentUser } from './useCurrentUser'
import { useTherapist } from './useTherapist'

type ContextType = Partial<
  Omit<TherapySession, 'patient' | 'therapist'> & { patient: CurrentUserContext['user']; therapist: UserTherapist }
> &
  Pick<TherapySession, 'id'>

const TherapySessionContext = createContext<ContextType | null>(null)

type TherapySessionProvider = PropsWithChildren<{
  therapySession: ContextType
}>

export const TherapySessionProvider = ({ children, therapySession }: TherapySessionProvider) => {
  const patient = useCurrentUser()
  const therapist = useTherapist()

  const value = useMemo<ContextType>(
    () => ({
      ...therapySession,
      therapist,
      patient,
    }),
    [patient, therapist, therapySession],
  )

  return <TherapySessionContext.Provider value={value}>{children}</TherapySessionContext.Provider>
}

export const useTherapySession = () => {
  const context = useContext(TherapySessionContext)

  if (!context) {
    throw new Error('useTherapySession hook must be wrapped in <TherapySessionCardPatientProvider />')
  }

  if (!context?.id) {
    throw new Error('useTherapySession should return a valid therapySession')
  }

  return context
}
