import { groupByToEntries } from 'arrays'
import { formatISO, startOfDay } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { filterTimeSlotsByIntervals } from '../utils/filterTimeSlotsByInterval'
import { mapAvailabilitiesToSlotsByDayAndTherapist } from '../utils/mapAvailabilitiesToSlotsByDayAndTherapist'
import { padSlotsByDay } from '../utils/padSlotsByDay'
import { padSlotsByDayAndTherapist } from '../utils/padSlotsByDayAndTherapist'
import { useAvailableTimeSlotsFirstBooking } from './useAvailableTimeSlotsFirstBooking'
import { useReservationFlow } from './useReservationFlow'
import { useSelectedTimeInterval } from './useSelectedTimeInterval'
import { useTherapyPathById } from './useTherapyPathById'

const useTimeSlots = () => {
  const { suggestedTherapistsProfiles } = useReservationFlow()
  const { therapyPath } = useTherapyPathById()

  const therapistIds = useMemo(() => suggestedTherapistsProfiles.map(({ id }) => id), [suggestedTherapistsProfiles])

  const { items, loading } = useAvailableTimeSlotsFirstBooking({
    userIds: therapistIds,
  })

  const selectedTimeInterval = useSelectedTimeInterval()

  const slotsByDayAndTherapist = useMemo(() => {
    const availabilitiesByDay: [string, typeof items][] = groupByToEntries(items, ({ startAt }) =>
      pipe(startAt, startOfDay, formatISO),
    )

    return mapAvailabilitiesToSlotsByDayAndTherapist(availabilitiesByDay, therapyPath.type)
  }, [items, therapyPath.type])

  const filtered = useMemo(
    () =>
      !selectedTimeInterval?.length
        ? slotsByDayAndTherapist
        : filterTimeSlotsByIntervals(slotsByDayAndTherapist, selectedTimeInterval),
    [selectedTimeInterval, slotsByDayAndTherapist],
  )

  return {
    loading,
    slotsByDayAndTherapist,
    slotsByDayAndTherapistFilteredByTimeInterval: filtered,
  }
}

export const useAvailableTimeSlotsByDay = () => {
  const { loading, slotsByDayAndTherapist, slotsByDayAndTherapistFilteredByTimeInterval } = useTimeSlots()

  const slotsByDay = useMemo(() => padSlotsByDay(slotsByDayAndTherapist), [slotsByDayAndTherapist])

  const slotsByDayFilteredByTimeInterval = useMemo(
    () => padSlotsByDay(slotsByDayAndTherapistFilteredByTimeInterval),
    [slotsByDayAndTherapistFilteredByTimeInterval],
  )

  return {
    loading,
    slotsByDay,
    slotsByDayFilteredByTimeInterval,
  }
}

export const useAvailableTimeSlotsByDayAndTherapists = () => {
  const { suggestedTherapistsProfiles } = useReservationFlow()

  const therapistIds = useMemo(() => suggestedTherapistsProfiles.map(({ id }) => id), [suggestedTherapistsProfiles])

  const { loading, slotsByDayAndTherapist, slotsByDayAndTherapistFilteredByTimeInterval } = useTimeSlots()

  const slotsByDayAndTherapistPadded = useMemo(
    () => padSlotsByDayAndTherapist(slotsByDayAndTherapist, therapistIds),
    [slotsByDayAndTherapist, therapistIds],
  )

  const slotsByDayAndTherapistFilteredByTimeIntervalPadded = useMemo(
    () => padSlotsByDayAndTherapist(slotsByDayAndTherapistFilteredByTimeInterval, therapistIds),
    [slotsByDayAndTherapistFilteredByTimeInterval, therapistIds],
  )

  return {
    loading,
    slotsByDayAndTherapist: slotsByDayAndTherapistPadded,
    slotsByDayAndTherapistFilteredByTimeInterval: slotsByDayAndTherapistFilteredByTimeIntervalPadded,
  }
}
