import { Flex } from 'cdk'
import { Icon } from 'icons'
import { PropsWithChildren, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationId } from '~/i18n/types'
import { getRoute } from '~/utils/getRoute'
import { TranslationMarkdown } from '../TranslationMarkdown'

const FlexWithBackground = styled(Flex).attrs({
  $align: 'flex-start',
  $backgroundColorName: 'grey-100',
  $borderRadius: 16,
  $gap: 16,
  $grow: 1,
  $justify: 'center',
  $p: 24,
  $wrap: 'wrap',
  $elevationName: 'md',
})`
  background-image: url('https://assets.serenis.it/bundles/bundles_lg.webp');
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
`

const FlexWithBackgroundSmall = styled(Flex).attrs({
  $backgroundColorName: 'grey-90',
  $basis: 110,
  $borderRadiusTopLeft: 16,
  $borderRadiusTopRight: 16,
  $elevationName: 'md',
  $gap: 16,
  $grow: 1,
  $justify: 'center',
  $p: 24,
})`
  background-image: url('https://assets.serenis.it/bundles/bundles_sm.webp');
  background-position: center;
  background-size: auto 110px;
  background-repeat: no-repeat;
`

const BundleModuleCard = ({ children }: PropsWithChildren) => (
  <>
    <FlexWithBackground $hide $mdHide={false}>
      {children}
    </FlexWithBackground>

    <Flex $borderRadius={16} $mdHide>
      <FlexWithBackgroundSmall />
      <Flex
        $align="center"
        $backgroundColorName="grey-100"
        $borderRadiusBottomLeft={16}
        $borderRadiusBottomRight={16}
        $direction="row"
        $gap={24}
        $p={24}
        $wrap="wrap"
      >
        {children}
      </Flex>
    </Flex>
  </>
)

export const BulletPoint = ({ id }: { id: TranslationId }) => (
  <Flex $align="center" $direction="row" $gap={4}>
    <Icon colorName="white" name="check" size={16} />
    <TranslationMarkdown colorName="white" id={id} kind="paragraph" />
  </Flex>
)

type Props = {
  kind?: 'light' | 'standard'
}

export const BundleBanner = ({ kind = 'standard' }: Props) => {
  const history = useHistory()

  const handleOnClick = useCallback(() => {
    history.push(getRoute('/bundles'))
  }, [history])

  return (
    <BundleModuleCard>
      <Flex $gap={16} $grow={1}>
        <Text colorName="white" kind="h2">
          <Translation id="bundle.banner.title" />
        </Text>
        {kind === 'standard' && (
          <Flex $gap={4}>
            <BulletPoint id="bundle.banner.bullet.1" />
            <BulletPoint id="bundle.banner.bullet.2" />
          </Flex>
        )}
      </Flex>
      <Button kind="tertiary" onClick={handleOnClick}>
        <Translation id="bundle.banner.start" />
      </Button>
    </BundleModuleCard>
  )
}
