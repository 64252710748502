import { setDate, setMonth, setYear, startOfDay } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'

const year2024 = pipe(nowInMilliseconds(), setYear(2024), startOfDay)
const year2025 = pipe(nowInMilliseconds(), setYear(2025), startOfDay)

const february = pipe(year2024, setMonth(1))
const march = pipe(year2024, setMonth(2))
const april = pipe(year2024, setMonth(3))
const may = pipe(year2024, setMonth(4))
const august = pipe(year2024, setMonth(7))
const september = pipe(year2024, setMonth(8))
const november = pipe(year2024, setMonth(10))
const december = pipe(year2024, setMonth(11))
const january = pipe(year2025, setMonth(0))

export const hardcodedUnavailabilities = [
  pipe(february, setDate(12)),
  pipe(march, setDate(29)),
  pipe(march, setDate(30)),
  pipe(march, setDate(31)),
  pipe(april, setDate(1)),
  pipe(april, setDate(2)),
  pipe(april, setDate(25)),
  pipe(april, setDate(26)),
  pipe(april, setDate(27)),
  pipe(april, setDate(28)),
  pipe(april, setDate(29)),
  pipe(april, setDate(30)),
  pipe(may, setDate(1)),
  pipe(may, setDate(2)),
  pipe(may, setDate(6)),
  pipe(august, setDate(15)),
  pipe(august, setDate(16)),
  pipe(august, setDate(17)),
  pipe(august, setDate(18)),
  pipe(august, setDate(19)),
  pipe(september, setDate(2)),
  pipe(november, setDate(1)),
  pipe(november, setDate(2)),
  pipe(november, setDate(3)),
  pipe(november, setDate(4)),
  pipe(december, setDate(24)),
  pipe(december, setDate(25)),
  pipe(december, setDate(26)),
  pipe(december, setDate(27)),
  pipe(december, setDate(28)),
  pipe(december, setDate(29)),
  pipe(december, setDate(30)),
  pipe(december, setDate(31)),
  pipe(january, setDate(1)),
  pipe(january, setDate(2)),
  pipe(january, setDate(6)),
  pipe(january, setDate(7)),
]
