import { Text } from 'ui'
import { CardBox, Emoji, Flex, PageLayout } from 'ui-deprecated'
import { Translation } from '~/components/Translation'

export const ReviewThankYou = () => (
  <PageLayout maxWidth="400px">
    <CardBox>
      <Flex>
        <Flex align="center" pb={8}>
          <Emoji>😊</Emoji>
        </Flex>

        <Flex pb={24}>
          <Text fontWeight="600" kind="h2" textAlign="center">
            <Translation id="productReviews.title.negativeReviewLeft" />
          </Text>
        </Flex>

        <Flex pb={24}>
          <Text kind="paragraph" textAlign="center">
            <Translation id="productReviews.subtitle.negativeReviewLeft" />
          </Text>
        </Flex>
      </Flex>
    </CardBox>
  </PageLayout>
)
