import { ComponentProps } from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { TextField } from 'ui-deprecated'
import { useLoadingState } from '~/hooks/useLoadingState'

type TextFieldProps = ComponentProps<typeof TextField>

type Props = Omit<TextFieldProps, 'value'> & {
  rules?: RegisterOptions
}

export const ReactHookFormTextField = ({ disabled, name, rules, ...props }: Props) => {
  const { control } = useFormContext()
  const { loading } = useLoadingState()

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error, invalid },
        formState: { isSubmitting, isSubmitted, isValid },
      }) => (
        <TextField
          {...props}
          ref={ref}
          disabled={disabled || ((isSubmitting || loading) && isValid)}
          errorMessage={error?.message}
          invalid={invalid && isSubmitted}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        />
      )}
      rules={rules}
    />
  )
}
