import { IFlagsmith } from 'flagsmith/types'
import { createContext, memo, ReactElement, useEffect } from 'react'
import { flagsmithByUserId } from '~/clients/flagsmith'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useFeatureFlagsInit } from '~/domains/featureFlags/hooks/useFeatureFlagsInit'
import { useSessionStorageParams } from '~/hooks/useSessionStorageParams'
import { upsertAnonymousFingerprint } from '~/utils/upsertAnonymousFingerprint'

type Props = {
  children: ReactElement
}

type Context = IFlagsmith

export const FeatureFlagsByUserIdContext = createContext<Context>(flagsmithByUserId)

/**
 * Warning about mounting both `FeatureFlagsByFingerprint` and `FeatureFlagsByUserId`
 *
 * Set `cacheFlags` to `false` in `src/clients/flagsmith.ts`
 */
export const FeatureFlagsByUserId = memo(({ children }: Props) => {
  const { init, initialized } = useFeatureFlagsInit(flagsmithByUserId)
  const { idTokenPayload } = useAuthState()
  const fingerprintId = upsertAnonymousFingerprint()

  const sessionStorage = useSessionStorageParams()

  useEffect(() => {
    if (!idTokenPayload?.user_id) {
      return
    }

    init(idTokenPayload.user_id, {
      ...(!!idTokenPayload.groups.length && { groups: idTokenPayload.groups.join(',') }),
      ...sessionStorage,
      email: idTokenPayload.email,
      userId: idTokenPayload.user_id,
      fingerprintId,
    })
  }, [fingerprintId, idTokenPayload, init, sessionStorage])

  useEffect(
    () => () => {
      flagsmithByUserId.logout()
    },
    [],
  )

  if (!initialized && idTokenPayload?.user_id) {
    return null
  }

  return (
    <FeatureFlagsByUserIdContext.Provider value={flagsmithByUserId}>{children}</FeatureFlagsByUserIdContext.Provider>
  )
})
