import { addMonths, getTime, startOfDay } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'

export const upsertReviewAskAt = () => {
  const timestamp = getLocalStorage('review-ask-at')

  if (timestamp) {
    return timestamp
  }

  const reviewAskAt = pipe(nowInMilliseconds(), addMonths(1), startOfDay, getTime, String)

  setLocalStorage('review-ask-at', reviewAskAt)

  return reviewAskAt
}
