import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'
import { JournalingProvider } from '../journaling/hooks/useJournalingContext'

const LazyJournalingSettingsPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.JournalingSettingsPageView,
})

export const JournalingSettingsPage: Page = {
  exact: true,
  id: 'journaling.settings',
  paths: ['/journaling/settings'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'journaling.settings',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: JournalingProvider,
  PageView: () => <LazyJournalingSettingsPageView />,
}
