import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertTherapist } from '~/domains/auth/components/AssertTherapist'
import { AssertFeatureFlagVariant1Redirect } from '~/domains/featureFlags/components/AssertFeatureFlagVariant1Redirect'
import { SaasTherapistProvider } from '~/domains/saas/hooks/useSaasTherapist'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

const LazySaasOnboardingInternal = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const SaasOnboardingInternalPage: Page = {
  exact: true,
  id: 'saas.onboardingInternal',
  paths: ['/gesto/onboarding/internal'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: true,
    seoKey: 'saas.onboardingInternal',
    title: 'saas.onboardingInternal',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertFeatureFlagVariant1Redirect name="ff_saas_onboarding_internal">
        <AssertTherapist>{children}</AssertTherapist>
      </AssertFeatureFlagVariant1Redirect>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: SaasTherapistProvider,
  PageView: () => <LazySaasOnboardingInternal />,
}
