import { Text } from 'ui'
import { Flex, LinkButton, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useIsNativeAppAndroid } from '~/domains/appNative/hooks/useIsNativeAppAndroid'
import { TranslationId } from '~/i18n/types'
import { getEnv } from '~/utils/getEnv'

export const AskForReviewRouteGood = () => {
  const isNativeAppAndroid = useIsNativeAppAndroid()

  const storeUrl = isNativeAppAndroid
    ? `${getEnv('SERENIS_PLAY_STORE')}&showAllReviews=true`
    : `${getEnv('SERENIS_APP_STORE')}?action=write-review`

  const storeReviewTranslationId: Extract<TranslationId, `reviews.askReviewModal.good.leaveReview.${string}`> =
    isNativeAppAndroid
      ? 'reviews.askReviewModal.good.leaveReview.android'
      : 'reviews.askReviewModal.good.leaveReview.ios'

  return (
    <>
      <OverflowAuto>
        <Text colorName="black" fontWeight="600" kind="h3" textAlign="center">
          <Translation id="reviews.askReviewModal.good.title" />
        </Text>
        <TranslationMarkdown
          colorName="black"
          id="reviews.askReviewModal.good.description"
          kind="paragraph"
          pt={8}
          textAlign="center"
        />
      </OverflowAuto>

      <Flex pt={16}>
        <LinkButton href={storeUrl} kind="primary" size="small" testId="leave-review-button">
          <Translation id={storeReviewTranslationId} />
        </LinkButton>
      </Flex>
    </>
  )
}
