import { useCallback } from 'react'
import { provinces } from 'validation'
import { SelectOption } from '~/components/SelectNew/types'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'
import { ReactHookFormSelectNew } from '../components/ReactHookFormSelectNew'

export const FieldProvince = () => {
  const {
    errors: { required },
    placeholders,
  } = useFormTranslations()

  const { isProvince } = useFormValidation()

  const validate = useCallback(
    (value: undefined | SelectOption<string>) => {
      if (!value) {
        return false
      }

      return isProvince(value.value)
    },
    [isProvince],
  )

  return (
    <ReactHookFormSelectNew
      name="province"
      options={provinces.map(({ name, code }) => ({ label: name, value: code }))}
      placeholder={placeholders.province}
      rules={{ required, validate }}
      validateOnSubmit={false}
    />
  )
}
