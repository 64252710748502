import { Flex, MountOn, OverflowHidden } from 'cdk'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { LanguageSwitcherPopover } from '~/i18n/components/LanguageSwitcherPopover'
import { TrustpilotWidget } from './TrustpilotWidget'
import { PropsWithChildren } from 'react'
import { useTranslationVariablesTherapySessionCosts } from '../hooks/useTranslationVariablesTherapySessionCosts'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { signupIntentHasFirstTherapySessionFree, useSignupIntent } from '../hooks/useSignupIntent'
import { useConventionCodeName } from '~/domains/convention/hooks/useConventionCodeName'
import { usePartnershipQueryParam } from '~/hooks/usePartnershipQueryParam'
import { Icon } from 'icons'
import { Price } from '~/components/Price'
import styled from 'styled-components'
import { useIsUtmSource } from '~/hooks/useIsUtmSource'

const FlexWithNegativeMargin = styled(Flex)`
  margin: -1px;
`

const ListItem = ({ children }: PropsWithChildren) => (
  <Flex $align="center" $direction="row" $gap={8}>
    <Flex $basis="24px">
      <Icon colorName="white" name="check" size={20} />
    </Flex>
    <Flex $shrink={1}>{children}</Flex>
  </Flex>
)

const Prices = () => {
  const intent = useSignupIntent()
  const isWellhubUtmSource = useIsUtmSource('wellhub-srns')
  const paymentPlanLeftDetail = useTranslation(`paymentPlan.${intent}.left.detail`)
  const paymentPlanRightDetail = useTranslation(`paymentPlan.${intent}.right.detail`)
  const i18Variables = useTranslationVariablesTherapySessionCosts(intent)
  const hasIntentFirstTherapySessionFree = signupIntentHasFirstTherapySessionFree(intent)

  if (isWellhubUtmSource) {
    return null
  }

  return (
    <Flex $align="flex-start" $gap={8}>
      <Text colorName="white" kind="paragraph">
        {!hasIntentFirstTherapySessionFree ? (
          <MountOn mediaQuery="lt-lg">
            <Translation id={`signup.${intent}.subtitle.variant`} />
          </MountOn>
        ) : (
          <Translation id={`signup.${intent}.subtitleOverPrice.variant`} />
        )}
      </Text>
      <Flex $direction="row">
        <OverflowHidden
          $basis={400}
          $borderColorName="white"
          $borderRadius={16}
          $borderSize={1}
          $direction="row"
          $maxWidth="100%"
          $minWidth={320}
          $wrap="wrap"
        >
          <Flex $grow={1} $lgBasis="50%" $p={16} $shrink={1}>
            <Price
              $color="white"
              amount={
                i18Variables.bundleTherapySessionCost
                  ? i18Variables.therapySessionCost
                  : i18Variables.firstTherapySessionCost
              }
              detail={paymentPlanLeftDetail}
            />
            <Text colorName="white" kind="caption">
              <Translation id={`paymentPlan.${intent}.left.planName`} />
            </Text>
          </Flex>
          <FlexWithNegativeMargin
            $borderColorName="white"
            $borderSizeLeft={1}
            $borderSizeTop={1}
            $grow={1}
            $lgBasis="50%"
            $p={16}
            $shrink={1}
          >
            <Price
              $color="white"
              amount={
                i18Variables.bundleTherapySessionCost
                  ? i18Variables.bundleTherapySessionCost
                  : i18Variables.therapySessionCost
              }
              detail={paymentPlanRightDetail}
            />
            <Text colorName="white" kind="caption">
              <Translation id={`paymentPlan.${intent}.right.planName`} />
            </Text>
          </FlexWithNegativeMargin>
        </OverflowHidden>
      </Flex>
    </Flex>
  )
}

export const SignupHeroContentControl = () => {
  const intent = useSignupIntent()
  const conventionCodeName = useConventionCodeName()
  const partnership = usePartnershipQueryParam()

  const i18Variables = useTranslationVariablesTherapySessionCosts(intent)

  return (
    <Flex $lgBasis="50%">
      <MountOn mediaQuery="gt-md">
        <Flex $align="center" $direction="row" $gap={8} $justify="flex-end" $pr={64} $pt={32}>
          <LanguageSwitcherPopover colorName="white" />
        </Flex>
      </MountOn>
      <Flex
        $gap={16}
        $grow={1}
        $lgJustify="space-between"
        $lgPx={64}
        $lgPy={64}
        $maxHeight="calc(100vh - 24px)"
        $px={16}
        $py={24}
        $shrink={1}
      >
        <Flex $gap={16}>
          <Text colorName="white" fontWeight="600" kind="public-h3">
            <Translation id={`signup.${intent}.title.variant`} />
          </Text>
          <MountOn mediaQuery="gt-md">
            <TranslationMarkdown
              colorName="white"
              fontWeight="400"
              id={`signup.${intent}.subtitle.variant`}
              kind="h3"
            />
            <Flex $pt={24}>
              <TrustpilotWidget />
            </Flex>
          </MountOn>
        </Flex>
        {conventionCodeName || partnership ? (
          <Flex $gap={8}>
            {(['1', '2', '3'] as const).map((item) => (
              <ListItem key={item}>
                <TranslationMarkdown
                  colorName="white"
                  id={`signup.firstSessionFree.ul.convention.${item}`}
                  kind="paragraph"
                  values={{
                    ...i18Variables,
                    convention: conventionCodeName || partnership || '',
                  }}
                />
              </ListItem>
            ))}
          </Flex>
        ) : (
          <Prices />
        )}
      </Flex>
    </Flex>
  )
}
