import { media } from 'cdk'
import { ComponentProps, PropsWithChildren, useCallback, useMemo } from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import { CardBox, MaxWidth1024px, MaxWidth400px, MaxWidth640px } from 'ui-deprecated'
import { Drawer } from '~/components/Drawer'
import { ModalView } from '~/components/ModalView'
import { useBreakpoints } from '~/hooks/useBreakpoints'
import { modalContainerDesktop, modalContainerMobile } from '../constants/containers'
import { styleDesktop, styleMobile } from '../constants/style'
import { ModalId, useModals } from '../hooks/useModals'

type Props = {
  id: ModalId
  closeOnClickOutside?: boolean
  size?: 'md' | 'lg' | 'xl'
}

const ContainerMd = styled(MaxWidth400px)`
  min-width: 400px;
`

const ContainerLg = styled(MaxWidth640px)`
  min-width: 640px;
`

const ContainerXl = styled(MaxWidth1024px)`
  ${media.md`
    min-width: 640px;
  `}

  ${media.gtMd`
    min-width: 960px;
  `}
`

export const Modal = ({ children, closeOnClickOutside = true, id, size = 'md' }: PropsWithChildren<Props>) => {
  const { dismiss, isOpen } = useModals()
  const { isSmallOnly } = useBreakpoints()

  const onRequestClose = useCallback(() => {
    dismiss(id)
  }, [dismiss, id])

  const appElement = useMemo(() => document.getElementById('__next') as HTMLElement, [])

  const desktopParentSelector = useCallback(() => document.getElementById(modalContainerDesktop) as HTMLElement, [])

  const mobileParentSelector = useCallback(() => document.getElementById(modalContainerMobile) as HTMLElement, [])

  const commonProps: ComponentProps<typeof ReactModal> = {
    isOpen: isOpen(id),
    onRequestClose,
    shouldCloseOnEsc: closeOnClickOutside,
    shouldCloseOnOverlayClick: closeOnClickOutside,
  }

  return (
    <>
      {commonProps.isOpen && <ModalView id={id} />}

      {!isSmallOnly && (
        <ReactModal
          {...commonProps}
          appElement={appElement}
          parentSelector={desktopParentSelector}
          style={styleDesktop}
        >
          {size === 'md' && (
            <CardBox borderColor="white" grow={1} shrink={1}>
              <ContainerMd grow={1} shrink={1}>
                {children}
              </ContainerMd>
            </CardBox>
          )}
          {size === 'lg' && (
            <CardBox borderColor="white" grow={1} shrink={1}>
              <ContainerLg grow={1} shrink={1}>
                {children}
              </ContainerLg>
            </CardBox>
          )}
          {size === 'xl' && (
            <CardBox borderColor="white" grow={1} lgPy={16} p={0} shrink={1}>
              <ContainerXl grow={1} shrink={1}>
                {children}
              </ContainerXl>
            </CardBox>
          )}
        </ReactModal>
      )}

      {isSmallOnly && (
        <ReactModal {...commonProps} appElement={appElement} parentSelector={mobileParentSelector} style={styleMobile}>
          <Drawer>{children}</Drawer>
        </ReactModal>
      )}
    </>
  )
}
