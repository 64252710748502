import { useCallback } from 'react'
import { Button } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useReservationFlow } from '~/domains/reservation/hooks/useReservationFlow'
import { useRootHistory } from '~/hooks/useRootHistory'
import { getRoute } from '~/utils/getRoute'

export const SkipBookingButton = () => {
  const { loading } = useReservationFlow()
  const history = useRootHistory()

  const skipBooking = useCallback(() => {
    history.push(getRoute('/'))
  }, [history])

  return (
    <Button
      form="reservationFlow"
      kind="primary"
      loading={loading}
      onClick={skipBooking}
      size="small"
      testId="skip-booking-cta"
      type="submit"
    >
      <Translation id="actions.proceed" />
    </Button>
  )
}
