import { useEffect } from 'react'
import { useStepNavigation } from '../useStepNavigation'

const useStepNavigationShowBackButtonEffect = () => {
  const { showBackButton } = useStepNavigation()

  useEffect(() => {
    showBackButton()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const StepNavigationShowBackButtonEffect = () => {
  useStepNavigationShowBackButtonEffect()

  return null
}
