import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

const LazyTherapiesPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.TherapiesPageView,
})

export const TherapiesPage: Page = {
  exact: true,
  id: 'therapies',
  paths: ['/therapies', '/therapies/:therapyId', '/therapies/:therapyId/details'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'therapies',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PatientTherapiesProvider,
  PageView: () => <LazyTherapiesPageView />,
}
