import { Flex } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import { Icon } from 'icons'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { Translation } from '~/components/Translation'
import { getRoute } from '~/utils/getRoute'
import { NavigationProps } from '../../types'
import { FlexWithTransition } from '../common/FlexWithTransition'
import { NavLinkContainer } from '../common/NavLinkContainer'
import { TextSize18 } from '../common/TextSize18'

type Props = {
  onLinkClick?: () => void
} & NavigationProps

export const LinkNotification = ({ extended, onLinkClick, isMobile = false }: Props) => {
  const { pathname } = useLocation()
  const active = !!matchPath(pathname, {
    path: [getRoute('/communications')],
    exact: true,
  })

  return (
    <Link onClick={onLinkClick} to={getRoute('/communications')}>
      <NavLinkContainer $direction="row" $gap={SPACING_SM}>
        <Flex>
          <Icon
            colorName={active ? 'primary-50' : 'grey-110'}
            fillColorName="lighter"
            name="srns-ringing-bell"
            size={24}
          />
        </Flex>
        {!isMobile && (
          <FlexWithTransition $opacity={extended ? 1 : 0}>
            <TextSize18 colorName={active ? 'primary-50' : 'grey-110'} fontWeight={active ? '600' : '500'} kind="h3">
              <Translation id="menu.notification" />
            </TextSize18>
          </FlexWithTransition>
        )}
      </NavLinkContainer>
    </Link>
  )
}
