import { Flex, OverflowAuto } from 'cdk'
import { Icon } from 'icons'
import Link from 'next/link'
import { useCallback, useEffect, useState } from 'react'
import { Button, Modal, ModalContent, ModalDescription, ModalTitle } from 'ui'
import { useExitIntent } from 'use-exit-intent'
import { Translation } from '~/components/Translation'
import { TrackModalView } from '~/domains/analytics/components/TrackModalView'
import { useTrackEvent } from '~/domains/analytics/hooks/useTrackEvent'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { getRoute } from '~/utils/getRoute'

export const BookingLeaveModal = () => {
  const trackClick = useTrackEvent('CLICK')
  const rootHistory = useRootHistory()
  const { registerHandler } = useExitIntent({
    desktop: {
      triggerOnIdle: false,
      useBeforeUnload: true,
      triggerOnMouseLeave: true,
      delayInSecondsToTrigger: 10,
    },
    mobile: {
      triggerOnIdle: false,
      delayInSecondsToTrigger: 10,
    },
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isBlocking, setIsBlocking] = useState(true)

  const handleWhatsappClick = useCallback(() => {
    trackClick({ name: 'clicks.bookingLeave.openWhatsapp' })
  }, [trackClick])

  const handleExitIntent = useCallback(() => {
    if (isBlocking) {
      setIsModalOpen(true)
    }
  }, [isBlocking])

  const handleContinueWithoutBookingClick = useCallback(() => {
    rootHistory.push(getRoute('/'))
    setIsModalOpen(false)
  }, [rootHistory])

  registerHandler({
    id: 'openExitIntentModal',
    handler: handleExitIntent,
  })

  const handleModalIsOpenChange = useCallback((isOpen: boolean) => {
    setIsModalOpen(isOpen)

    if (!isOpen) {
      setIsBlocking(false)
    }
  }, [])

  useEffect(() => () => {
    window.onbeforeunload = null
  })

  return (
    <Modal isOpen={isModalOpen} onIsOpenChange={handleModalIsOpenChange}>
      <ModalContent>
        <TrackModalView name="exitIntentBookingClose" />
        <Flex $gap={32}>
          <OverflowAuto $gap={16} $grow={1} $shrink={1}>
            <ModalTitle>
              <Translation id="formFlow.modal.leave.email.title" />
            </ModalTitle>
            <ModalDescription>
              <Translation id="formFlow.modal.leave.email.description" />
            </ModalDescription>
          </OverflowAuto>
          <Flex $gap={16}>
            <Flex>
              <Button isGhost kind="primary" onClick={handleContinueWithoutBookingClick} size="lg">
                <Translation id="suggestedTherapist.noReservation.continue.cta" />
              </Button>
            </Flex>
            <Link href={useTranslation('whatsapp.link.booking')} onClick={handleWhatsappClick}>
              <Flex>
                <Button kind="secondary" size="lg">
                  <Translation id="actions.openWhatsapp" />
                  <Icon name="whatsapp" size={32} />
                </Button>
              </Flex>
            </Link>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
