import { ReactHookFormTextFieldNew } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'

export const FieldPostalCode = () => {
  const {
    errors: { required },
    labels,
    placeholders,
  } = useFormTranslations()

  const { isPostalCode } = useFormValidation()

  return (
    <ReactHookFormTextFieldNew
      autoComplete="billing postal-code"
      label={labels.postalCode}
      name="postalCode"
      placeholder={placeholders.postalCode}
      rules={{ required, validate: isPostalCode }}
      type="text"
      validateOnSubmit={false}
    />
  )
}
