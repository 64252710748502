import { format, FormatDateEnum } from 'dates'
import { pipe } from 'fp-ts/function'
import { useCallback, useState } from 'react'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { useModals } from '~/domains/modals'
import { useMarkAsNoShow } from '~/domains/therapy-session/hooks/useMarkAsNoShowTherapySession'
import { useTherapySessionsTherapistAgenda } from '~/domains/therapy-session/hooks/useTherapySessionsTherapistAgenda'
import { useTherapistAgendaByPatientId } from '~/hooks/useTherapistAgendaByPatientId'
import { useToasts } from '~/hooks/useToasts'

type Props = Pick<TherapistAgendaTherapySession, 'id' | 'startAt' | 'patient'>

export const MarkAsNoShowTherapySessionModal = ({ id, startAt, patient }: Props) => {
  const [loading, setLoading] = useState(false)
  const { close } = useModals()
  const { addToast } = useToasts()

  const [markAsNoShow] = useMarkAsNoShow()
  const { refetch: refetchUserTherapistAgenda } = useTherapySessionsTherapistAgenda()
  const { refetch: refetchTherapistAgendaByPatientId } = useTherapistAgendaByPatientId(patient.id, 'cache-only')

  const handleClose = useCallback(async () => {
    close('markAsNoShowTherapySession')
  }, [close])

  const onConfirm = useCallback(async () => {
    setLoading(true)

    try {
      const response = await markAsNoShow({
        variables: {
          input: {
            id,
          },
        },
      })

      setLoading(false)

      if (!response.data?.markAsNoShow?.ok) {
        addToast({ translationId: 'therapySession.markAsNoShow.cannotMarkAsNoShowTherapySession', type: 'alert' })

        return
      }

      addToast({ translationId: 'therapySession.markAsNoShow.therapySessionMarkedAsNoShow', type: 'success' })

      await refetchUserTherapistAgenda()
      await refetchTherapistAgendaByPatientId()

      await handleClose()
    } catch (error) {
      addToast({ translationId: 'therapySession.markAsNoShow.cannotMarkAsNoShowTherapySession', type: 'alert' })

      setLoading(false)
    }
  }, [addToast, handleClose, id, markAsNoShow, refetchTherapistAgendaByPatientId, refetchUserTherapistAgenda])

  return (
    <>
      <OverflowAuto>
        <Text fontWeight="600" kind="h3" textAlign="center">
          <Translation
            id="therapySession.markAsNoShow.modal.title"
            values={{
              dateWithDayAndMonth: pipe(startAt, format(FormatDateEnum.DAY_MONTH_YEAR)),
            }}
          />
        </Text>

        <TranslationMarkdown
          id="therapySession.markAsNoShow.modal.subtitle"
          kind="paragraph"
          pt={24}
          values={{
            patientFirstName: patient.firstName,
          }}
        />
      </OverflowAuto>

      <Flex pt={16}>
        <Button kind="danger" loading={loading} onClick={onConfirm} type="button">
          <Translation id="actions.markAsNoShow" />
        </Button>
      </Flex>
    </>
  )
}
