import { pipe } from 'fp-ts/function'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Rating } from '~/components/Rating'
import { Translation } from '~/components/Translation'
import { useIsNativeAppAndroid } from '~/domains/appNative/hooks/useIsNativeAppAndroid'
import { useProductUserFeedbackAnswerUpsert } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackAnswerUpsert'
import { getReviewRoute } from '~/domains/reviews/utils/getReviewRoute'
import { useToasts } from '~/hooks/useToasts'
import { getEnv } from '~/utils/getEnv'

type Props = {
  onConfirm: () => void
  onDismiss: () => void
  productUserFeedbackId: string
}

export const AskForReviewRouteIntro = ({ onConfirm, onDismiss, productUserFeedbackId }: Props) => {
  const history = useHistory()
  const { addToast } = useToasts()
  const [rating, setRating] = useState(0)
  const [loading, setLoading] = useState(false)
  const isNativeAppAndroid = useIsNativeAppAndroid()
  const { productUserFeedbackAnswerUpsert } = useProductUserFeedbackAnswerUpsert(productUserFeedbackId)

  const storeUrl = isNativeAppAndroid
    ? `${getEnv('SERENIS_PLAY_STORE')}&showAllReviews=true`
    : `${getEnv('SERENIS_APP_STORE')}?action=write-review`

  const onSubmit = useCallback(async () => {
    if (!rating || Number.isNaN(rating)) {
      addToast({
        translationId: 'reviews.askReviewModal.intro.missingRating',
        type: 'alert',
      })

      return
    }

    setLoading(true)

    const productUserFeedbackAnswerUpsertResponse = await productUserFeedbackAnswerUpsert({
      id: 'APP_REVIEW_RATING',
      value: pipe(rating, String),
    })

    setLoading(false)

    if (!productUserFeedbackAnswerUpsertResponse.data?.productUserFeedbackAnswerUpsert?.id) {
      addToast({
        translationId: 'reviews.askReviewModal.intro.unableToSaveResponse',
        type: 'alert',
      })

      return
    }

    if (rating <= 3) {
      history.push(getReviewRoute('/bad'))

      return
    }

    window.open(storeUrl, '_blank')

    history.push(getReviewRoute('/good'))

    onConfirm()
  }, [addToast, history, onConfirm, productUserFeedbackAnswerUpsert, rating, storeUrl])

  return (
    <>
      <OverflowAuto>
        <Text colorName="black" fontWeight="600" kind="h3">
          <Translation id="reviews.askReviewModal.intro.title" />
        </Text>
        <Flex pt={8}>
          <Text colorName="black" kind="paragraph">
            <Translation id="reviews.askReviewModal.intro.description" />
          </Text>
        </Flex>
        <Flex pt={24}>
          <Rating onRate={setRating} />
        </Flex>
      </OverflowAuto>

      <Flex pt={16}>
        <Button
          disabled={!rating}
          kind="primary"
          loading={loading}
          onClick={onSubmit}
          size="small"
          testId="ask-for-review-button-confirm"
        >
          <Translation id="reviews.askReviewModal.intro.confirm" />
        </Button>
        <Flex pt={16}>
          <Button
            disabled={loading}
            kind="secondary"
            onClick={onDismiss}
            size="small"
            testId="ask-for-review-button-dismiss"
          >
            <Translation id="reviews.askReviewModal.intro.dismiss" />
          </Button>
        </Flex>
      </Flex>
    </>
  )
}
