import { Icon } from 'icons'
import { useCallback, useState } from 'react'
import { Text } from 'ui'
import { CardBox, Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'

type Props = {
  accordionAlways?: boolean
}

export const HowItWorksPayment = ({ accordionAlways = false }: Props) => {
  const [hidden, setHidden] = useState(true)

  const onClick = useCallback(() => {
    setHidden((value) => !value)
  }, [])

  return (
    <CardBox>
      <Pressable
        align="center"
        colorName="purple08"
        colorNameHover="purple10"
        direction="row"
        justify="space-between"
        mdHide={!accordionAlways}
        onClick={onClick}
      >
        <Text fontWeight="600" kind="paragraph">
          <Translation id="payments.howItWorks.settings.title" />
        </Text>

        <Flex align="flex-end" pl={8}>
          {hidden && <Icon colorName="primary" name="nav-arrow-down" size={24} />}
          {!hidden && <Icon colorName="primary" name="nav-arrow-up" size={24} />}
        </Flex>
      </Pressable>

      <Flex hide mdHide={accordionAlways}>
        <Text colorName="primary" fontWeight="600" kind="paragraph">
          <Translation id="payments.howItWorks.settings.title" />
        </Text>
      </Flex>

      <Flex hide={hidden} mdHide={accordionAlways ? hidden : accordionAlways}>
        <Flex direction="row" pt={20}>
          <Flex pr={16}>
            <Icon colorName="grey-60" name="credit-card" size={24} />
          </Flex>

          <Flex grow={1} shrink={1}>
            <TranslationMarkdown
              colorName="black"
              id="payments.howItWorks.settings.chargeFirstAttempt"
              kind="paragraph"
            />
          </Flex>
        </Flex>

        <Flex direction="row" pt={20}>
          <Flex pr={16}>
            <Icon colorName="grey-60" name="alarm" size={24} />
          </Flex>

          <Flex grow={1} shrink={1}>
            <TranslationMarkdown
              colorName="black"
              id="payments.howItWorks.settings.chargeFirstAttemptFailure"
              kind="paragraph"
            />
          </Flex>
        </Flex>

        <Flex direction="row" pt={20}>
          <Flex pr={16}>
            <Icon colorName="grey-60" name="credit-card-slash" size={24} />
          </Flex>

          <Flex grow={1} shrink={1}>
            <TranslationMarkdown
              colorName="black"
              id="payments.howItWorks.settings.chargeSecondAttempt"
              kind="paragraph"
            />
          </Flex>
        </Flex>
      </Flex>
    </CardBox>
  )
}
