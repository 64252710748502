import { Flex, Pressable } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import { Icon } from 'icons'
import { useModals } from '~/domains/modals'
import { NavLinkContainer } from '../common/NavLinkContainer'

export const LinkMemberGetMember = () => {
  const { open } = useModals()

  return (
    <Pressable onClick={() => open('referralShare')}>
      <NavLinkContainer $direction="row" $gap={SPACING_SM}>
        <Flex>
          <Icon colorName="neutral-110" fillColorName="lighter" name="srns-member-get-member" size={24} />
        </Flex>
      </NavLinkContainer>
    </Pressable>
  )
}
