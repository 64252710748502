import { memo } from 'react'
import { useBreakpoints } from '~/hooks/useBreakpoints'
import { TabNavigationMenuProvider } from './hooks/useTabNavigationMenu'
import { PatientTabNavigationHorizontal } from './PatientTabNavigationHorizontal'
import { PatientTabNavigationVertical } from './PatientTabNavigationVertical'
import { NavigationProps } from './types'

export const PatientTabNavigation = memo(({ direction }: NavigationProps) => {
  const { isMedium, isSmallOnly } = useBreakpoints()

  const horizontal = isSmallOnly && direction === 'horizontal'
  const vertical = isMedium && direction === 'vertical'

  if (!horizontal && !vertical) {
    return null
  }

  return (
    <TabNavigationMenuProvider>
      {horizontal && <PatientTabNavigationHorizontal />}
      {vertical && <PatientTabNavigationVertical />}
    </TabNavigationMenuProvider>
  )
})

PatientTabNavigation.displayName = 'PatientTabNavigation'
