import {
  registerDecorator,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator'

export const isPassword = (value: unknown) => {
  if (typeof value !== 'string') {
    return false
  }

  return /\S{8,}$/.test(value.trim())
}

@ValidatorConstraint({ name: 'password', async: false })
export class IsPasswordConstraint implements ValidatorConstraintInterface {
  validate = isPassword

  defaultMessage = () => 'Input is not a valid password'
}

export function IsPassword<T extends object>(validationOptions?: ValidationOptions) {
  return function (object: T, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsPasswordConstraint,
    })
  }
}
