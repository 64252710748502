import { ComponentProps } from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { useLoadingState } from '~/hooks/useLoadingState'
import { TextArea } from './TextArea'

type TextAreaProps = ComponentProps<typeof TextArea>

type Props = Omit<TextAreaProps, 'errorMessage' | 'value'> & {
  rules?: RegisterOptions
  validateOnSubmit?: boolean
}

export const ReactHookFormTextArea = ({ disabled, name, rules, validateOnSubmit = true, ...props }: Props) => {
  const { control, formState } = useFormContext()
  const { loading } = useLoadingState()

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error, invalid },
        formState: { isValid, isSubmitted },
      }) => (
        <TextArea
          {...props}
          ref={ref}
          disabled={disabled || ((formState.isSubmitting || loading) && isValid)}
          errorMessage={error?.message}
          invalid={(invalid && !validateOnSubmit) || (invalid && isSubmitted && validateOnSubmit)}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
        />
      )}
      rules={rules}
    />
  )
}
