import { CSSProperties, ReactNode, Ref, createContext, useCallback, useContext, useRef, useState } from 'react'
import { FlexProps, OverflowAuto } from 'ui-deprecated'

type Context = {
  ref: Ref<HTMLDivElement>
  scrollTo: () => void
}

const ScrollRestoreContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
} & Partial<FlexProps>

const options: ScrollToOptions = {
  behavior: 'auto',
  top: 0,
  left: 0,
}

export const ScrollRestoreProvider = ({ children, ...props }: Props) => {
  const [scrollBehavior, setScrollBehavior] = useState<NonNullable<CSSProperties['scrollBehavior']>>('smooth')
  const ref = useRef<HTMLDivElement>(null)

  const scrollTo = useCallback(() => {
    if (!ref.current) {
      return
    }

    setScrollBehavior('auto')

    const { current } = ref

    setTimeout(() => {
      current.scroll(options)

      window.scroll(options)

      setScrollBehavior('smooth')
    }, 0)
  }, [])

  return (
    <ScrollRestoreContext.Provider value={{ ref, scrollTo }}>
      <OverflowAuto ref={ref} {...props} style={{ scrollBehavior, overflowX: 'hidden' }}>
        {children}
      </OverflowAuto>
    </ScrollRestoreContext.Provider>
  )
}

export const useScrollRestore = () => {
  const context = useContext(ScrollRestoreContext)

  if (!context) {
    throw new Error('The `useScrollRestore` should be wrapped with `ScrollRestoreProvider`.')
  }

  return context
}
