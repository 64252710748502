import { ReactHookFormTextFieldNew } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'

export const FieldForeignRegion = () => {
  const {
    errors: { required },
    labels,
    placeholders,
  } = useFormTranslations()

  return (
    <ReactHookFormTextFieldNew
      autoComplete="billing address-level2"
      label={labels.foreignRegion}
      name="foreignRegion"
      placeholder={placeholders.foreignRegion}
      rules={{ required }}
      type="text"
      validateOnSubmit={false}
    />
  )
}
