import styled from 'styled-components'
import { View } from 'ui-deprecated'
import { LoginIllustration } from '~/domains/formFlow/components/LoginIllustration'

const Image = styled(View).withConfig({
  shouldForwardProp: (value: string): boolean => !['src'].includes(value),
})<{ src: string }>`
  max-width: 140px;
  min-height: 140px;
  background-image: ${({ src }) => src};
  background-size: 100%;
  background-repeat: no-repeat;
  transform: scaleX(-1);
`

export const LoginImage = () => <Image src={LoginIllustration} />
