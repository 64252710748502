import { useMemo } from 'react'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useCountries } from '~/i18n/hooks/useCountries'
import { ReactHookFormSelectNew } from '../components/ReactHookFormSelectNew'

export const FieldCountry = () => {
  const { countries } = useCountries()

  const {
    errors: { required },
    placeholders,
  } = useFormTranslations()

  const options = useMemo(
    () =>
      countries.map(({ code, name }) => ({
        label: name,
        value: code,
      })),
    [countries],
  )

  return (
    <ReactHookFormSelectNew
      name="country"
      options={options}
      placeholder={placeholders.country}
      rules={{ required }}
      validateOnSubmit={false}
    />
  )
}
