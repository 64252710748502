import { useEffect } from 'react'
import { useStepNavigation } from '../useStepNavigation'

const useStepNavigationHideBackButtonEffect = () => {
  const { hideBackButton } = useStepNavigation()

  useEffect(() => {
    hideBackButton()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const StepNavigationHideBackButtonEffect = () => {
  useStepNavigationHideBackButtonEffect()

  return null
}
