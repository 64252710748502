import { Flex, PositionFixed } from 'cdk'
import { COLOR_PRIMARY_80, OPACITY_24, SPACING_XS } from 'design-tokens'
import styled from 'styled-components'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useTabNavigationMenu } from '../../TabNavigation/hooks/useTabNavigationMenu'
import { PatientTabMenu } from '../../TabNavigation/PatientTabMenu'
import { TherapistTabMenu } from '../../TabNavigation/TherapistTabMenu'
import { patientNavigationLinks } from '../constants/patientNavigationLinks'
import { therapistNavigationLinks } from '../constants/therapistNavigationLinks'
import { BottomBarLinks } from './BottomBarLinks'
import { LinkMenu } from './custom/LinkMenu'

const BOTTOM_BAR_HEIGHT = '56px'

const BottomBarContainer = styled(Flex).attrs({
  $basis: 1,
  $direction: 'row',
  $gap: SPACING_XS,
  $grow: 1,
  $justify: 'space-evenly',
  $maxHeight: BOTTOM_BAR_HEIGHT,
  $minHeight: BOTTOM_BAR_HEIGHT,
})`
  box-shadow: 0 0.5px 0 0 rgb(from ${COLOR_PRIMARY_80} r g b / ${OPACITY_24}) inset;
  transition: all 0.2s;
`

export const BottomBar = () => {
  const { open } = useTabNavigationMenu()
  const { isVariant1 } = useFeatureFlagsByUserId()
  const isFoodJournalEnabled = isVariant1('ff_nutrition')

  const isTherapist = useUserActingAsGroup() === 'therapist'
  const links = isTherapist ? therapistNavigationLinks : patientNavigationLinks(isFoodJournalEnabled)

  return (
    <>
      <BottomBarContainer as="footer">
        <BottomBarLinks isMenuOpen={open} links={links} />
        <LinkMenu extended={false} isMobile />
      </BottomBarContainer>

      {open && (
        <PositionFixed
          $backgroundColorName="lighter"
          $bottom={BOTTOM_BAR_HEIGHT}
          $left={0}
          $right={0}
          $top={BOTTOM_BAR_HEIGHT}
        >
          {isTherapist ? <TherapistTabMenu /> : <PatientTabMenu />}
        </PositionFixed>
      )}
    </>
  )
}
