import loadable from '@loadable/component'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'
import { TherapySessionLiveExternalPageGuard } from './Guard'

const LazyTherapySessionLiveExternalPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.TherapySessionLiveExternalPageView,
})

export const TherapySessionLiveExternalPage: Page = {
  exact: true,
  id: 'therapy-sessions.live.external',
  paths: ['/therapy-session/:therapySessionId/live/external'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'therapy-session.live.external',
    title: null,
  },
  PageGuard: ({ children }) => <TherapySessionLiveExternalPageGuard>{children}</TherapySessionLiveExternalPageGuard>,
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyTherapySessionLiveExternalPageView />,
}
