import { LoginCallback as LoginCallbackPageView } from '~/domains/auth/components/LoginCallback'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

export const LoginCallbackPage: Page = {
  exact: true,
  id: 'auth.token',
  paths: ['/token'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'token',
    title: null,
  },
  PageGuard: PageNoop,
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: LoginCallbackPageView,
}
