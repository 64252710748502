import loadable from '@loadable/component'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { Modal, ModalId, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { getRoute } from '~/utils/getRoute'

const Fallback = styled.div`
  width: 100px;
  height: 100px;
`

const LazyActivateConventionDefaultIcon = loadable(() => import('./ActivateConventionDefaultIcon.lazy'), {
  resolveComponent: (components) => components.ActivateConventionDefaultIcon,
  fallback: <Fallback />,
})

const id: ModalId = 'activateConvention'

export const ActivateConventionModal = () => {
  const history = useHistory()
  const { close } = useModals()

  const goToPayments = useCallback(async () => {
    history.push(getRoute('/settings/payments'))

    close(id)
  }, [close, history])

  const goBackInAgenda = useCallback(() => {
    history.push(getRoute('/'))

    close(id)
  }, [close, history])

  return (
    <Modal id={id}>
      <ModalCloseButton id={id} />

      <OverflowAuto>
        <Flex align="center" pt={16}>
          <LazyActivateConventionDefaultIcon />
        </Flex>

        <Flex pt={32}>
          <Text fontWeight="600" kind="h3" textAlign="center">
            <Translation id="conventionCode.modal.activate.title.variant" />
          </Text>
        </Flex>

        <Flex pt={16}>
          <TranslationMarkdown
            id="conventionCode.modal.activate.description.1.variant"
            kind="paragraph"
            textAlign="left"
          />
          <Flex pt={16}>
            <TranslationMarkdown
              id="conventionCode.modal.activate.description.2.variant"
              kind="paragraph"
              textAlign="left"
            />
          </Flex>
        </Flex>
      </OverflowAuto>

      <Flex pt={32}>
        <Button kind="primary" onClick={goToPayments}>
          <Translation id="actions.chooseTherapy" />
        </Button>

        <Flex pt={16}>
          <Button kind="secondary" onClick={goBackInAgenda}>
            <Translation id="actions.backToAgenda" />
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}
