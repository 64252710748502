import { secondsToMinutes } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { TherapyTherapyPathType } from '~/types/graphql'
import { getTherapySessionMeetingDurationInSeconds } from '~/utils/getTherapySessionMeetingDurationInSeconds'

type Param = {
  duration: number
  firstTherapySession: boolean
  therapyPathType: TherapyTherapyPathType | null
}

export const useTherapySessionMeetingDuration = ({ duration, firstTherapySession, therapyPathType }: Param) => {
  const seconds = getTherapySessionMeetingDurationInSeconds({
    duration,
    firstTherapySession,
    therapyPathType,
  })

  return {
    minutes: pipe(seconds, secondsToMinutes),
    seconds,
  }
}
