import loadable from '@loadable/component'
import { useCallback } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex, PositionRelative, Pressable } from 'ui-deprecated'
import { TertiaryButtonIcon } from '~/components/TertiaryButton/TertiaryButtonIcon'
import { Translation } from '~/components/Translation'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useModals } from '~/domains/modals'
import { useCopyToClipboard } from '~/hooks/useCopyToClipboard'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useToasts } from '~/hooks/useToasts'
import { buildReferralSharedLink } from '../../utils/buildReferralSharedLink'
import { ShareReferralCodeModal } from '../ShareReferralCodeModal'
import { ShareReferralCodeModalVariant } from '../ShareReferralCodeModalVariant'

const Fallback = styled.div`
  width: 80px;
  height: 56px;
`

const LazyPeopleImage = loadable(() => import('./PeopleImage.lazy'), {
  resolveComponent: (components) => components.PeopleImage,
  fallback: <Fallback />,
})

const PressableWithIndex = styled(Pressable)`
  z-index: 2;
`

export const ShareReferralCode = () => {
  const { referralCode } = useCurrentUser()
  const { isVariant } = useFeatureFlagsByUserId()

  const [, copy] = useCopyToClipboard()

  const { addToast } = useToasts()

  const { open } = useModals()

  const handleOpenModal = useCallback(() => {
    open('referralShare')
  }, [open])

  const handleCopyLink = useCallback(async () => {
    if (referralCode) {
      const result = await copy(buildReferralSharedLink(referralCode))

      addToast(
        result
          ? { translationId: 'referralCode.share.copied', type: 'success' }
          : { translationId: 'generic.error', type: 'alert' },
      )
    }
  }, [addToast, copy, referralCode])

  return (
    <>
      <PositionRelative
        align="center"
        background="purple08"
        borderRadius={16}
        direction="row"
        justify="space-between"
        px={16}
        py={20}
        zIndex={0}
      >
        <LazyPeopleImage />
        <Flex align="flex-start" grow={1} pl={16} shrink={1}>
          <Text colorName="white" fontWeight="600" kind="paragraph" textAlign="left">
            <Translation id="referralCode.share.title.suggestion" />
          </Text>
          <Flex pt={4}>
            <Text colorName="white" fontWeight="400" kind="paragraph" textAlign="left">
              <Translation id="referralCode.share.body" />
            </Text>
          </Flex>
        </Flex>
        <PressableWithIndex autoWidth onClick={handleCopyLink}>
          <TertiaryButtonIcon colorName="white" iconName="copy" />
        </PressableWithIndex>
        <Flex pl={8}>
          <PressableWithIndex autoWidth onClick={handleOpenModal}>
            <TertiaryButtonIcon colorName="white" iconName="arrow-right" />
          </PressableWithIndex>
        </Flex>
      </PositionRelative>

      {isVariant('ff_member_get_member_v2') ? <ShareReferralCodeModalVariant /> : <ShareReferralCodeModal />}
    </>
  )
}
