import { Flex, MaxWidth640px } from 'cdk'
import { SPACING_LG, SPACING_MD, SPACING_XL, SPACING_XS } from 'design-tokens'
import { useFormContext } from 'react-hook-form'
import { Text } from 'ui'
import { StepProgressCounter } from '~/domains/navigation/step/StepProgressCounter'
import { useStepNavigation } from '~/domains/navigation/step/useStepNavigation'
import { ReactHookFormRadioFieldVariant } from '~/domains/react-hook-form/components/ReactHookFormRadioFieldVariant'
import { ClinicalTestAnswerTranslation } from '../components/ClinicalTestAnswerTranslation'
import { ClinicalTestQuestionTranslation } from '../components/ClinicalTestQuestionTranslation'
import { ClinicalTestStepActions } from '../components/ClinicalTestStepActions'

type Props = {
  questionId: string
  onSubmit: VoidFunction
}

export const ClinicalTestQuestionStep = ({ onSubmit, questionId }: Props) => {
  const { current, goNext } = useStepNavigation()

  const form = useFormContext()
  const questionValue = form.watch(questionId)

  return (
    <>
      <MaxWidth640px $gap={SPACING_LG} $grow={1} $px={SPACING_MD} $py={SPACING_XL} $shrink={1} $zIndex={1}>
        <StepProgressCounter />

        <Flex $pt={SPACING_XS}>
          <Text kind="h2">
            <ClinicalTestQuestionTranslation questionId={questionId} />
          </Text>
        </Flex>

        <Flex $gap={SPACING_XS}>
          {[0, 1, 2, 3].map((answer) => (
            <ReactHookFormRadioFieldVariant
              key={answer}
              name={questionId}
              rules={{ required: true }}
              value={`${answer}`}
            >
              <ClinicalTestAnswerTranslation answer={answer} />
            </ReactHookFormRadioFieldVariant>
          ))}
        </Flex>
      </MaxWidth640px>

      <ClinicalTestStepActions
        nextDisabled={!questionValue}
        onNext={() => {
          if (current.isSubmitStep) {
            onSubmit()
          }

          goNext()
        }}
      />
    </>
  )
}
