import { Translation } from '~/components/Translation'
import { TranslationId } from '~/i18n/types'
import { ClinicalTestQuestion } from '~/types/graphql'

const translationsByQuestionId: Record<
  ClinicalTestQuestion['id'],
  Extract<TranslationId, `clinicalTests.tests.questions.${ClinicalTestQuestion['id']}`>
> = {
  cltq_ba2w61i9lo97nagg: 'clinicalTests.tests.questions.cltq_ba2w61i9lo97nagg',
  cltq_jjflvykhal40f4wz: 'clinicalTests.tests.questions.cltq_jjflvykhal40f4wz',
  cltq_kxpe4gvzdsybpiuf: 'clinicalTests.tests.questions.cltq_kxpe4gvzdsybpiuf',
  cltq_o7yc4heb29l4ft8z: 'clinicalTests.tests.questions.cltq_o7yc4heb29l4ft8z',
  cltq_k7zmrnup9o8kfqg8: 'clinicalTests.tests.questions.cltq_k7zmrnup9o8kfqg8',
  cltq_vitiqfwo0leaeicg: 'clinicalTests.tests.questions.cltq_vitiqfwo0leaeicg',
  cltq_m1ayoz7atmx06w75: 'clinicalTests.tests.questions.cltq_m1ayoz7atmx06w75',
  cltq_k945d0f1cfgvufwa: 'clinicalTests.tests.questions.cltq_k945d0f1cfgvufwa',
  cltq_7d0cehvmb8g9717z: 'clinicalTests.tests.questions.cltq_7d0cehvmb8g9717z',
  cltq_5d28r8tb3msy5wcj: 'clinicalTests.tests.questions.cltq_5d28r8tb3msy5wcj',
  cltq_i3mh2qe8lb24fa8j: 'clinicalTests.tests.questions.cltq_i3mh2qe8lb24fa8j',
  cltq_e7dj4z8en0g0falz: 'clinicalTests.tests.questions.cltq_e7dj4z8en0g0falz',
  cltq_85s7q6v4wj5qk9c7: 'clinicalTests.tests.questions.cltq_85s7q6v4wj5qk9c7',
  cltq_lqy4ztnwx40pon83: 'clinicalTests.tests.questions.cltq_lqy4ztnwx40pon83',
  cltq_ak0wg1ytxtsqhvmi: 'clinicalTests.tests.questions.cltq_ak0wg1ytxtsqhvmi',
  cltq_bz6x4543sfn44elv: 'clinicalTests.tests.questions.cltq_bz6x4543sfn44elv',
}

type ClinicalTestQuestionTranslationProps = {
  questionId: string
}

export const ClinicalTestQuestionTranslation = ({ questionId }: ClinicalTestQuestionTranslationProps) => {
  const translationId = translationsByQuestionId[questionId]

  return translationId ? <Translation id={translationId} /> : null
}
