import { StepNavigationHideBackButtonEffect } from './StepNavigationHideBackButtonEffect'
import { StepNavigationShowBackButtonEffect } from './StepNavigationShowBackButtonEffect'

type Props = {
  isVisibleBackButton: boolean
}

export const StepAppBarEffect = ({ isVisibleBackButton }: Props) => (
  <>
    {isVisibleBackButton && <StepNavigationShowBackButtonEffect />}
    {!isVisibleBackButton && <StepNavigationHideBackButtonEffect />}
  </>
)
