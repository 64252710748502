import { gql, useLazyQuery } from '@apollo/client'
import { UserHasAttributionScoreAlertQuery, UserHasAttributionScoreAlertQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query userHasAttributionScoreAlert {
    userHasAttributionScoreAlert
  }
`

export const useUserHasAttributionScoreAlertQuery = () => {
  const [fetchUserHasAttributionScoreAlert] = useLazyQuery<
    UserHasAttributionScoreAlertQuery,
    UserHasAttributionScoreAlertQueryVariables
  >(QUERY)

  return {
    fetchUserHasAttributionScoreAlert,
  }
}
