import { useStripe } from '@stripe/react-stripe-js'
import { noop } from 'functions'
import { toCurrency } from 'numbers'
import { useCallback, useEffect } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { Text } from 'ui'
import { Button, CardBox, Flex, PageLayout, Sticky } from 'ui-deprecated'
import { CenteredLoader } from '~/components/CenteredLoader'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { HowItWorksInvoice } from '~/domains/payments/components/HowItWorksInvoice'
import { HowItWorksPayment } from '~/domains/payments/components/HowItWorksPayment'
import { PaymentSecurity } from '~/domains/payments/components/PaymentSecurity'
import { useLoadingState } from '~/hooks/useLoadingState'
import { useToasts } from '~/hooks/useToasts'
import { getRoute } from '~/utils/getRoute'
import { PaymentMethodElement } from '../components/PaymentMethodElement'
import { useConfirmPaymentIntentByTransactionId } from '../hooks/useConfirmPaymentIntentByTransactionId'
import { useGetConfirmablePaymentIntentByTransactionId } from '../hooks/useGetConfirmablePaymentIntentByTransactionId'

type Param = {
  transactionId: string
}

export const PaymentsConfirm = () => {
  const { loading: isLoading, startLoading, stopLoading } = useLoadingState()

  const [confirmPaymentIntentByTransactionId] = useConfirmPaymentIntentByTransactionId()
  const { error, loading, paymentIntent } = useGetConfirmablePaymentIntentByTransactionId()

  const { addToast } = useToasts()
  const stripe = useStripe()
  const history = useHistory()

  const { transactionId } = useParams<Param>()

  const onConfirm = useCallback(async () => {
    if (!paymentIntent?.paymentMethodId || !stripe) {
      return
    }

    startLoading('local')

    try {
      const response = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
        payment_method: paymentIntent.paymentMethodId,
        setup_future_usage: 'off_session',
      })

      if (response.error || !response.paymentIntent) {
        throw new Error('Unable to confirm payment')
      }

      const { data } = await confirmPaymentIntentByTransactionId({
        variables: {
          transactionId,
        },
      })

      if (data?.confirmPaymentIntentByTransactionId?.ok) {
        addToast({ translationId: 'payments.confirmPayment.success', type: 'success' })

        history.replace(getRoute('/'))
      }
    } catch (err) {
      addToast({ translationId: 'payments.confirmPayment.error', type: 'alert' })
    } finally {
      stopLoading()
    }
  }, [
    addToast,
    confirmPaymentIntentByTransactionId,
    history,
    paymentIntent,
    startLoading,
    stopLoading,
    stripe,
    transactionId,
  ])

  useEffect(() => {
    if (error || loading || !paymentIntent?.paymentMethodId) {
      return
    }

    onConfirm().catch(noop)
  }, [error, loading, onConfirm, paymentIntent])

  if (loading) {
    return <CenteredLoader />
  }

  if (error || !paymentIntent?.paymentMethodId) {
    return <Redirect to={getRoute('/')} />
  }

  return (
    <PageLayout maxWidth="1024px" pb={96}>
      <Flex mdHide pb={40}>
        <HowItWorksPayment />

        <Flex pt={24}>
          <HowItWorksInvoice />
        </Flex>
      </Flex>

      <Flex direction="column" justify="space-between" mdDirection="row">
        <Flex mdBasis="50%">
          <Flex pb={24}>
            <Text fontWeight="600" kind="h3">
              <Translation id="payments.title.confirm" />
            </Text>
          </Flex>

          <CardBox>
            <TranslationMarkdown
              colorName="black"
              id="payments.confirmPayment.subtitle"
              kind="paragraph"
              values={{
                cost: toCurrency(paymentIntent.amount),
              }}
            />

            <Flex pt={24}>
              <PaymentMethodElement paymentMethodId={paymentIntent.paymentMethodId} />
            </Flex>

            <Flex pt={24}>
              <PaymentSecurity />
            </Flex>

            <Flex pt={16}>
              <Button id="confirm-payment-button" kind="primary" loading={isLoading} onClick={onConfirm} type="button">
                <Translation id="actions.confirm" />
              </Button>
            </Flex>
          </CardBox>
        </Flex>

        <Sticky align="flex-end" hide mdBasis="40%" mdHide={false} mdPt={52} pt={44}>
          <HowItWorksPayment />

          <Flex pt={24}>
            <HowItWorksInvoice />
          </Flex>
        </Sticky>
      </Flex>
    </PageLayout>
  )
}
