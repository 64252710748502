import { Flex } from 'cdk'
import { toDayOfMonth, toHoursMinutes, toMonthName, toWeekDay } from 'dates'
import {
  BORDER_RADIUS_4XS,
  COLOR_ACCENT,
  COLOR_GREEN_40,
  COLOR_GREEN_60,
  COLOR_PRIMARY,
  SPACING_4XS,
  SPACING_SM,
} from 'design-tokens'
import styled from 'styled-components'
import { Text } from 'ui'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { TherapyTherapyPathType } from '~/types/graphql'

const Container = styled(Flex)`
  @keyframes slide-thx-page-session {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  opacity: 0;
  transform: translateX(-30%);
  animation: slide-thx-page-session 1.5s ease forwards;
  animation-delay: 0.3s;
`

type TherapyInfoProps = {
  $isNutrition: boolean
}

const TherapyInfo = styled(Flex).attrs({
  $borderRadius: BORDER_RADIUS_4XS,
  $minWidth: 8,
})<TherapyInfoProps>`
  ${({ $isNutrition }) =>
    $isNutrition
      ? `background: linear-gradient(180deg, ${COLOR_GREEN_60} 0%, ${COLOR_GREEN_40} 99.36%);`
      : `background: linear-gradient(180deg, ${COLOR_PRIMARY} 0%, ${COLOR_ACCENT} 99.36%);`}
`

type TherapistCardSessionProps = {
  endAt: Date
  startAt: Date
  therapyPathType?: TherapyTherapyPathType | null
}

export const TherapistCardSession = ({ endAt, startAt, therapyPathType }: TherapistCardSessionProps) => (
  <Container $direction="row" $gap={SPACING_SM}>
    <TherapyInfo
      $isNutrition={!!therapyPathType && ['PATH_NUTRITION_DCA', 'PATH_NUTRITION_WEIGHT_LOSS'].includes(therapyPathType)}
    />
    <Flex $gap={SPACING_4XS}>
      <Text kind="paragraph-strong">{toWeekDay(startAt)}</Text>
      <Text kind="h1">
        {toDayOfMonth(startAt)} {toMonthName(startAt)}
      </Text>
      <TranslationMarkdown
        id="thankYouPage.therapist-card.sessionTime"
        pt={8}
        values={{ startAt: toHoursMinutes(startAt), endAt: toHoursMinutes(endAt) }}
      />
    </Flex>
  </Container>
)
