import { noop } from 'functions'
import { useEffect, useState } from 'react'
import { ModalId, useModals } from '~/domains/modals'
import { AskForReviewModal } from '~/domains/reviews/components/AskForReviewModal'
import { useProductUserFeedbackReviewCreate } from '../hooks/useReviewCreate'

const modalId: ModalId = 'askForReview'

export const AskForReviewTrigger = () => {
  const [productUserFeedbackId, setProductUserFeedbackId] = useState<string | null>(null)
  const { open } = useModals()
  const { productUserFeedbackCreate } = useProductUserFeedbackReviewCreate()

  useEffect(() => {
    productUserFeedbackCreate()
      .then((response) => {
        if (!response.data?.productUserFeedbackCreate?.id) {
          return
        }

        setProductUserFeedbackId(response.data.productUserFeedbackCreate.id)

        open(modalId)
      })
      .catch(noop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!productUserFeedbackId) {
    return null
  }

  return <AskForReviewModal productUserFeedbackId={productUserFeedbackId} />
}
