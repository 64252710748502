import { useEffect } from 'react'
import { useModals } from '~/domains/modals'

export const RecalculateAvailableTherapistsTrigger = () => {
  const { open } = useModals()

  useEffect(() => {
    open('recalculateAvailableTherapists')
  }, [open])

  return null
}
