import {
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
  registerDecorator,
} from 'class-validator'

const sdiCodeRegExp = /^[a-zA-Z0-9]{6,7}$/

export const isSdiCode = (value: unknown) => {
  if (typeof value !== 'string') {
    return false
  }

  return sdiCodeRegExp.test(value.trim())
}

@ValidatorConstraint({ name: 'sdiCode', async: false })
export class IsSdiCodeConstraint implements ValidatorConstraintInterface {
  validate = isSdiCode

  defaultMessage = () => 'Input is not a valid sdi code'
}

export function IsSdiCode<T extends object>(validationOptions?: ValidationOptions) {
  return function (object: T, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsSdiCodeConstraint,
    })
  }
}
