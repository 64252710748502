import loadable from '@loadable/component'
import { Flex, OverflowAuto } from 'cdk'
import styled from 'styled-components'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'

const Fallback = styled.div`
  width: 200px;
  height: 200px;
`

const LazyPaymentFailureImg = loadable(() => import('./PaymentFailureImg.lazy'), {
  resolveComponent: (components) => components.PaymentFailureImg,
  fallback: <Fallback />,
})

type Props = {
  onChangePaymentMethod: () => void
  onRetryClicked: () => void
}

export const TriggerPaymentFailure = ({ onChangePaymentMethod, onRetryClicked }: Props) => (
  <>
    <OverflowAuto $align="center">
      <LazyPaymentFailureImg />
      <Text colorName="black" fontWeight="600" kind="h2">
        <Translation id="triggerPaymentModal.failure.title" />
      </Text>
      <Flex $pt={16}>
        <Text colorName="black" kind="paragraph" textAlign="center">
          <Translation id="triggerPaymentModal.failure.body" />
        </Text>
      </Flex>
    </OverflowAuto>
    <Flex $pt={32}>
      <Button kind="primary" onClick={onRetryClicked}>
        <Translation id="triggerPaymentModal.failure.tryAgain" />
      </Button>
    </Flex>
    <Flex $pt={8}>
      <Button kind="secondary" onClick={onChangePaymentMethod}>
        <Translation id="triggerPaymentModal.failure.changeMethod" />
      </Button>
    </Flex>
  </>
)
