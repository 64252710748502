import { isOfExactType, noop } from 'functions'
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ConnectionOpen, DefaultGenerics, StreamChat } from 'stream-chat'
import { Streami18n } from 'stream-chat-react'
import { Chat } from '~/clients/chat'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useChatUnreadCount } from '~/domains/chat/hooks/useChatUnreadCount'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { useChatToken } from './useChatToken'

type Context = {
  client: StreamChat<DefaultGenerics>
  i18n: Streami18n
  loading: boolean
}

const ChatContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
}

const Provider = ({ children }: Props) => {
  const { isAuthenticated } = useAuthState()
  const { getToken, token, loading } = useChatToken()
  const { setUnread } = useChatUnreadCount()
  const { id } = useCurrentUserNullable()
  const [connected, setConnected] = useState(false)

  const { language } = useLanguage()

  const connectUser = useCallback(async () => {
    if (!token || connected || !id) {
      return
    }

    try {
      const connection = await Chat.connectUser({ id, total_unread_count: true }, token)

      if (connection && isOfExactType<ConnectionOpen>(connection) && connection.me) {
        setConnected(true)
        setUnread(connection.me.total_unread_count)
      }
    } catch (error) {
      setConnected(false)

      setUnread(0)
    }
  }, [connected, setUnread, token, id])

  useEffect(() => {
    connectUser().catch(noop)
  }, [connectUser])

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    getToken().catch(noop)
  }, [getToken, isAuthenticated])

  const value = useMemo(
    () => ({
      client: Chat,
      i18n: new Streami18n({
        language,
        translationsForLanguage: {
          'Nothing yet...': 'Nessun messaggio',
          'Empty message...': '',
        },
      }),
      loading: loading || !connected,
    }),
    [connected, language, loading],
  )

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>
}

export const ChatProvider = ({ children }: Props) => <Provider>{children}</Provider>

export const useChat = () => {
  const state = useContext(ChatContext)

  if (!state) {
    throw new Error('The `useChat` should be wrapped with `ChatProvider`.')
  }

  return state
}
