import { secondsToMinutes } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useMemo } from 'react'
import { ReactHookFormSelect } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useTranslate } from '~/i18n/hooks/useTranslation'
import { TherapySession } from '~/types/graphql'
import { getTherapySessionMeetingDurationInSeconds } from '~/utils/getTherapySessionMeetingDurationInSeconds'

type Props = {
  defaultValue?: string
} & Pick<TherapySession, 'therapyPathType'>

export const FieldTherapySessionDuration = ({ defaultValue = '1', therapyPathType }: Props) => {
  const { labels } = useFormTranslations()
  const translate = useTranslate()

  const options = useMemo(
    (): { label: string; value: number }[] => [
      {
        label: translate('forms.labels.therapySessionsDuration.option', {
          minutes: pipe(
            getTherapySessionMeetingDurationInSeconds({ duration: 1, firstTherapySession: false, therapyPathType }),
            secondsToMinutes,
          ),
        }),
        value: 1,
      },
      {
        label: translate('forms.labels.therapySessionsDuration.option', {
          minutes: pipe(
            getTherapySessionMeetingDurationInSeconds({ duration: 2, firstTherapySession: false, therapyPathType }),
            secondsToMinutes,
          ),
        }),
        value: 2,
      },
    ],
    [translate, therapyPathType],
  )
  return (
    <>
      <ReactHookFormSelect defaultValue={defaultValue} label={labels.therapySessionsDuration} name="duration">
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </ReactHookFormSelect>
    </>
  )
}
