import { Flex, MountOn, OverflowHidden } from 'cdk'
import { PropsWithChildren } from 'react'
import { Divider } from 'ui'

type ContentSkeletonProps = PropsWithChildren<{
  bottomChildren?: PropsWithChildren['children']
  topEndChildren?: PropsWithChildren['children']
  topStartChildren?: PropsWithChildren['children']
  useDivider?: boolean
}>

export const ContentSkeleton = ({
  bottomChildren,
  children,
  topEndChildren,
  topStartChildren,
  useDivider = true,
}: ContentSkeletonProps) => (
  <OverflowHidden $mdAlign="center" $mdDirection="row" $mdJustify="space-between">
    <Flex $direction="row" $grow={1} $justify="space-between">
      <Flex $align="flex-start" $mdBasis={332} $shrink={1}>
        {topStartChildren}
        {children && <Flex $pt={16}>{children}</Flex>}
      </Flex>
      {topEndChildren && (
        <Flex $align="flex-end" $grow={1} $mdAlign="center" $mdJustify="center">
          {topEndChildren}
        </Flex>
      )}
    </Flex>
    <Flex $mdBasis={180} $mdPt={0} $pt={16}>
      {bottomChildren && (
        <>
          <MountOn mediaQuery="sm">
            {useDivider && (
              <Flex $pb={16}>
                <Divider />
              </Flex>
            )}
          </MountOn>
          {bottomChildren}
        </>
      )}
    </Flex>
  </OverflowHidden>
)
