import { gql, useMutation } from '@apollo/client'
import {
  ConfirmPaymentIntentByTransactionIdMutation,
  ConfirmPaymentIntentByTransactionIdMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation confirmPaymentIntentByTransactionId($transactionId: String!) {
    confirmPaymentIntentByTransactionId(id: $transactionId) {
      ok
    }
  }
`

export const useConfirmPaymentIntentByTransactionId = () =>
  useMutation<ConfirmPaymentIntentByTransactionIdMutation, ConfirmPaymentIntentByTransactionIdMutationVariables>(
    MUTATION,
  )
