import { useEffect } from 'react'
import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { Modal, useModals } from '~/domains/modals'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useEmailVerification } from '../hooks/useEmailVerification'
import { getEmailVerificationRoute } from '../utils/getEmailVerificationRoute'
import { EmailVerificationModalDefaultRoute } from './EmailVerificationModalDefaultRoute'
import { EmailVerificationModalEditRoute } from './EmailVerificationModalEditRoute'

const initialEntries = [getEmailVerificationRoute('/')]

export const EmailVerificationModal = () => {
  const { open } = useModals()
  const { email, emailVerified } = useCurrentUser()
  const { requestVerification } = useEmailVerification()

  useEffect(() => {
    open('emailVerification')
    requestVerification(email)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (emailVerified) {
    return null
  }

  return (
    <Modal closeOnClickOutside={false} id="emailVerification">
      <MemoryRouter initialEntries={initialEntries}>
        <Switch>
          <Route path={getEmailVerificationRoute('/edit')}>
            <EmailVerificationModalEditRoute />
          </Route>

          <Route path={getEmailVerificationRoute('/')}>
            <EmailVerificationModalDefaultRoute />
          </Route>
        </Switch>
      </MemoryRouter>
    </Modal>
  )
}
