import { ReactNode } from 'react'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useUserRedirectEffect } from '~/hooks/useUserRedirectEffect'
import { Route } from '~/utils/getRoute'

type Props = {
  children: ReactNode
  target?: Route
}

export const AssertPatientOrAnonymous = ({ children, target }: Props) => {
  const { isAuthenticated } = useAuthState()
  const isPatient = useUserActingAsGroup() === 'patient'

  const isAuthorized = !isAuthenticated || isPatient

  useUserRedirectEffect(!isAuthorized, target)

  if (!isAuthorized) {
    return null
  }

  return <>{children}</>
}
