import { Flex, Space } from 'cdk'
import { FONT_WEIGHT_500 } from 'design-tokens'
import styled, { CSSProperties } from 'styled-components'
import { Text, TextProps } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { TranslationId } from '~/i18n/types'
import { TherapyPathKey } from './StartTherapyPathCard'

export const colorByTherapyPathName: Record<TherapyPathKey, CSSProperties['background']> = {
  COUPLES_PSYCHOTHERAPY: 'linear-gradient(124deg, #2D0D7A 20.04%, #7A59F5 59.32%)',
  MYSELF_PSYCHIATRY: 'linear-gradient(115deg, #0056D2 16.07%, #29AFFF 84.14%)',
  MYSELF_PSYCHOTHERAPY: 'linear-gradient(123deg, #7a59f5 19.75%, #fc8dfe 80.44%)',
  PATH_COACHING: 'linear-gradient(124deg, #FD80A8 20.19%, #FCCE42 79.99%)',
  PATH_NUTRITION_DCA: 'linear-gradient(115deg, #7A59F5 16.07%, #6EEEC1 84.14%)',
  PATH_NUTRITION_WEIGHT_LOSS: 'linear-gradient(123deg, #13B1A2 19.75%, #6EEEC1 80.44%)',
  PATH_SEXOLOGY: 'linear-gradient(124deg, #775EF6 20.32%, #F95A6D 70.27%)',
}

type TextWithBackgroundProps = {
  $therapyPathType: TherapyPathKey
}

const TextWithBackground = styled(Text)<TextWithBackgroundProps>`
  background: ${({ $therapyPathType }) => colorByTherapyPathName[$therapyPathType]};
  background-clip: text;
  font-weight: ${FONT_WEIGHT_500};
  -webkit-text-fill-color: transparent;
`

type StartTherapyPathNameProps = {
  kind: Extract<Required<TextProps['kind']>, 'h2' | 'h3'>
  therapyPathType: TherapyPathKey
}

const titleByTherapyPathName: Record<
  TherapyPathKey,
  Extract<TranslationId, `therapies.startTherapyPath.card.title.${string}`>
> = {
  COUPLES_PSYCHOTHERAPY: 'therapies.startTherapyPath.card.title.couples',
  MYSELF_PSYCHIATRY: 'therapies.startTherapyPath.card.title.psychiatry',
  MYSELF_PSYCHOTHERAPY: 'therapies.startTherapyPath.card.title.individual',
  PATH_COACHING: 'therapies.startTherapyPath.card.title.coaching',
  PATH_NUTRITION_DCA: 'therapies.startTherapyPath.card.title.nutrition.dca',
  PATH_NUTRITION_WEIGHT_LOSS: 'therapies.startTherapyPath.card.title.nutrition.weightLoss',
  PATH_SEXOLOGY: 'therapies.startTherapyPath.card.title.sexology',
}

export const StartTherapyPathName = ({ kind, therapyPathType }: StartTherapyPathNameProps) => {
  const { isVariant1 } = useFeatureFlagsByFingerprint()

  return (
    <Flex $align="baseline" $direction="row" $wrap="wrap">
      {isVariant1('ff_restyle_survey') ? (
        <Text kind="h2">
          <Translation id={titleByTherapyPathName[therapyPathType]} />
        </Text>
      ) : (
        <TextWithBackground $therapyPathType={therapyPathType} kind={kind}>
          <Translation id={titleByTherapyPathName[therapyPathType]} />
        </TextWithBackground>
      )}
      {therapyPathType === 'PATH_SEXOLOGY' && (
        <>
          <Space />
          <TranslationMarkdown fontWeight="400" id="therapies.startTherapyPath.card.title.sexology.partnership" />
        </>
      )}
    </Flex>
  )
}
