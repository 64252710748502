import { pipe } from 'fp-ts/function'
import { identity, noop } from 'functions'
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useMemo, useState } from 'react'
import { fillEmojiValues } from '~/utils/fillEmojiValues'
import { fillTextValues } from '~/utils/fillTextValues'

type Props = {
  children: ReactNode
}

type Context = {
  fillWithTextValues: (text: string) => string
  setTextValues: Dispatch<SetStateAction<Record<string, string>>>
  textValues: Record<string, string>
}

const defaultContext: Context = {
  fillWithTextValues: identity,
  setTextValues: noop,
  textValues: {},
}

const FormFlowsTextValuesContext = createContext<Context>(defaultContext)

export const FormFlowsTextValuesProvider = ({ children }: Props) => {
  const [textValues, setTextValues] = useState<Record<string, string>>({})

  const value = useMemo(
    () => ({
      textValues,
      setTextValues,
      fillWithTextValues: (text: string): string => pipe({ text, values: textValues }, fillTextValues, fillEmojiValues),
    }),
    [textValues],
  )

  return <FormFlowsTextValuesContext.Provider value={value}>{children}</FormFlowsTextValuesContext.Provider>
}

export const useFormFlowTextValues = () => {
  const context = useContext(FormFlowsTextValuesContext)
  if (!context) {
    throw new Error('The `useFormFlowTextValues` should be wrapped with `FormFlowsTextValuesProvider`.')
  }
  return context
}
