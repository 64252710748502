import { useCallback, useState } from 'react'
import { useDeleteForTherapistEmergency as useDeleteForTherapistEmergencyMutation } from '~/domains/therapy-session/hooks/useDeleteForTherapistEmergency'
import { useToasts } from '~/hooks/useToasts'
import { FormValues } from '../types/formValues'

export const useDeleteForTherapistEmergencyOnSubmit = () => {
  const [deleteForTherapistEmergency] = useDeleteForTherapistEmergencyMutation()
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()

  const onSubmit = useCallback(
    async (id: string, { message, therapistEmergencyReason }: FormValues) => {
      if (!therapistEmergencyReason) {
        return
      }

      setLoading(true)

      try {
        const response = await deleteForTherapistEmergency({
          variables: {
            input: {
              id,
              message,
              therapistEmergencyReason,
            },
          },
        })

        setLoading(false)

        const therapySession = response.data?.deleteForTherapistEmergency

        if (!therapySession) {
          addToast({ translationId: 'therapySession.deleteForTherapistEmergency.cannotDelete', type: 'alert' })

          return
        }

        addToast({
          translationId: 'therapySession.deleteForTherapistEmergency.deletedSuccess',
          type: 'success',
        })
      } catch (error) {
        addToast({ translationId: 'therapySession.deleteForTherapistEmergency.cannotDelete', type: 'alert' })

        setLoading(false)
      }
    },
    [addToast, deleteForTherapistEmergency],
  )

  return { loading, onSubmit }
}
