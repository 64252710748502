import { parseISO } from 'date-fns/fp'
import { sortByDateAsc } from 'dates'
import { pipe } from 'fp-ts/function'
import { ReactNode, createContext, useCallback, useMemo, useState } from 'react'

type Context = {
  day: string | null
  setDayVisibility: (day: string, isVisible: boolean) => void
}

type Props = {
  children: ReactNode
}

const AvailabilityDayDropdownContext = createContext<Context | null>(null)

type DayState = {
  [day: string]: boolean
}

export const AvailabilityDayDropdownProvider = ({ children }: Props) => {
  const [days, setDays] = useState<DayState[]>([])

  const setDayVisibility = useCallback((day: string, isVisible: boolean) => {
    setDays((oldDays) => ({
      ...oldDays,
      [day]: isVisible,
    }))
  }, [])

  const firstVisibleDayDate = useMemo(
    () =>
      Object.entries(days)
        .filter(([, isVisible]) => isVisible)
        .map(([day]) => ({
          day: pipe(day, parseISO),
        }))
        .sort(sortByDateAsc('day'))?.[0]?.day,
    [days],
  )

  const value = {
    day: firstVisibleDayDate ? firstVisibleDayDate.toISOString() : null,
    setDayVisibility,
  }

  return <AvailabilityDayDropdownContext.Provider value={value}>{children}</AvailabilityDayDropdownContext.Provider>
}
