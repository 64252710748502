import { useMemo } from 'react'
import { PartnershipSource } from '~/types/graphql'
import { useCurrentUserNullable } from './useCurrentUser'
import { useSessionStorageParams } from './useSessionStorageParams'

const partnerships = ['durex-srns', 'mysc-srns'] as const

type Partnership = (typeof partnerships)[number]

const partnershipByUtmSource: Record<Partnership, PartnershipSource> = {
  'durex-srns': 'DUREX',
  'mysc-srns': 'MY_SECRET_CASE',
}

const isValidPartnership = (partnership: string): partnership is Partnership =>
  (partnerships as unknown as string[]).includes(partnership)

const usePartnershipUtmSource = (): PartnershipSource | null => {
  const storageParam = useSessionStorageParams()

  const utmSource = useMemo(() => {
    if (!('utm_source' in storageParam)) {
      return null
    }

    if (typeof storageParam.utm_source !== 'string') {
      return null
    }

    return storageParam.utm_source.toLowerCase().trim()
  }, [storageParam])

  return utmSource && isValidPartnership(utmSource) ? partnershipByUtmSource[utmSource] : null
}

export const usePartnership = () => {
  const user = useCurrentUserNullable()
  const partnershipUtmSource = usePartnershipUtmSource()

  return useMemo(() => {
    if (!!user?.id) {
      return user.partnershipSource
    }

    return partnershipUtmSource
  }, [partnershipUtmSource, user])
}

export const usePartnershipByUtmSource = () => {
  const user = useCurrentUserNullable()
  const partnershipUtmSource = usePartnershipUtmSource()

  return useMemo(
    () => partnershipUtmSource ?? user?.partnershipSource ?? null,
    [partnershipUtmSource, user?.partnershipSource],
  )
}
