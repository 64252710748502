import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { getDeleteBatchRecurrencyRoute } from '../utils/getDeleteBatchRecurrencyRoute'

type Props = Pick<TherapistAgendaTherapySession, 'patient'> & {
  id: string
}

export const StepWarning = ({ id, patient }: Props) => {
  const history = useHistory()
  const { close } = useModals()

  const modalId = `deleteBatchRecurrency:${id}` as const

  const goNext = useCallback(() => {
    history.push(getDeleteBatchRecurrencyRoute('/reason'))
  }, [history])

  const handleGoBack = useCallback(() => {
    close(modalId)

    history.goBack()
  }, [close, history, modalId])

  return (
    <>
      <ModalCloseButton id={modalId} />

      <OverflowAuto>
        <Text fontWeight="600" kind="h3">
          <Translation id="therapySession.deleteBatchRecurrencyConfirm.title" />
        </Text>
        <Flex pt={4}>
          <Text fontWeight="400" kind="paragraph">
            <Translation
              id={`therapySession.deleteBatchRecurrencyConfirm.${patient.fullName ? '1' : '2'}.description`}
              values={{ patientFullName: patient.fullName || '' }}
            />
          </Text>
        </Flex>
      </OverflowAuto>

      <Flex pt={16}>
        <Button kind="primary" onClick={goNext} type="submit">
          <Translation id="actions.proceed" />
        </Button>

        <Flex pt={8}>
          <Button kind="secondary" onClick={handleGoBack} type="button">
            <Translation id="actions.goBack" />
          </Button>
        </Flex>
      </Flex>
    </>
  )
}
