import { ColorName } from 'design-tokens'
import { Icon } from 'icons'
import { useMemo } from 'react'
import { ClearIndicatorProps, components } from 'react-select'
import { SelectOption } from '~/components/SelectNew/types'

export const ClearIndicator = (props: ClearIndicatorProps<SelectOption>) => {
  const colorName: ColorName = useMemo(() => {
    if (props.isFocused) {
      return 'primary-80'
    }

    return 'primary'
  }, [props])

  return (
    <components.ClearIndicator {...props}>
      <Icon colorName={colorName} name="xmark-circle" size={24} />
    </components.ClearIndicator>
  )
}
