import { ColorName, cssvarColor } from 'design-tokens'
import { Icon } from 'icons'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { Text, TextProps, shouldForwardProp } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'

type StyledCustomMessageWrapperProps = {
  background: ColorName
  borderColor: ColorName
}

const StyledCustomMessageWrapper = styled.div.withConfig({
  shouldForwardProp,
})<StyledCustomMessageWrapperProps>`
  margin: 0 0 16px;
  padding: 16px;
  border-left: 4px solid ${({ borderColor }) => cssvarColor(borderColor)};
  background-color: ${({ background }) => cssvarColor(background)};
`

type Props = Partial<StyledCustomMessageWrapperProps> & {
  fontColorName?: (TextProps & { kind: 'footnote' })['colorName']
  children: ReactNode
  publicMessage?: boolean
  testId?: string
}

export const CustomMessageWrapper = ({
  background = 'primary-10',
  borderColor = 'primary-40',
  children,
  fontColorName = 'black',
  publicMessage = false,
  testId,
}: Props) => (
  <StyledCustomMessageWrapper background={background} borderColor={borderColor} data-test-id={testId}>
    {!publicMessage && (
      <Flex align="center" direction="row" pb={8}>
        <Flex color={cssvarColor(fontColorName)} pr={4}>
          <Icon name="eye" size={24} />
        </Flex>
        <Text colorName={fontColorName} fontWeight="600" kind="footnote">
          <Translation id="systemMessage.onlyForYou" />
        </Text>
      </Flex>
    )}
    {children}
  </StyledCustomMessageWrapper>
)
