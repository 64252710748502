import { Flex } from 'cdk'
import { COLOR_PRIMARY, cssvarColor } from 'design-tokens'
import { Svg } from 'illustrations'
import styled from 'styled-components'
import { ReferralCodeStatus } from './ReferralCode'

const Dot = styled(Flex).attrs({
  $backgroundColorName: 'white',
})`
  width: 20px;
  height: 10px;
  border: 1px solid ${COLOR_PRIMARY};
`

const UpperDot = styled(Dot)`
  border-top: 0;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
`

const LowerDot = styled(Dot)`
  border-bottom: 0;
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
`

export const DashedLine = ({ status }: { status: ReferralCodeStatus }) => {
  const dash = status === 'REQUESTED' ? 'primary' : 'white'

  return (
    <Flex $basis="20px" $justify="space-between">
      <UpperDot />

      <Flex $align="center" $direction="row" $grow={1} $justify="center" $shrink={1}>
        <Svg fill="none" height="100%" viewBox="0 0 2 50" width="2">
          <path d="M1 -5V84.5" stroke={cssvarColor(dash)} strokeDasharray="8 8" strokeLinecap="round" strokeWidth="2" />
        </Svg>
      </Flex>

      <LowerDot />
    </Flex>
  )
}
