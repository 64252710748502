import {
  registerDecorator,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator'

type Province = {
  code: string
  name: string
}

export const provinces: Province[] = [
  { name: 'Agrigento', code: 'AG' },
  { name: 'Alessandria', code: 'AL' },
  { name: 'Ancona', code: 'AN' },
  { name: 'Aosta', code: 'AO' },
  { name: 'Arezzo', code: 'AR' },
  { name: 'Ascoli Piceno', code: 'AP' },
  { name: 'Asti', code: 'AT' },
  { name: 'Avellino', code: 'AV' },
  { name: 'Bari', code: 'BA' },
  { name: 'Barletta-Andria-Trani', code: 'BT' },
  { name: 'Belluno', code: 'BL' },
  { name: 'Benevento', code: 'BN' },
  { name: 'Bergamo', code: 'BG' },
  { name: 'Biella', code: 'BI' },
  { name: 'Bologna', code: 'BO' },
  { name: 'Bolzano', code: 'BZ' },
  { name: 'Brescia', code: 'BS' },
  { name: 'Brindisi', code: 'BR' },
  { name: 'Cagliari', code: 'CA' },
  { name: 'Caltanissetta', code: 'CL' },
  { name: 'Campobasso', code: 'CB' },
  { name: 'Carbonia Iglesias', code: 'CI' },
  { name: 'Caserta', code: 'CE' },
  { name: 'Catania', code: 'CT' },
  { name: 'Catanzaro', code: 'CZ' },
  { name: 'Chieti', code: 'CH' },
  { name: 'Como', code: 'CO' },
  { name: 'Cosenza', code: 'CS' },
  { name: 'Cremona', code: 'CR' },
  { name: 'Crotone', code: 'KR' },
  { name: 'Cuneo', code: 'CN' },
  { name: 'Enna', code: 'EN' },
  { name: 'Fermo', code: 'FM' },
  { name: 'Ferrara', code: 'FE' },
  { name: 'Firenze', code: 'FI' },
  { name: 'Fiume', code: 'FU' },
  { name: 'Foggia', code: 'FG' },
  { name: 'Forlì-Cesena', code: 'FC' },
  { name: 'Frosinone', code: 'FR' },
  { name: 'Genova', code: 'GE' },
  { name: 'Gorizia', code: 'GO' },
  { name: 'Grosseto', code: 'GR' },
  { name: 'Imperia', code: 'IM' },
  { name: 'Isernia', code: 'IS' },
  { name: "L'Aquila", code: 'AQ' },
  { name: 'La Spezia', code: 'SP' },
  { name: 'Latina', code: 'LT' },
  { name: 'Lecce', code: 'LE' },
  { name: 'Lecco', code: 'LC' },
  { name: 'Livorno', code: 'LI' },
  { name: 'Lodi', code: 'LO' },
  { name: 'Lucca', code: 'LU' },
  { name: 'Macerata', code: 'MC' },
  { name: 'Mantova', code: 'MN' },
  { name: 'Massa-Carrara', code: 'MS' },
  { name: 'Matera', code: 'MT' },
  { name: 'Medio Campidano', code: 'VS' },
  { name: 'Messina', code: 'ME' },
  { name: 'Milano', code: 'MI' },
  { name: 'Modena', code: 'MO' },
  { name: 'Monza e Brianza', code: 'MB' },
  { name: 'Napoli', code: 'NA' },
  { name: 'Novara', code: 'NO' },
  { name: 'Nuoro', code: 'NU' },
  { name: 'Ogliastra', code: 'OG' },
  { name: 'Olbia Tempio', code: 'OT' },
  { name: 'Oristano', code: 'OR' },
  { name: 'Padova', code: 'PD' },
  { name: 'Palermo', code: 'PA' },
  { name: 'Parma', code: 'PR' },
  { name: 'Pavia', code: 'PV' },
  { name: 'Perugia', code: 'PG' },
  { name: 'Pesaro E Urbino', code: 'PU' },
  { name: 'Pescara', code: 'PE' },
  { name: 'Piacenza', code: 'PC' },
  { name: 'Pisa', code: 'PI' },
  { name: 'Pistoia', code: 'PT' },
  { name: 'Pola', code: 'PL' },
  { name: 'Pordenone', code: 'PN' },
  { name: 'Potenza', code: 'PZ' },
  { name: 'Prato', code: 'PO' },
  { name: 'Ragusa', code: 'RG' },
  { name: 'Ravenna', code: 'RA' },
  { name: 'Reggio Calabria', code: 'RC' },
  { name: 'Reggio Emilia', code: 'RE' },
  { name: 'Rieti', code: 'RI' },
  { name: 'Rimini', code: 'RN' },
  { name: 'Roma', code: 'RM' },
  { name: 'Rovigo', code: 'RO' },
  { name: 'Salerno', code: 'SA' },
  { name: 'Sassari', code: 'SS' },
  { name: 'Savona', code: 'SV' },
  { name: 'Siena', code: 'SI' },
  { name: 'Siracusa', code: 'SR' },
  { name: 'Sondrio', code: 'SO' },
  { name: 'Sud Sardegna', code: 'SU' },
  { name: 'Taranto', code: 'TA' },
  { name: 'Teramo', code: 'TE' },
  { name: 'Terni', code: 'TR' },
  { name: 'Torino', code: 'TO' },
  { name: 'Trapani', code: 'TP' },
  { name: 'Trento', code: 'TN' },
  { name: 'Treviso', code: 'TV' },
  { name: 'Trieste', code: 'TS' },
  { name: 'Udine', code: 'UD' },
  { name: 'Varese', code: 'VA' },
  { name: 'Venezia', code: 'VE' },
  { name: 'Verbano-Cusio-Ossola', code: 'VB' },
  { name: 'Vercelli', code: 'VC' },
  { name: 'Verona', code: 'VR' },
  { name: 'Vibo Valentia', code: 'VV' },
  { name: 'Vicenza', code: 'VI' },
  { name: 'Viterbo', code: 'VT' },
  { name: 'Zara', code: 'ZA' },
]

export const isProvince = (value: unknown) => provinces.some(({ code }) => code === value)

@ValidatorConstraint({ name: 'province', async: false })
export class IsProvinceConstraint implements ValidatorConstraintInterface {
  validate = isProvince

  defaultMessage = () => 'Input is not a valid province'
}

export function IsProvince<T extends object>(validationOptions?: ValidationOptions) {
  return function (object: T, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsProvinceConstraint,
    })
  }
}
