import loadable from '@loadable/component'
import { Flex, PositionAbsolute, PositionRelative } from 'cdk'
import { BORDER_RADIUS_MD, BORDER_WIDTH_0, cssvarColor, SPACING_MD } from 'design-tokens'
import { Svg } from 'illustrations'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Card, CardProps, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { TranslationId } from '~/i18n/types'

const LazyClinicalDirectorAvatar = loadable(() => import('./ClinicalDirectorAvatar.lazy'), {
  resolveComponent: (components) => components.ClinicalDirectorAvatar,
})

const ClinicalDirectorQuoteCardContainer = styled(Card)`
  padding-top: ${SPACING_MD};
  border: ${BORDER_WIDTH_0};
`

type Props = PropsWithChildren<{
  backgroundColorName?: CardProps['backgroundColorName']
  quote?: TranslationId
}>

export const ClinicalDirectorQuoteCard = ({ backgroundColorName = 'neutral-30', children, quote }: Props) => {
  const { isVariant1 } = useFeatureFlagsByFingerprint()

  if (isVariant1('ff_restyle_survey')) {
    return (
      <Flex $align="flex-end" $direction="row" $gap={12}>
        <LazyClinicalDirectorAvatar size="md" />
        <Flex $gap={4} $grow={1} $shrink={1}>
          <PositionRelative
            $backgroundColorName={backgroundColorName}
            $borderRadius={BORDER_RADIUS_MD}
            $pb={4}
            $px={12}
          >
            <PositionAbsolute $bottom={0} $left={-5}>
              <Svg height={16} viewBox="0 0 20 16" width={20}>
                <path
                  d="M5 0h15c0 8.837-6.268 16-14 16H.4a.4.4 0 0 1-.277-.69c1.376-1.34 2.517-2.809 3.35-4.678C4.597 8.115 4.923 5.025 5 0Z"
                  fill={cssvarColor(backgroundColorName)}
                />
              </Svg>
            </PositionAbsolute>
            <PositionRelative>{quote ? <TranslationMarkdown id={quote} pt={8} /> : children}</PositionRelative>
          </PositionRelative>
          <Flex $px={12}>
            <Text colorName="neutral-70" fontWeight="500" kind="caption">
              Domenico De Donatis | <Translation id="generic.clinicalDirector" />
            </Text>
          </Flex>
        </Flex>
      </Flex>
    )
  }

  return (
    <ClinicalDirectorQuoteCardContainer backgroundColorName={backgroundColorName}>
      <Text as="div" fontStyle="italic" kind="paragraph">
        {quote ? <TranslationMarkdown id={quote} pt={8} /> : children}
      </Text>
      <Flex $align="center" $direction="row" $pt={8}>
        <LazyClinicalDirectorAvatar size="xs" />
        <Flex $pl={8}>
          <Text fontWeight="600" kind="paragraph">
            Domenico De Donatis
          </Text>
          <Text kind="caption">
            <Translation id="generic.clinicalDirector" />
          </Text>
        </Flex>
      </Flex>
    </ClinicalDirectorQuoteCardContainer>
  )
}
