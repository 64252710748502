import { useCallback, useState } from 'react'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useProductUserFeedbackAnswerUpsert } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackAnswerUpsert'
import { ReactHookFormProvider, ReactHookFormTextArea, useReactHookForm } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useToasts } from '~/hooks/useToasts'

type Props = {
  onClose: () => void
  productUserFeedbackId: string
}

type FormValues = {
  message: string
}

const max = 600

export const AskForReviewRouteBad = ({ onClose, productUserFeedbackId }: Props) => {
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()
  const { productUserFeedbackAnswerUpsert } = useProductUserFeedbackAnswerUpsert(productUserFeedbackId)

  const form = useReactHookForm<FormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      message: '',
    },
  })

  const {
    errors: { required },
    placeholders: { askForReviewMessage },
  } = useFormTranslations()

  const onSubmit = useCallback(
    async ({ message }: FormValues) => {
      setLoading(true)

      const productUserFeedbackAnswerUpsertResponse = await productUserFeedbackAnswerUpsert({
        id: 'APP_REVIEW_MESSAGE',
        value: message,
      })

      setLoading(false)

      if (!productUserFeedbackAnswerUpsertResponse.data?.productUserFeedbackAnswerUpsert?.id) {
        addToast({
          translationId: 'reviews.askReviewModal.bad.unableToSaveResponse',
          type: 'alert',
        })

        return
      }

      onClose()
    },
    [addToast, onClose, productUserFeedbackAnswerUpsert],
  )

  return (
    <ReactHookFormProvider {...form}>
      <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
        <OverflowAuto>
          <Text colorName="black" fontWeight="600" kind="h3">
            <Translation id="reviews.askReviewModal.bad.title" />
          </Text>
          <TranslationMarkdown colorName="black" id="reviews.askReviewModal.bad.description" kind="paragraph" pt={8} />

          <Flex pt={32}>
            <ReactHookFormTextArea
              max={max}
              minHeight="200px"
              name="message"
              placeholder={askForReviewMessage}
              rules={{ max, required }}
            />
          </Flex>
        </OverflowAuto>

        <Flex pt={16}>
          <Button
            disabled={!form.formState.isValid}
            kind="primary"
            loading={loading}
            size="small"
            testId="ask-for-review-button-bad"
            type="submit"
          >
            <Translation id="reviews.askReviewModal.bad.confirm" />
          </Button>
        </Flex>
      </Form>
    </ReactHookFormProvider>
  )
}
