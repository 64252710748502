import { Flex, PositionRelative } from 'cdk'
import { SPACING_4XS, SPACING_SM, SPACING_XS } from 'design-tokens'
import { Icon } from 'icons'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { Badge, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { getRoute } from '~/utils/getRoute'
import { NavigationLink, NavigationProps } from '../types'
import { FlexWithTransition } from './common/FlexWithTransition'
import { NavLinkContainer } from './common/NavLinkContainer'
import { TextSize18 } from './common/TextSize18'
import { UnreadDot } from './common/UnreadDot'

type NavLinkProps = {
  link: NavigationLink
  unread?: number
  isMenuOpen?: boolean
  isMobile?: boolean
  onLinkClick?: () => void
} & NavigationProps

export const NavLink = ({
  extended,
  isMobile = false,
  isMenuOpen = false,
  link,
  unread,
  onLinkClick,
}: NavLinkProps) => {
  const { pathname } = useLocation()

  /**
   * TODO (amin-khayam)
   * The condition "!(isMenuOpen && isMobile)" is a temporary fix to prevent useless active state in the mobile menu
   * When the new /settings page will be implemented, this condition should be removed
   * More details here: https://linear.app/serenis/issue/ENG-303/[mobile]-quando-apro-il-menu-mi-rimane-active-la-voce-precedentemente
   */

  const active =
    !!matchPath(pathname, {
      path: link.pathMatches.map((path) => getRoute(path)),
      exact: link.exact,
    }) && !(isMenuOpen && isMobile)

  return (
    <Link data-test-id={link.dataTestId} onClick={onLinkClick} to={getRoute(link?.route)}>
      <NavLinkContainer
        $direction={isMobile ? 'column' : 'row'}
        $gap={isMobile ? SPACING_4XS : SPACING_SM}
        $isMobile={isMobile}
      >
        <PositionRelative>
          <Icon
            colorName={active ? 'primary-50' : 'grey-110'}
            name={active ? link.iconNameActive : link.iconName}
            size={24}
          />
          {!!unread && <UnreadDot />}
        </PositionRelative>

        {!isMobile ? (
          <FlexWithTransition
            $direction="row"
            $grow={1}
            $justify="space-between"
            $opacity={extended ? 1 : 0}
            $pr={SPACING_XS}
          >
            <TextSize18 colorName={active ? 'primary-50' : 'grey-110'} fontWeight={active ? '600' : '500'} kind="h3">
              <Translation id={link.translation} />
            </TextSize18>
            <Flex $pt={SPACING_XS}>{!!unread && <Badge sup={unread} />}</Flex>
          </FlexWithTransition>
        ) : (
          <Text colorName={active ? 'primary-50' : 'grey-110'} kind="footnote">
            <Translation id={link.translation} />
          </Text>
        )}
      </NavLinkContainer>
    </Link>
  )
}
