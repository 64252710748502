import { TherapyTherapyPathType } from '~/types/graphql'
import { getEnv } from '~/utils/getEnv'

export const getTherapySessionAvailabilityDurationInSeconds = (therapyPathType: TherapyTherapyPathType) => {
  if (['PATH_NUTRITION_WEIGHT_LOSS', 'PATH_NUTRITION_DCA'].includes(therapyPathType)) {
    return Number(getEnv('SERENIS_THERAPY_SESSION_NUTRITION_AVAILABILITY_DURATION'))
  }

  return Number(getEnv('SERENIS_THERAPY_SESSION_DEFAULT_AVAILABILITY_DURATION'))
}
