import { LoginPageView } from '~/domains/auth/pages/LoginPageView'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

export const LoginPage: Page = {
  exact: true,
  id: 'auth.login',
  paths: ['/login'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'login',
    title: null,
  },
  PageGuard: PageNoop,
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: LoginPageView,
}
