import { ComponentProps, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { AutoComplete, PositionAbsolute, PositionRelative, Pressable, TextFieldNew } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { ReactHookFormTextFieldNew } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'
import { TranslationId } from '~/i18n/types'

const PasswordFieldContainer = styled(PositionRelative)`
  input {
    max-width: calc(100% - 70px);
    text-overflow: ellipsis;
  }
`

type Props = {
  autoComplete: Extract<AutoComplete, 'new-password' | 'current-password'>
  helperText?: string
  label: string
  name: 'newPassword' | 'oldPassword' | 'password'
  size?: ComponentProps<typeof TextFieldNew>['size']
  validateOnSubmit?: boolean
}

export const FieldPasswordTextFieldNew = ({
  autoComplete,
  helperText,
  label,
  name,
  size = 'medium',
  validateOnSubmit = true,
}: Props) => {
  const [type, setType] = useState<'text' | 'password'>('password')

  const {
    errors: { required },
  } = useFormTranslations()

  const { isPassword } = useFormValidation()

  const onTogglePasswordType = useCallback(() => {
    setType(type === 'text' ? 'password' : 'text')
  }, [type])

  const togglePasswordTranslationId: TranslationId =
    type === 'password' ? 'actions.showPassword' : 'actions.hidePassword'

  return (
    <PasswordFieldContainer>
      <ReactHookFormTextFieldNew
        autoComplete={autoComplete}
        helperText={helperText}
        name={name}
        placeholder={label}
        rules={{ required, validate: isPassword }}
        size={size}
        type={type}
        validateOnSubmit={validateOnSubmit}
      />

      <PositionAbsolute align="flex-end" right="16px" top={size === 'medium' ? '22px' : '16px'}>
        <Pressable
          autoWidth
          colorName="purple08"
          colorNameHover="purple10"
          onClick={onTogglePasswordType}
          tabIndex={-1}
        >
          <Text fontWeight="600" kind="caption">
            <Translation id={togglePasswordTranslationId} />
          </Text>
        </Pressable>
      </PositionAbsolute>
    </PasswordFieldContainer>
  )
}
