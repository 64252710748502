import { Flex, MaxWidth640px } from 'cdk'
import { COLOR_GREY_120, COLOR_GREY_70, COLOR_PRIMARY, COLOR_PRIMARY_20, SPACING_MD, SPACING_XL } from 'design-tokens'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { ClinicalDirectorQuoteToast } from '~/domains/formFlow/components/ClinicalDirectorQuote/ClinicalDirectorQuoteToast'
import { useStepNavigation } from '~/domains/navigation/step/useStepNavigation'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { useClinicalTestAnswer } from '../hooks/useClinicalTestAnswer'

const QuestionsSvg = () => (
  <svg fill="none" viewBox="0 0 120 120" width={120} xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M88.58 15.73c7.53 0 13.63 6.1 13.63 13.63v30.67c0 1.14-.14 2.25-.4 3.3l6.9 12.72a1.36 1.36 0 0 1-1.63 1.94l-14.45-4.93c-1.28.4-2.64.6-4.05.6H57.9c-7.52 0-13.63-6.1-13.63-13.63V29.36c0-7.53 6.1-13.63 13.63-13.63h30.68Z"
      fill={COLOR_PRIMARY_20}
      fillRule="evenodd"
    />
    <path
      d="M59.08 34.13c-1.23.82-2.82 1.92-3.58 3.6-1.9 4.18 5.33 4.04 6.38 3.8.99-.22 6-2.8 3.69-5.64-.64-.78-1.78 1.99-1.41 2.72.98 1.94 4.3 1.22 5.58.87a8.16 8.16 0 0 0 2.98-1.5c.44-.33 1.03-1.6 1.3-1 .18.38.1.9.24 1.31.57 1.84 1.6 2.6 2.96 2.85 5.18.99 9.99-2.28 14.76-4.5m-32.9 10.44c-1.23.82-2.82 1.92-3.58 3.6-1.9 4.19 5.33 4.04 6.38 3.8.99-.22 6-2.8 3.69-5.64-.64-.78-1.78 1.99-1.41 2.72.98 1.94 4.3 1.22 5.58.87a8.14 8.14 0 0 0 2.98-1.5c.44-.33 1.03-1.6 1.3-1 .18.38.1.9.24 1.31.57 1.85 1.6 2.6 2.96 2.85 5.18.99 9.99-2.28 14.76-4.5"
      stroke={COLOR_GREY_70}
      strokeLinecap="round"
      strokeWidth="1.36"
    />
    <path
      clipRule="evenodd"
      d="M31.87 41.63c-7.53 0-13.64 6.1-13.64 13.63v30.68c0 1.14.14 2.24.4 3.3l-6.9 12.71c-.6 1.1.46 2.34 1.63 1.94l14.46-4.93c1.28.4 2.64.6 4.05.6h30.67c7.53 0 13.63-6.1 13.63-13.62V55.26c0-7.53-6.1-13.63-13.63-13.63H31.87Z"
      fill="url(#a)"
      fillOpacity=".6"
      fillRule="evenodd"
    />
    <path
      d="M50.85 72.98c-1.9.33-2.55 1.3-2.82 2.33l-.1.37-4.28-1.14.2-.74c.72-2.68 2.07-3.77 5.12-4.5 2.65-.65 3.94-1.18 4.43-3.04.61-2.26-.61-3.78-3.04-4.43-3.45-.92-5.81 1.14-7.3 4.57l-4.2-1.96c2.2-5.23 6.4-8.6 12.66-6.92 5.38 1.44 7.9 4.89 6.62 9.66-1.11 4.15-3.97 5.28-7.29 5.8Zm-9.66 8.78 1.35-5.05 5.14 1.37-1.35 5.06-5.14-1.37Z"
      fill={COLOR_GREY_120}
      fillOpacity=".96"
    />
    <defs>
      <linearGradient gradientUnits="userSpaceOnUse" id="a" x1="20" x2="76" y1="42" y2="104">
        <stop stopColor="#F6BFFF" />
        <stop offset="1" stopColor={COLOR_PRIMARY} />
      </linearGradient>
    </defs>
  </svg>
)

type ClinicalTestIntroStepProps = {
  clinicalTestAnswerId: string
}

export const ClinicalTestIntroStep = ({ clinicalTestAnswerId }: ClinicalTestIntroStepProps) => {
  const { clinicalTestAnswer } = useClinicalTestAnswer(clinicalTestAnswerId)
  const { goNext } = useStepNavigation()
  const { therapies } = usePatientTherapies()
  const trackClick = useTrackEventClick()

  if (!clinicalTestAnswer) {
    return null
  }

  const therapist = therapies.find((therapy) => therapy.id === clinicalTestAnswer.therapyId)?.therapist

  return (
    <Flex $grow={1} $justify="space-between">
      <MaxWidth640px $align="flex-start" $gap={SPACING_XL} $px={SPACING_MD} $py={SPACING_XL} $zIndex={1}>
        <QuestionsSvg />
        <Flex $gap={SPACING_MD}>
          <Text kind="h1">
            <Translation id="clinicalTests.tests.intro.title" />
          </Text>
          <Text kind="paragraph">
            <TranslationMarkdown
              id="clinicalTests.tests.intro.description"
              values={{ therapistFullName: therapist?.fullName ?? '' }}
            />
          </Text>
        </Flex>
        <Button
          kind="primary"
          onClick={() => {
            goNext()
            trackClick('clinical-test.step.intro.start')
          }}
        >
          <Translation id="clinicalTests.tests.intro.action" />
        </Button>
      </MaxWidth640px>
      <ClinicalDirectorQuoteToast
        description="clinicalTests.clinicalDirector.intro.description"
        title="clinicalTests.clinicalDirector.intro.title"
      />
    </Flex>
  )
}
