import { ProgressBar } from '~/components/ProgressBar'
import { useStepNavigation } from './useStepNavigation'

export const StepProgressBar = () => {
  const { currentIndex, entries } = useStepNavigation()

  const total = entries.length - 1
  const current = currentIndex

  return <ProgressBar backgroundColorName="transparent" total={total} trackColorName="primary" value={current} />
}
