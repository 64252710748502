import { gql, useMutation } from '@apollo/client'
import { UserLastActivityMutation, UserLastActivityMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation userLastActivity {
    userLastActivity {
      ok
    }
  }
`

export const useUserLastActivity = () =>
  useMutation<UserLastActivityMutation, UserLastActivityMutationVariables>(MUTATION)
