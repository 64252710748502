import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { useModals } from '../../modals'
import { useEffect } from 'react'

export const useActivateReferralEffect = () => {
  const shouldShowReferralActivationModal = getLocalStorage('referral-code-name')

  const { open } = useModals()

  useEffect(() => {
    if (shouldShowReferralActivationModal) {
      open('referralActivation')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
