import { Icon } from 'icons'
import { title } from 'strings'
import { Text } from 'ui'
import { CardBox, Flex } from 'ui-deprecated'
import { usePaymentMethods } from '~/domains/payments/hooks/usePaymentMethods'
import { PaymentMethodElementSkeleton } from './PaymentMethodElementSkeleton'

type Props = {
  paymentMethodId: string
}

export const PaymentMethodElement = ({ paymentMethodId }: Props) => {
  const { items } = usePaymentMethods()

  const paymentMethod = items.find(({ id }) => paymentMethodId === id)

  if (!paymentMethod) {
    return <PaymentMethodElementSkeleton />
  }

  return (
    <CardBox align="center" background="purple02" borderSize={0} direction="row">
      <Flex pr={16}>
        <Icon colorName="primary" name="credit-card" size={24} />
      </Flex>
      <Flex align="center" direction="row" grow={1} justify="space-between" shrink={1}>
        <Flex>
          <Flex direction="row" pb={8}>
            <Flex pr={4}>
              <Text fontWeight="600" kind="paragraph">
                {paymentMethod.type === 'paypal' ? 'Paypal' : title(String(paymentMethod.brand))}
              </Text>
            </Flex>
            {paymentMethod.exp_month && paymentMethod.exp_year && (
              <Text fontWeight="400" kind="paragraph">
                {paymentMethod.exp_month}/{paymentMethod.exp_year}
              </Text>
            )}
          </Flex>
          <Flex>
            <Text fontWeight={paymentMethod.last4 ? '600' : '400'} kind="paragraph">
              {paymentMethod.last4 ? `**** **** **** ${paymentMethod.last4}` : paymentMethod.verified_email}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </CardBox>
  )
}
