import {
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'
import { ReactElement, useCallback, useMemo, useState } from 'react'
import { PopoverContent } from './PopoverContent'
import { PopoverContext } from './PopoverContext'
import { PopoverTrigger } from './PopoverTrigger'

type Param = {
  placement?: Placement
}

type Props = {
  children: [ReactElement<typeof PopoverTrigger>, ReactElement<typeof PopoverContent>]
} & Param

export const usePopover = ({ placement = 'top-start' }: Param) => {
  const [isOpen, setIsOpen] = useState(false)

  const open = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const { context, ...data } = useFloating({
    middleware: [offset(8), flip(), shift({ padding: 16 })],
    onOpenChange: setIsOpen,
    open: isOpen,
    placement,
    whileElementsMounted: autoUpdate,
  })
  const { styles, isMounted } = useTransitionStyles(context, { duration: 100 })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)
  const interactions = useInteractions([dismiss, click, role])

  return useMemo(
    () => ({
      context,
      isMounted,
      open,
      close,
      styles,
      ...interactions,
      ...data,
    }),
    [close, context, data, interactions, isMounted, open, styles],
  )
}

export const Popover = ({ children, ...props }: Props) => {
  const popover = usePopover(props)

  return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>
}
