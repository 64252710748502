import { gql, useQuery } from '@apollo/client'
import { JournalingLogsQuery, JournalingLogsQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query journalingLogs {
    journalingLogs {
      date
      id
      journalingActivities {
        id
        name
        slug
      }
      journalingMoods {
        id
        name
        slug
      }
      score
      sharedWithTherapistUserId
      sharedWithTherapistUserIds
      thankfulReasons
      todayThoughts
    }
  }
`

export const useJournalingLogs = () => {
  const { data, error, loading, refetch } = useQuery<JournalingLogsQuery, JournalingLogsQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    error: !!error,
    loading,
    journalingLogs: data?.journalingLogs || [],
    refetch,
  }
}
