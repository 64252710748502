import { Elements } from '@stripe/react-stripe-js'
import { Appearance, CustomFontSource, loadStripe } from '@stripe/stripe-js'
import {
  BORDER_RADIUS_XS_VALUE,
  BORDER_WIDTH_0_VALUE,
  COLOR_DARKER_VALUE,
  COLOR_GREEN_60_VALUE,
  COLOR_GREY_40_VALUE,
  COLOR_GREY_50_VALUE,
  COLOR_GREY_80_VALUE,
  COLOR_LIGHTER_VALUE,
  COLOR_PRIMARY_40_VALUE,
  COLOR_PRIMARY_50_VALUE,
  COLOR_PRIMARY_80_VALUE,
  COLOR_RED_80_VALUE,
  COLOR_YELLOW_60_VALUE,
  ELEVATION_0_VALUE,
  FONT_FAMILY_DEGULAR_TEXT_VALUE,
  SPACING_0_VALUE,
  SPACING_LG_VALUE,
  SPACING_SM_VALUE,
  TYPOGRAPHY_PARAGRAPH_FONT_SIZE_VALUE,
} from 'design-tokens'
import { noop } from 'functions'
import { ReactNode, useEffect, useState } from 'react'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useCreateSetupIntent } from '~/domains/payments/hooks/useCreateSetupIntent'
import { getEnv } from '~/utils/getEnv'

type Props = {
  children: ReactNode
}

// NOTE(@piz): we should pass only HEX or RGB, not working with var(--srns...)
const appearance: Appearance = {
  labels: 'floating',
  rules: {
    '.AccordionItem': {
      border: BORDER_WIDTH_0_VALUE,
      borderBottomColor: COLOR_GREY_40_VALUE,
      borderTopColor: COLOR_GREY_40_VALUE,
      boxShadow: ELEVATION_0_VALUE,
      fontFamily: FONT_FAMILY_DEGULAR_TEXT_VALUE,
      paddingBottom: SPACING_LG_VALUE,
      paddingLeft: SPACING_0_VALUE,
      paddingRight: SPACING_0_VALUE,
      paddingTop: SPACING_0_VALUE,
    },
    '.Label': {
      color: COLOR_GREY_80_VALUE,
    },
    '.Input': {
      boxShadow: ELEVATION_0_VALUE,
      borderColor: COLOR_GREY_40_VALUE,
    },
    '.Input:focus': {
      boxShadow: ELEVATION_0_VALUE,
      borderColor: COLOR_PRIMARY_40_VALUE,
    },
    '.Input::placeholder': {
      color: COLOR_GREY_50_VALUE,
    },
    '.Input--invalid': {
      boxShadow: ELEVATION_0_VALUE,
      borderColor: COLOR_RED_80_VALUE,
    },
  },
  theme: 'stripe',
  variables: {
    borderRadius: BORDER_RADIUS_XS_VALUE,
    colorBackground: COLOR_LIGHTER_VALUE,
    colorDanger: COLOR_RED_80_VALUE,
    colorIcon: COLOR_PRIMARY_50_VALUE,
    colorIconHover: COLOR_PRIMARY_80_VALUE,
    colorIconTabHover: COLOR_PRIMARY_80_VALUE,
    colorPrimary: COLOR_PRIMARY_50_VALUE,
    colorSuccess: COLOR_GREEN_60_VALUE,
    colorText: COLOR_DARKER_VALUE,
    colorWarning: COLOR_YELLOW_60_VALUE,
    fontFamily: FONT_FAMILY_DEGULAR_TEXT_VALUE,
    fontSizeBase: TYPOGRAPHY_PARAGRAPH_FONT_SIZE_VALUE,
    fontSizeLg: TYPOGRAPHY_PARAGRAPH_FONT_SIZE_VALUE,
    fontSizeXs: TYPOGRAPHY_PARAGRAPH_FONT_SIZE_VALUE,
    fontWeightBold: '600',
    fontWeightMedium: '600',
    spacingGridRow: SPACING_SM_VALUE,
    tabIconSelectedColor: COLOR_PRIMARY_80_VALUE,
  },
}

const fonts: CustomFontSource[] = [
  {
    family: 'Degular Text',
    src: 'url(https://assets.serenis.it/fonts/DegularText-Regular.otf)',
    weight: '400',
  },
  {
    family: 'Degular Text',
    src: 'url(https://assets.serenis.it/fonts/DegularText-Semibold.otf)',
    weight: '600',
  },
]

const stripePromise = loadStripe(getEnv('STRIPE_API_KEY_PUBLISHABLE'), { locale: 'it-IT' })

export const StripeElementsProvider = ({ children }: Props) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null)

  const [createSetupIntent] = useCreateSetupIntent()

  useEffect(() => {
    createSetupIntent()
      .then((response) => {
        const secret = response.data?.createSetupIntent?.client_secret

        if (!secret) {
          return
        }

        setClientSecret(secret)
      })
      .catch(noop)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!clientSecret) {
    return <CenteredLoader />
  }

  return (
    <Elements options={{ appearance, clientSecret, fonts }} stripe={stripePromise}>
      {children}
    </Elements>
  )
}
