import { ProductReviewDetail } from '~/domains/product-reviews/detail'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

export const ProductReviewDetailPage: Page = {
  exact: true,
  id: 'productReview.detail',
  paths: ['/product-review/:productReviewId/:score'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: false,
    seoKey: 'productReview.detail',
    title: null,
  },
  PageGuard: PageNoop,
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: ProductReviewDetail,
}
