import { Flex } from 'cdk'
import { SPACING_SM } from 'design-tokens'
import { Icon } from 'icons'
import { memo, useCallback } from 'react'
import { Link } from 'ui'
import { Translation } from '~/components/Translation'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { useTabNavigationMenu } from '~/domains/navigation/TabNavigation/hooks/useTabNavigationMenu'
import { NavLinkContainer } from '../common/NavLinkContainer'
import { TextSize18 } from '../common/TextSize18'

type Props = {
  extended: boolean
  onLinkClick?: () => void
}

export const LinkSupport = memo(({ extended, onLinkClick }: Props) => {
  const { open } = useIntercom()
  const { closeMenu } = useTabNavigationMenu()

  const handleClick = useCallback(() => {
    open('home')
    open('help')
    closeMenu()
    onLinkClick?.()
  }, [closeMenu, onLinkClick, open])

  return (
    <Link onClick={handleClick}>
      <NavLinkContainer $direction="row" $gap={SPACING_SM}>
        <Flex>
          <Icon colorName="grey-110" fillColorName="lighter" name="question-mark-circle" size={24} />
        </Flex>
        {extended && (
          <TextSize18 colorName="grey-110" fontWeight="500" kind="h3">
            <Translation id="menu.help.support" />
          </TextSize18>
        )}
      </NavLinkContainer>
    </Link>
  )
})
