import { useCallback } from 'react'
import { TrackEventInput } from '~/types/graphql'
import { useTrackEvent } from './useTrackEvent'

type EventViewId = 'reservation.therapists' | 'reservation.availabilities'

export const useTrackEventImpression = (id: EventViewId) => {
  const trackEvent = useTrackEvent('IMPRESSION')

  return useCallback(
    (payload: TrackEventInput['payload']) => {
      trackEvent({ name: `impressions.${id}`, payload })
    },
    [id, trackEvent],
  )
}
