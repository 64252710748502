import { Flex } from 'cdk'
import { Link } from 'react-router-dom-v5-compat'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { CustomMessageWrapper } from '~/domains/chat/components/CustomMessage/CustomMessageWrapper'
import { useChatMessage } from '~/domains/chat/hooks/useChatMessage'
import { useClinicalTestNameTranslation } from '~/domains/clinicalTests'
import { ClinicalTestCode } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'

type TitleForTherapistProps = {
  patientFirstName: string
  clinicalTestCode: ClinicalTestCode
}

const TitleForTherapist = ({ patientFirstName, clinicalTestCode }: TitleForTherapistProps) => {
  const clinicalTestName = useClinicalTestNameTranslation(clinicalTestCode)

  return (
    <Translation
      id="clinicalTests.chat.filledByPatient.therapist.title"
      values={{ patientFirstName, clinicalTestName }}
    />
  )
}

export const MessageClinicalTestFilledByPatient = () => {
  const isPatient = useUserActingAsGroup() === 'patient'
  const { message } = useChatMessage<'SYSTEM_CLINICAL_TEST_FILLED_BY_PATIENT'>()
  const trackClick = useTrackEventClick()

  if (!message.srns?.payload) {
    return null
  }

  const { patientFirstName, therapistId, clinicalTestCode, clinicalTestAnswerId, patientId, therapistFullName } =
    message.srns.payload

  if (isPatient) {
    return (
      <CustomMessageWrapper
        background="green-20"
        borderColor="green-40"
        testId="system-message-clinical-test-filled-by-patient-patient"
      >
        <Flex $align="flex-start" $gap={12}>
          <Flex>
            <Text as="p" fontWeight="600" kind="paragraph">
              <Translation id="clinicalTests.chat.filledByPatient.patient.title" values={{ therapistFullName }} />
            </Text>
            <Text as="p" kind="paragraph">
              <Translation id="clinicalTests.chat.filledByPatient.patient.body" />
            </Text>
          </Flex>
          <Link to={getRoute(`/chat/${therapistId}/clinical-tests/${clinicalTestAnswerId}`)}>
            <Button
              kind="primary"
              onClick={() => {
                trackClick('clinical-test.chat.patient.read-answers', { clinicalTestAnswerId })
              }}
              size="sm"
            >
              <Translation id="clinicalTests.readAnswers" />
            </Button>
          </Link>
        </Flex>
      </CustomMessageWrapper>
    )
  }

  return (
    <CustomMessageWrapper
      background="blue-20"
      borderColor="blue-40"
      testId="system-message-clinical-test-filled-by-patient-therapist"
    >
      <Flex $align="flex-start" $gap={12}>
        <Flex>
          <Text as="p" fontWeight="600" kind="paragraph">
            <TitleForTherapist clinicalTestCode={clinicalTestCode} patientFirstName={patientFirstName} />
          </Text>
          <Text as="p" kind="paragraph">
            <Translation id="clinicalTests.chat.filledByPatient.therapist.body" />
          </Text>
        </Flex>
        <Link to={getRoute(`/chat/${patientId}/clinical-tests/${clinicalTestAnswerId}`)}>
          <Button
            kind="primary"
            onClick={() => {
              trackClick('clinical-test.chat.therapist.read-answers', { clinicalTestAnswerId })
            }}
            size="sm"
          >
            <Translation id="clinicalTests.readAnswers" />
          </Button>
        </Link>
      </Flex>
    </CustomMessageWrapper>
  )
}
