import { useEffect } from 'react'
import { useReactNativePostMessage } from '~/domains/appNative/hooks/useReactNativePostMessage'

export const PushNotificationRequest = () => {
  const { requestPushNotificationToken } = useReactNativePostMessage()

  useEffect(() => {
    requestPushNotificationToken()
  }, [requestPushNotificationToken])

  return null
}
