import { getSessionStorage } from '~/utils/sessionStorage/getSessionStorage'

export const usePartnershipQueryParam = () => {
  const queryParam = getSessionStorage('srns-query-param')

  if (!queryParam) {
    return null
  }

  const firstLandingPage = JSON.parse(queryParam).firstLandingPage

  return firstLandingPage ? firstLandingPage.match(/partnership\/([^\/]+)$/)[1] : null
}
