import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, ModalContent, ModalDescription, ModalTitle, ModalTrigger, Text } from 'ui'
import { Button } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { getRoute } from '~/utils/getRoute'
import { ChooseTherapistBeforeBookingModalContent } from './components/ChooseTherapistBeforeBookingModalContent'

export const ChooseTherapistBeforeBookingModal = () => {
  const history = useHistory()

  const handleRedirect = useCallback(
    (therapyId: string) => {
      history.push(getRoute(`/therapy-session/booking/${therapyId}`))
    },
    [history],
  )

  return (
    <Modal>
      <ModalTrigger>
        <Button kind="primary" type="button">
          <Text kind="caption">
            <Translation id="actions.scheduleNextTherapySession" />
          </Text>
        </Button>
      </ModalTrigger>

      <ModalContent>
        <ModalTitle>
          <Translation id="agenda.chooseTherapistBeforeBookingModal.title" />
        </ModalTitle>
        <ModalDescription>
          <Translation id="agenda.chooseTherapistBeforeBookingModal.description" />
        </ModalDescription>
        <ChooseTherapistBeforeBookingModalContent handleRedirect={handleRedirect} />
      </ModalContent>
    </Modal>
  )
}
