import { gql, useMutation } from '@apollo/client'
import { CreatePatientMutation, CreatePatientMutationVariables } from '~/types/graphql'

const CREATE_PATIENT = gql`
  mutation createPatient($input: CreatePatientInput!) {
    createPatient(input: $input) {
      id
    }
  }
`

export const useCreatePatientMutation = () =>
  useMutation<CreatePatientMutation, CreatePatientMutationVariables>(CREATE_PATIENT)
