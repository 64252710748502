import { useMemo } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { ReactHookFormRadioField, useReactHookFormContext } from '~/domains/react-hook-form'
import { useCurrentUserProfessionTypeValue } from '~/hooks/useCurrentUserProfessionTypeValue'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { TranslationId } from '~/i18n/types'
import { ChurnReason, ProfessionTypeValue } from '~/types/graphql'
import { TranslationMarkdown } from './TranslationMarkdown'

export type FormValues = {
  churnReason: ChurnReason
}

export type TherapistChurnReasonFieldsProps = {
  options: {
    availableFor: ProfessionTypeValue[]
    description: Extract<TranslationId, `therapistChurnReasonModal.option.description.${string}`>
    title: Extract<TranslationId, `therapistChurnReasonModal.option.title.${string}`>
    titlePsychiatrist?: Extract<TranslationId, `therapistChurnReasonModal.option.title.${string}.psychiatrist`>
    value: ChurnReason
  }[]
}

export const TherapistChurnReasonFields = ({ options }: TherapistChurnReasonFieldsProps) => {
  const {
    errors: { required },
  } = useFormTranslations()

  const form = useReactHookFormContext<FormValues>()

  const { isPsychiatrist } = useCurrentUserProfessionTypeValue()

  const churnReasonOptions = useMemo(() => {
    if (!isPsychiatrist) {
      return options.filter(({ availableFor }) => !availableFor.length || !availableFor.includes('PSYCHIATRIST'))
    }

    return options.filter(({ availableFor }) => !availableFor.length || availableFor.includes('PSYCHIATRIST'))
  }, [isPsychiatrist, options])

  const churnReason = form.watch('churnReason')

  return (
    <>
      {churnReasonOptions.map(({ title, titlePsychiatrist, description, value }, index) => {
        const titleTranslationId: TranslationId = isPsychiatrist && !!titlePsychiatrist ? titlePsychiatrist : title

        return (
          <Flex key={value} pt={index === 0 ? 0 : 8}>
            <Flex
              as="label"
              borderColor={value === churnReason ? 'purple08' : 'purple04'}
              borderRadius={16}
              borderSize={1}
              p={16}
            >
              <ReactHookFormRadioField name="churnReason" rules={{ required }} value={value}>
                <Text colorName="black" fontWeight="600" kind="paragraph">
                  <Translation id={titleTranslationId} />
                </Text>
                <TranslationMarkdown colorName="black" id={description} kind="paragraph" />
              </ReactHookFormRadioField>
            </Flex>
          </Flex>
        )
      })}
    </>
  )
}
