import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import {
  ProductUserFeedbackAnswerUpsertInput,
  ProductUserFeedbackAnswerUpsertMutation,
  ProductUserFeedbackAnswerUpsertMutationVariables,
} from '~/types/graphql'

const MUTATION = gql`
  mutation productUserFeedbackAnswerUpsert($input: ProductUserFeedbackAnswerUpsertInput!) {
    productUserFeedbackAnswerUpsert(input: $input) {
      id
    }
  }
`

export const useProductUserFeedbackAnswerUpsert = (id: ProductUserFeedbackAnswerUpsertInput['id']) => {
  const [mutation] = useMutation<
    ProductUserFeedbackAnswerUpsertMutation,
    ProductUserFeedbackAnswerUpsertMutationVariables
  >(MUTATION)

  const productUserFeedbackAnswerUpsert = useCallback(
    (answer: ProductUserFeedbackAnswerUpsertInput['answer']) =>
      mutation({
        variables: {
          input: {
            answer,
            id,
          },
        },
      }),
    [id, mutation],
  )

  return {
    productUserFeedbackAnswerUpsert,
  }
}
