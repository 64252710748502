import { toCurrency } from 'numbers'
import { useMemo } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTherapySession } from '../../../hooks/useTherapySession'

export const Price = () => {
  const { cost, free, therapyBundleId } = useTherapySession()

  const costAsCurrency = useMemo(() => {
    if (therapyBundleId && cost === 0) {
      return <Translation id="patientAgenda.therapySessionCard.cost.bundle" />
    }

    if (free || !cost) {
      return <Translation id="patientAgenda.therapySessionCard.cost.free" />
    }
    return toCurrency(cost)
  }, [cost, therapyBundleId, free])

  return (
    <Text fontWeight="400" kind="paragraph">
      {costAsCurrency}
    </Text>
  )
}
