import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'

export const RecalculateAvailableTherapistsModal = () => {
  const { dismiss } = useModals()

  const onRecalculateAvailableTherapists = useCallback(async () => {
    dismiss('recalculateAvailableTherapists')
  }, [dismiss])

  return (
    <>
      <Modal id="recalculateAvailableTherapists">
        <ModalCloseButton id="recalculateAvailableTherapists" />
        <OverflowAuto>
          <Text colorName="black" fontWeight="600" kind="h2">
            <Translation id="reservation.recalculateAvailableTherapistsModal.title" />
          </Text>
          <TranslationMarkdown id="reservation.recalculateAvailableTherapistsModal.body" kind="paragraph" pt={8} />
        </OverflowAuto>
        <Flex>
          <Flex pt={32}>
            <Button kind="primary" onClick={onRecalculateAvailableTherapists}>
              <Translation id="reservation.recalculateAvailableTherapistsModal.proceed" />
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}
