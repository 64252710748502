import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'

type Props = {
  onConfirm: () => void
}

export const ChangeTherapistInfoModal = ({ onConfirm }: Props) => {
  const { close } = useModals()

  const handleOnConfirm = useCallback(() => {
    onConfirm()

    close('changeTherapistInfo')
  }, [close, onConfirm])

  return (
    <Modal id="changeTherapistInfo">
      <ModalCloseButton id="changeTherapistInfo" />
      <Flex pt={16}>
        <Text fontWeight="600" kind="h2">
          <Translation id="reservation.changeTherapistInfoModal.title" />
        </Text>
      </Flex>
      <Flex pb={32} pt={16}>
        <Text kind="paragraph">
          <Translation id="reservation.changeTherapistInfoModal.body" />
        </Text>
      </Flex>
      <Button kind="primary" onClick={handleOnConfirm}>
        <Translation id="actions.confirm" />
      </Button>
    </Modal>
  )
}
