import { gql, useQuery } from '@apollo/client'
import { SuggestedTherapistsQuery } from '~/types/graphql'

const QUERY = gql`
  query suggestedTherapists {
    suggestedTherapists {
      therapistsIds
      isChanged
    }
  }
`

export const useSuggestedTherapists = () => {
  const { data, error, loading } = useQuery<SuggestedTherapistsQuery>(QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  return { error, loading, data }
}
