import loadable from '@loadable/component'
import { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { Translation } from '../Translation'
import { TranslationMarkdown } from '../TranslationMarkdown'
import { modalId } from './constants'
import { shouldOpenSentencesTutorial } from './utils/shouldOpenSentencesTutorial'
import { upsertSentencesTutorialViewedAt } from './utils/upsertSentencesTutorialViewedAt'

const Fallback = styled.div`
  width: 120px;
  height: 120px;
`

const LazyImage = loadable(() => import('./Image.lazy'), {
  resolveComponent: (components) => components.Image,
  fallback: <Fallback />,
})

const SentencesTutorialTrigger = () => {
  const { open } = useModals()

  useEffect(() => {
    open(modalId)

    upsertSentencesTutorialViewedAt()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export const SentencesTutorialIntroductionModal = () => {
  const { close } = useModals()

  const onClose = useCallback(() => {
    close(modalId)
  }, [close])

  return (
    <>
      {shouldOpenSentencesTutorial() && <SentencesTutorialTrigger />}

      <Modal id={modalId}>
        <OverflowAuto grow={1}>
          <ModalCloseButton id={modalId} />
          <LazyImage />
          <Flex pt={32}>
            <Text fontWeight="600" kind="h2">
              <Translation id="sentences.tutorial.title" />
            </Text>
            <TranslationMarkdown id="sentences.tutorial.body.1" kind="paragraph" pt={16} />
            <Flex pt={4}>
              <Text fontWeight="400" kind="paragraph">
                <Translation id="sentences.tutorial.body.2" />
                🎯
              </Text>
            </Flex>
          </Flex>
        </OverflowAuto>
        <Button kind="primary" onClick={onClose}>
          <Translation id="actions.gotIt" />
        </Button>
      </Modal>
    </>
  )
}
