import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationId, TranslationValues } from '~/i18n/types'

type Props = {
  id: TranslationId
  values?: TranslationValues
}

export const ModalTitle = ({ id, values }: Props) => (
  <Flex pb={16}>
    <Text fontWeight="600" kind="h2">
      <Translation id={id} values={values} />
    </Text>
  </Flex>
)
