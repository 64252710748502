import { Icon } from 'icons'
import { memo, useCallback } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { useTabNavigationMenu } from '../hooks/useTabNavigationMenu'
import { MenuAction } from './MenuAction'

export const MenuItemFAQ = memo(() => {
  const { open } = useIntercom()
  const { closeMenu } = useTabNavigationMenu()

  const handleClick = useCallback(() => {
    // NOTE: Open `home` as default, than `help` if it exists
    open('home')
    open('help')

    closeMenu()
  }, [closeMenu, open])

  return (
    <MenuAction onClick={handleClick}>
      <Flex pr={12}>
        <Icon colorName="grey-60" name="question-mark" size={24} />
      </Flex>

      <Flex align="flex-start" grow={1} shrink={1}>
        <Text fontWeight="400" kind="paragraph">
          <Translation id="menu.help.faq" />
        </Text>
      </Flex>
    </MenuAction>
  )
})

MenuItemFAQ.displayName = 'MenuItemFAQPatient'
