import { ReactHookFormTextFieldNew } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'

export const FieldPhoneNumberNew = () => {
  const {
    errors: { required },
    placeholders,
  } = useFormTranslations()

  const { isMobilePhone } = useFormValidation()

  return (
    <ReactHookFormTextFieldNew
      autoComplete="tel-national"
      name="phoneNumber"
      placeholder={placeholders.phoneNumberLongText}
      rules={{ required, validate: isMobilePhone }}
      type="tel"
    />
  )
}
