import { fold } from 'fp-ts/boolean'
import { pipe } from 'fp-ts/function'
import { useCallback } from 'react'
import { isEmail } from 'validation'
import { isEmailTypo } from './isEmailTypo'
import { useFormTranslations } from './useFormTranslations'

export const useIsEmail = () => {
  const {
    errors: { email: label },
  } = useFormTranslations()

  return useCallback(
    (value: unknown) =>
      pipe(
        value,
        isEmail,
        fold<boolean | string>(
          () => label,
          () => true,
        ),
      ),
    [label],
  )
}

export const useIsEmailTypo = () =>
  useCallback(
    (value: unknown) =>
      pipe(
        value,
        isEmailTypo,
        fold<boolean>(
          () => false,
          () => true,
        ),
      ),
    [],
  )
