import { ReactNode } from 'react'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useUserRedirectEffect } from '~/hooks/useUserRedirectEffect'
import { Route } from '~/utils/getRoute'

type Props = {
  children: ReactNode
  target?: Route
}

export const AssertPatient = ({ children, target }: Props) => {
  const isAuthorized = useUserActingAsGroup() === 'patient'

  useUserRedirectEffect(!isAuthorized, target)

  if (!isAuthorized) {
    return null
  }

  return <>{children}</>
}
