import { ReactNode } from 'react'
import { EventViewId, useTrackEventView } from '~/domains/analytics/hooks/useTrackEventView'
import { useScrollTopEffect } from '~/hooks/useScrollTopEffect'

type Props = {
  children: ReactNode
  id: EventViewId
}

export const PageRoute = ({ children, id }: Props) => {
  useScrollTopEffect()

  useTrackEventView(id)

  return <>{children}</>
}
