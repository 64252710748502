import { gql, useQuery } from '@apollo/client'
import { UserCustomerQuery, UserCustomerQueryVariables } from '~/types/graphql'
import { useCurrentUser } from './useCurrentUser'

const QUERY = gql`
  query userCustomer($id: String!) {
    user(id: $id) {
      id
      customer {
        id
        address {
          id
          city
          country
          name
          postalCode
          province
          street
        }
        invoiceEnabledAt
        stripeDefaultPaymentMethodId
        stsEnabledAt
        taxCode
      }
    }
  }
`

export const useUserCustomer = () => {
  const { id } = useCurrentUser()

  const { data, error, loading } = useQuery<UserCustomerQuery, UserCustomerQueryVariables>(QUERY, {
    variables: {
      id,
    },
  })

  return {
    error: !!error,
    loading,
    customer: data?.user?.customer,
  }
}
