import { gql, useQuery } from '@apollo/client'
import { createContext, ReactNode, useContext, useMemo } from 'react'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import {
  FindTherapistAvailabilityGivenCountByIdQuery,
  FindTherapistAvailabilityGivenCountByIdQueryVariables,
} from '~/types/graphql'

type Props = {
  children: ReactNode
}

const QUERY = gql`
  query findTherapistAvailabilityGivenCountById($id: String!) {
    findTherapistProfileById(id: $id) {
      id
      therapist {
        availableHoursPerWeek
        id
      }
      monthlyAvailabilitiesGivenCount {
        current {
          available
        }
      }
      weeklyAvailabilitiesGivenCount {
        current {
          available
        }
        next {
          available
        }
      }
    }
  }
`

type ContextType = {
  data?: FindTherapistAvailabilityGivenCountByIdQuery
  error: boolean
  loading: boolean
  refetch: () => void
}

const Context = createContext<ContextType | null>(null)

const Provider = ({ children }: Props) => {
  const { id } = useCurrentUser()

  const { data, error, loading, refetch } = useQuery<
    FindTherapistAvailabilityGivenCountByIdQuery,
    FindTherapistAvailabilityGivenCountByIdQueryVariables
  >(QUERY, {
    variables: {
      id,
    },
  })

  const value = useMemo(() => ({ data, error: !!error, loading, refetch }), [data, error, loading, refetch])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const TherapistAvailabilitiesGivenCountProvider = ({ children }: Props) => {
  const { isAuthenticated } = useAuthState()
  const isTherapist = useUserActingAsGroup() === 'therapist'

  if (!isAuthenticated || !isTherapist) {
    return <>{children}</>
  }

  return <Provider>{children}</Provider>
}

export const useTherapistAvailabilitiesGivenCount = (): ContextType => {
  const value = useContext(Context)

  if (!value) {
    throw new Error(
      'The `useTherapistAvailabilitiesGivenCount` should be wrapped with `TherapistAvailabilitiesGivenCountProvider`.',
    )
  }

  return value
}
