import { Svg } from 'ui-deprecated'

export const JournalingLogScoreIllustration3 = ({ size }: { size: number }) => (
  <Svg fill="none" height={size} style={{ maxWidth: '100%' }} viewBox="0 0 64 64" width={size}>
    <circle cx="32" cy="32" fill="#DFDAF6" r="21.33" />
    <circle cx="25.39" cy="29.44" fill="#141323" r=".64" />
    <circle cx=".64" cy=".64" fill="#141323" r=".64" transform="matrix(-1 0 0 1 39.25 28.8)" />
    <path d="M27.73 34.35h8.54" stroke="#000" strokeWidth="1.28" />
    <path
      d="M15.86 40.53a9.17 9.17 0 0 0 2.37 12.76M48.05 40.53a9.17 9.17 0 0 1-2.37 12.76"
      stroke="#B5B2EA"
      strokeLinecap="round"
      strokeWidth="2.56"
    />
  </Svg>
)
