import { Chip } from 'ui'
import { Translation } from '~/components/Translation'
import { AppointmentDetails } from '../components/AppointmentDetails'
import { ConnectToLiveButton } from '../components/ConnectToLiveButton'
import { ContentSkeleton } from '../components/ContentSkeleton'
import { Price } from '../components/Price'

type Props = {
  isVeryNextTherapySession?: boolean
}

export const BookedWithoutPaymentMethodContent = ({ isVeryNextTherapySession }: Props) => (
  <ContentSkeleton
    bottomChildren={<ConnectToLiveButton isVeryNextTherapySession={isVeryNextTherapySession} />}
    topEndChildren={<Price />}
    topStartChildren={
      <Chip kind="warning">
        <Translation id="therapySession.patient.noPaymentMethod.statusValue" />
      </Chip>
    }
  >
    <AppointmentDetails canManage />
  </ContentSkeleton>
)
