import { useEffect } from 'react'
import { useNavigation } from '../hooks/useNavigation'

const useNavigationHideBackButtonEffect = () => {
  const { hideBackButton } = useNavigation()

  useEffect(() => {
    hideBackButton()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const NavigationHideBackButtonEffect = () => {
  useNavigationHideBackButtonEffect()

  return null
}
