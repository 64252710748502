import { CenteredLoader } from '~/components/CenteredLoader'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { useSuggestedTherapists } from '~/hooks/useSuggestedTherapists'
import { usePaymentMethods } from '../payments/hooks/usePaymentMethods'
import { Reservation } from './components/Reservation'

export const ReservationPage = () => {
  const { loading: suggestedTherapistsLoading, data: suggestedTherapists } = useSuggestedTherapists()

  const { therapies, loading: therapiesLoading } = usePatientTherapies()

  const { items, loading: loadingPaymentMethods } = usePaymentMethods()

  if (suggestedTherapistsLoading || therapiesLoading || loadingPaymentMethods) {
    return <CenteredLoader />
  }

  // TODO if suggestedTherapistsLoading is false and suggestedTherapists is null, show an error message or something
  // if (!suggestedTherapists) {
  //   return null
  // }

  return (
    <Reservation
      paymentMethodsIds={items.map(({ id }) => id)}
      suggestedTherapistsIds={suggestedTherapists?.suggestedTherapists.therapistsIds || []}
      therapies={therapies}
    />
  )
}
