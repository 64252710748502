import { ComponentProps } from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'
import { CheckboxField } from 'ui-deprecated'
import { useLoadingState } from '~/hooks/useLoadingState'

type CheckboxProps = ComponentProps<typeof CheckboxField>

type Props = CheckboxProps & {
  rules?: RegisterOptions
  validateOnSubmit?: boolean
}

export const ReactHookFormCheckboxField = ({ disabled, name, rules, validateOnSubmit = true, ...props }: Props) => {
  const { control } = useFormContext()
  const { loading } = useLoadingState()

  return (
    <Controller
      control={control}
      defaultValue={false}
      name={name}
      render={({
        field: { onChange, value, ref },
        fieldState: { invalid },
        formState: { isSubmitting, isSubmitted, isValid },
      }) => (
        <CheckboxField
          {...props}
          ref={ref}
          checked={value}
          disabled={(isValid && (isSubmitting || loading)) || disabled}
          invalid={(invalid && !validateOnSubmit) || (invalid && isSubmitted && validateOnSubmit)}
          name={name}
          onChange={onChange}
          type="checkbox"
          value={name}
        />
      )}
      rules={rules}
    />
  )
}
