import { gql, useQuery } from '@apollo/client'
import { ClinicalTestAnswerIsCompletedQuery, ClinicalTestAnswerIsCompletedQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query clinicalTestAnswerIsCompleted($answerId: String!) {
    clinicalTestAnswer(answerId: $answerId) {
      id
      answers {
        answer
        questionId
      }
    }
  }
`

export const useClinicalTestAnswerIsCompleted = (answerId: string) => {
  const { data, error, loading } = useQuery<
    ClinicalTestAnswerIsCompletedQuery,
    ClinicalTestAnswerIsCompletedQueryVariables
  >(QUERY, {
    variables: {
      answerId,
    },
  })

  return {
    isCompleted: !!data?.clinicalTestAnswer.answers.length,
    error: !!error,
    loading,
  }
}
