import { Icon } from 'icons'
import { memo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { MenuHorizontalItem } from '~/domains/navigation/TabNavigation/components/MenuHorizontalItem'
import { MenuVerticalItem } from '~/domains/navigation/TabNavigation/components/MenuVerticalItem'
import { useTabNavigationMenu } from '~/domains/navigation/TabNavigation/hooks/useTabNavigationMenu'
import { NavigationProps } from '~/domains/navigation/TabNavigation/types'
import { getRoutes } from '~/utils/getRoutes'

export const RouteSettingsMenuItem = memo(({ direction }: NavigationProps) => {
  const { pathname } = useLocation()

  const active = !!matchPath(pathname, {
    path: getRoutes(
      '/historical-therapy-sessions',
      '/invoices',
      '/notifications',
      '/notifications/journaling',
      '/plan',
      '/settings/account',
      '/settings/billing',
      '/settings/payments',
    ),
  })

  const { open, toggleMenu } = useTabNavigationMenu()

  const Item = direction === 'horizontal' ? MenuHorizontalItem : MenuVerticalItem

  return (
    <Flex data-test-id="tab-item-settings">
      <Pressable onClick={toggleMenu}>
        <Item active={active || open} open={open}>
          <Flex basis="32px" pb={8}>
            <Icon colorName="white" name="menu" size={24} />
          </Flex>

          <Text fontWeight={active || open ? '600' : '400'} kind="footnote">
            <Translation id="menu.menu" />
          </Text>
        </Item>
      </Pressable>
    </Flex>
  )
})

RouteSettingsMenuItem.displayName = 'RouteSettingsMenuItem'
