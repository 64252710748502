import { useElements, useStripe } from '@stripe/react-stripe-js'
import queryString from 'query-string'
import { useCallback } from 'react'
import { useAddPaymentMethodByPaymentElement } from '~/domains/payments/hooks/useAddPaymentMethodByPaymentElement'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useIpAddress } from '~/hooks/useIpAddress'
import { useToasts } from '~/hooks/useToasts'
import { getEnv } from '~/utils/getEnv'
import { getRoute } from '~/utils/getRoute'

type Param = {
  returnRoute: string
}

export const useConfirmSetup = ({ returnRoute }: Param) => {
  const stripe = useStripe()
  const elements = useElements()
  const { addToast } = useToasts()
  const { email, fullName } = useCurrentUser()
  const addPaymentMethodByPaymentElement = useAddPaymentMethodByPaymentElement()
  const { getIp } = useIpAddress()

  const confirmSetup = useCallback(async () => {
    if (!stripe || !elements) {
      return {
        ok: false,
      }
    }

    const params = `?${queryString.stringify({ returnRoute })}`
    const route = getRoute('/settings/payments/confirm')

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        mandate_data: {
          customer_acceptance: {
            online: {
              ip_address: await getIp(),
              user_agent: navigator.userAgent,
            },
            type: 'online',
          },
        },
        payment_method_data: {
          billing_details: {
            address: {
              country: 'IT',
            },
            email,
            name: fullName,
          },
        },
        return_url: `${getEnv('SERENIS_APP_URL')}${route}${params}`,
      },
      redirect: 'if_required',
    })

    if (error) {
      addToast({
        translationId: 'settings.payments.genericError',
        translationValues: {
          errorMessage: error.message || '',
        },
        type: 'alert',
      })

      return {
        ok: false,
      }
    }

    const paymentMethodId = setupIntent.payment_method

    if (!paymentMethodId) {
      return {
        ok: false,
      }
    }

    await addPaymentMethodByPaymentElement(String(paymentMethodId))

    return {
      ok: true,
    }
  }, [addPaymentMethodByPaymentElement, addToast, elements, email, fullName, getIp, returnRoute, stripe])

  return {
    confirmSetup,
  }
}
