import { Flex } from 'ui-deprecated'
import { FieldAvailabilityHours } from '~/domains/availabilities/components/FieldAvailabilityHours'
import { FieldAvailabilityMinutes } from '~/domains/availabilities/components/FieldAvailabilityMinutes'

type Props = {
  isPatientAvailable?: boolean
  restrictHoursRange?: boolean
  type: 'endAt' | 'startAt'
}

export type CalendarAvailabilityStartAtFormValues = {
  startAtHours: number
  startAtMinutes: number
}

export type CalendarAvailabilityEndAtFormValues = {
  endAtHours: number
  endAtMinutes: number
}

export const CalendarAvailabilityFormSelect = ({ isPatientAvailable, restrictHoursRange = false, type }: Props) => (
  <Flex align="center" direction="row" justify="space-between">
    <Flex basis="50%" grow={1} pr={8} shrink={1}>
      <FieldAvailabilityHours
        isPatientAvailable={isPatientAvailable}
        name={`${type}Hours`}
        restrictHoursRange={restrictHoursRange}
      />
    </Flex>
    <Flex basis="50%" grow={1} pl={8} shrink={1}>
      <FieldAvailabilityMinutes isPatientAvailable={isPatientAvailable} name={`${type}Minutes`} />
    </Flex>
  </Flex>
)
