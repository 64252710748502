import { matchPath, useParams } from 'react-router-dom'
import { useRootHistory } from '~/hooks/useRootHistory'
import { getRoute } from '~/utils/getRoute'

type Param = {
  therapyId: string
}

export const useTherapyIdLocationParam = () => {
  const params = useParams<Param>()

  const { location } = useRootHistory()

  const match = matchPath<Partial<Param>>(location.pathname, {
    path: [getRoute('/change-therapist/:therapyId'), getRoute('/change-therapist/:therapyId/booking')],
  })

  return params.therapyId ?? match?.params?.therapyId
}
