import { timeZones } from 'validation'
import { ReactHookFormSelect } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'
import { useIntl } from '~/i18n/hooks/useIntl'
import { useTranslation } from '~/i18n/hooks/useTranslation'

export const FieldTimeZone = () => {
  const {
    errors: { required },
    labels,
    placeholders,
  } = useFormTranslations()

  const { isTimeZone } = useFormValidation()
  const { timeZone } = useIntl()

  const helperText = useTranslation('forms.helperTexts.timeZone', { timeZone })

  return (
    <ReactHookFormSelect
      helperText={helperText}
      label={labels.timeZone}
      name="timeZone"
      rules={{ required, validate: isTimeZone }}
    >
      <option value="">{placeholders.timeZone}</option>

      {timeZones.map(({ name, code }) => (
        <option key={code} value={code}>
          {name}
        </option>
      ))}
    </ReactHookFormSelect>
  )
}
