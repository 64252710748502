import { FormatDateEnum, format } from 'dates'
import { pipe } from 'fp-ts/function'
import { Card, Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { getTherapySessionDurationRange } from '~/utils/dates/getTherapySessionDurationRange'

type Props = {
  endAt: Date
  startAt: Date
}

export const DateInfoBox = ({ endAt, startAt }: Props) => (
  <Card>
    <Flex align="center" direction="row" justify="space-between" px={8}>
      <Flex align="center" direction="row">
        <Flex basis="80%" pr={4}>
          <Text colorName="grey-80" fontWeight="400" kind="paragraph">
            <Translation id="generic.date" />
          </Text>
          <Flex pt={4}>
            <Text colorName="black" fontWeight="600" kind="paragraph">
              {pipe(startAt, format(FormatDateEnum.LONG_DAY_DATE_MONTH_NAME))}
            </Text>
          </Flex>
        </Flex>

        <Flex basis="50%" pr={16}>
          <Text colorName="grey-80" fontWeight="400" kind="paragraph">
            <Translation id="generic.hour" />
          </Text>
          <Flex pt={4}>
            <Text colorName="black" fontWeight="600" kind="paragraph">
              <Translation
                id="generic.hoursAndMinutes"
                values={{
                  hoursAndMinutes: getTherapySessionDurationRange({ endAt, startAt }),
                }}
              />
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  </Card>
)
