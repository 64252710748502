import {
  BORDER_RADIUS_CIRCLE,
  BORDER_WIDTH_1,
  COLOR_PRIMARY_10,
  COLOR_PRIMARY_40,
  COLOR_PRIMARY_80,
} from 'design-tokens'
import { Icon, IconName } from 'icons'
import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { Text } from 'ui'
import { Button, Flex, PositionRelative } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useTherapist } from '~/hooks/useTherapist'
import { TranslationId } from '~/i18n/types'
import { TherapistProfessionalExperienceType } from '~/types/graphql'

type CurriculumIconProps = {
  showLine: boolean
  type: TherapistProfessionalExperienceType | null
}

type CurriculumIconContainerProps = Pick<CurriculumIconProps, 'showLine'>

const CurriculumIconContainer = styled(Flex)<CurriculumIconContainerProps>`
  width: 32px;
  height: 32px;
  border: ${BORDER_WIDTH_1} solid ${COLOR_PRIMARY_40};
  border-radius: ${BORDER_RADIUS_CIRCLE};
  background-color: ${COLOR_PRIMARY_10};

  ${({ showLine }) =>
    showLine &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 33px;
        bottom: 1px;
        left: 15px;
        display: block;
        border: ${BORDER_WIDTH_1} dashed ${COLOR_PRIMARY_80};
      }
    `}
`

const CurriculumIcon = ({ showLine, type }: CurriculumIconProps) => {
  const iconName: Extract<IconName, 'graduation-cap' | 'calendar' | 'bookmark-book'> | null = useMemo(() => {
    switch (type) {
      case 'DEGREE':
        return 'graduation-cap'
      case 'REGISTER_REGISTRATION':
        return 'calendar'
      case 'SPECIALIZATION':
        return 'bookmark-book'
      default:
        return null
    }
  }, [type])

  return (
    <CurriculumIconContainer align="center" justify="center" showLine={showLine}>
      {iconName && <Icon colorName="primary-80" name={iconName} size={20} />}
    </CurriculumIconContainer>
  )
}

type MainItemProps = PropsWithChildren<CurriculumIconProps>

const MainItem = ({ children, showLine, type }: MainItemProps) => {
  const titleTranslation: Extract<TranslationId, `profile.education.type.${string}`> | null = useMemo(() => {
    switch (type) {
      case 'DEGREE':
        return 'profile.education.type.degree'
      case 'REGISTER_REGISTRATION':
        return 'profile.education.type.registerRegistration'
      case 'SPECIALIZATION':
        return 'profile.education.type.specialization'
      default:
        return null
    }
  }, [type])

  return (
    <PositionRelative direction="row" pb={showLine ? 16 : 0}>
      <Flex>
        <CurriculumIcon showLine={showLine} type={type} />
      </Flex>
      <Flex grow={1} justify="center" pl={16} shrink={1}>
        {titleTranslation && (
          <Text colorName="grey-80" fontWeight="600" kind="paragraph">
            <Translation id={titleTranslation} />
          </Text>
        )}
        <Flex pt={4}>{children}</Flex>
      </Flex>
    </PositionRelative>
  )
}

type OtherItemProps = { name: string }

const OtherItem = ({ name }: OtherItemProps) => (
  <Flex align="center" direction="row">
    <Flex>
      <Icon colorName="grey-80" name="book-stack" size={24} />
    </Flex>
    <Flex grow={1} pl={8} shrink={1}>
      <Text kind="caption">{name}</Text>
    </Flex>
  </Flex>
)

export const TherapistCurriculum = () => {
  const {
    therapist: { professionalExperiences },
  } = useTherapist()

  const mainItems = useMemo(
    () => professionalExperiences.filter(({ type }) => type !== null),
    [professionalExperiences],
  )

  const otherItems = useMemo(
    () => professionalExperiences.filter(({ type }) => type === null),
    [professionalExperiences],
  )

  const [areAllItemsVisible, setAreAllItemsVisible] = useState(false)

  const showAllItems = useCallback(() => {
    setAreAllItemsVisible(true)
  }, [])

  return (
    <>
      <Text fontWeight="600" kind="paragraph">
        <Translation id="therapist.presentationCard.curriculum.title" />
      </Text>
      <Flex pt={16}>
        {mainItems.map(({ id, name, type }, index, { length }) => (
          <MainItem key={id} showLine={index < length - 1} type={type}>
            <Text kind="caption">{name}</Text>
          </MainItem>
        ))}
        {otherItems.length > 0 && (
          <>
            {!areAllItemsVisible ? (
              <Flex align="flex-start" pt={16}>
                <Button kind="secondary" onClick={showAllItems} size="small">
                  <Translation id="actions.learnMore" />
                </Button>
              </Flex>
            ) : (
              <>
                <Flex pt={16}>
                  <Text fontWeight="600" kind="paragraph">
                    <Translation id="therapist.presentationCard.curriculum.title2" />
                  </Text>
                </Flex>
                <Flex pt={16}>
                  {otherItems.map(({ id, name }) => (
                    <OtherItem key={id} name={name} />
                  ))}
                </Flex>
              </>
            )}
          </>
        )}
      </Flex>
    </>
  )
}
