import { useEffect } from 'react'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'

export const useUpdateIntercomFeatureFlags = () => {
  const { update } = useIntercom()
  const { getAllFlags, getValue, identity } = useFeatureFlagsByUserId()

  useEffect(() => {
    if (!identity) {
      return
    }

    try {
      const flags = getAllFlags()

      if (!flags) {
        return
      }

      update({
        customAttributes: flags,
      })
    } catch (error) {}
  }, [getAllFlags, getValue, identity, update])

  return null
}
