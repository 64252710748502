type Route =
  | '/clinical-tests'
  | `/clinical-tests/${string}`
  | '/detail'
  | '/diagnosis'
  | '/diagnosis/edit'
  | '/diary'
  | `/diary/${string}`

export const getPatientDetailRoute = (route: Route) => route
