import { pipe } from 'fp-ts/function'
import { useEffect, useState } from 'react'
import { getEnv } from '~/utils/getEnv'
import { useAmplifyMethods } from './useAmplifyMethods'
import { useAuthMethods } from './useAuthMethods'

export const useAuthConfigureEffect = () => {
  const [init, setInit] = useState(false)
  const { configure: amplifyConfigure } = useAmplifyMethods()
  const { configure: authConfigure } = useAuthMethods()

  useEffect(() => {
    if (init) {
      return
    }

    setInit(true)

    amplifyConfigure({
      Auth: {
        region: getEnv('COGNITO_REGION'),
        userPoolId: getEnv('COGNITO_USER_POOL_ID'),
        userPoolWebClientId: getEnv('COGNITO_USER_POOL_CLIENT_ID'),
        cookieStorage: {
          domain: getEnv('COGNITO_AUTH_COOKIE_DOMAIN'),
          path: '/',
          expires: 30,
          secure: pipe(getEnv('COGNITO_AUTH_COOKIE_SECURE'), Boolean),
        },
      },
    })

    authConfigure({
      oauth: {
        domain: getEnv('COGNITO_IDP_DOMAIN'),
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: getEnv('COGNITO_REDIRECT_SIGN_IN'),
        redirectSignOut: getEnv('COGNITO_REDIRECT_SIGN_OUT'),
        responseType: 'code',
      },
    })
  }, [amplifyConfigure, authConfigure, init])
}
