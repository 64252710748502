import { Flex, Pressable } from 'cdk'
import { ColorName } from 'design-tokens'
import { Icon } from 'icons'
import { useCallback } from 'react'
import { Text } from 'ui'
import { Popover, PopoverContent, PopoverTrigger } from '~/components/FloatingUI/Popover'
import { usePopoverContext } from '~/components/FloatingUI/Popover/PopoverContext'
import { Translation } from '~/components/Translation'
import { Language, useLanguage } from '../hooks/useLanguage'

type Props = {
  colorName?: Extract<ColorName, 'neutral-80' | 'white'>
}

const OpeningIcon = ({ colorName = 'neutral-80' }: Props) => {
  const { isMounted } = usePopoverContext()

  return <Icon colorName={colorName} name={isMounted ? 'nav-arrow-up' : 'nav-arrow-down'} size={16} />
}

type LanguageItemProps = {
  lang: Language
}

const LanguageItem = ({ lang }: LanguageItemProps) => {
  const { close } = usePopoverContext()
  const { language, setLanguage } = useLanguage()

  const onSetEnglishLanguage = useCallback(() => {
    close()
    setLanguage(lang)
  }, [close, lang, setLanguage])

  return (
    <Pressable onClick={onSetEnglishLanguage}>
      <Text
        colorName={language === lang ? 'primary' : undefined}
        fontWeight={language === lang ? '600' : '400'}
        kind="caption"
      >
        <Translation id={`generic.language.long.${lang}`} />
      </Text>
    </Pressable>
  )
}

export const LanguageSwitcherPopover = ({ colorName = 'neutral-80' }: Props) => {
  const { language } = useLanguage()

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Flex $align="center" $direction="row">
          <Icon colorName={colorName} name="language" size={12} />
          <Flex $px={2} />
          <Text colorName={colorName} fontWeight="600" kind="caption" textTransform="uppercase">
            <Translation id={`generic.language.short.${language}`} />
          </Text>
          <Flex $px={2} />
          <OpeningIcon colorName={colorName} />
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <Flex $align="flex-start">
          <LanguageItem lang="it" />
          <Flex $py={8} />
          <LanguageItem lang="en" />
        </Flex>
      </PopoverContent>
    </Popover>
  )
}
