import {
  autoUpdate,
  flip,
  offset,
  Placement,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'
import { ReactElement, useCallback, useMemo, useState } from 'react'
import { TooltipContent } from './TooltipContent'
import { TooltipContext } from './TooltipContext'
import { TooltipTrigger } from './TooltipTrigger'

type Param = {
  kind?: 'card' | 'title'
  placement?: Placement
}

type Props = {
  children: [ReactElement<typeof TooltipTrigger>, ReactElement<typeof TooltipContent>]
} & Param

export const useTooltip = ({ kind = 'card', placement = 'top-start' }: Param) => {
  const [isOpen, setIsOpen] = useState(false)

  const open = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const { context, ...data } = useFloating({
    middleware: [offset(8), flip(), shift({ padding: 16 })],
    onOpenChange: setIsOpen,
    open: isOpen,
    placement,
    whileElementsMounted: autoUpdate,
  })
  const { styles, isMounted } = useTransitionStyles(context)

  const hover = useHover(context, { handleClose: safePolygon() })
  const dismiss = useDismiss(context)
  const role = useRole(context)
  const interactions = useInteractions([dismiss, hover, role])

  return useMemo(
    () => ({
      context,
      isMounted,
      kind,
      open,
      styles,
      ...interactions,
      ...data,
    }),
    [context, data, interactions, isMounted, kind, open, styles],
  )
}

export const Tooltip = ({ children, ...props }: Props) => {
  const tooltip = useTooltip(props)

  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>
}
