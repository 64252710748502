import { PositionRelative } from 'cdk'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { APP_BAR_HEIGHT, APP_BAR_HEIGHT_VARIANT } from '../AppBar/constants'

const FlowWrapperContainer = styled(PositionRelative)`
  height: calc(100vh - 144px);
`

export const FlowWrapper = ({ children }: PropsWithChildren) => {
  const { isVariant } = useFeatureFlagsByFingerprint()

  return (
    <FlowWrapperContainer
      $grow={1}
      $maxHeight={`calc(100vh - ${isVariant('ff_restyle_survey') ? APP_BAR_HEIGHT_VARIANT : APP_BAR_HEIGHT}px)`}
      $overflow="hidden"
      $shrink={1}
    >
      {children}
    </FlowWrapperContainer>
  )
}

FlowWrapper.displayName = 'FormFlowWrapper'
