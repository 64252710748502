import { Flex } from 'cdk'
import { BORDER_RADIUS_SM, SPACING_LG, SPACING_MD, SPACING_SM } from 'design-tokens'
import { useTherapist } from '~/hooks/useTherapist'
import { TherapyTherapyPathType } from '~/types/graphql'
import { useValidTherapistProfessionTypeValue } from '../hooks/useValidTherapistProfessionTypeValue'
import { TherapistCardAvatar } from './TherapistCardAvatar'
import { TherapistCardDescription } from './TherapistCardDescription'
import { TherapistCardFree } from './TherapistCardFree'
import { TherapistCardSession } from './TherapistCardSession'

type TherapistCardProps = {
  free?: boolean
  endAt: Date
  startAt: Date
  therapyPathType?: TherapyTherapyPathType | null
}

export const TherapistCard = ({ free, ...props }: TherapistCardProps) => {
  const { fullName, therapist } = useTherapist()
  const titleId = useValidTherapistProfessionTypeValue({ pathType: props.therapyPathType, sex: therapist.sex })

  return (
    <Flex
      $borderRadius={BORDER_RADIUS_SM}
      $direction="column"
      $elevationName="xl"
      $gap={SPACING_LG}
      $justify="space-between"
      $overflow="hidden"
      $p={SPACING_SM}
    >
      <Flex $direction="row" $justify="space-between">
        <TherapistCardSession {...props} />
        {free && <TherapistCardFree />}
      </Flex>
      <Flex $direction="row" $gap={SPACING_MD} $justify="space-between">
        <TherapistCardDescription fullName={fullName} titleId={titleId} />
        <TherapistCardAvatar image={therapist.profileImage?.s} name={fullName} />
      </Flex>
    </Flex>
  )
}
