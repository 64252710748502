import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useModals } from '~/domains/modals'
import { TriggerPaymentModal } from '~/domains/triggerPayment'
import { useTriggerPayment } from '~/domains/triggerPayment/useTriggerPayment'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { getRoute } from '~/utils/getRoute'
import { useTherapySession } from '../../../hooks/useTherapySession'

export const PayNowButton = () => {
  const { close, open } = useModals()
  const [triggerPayment] = useTriggerPayment()
  const [loading, setLoading] = useState(false)
  const [loopCompleted, setLoopCompleted] = useState(false)
  const [success, setSuccess] = useState<boolean | null>(null)
  const { refetch: refetchPatientAgenda } = usePatientAgenda()
  const history = useHistory()
  const { id } = useTherapySession()

  const triggerPaymentCallback = useCallback(async () => {
    try {
      setLoading(true)
      setLoopCompleted(false)

      const response = await triggerPayment({
        variables: {
          input: {
            therapySessionId: id,
          },
        },
      })

      setSuccess(Boolean(response.data?.triggerPayment.ok))
    } catch (error) {
      setSuccess(false)
    } finally {
      setLoading(false)
    }
  }, [id, triggerPayment])

  const handlePayNow = useCallback(() => {
    open(`triggerPayment:${id}`)

    triggerPaymentCallback()
  }, [id, open, triggerPaymentCallback])

  const onClose = useCallback(async () => {
    await refetchPatientAgenda()

    close(`triggerPayment:${id}`)
    setSuccess(null)
  }, [close, id, refetchPatientAgenda])

  const onChangePaymentMethod = useCallback(() => {
    onClose()

    history.push(getRoute('/settings/payments'))
  }, [history, onClose])

  const onLoopCompleted = useCallback(() => {
    setLoopCompleted(true)
  }, [setLoopCompleted])

  return (
    <>
      <Button kind="secondary" onClick={handlePayNow}>
        <Translation id="patientAgenda.therapySessionCard.scheduled.cta" />
      </Button>
      <TriggerPaymentModal
        loading={loading}
        loopCompleted={loopCompleted}
        onChangePaymentMethod={onChangePaymentMethod}
        onClose={onClose}
        onLoopCompleted={onLoopCompleted}
        success={success}
        therapySessionId={id}
        triggerPaymentCallback={triggerPaymentCallback}
      />
    </>
  )
}
