import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { NavigationProps } from '~/domains/navigation/TabNavigation/types'
import { useBreakpoints } from '~/hooks/useBreakpoints'
import { PatientTabNavigation } from './PatientTabNavigation'
import { TherapistTabNavigation } from './TherapistTabNavigation'

export const TabNavigation = ({ direction }: NavigationProps) => {
  const isTherapist = useUserActingAsGroup() === 'therapist'

  const { isMedium, isSmallOnly } = useBreakpoints()
  const horizontal = isSmallOnly && direction === 'horizontal'
  const vertical = isMedium && direction === 'vertical'

  if (!horizontal && !vertical) {
    return null
  }

  if (isTherapist) {
    return <TherapistTabNavigation direction={direction} />
  }

  return <PatientTabNavigation direction={direction} />
}
