import { minutesToMilliseconds } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useEffect, useState } from 'react'

const pollInterval = pipe(5, minutesToMilliseconds)

type Props = {
  startPolling: (pollInterval: number) => void
  stopPolling: () => void
}

export const AgendaPollingEffect = ({ startPolling, stopPolling }: Props) => {
  const [polling, setPolling] = useState(false)

  useEffect(() => {
    if (polling) {
      return
    }

    setPolling(true)

    startPolling(pollInterval)

    return () => {
      setPolling(false)

      stopPolling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
