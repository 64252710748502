import { useEffect } from 'react'
import { useNavigation } from '../hooks/useNavigation'

const useNavigationShowNotificationsInboxEffect = () => {
  const { showNotificationsInbox } = useNavigation()

  useEffect(() => {
    showNotificationsInbox()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const NavigationShowNotificationsInboxEffect = () => {
  useNavigationShowNotificationsInboxEffect()

  return null
}
