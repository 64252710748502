import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatientOrTherapist } from '~/domains/auth/components/AssertPatientOrTherapist'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

const LazyNotificationsPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.NotificationsPageView,
})

export const NotificationsPage: Page = {
  exact: true,
  id: 'notifications',
  paths: ['/notifications'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'notifications',
    title: 'menu.notifications',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatientOrTherapist>{children}</AssertPatientOrTherapist>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyNotificationsPageView />,
}
