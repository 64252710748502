import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { formatFoodJournalDay } from '../utils/formatFoodJournalDay'
import { startOfMonth } from 'date-fns'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { usePatientId } from '~/hooks/usePatientId'

type Context = {
  patientId: string
  currentDay: Date
  setCurrentDay: Dispatch<SetStateAction<Date>>
  currentCalendarMonth: Date
  setCurrentCalendarMonth: Dispatch<SetStateAction<Date>>
} | null

const FoodJournalContext = createContext<Context>(null)
const { Provider } = FoodJournalContext

export const useFoodJournal = () => {
  const context = useContext(FoodJournalContext)

  if (context == null) {
    throw new Error('Missing provider: <FoodJournalProvider />')
  }

  return context
}

type Props = PropsWithChildren<{
  initialCurrentDay?: Date
}>

export const FoodJournalProvider = ({ children, initialCurrentDay }: Props) => {
  const [currentDay, rawSetCurrentDay] = useState(initialCurrentDay ?? formatFoodJournalDay(new Date()))
  const [currentCalendarMonth, rawSetCurrentCalendarMonth] = useState(
    initialCurrentDay ?? formatFoodJournalDay(startOfMonth(new Date())),
  )

  const urlPatientId = usePatientId()
  const { id: mePatientId } = useCurrentUser()
  const patientId = urlPatientId ?? mePatientId

  const setCurrentDay: Dispatch<SetStateAction<Date>> = useCallback(
    (param) => {
      rawSetCurrentDay(
        typeof param === 'function' ? formatFoodJournalDay(param(currentDay)) : formatFoodJournalDay(param),
      )
    },
    [currentDay],
  )

  const setCurrentCalendarMonth: Dispatch<SetStateAction<Date>> = useCallback(
    (param) => {
      rawSetCurrentCalendarMonth(
        typeof param === 'function' ? startOfMonth(param(currentCalendarMonth)) : startOfMonth(param),
      )
    },
    [currentCalendarMonth],
  )

  const value = useMemo(
    () => ({
      patientId,
      currentDay,
      setCurrentDay,
      currentCalendarMonth,
      setCurrentCalendarMonth,
    }),
    [patientId, currentDay, setCurrentDay, currentCalendarMonth, setCurrentCalendarMonth],
  )

  return <Provider value={value}>{children}</Provider>
}
