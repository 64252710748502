import { TranslationId } from '~/i18n/types'
import { TherapyTherapyPathType } from '~/types/graphql'

type JobTitleTranslationId = Extract<TranslationId, `therapist.jobTitle.${string}`>

type Param = {
  sex: number
  therapyPathType?: TherapyTherapyPathType
}

export const getTherapistJobTitleTranslationId = ({ sex, therapyPathType }: Param): JobTitleTranslationId => {
  if (therapyPathType === 'PATH_NUTRITION_WEIGHT_LOSS' || therapyPathType === 'PATH_NUTRITION_DCA') {
    return sex === 1 ? 'therapist.jobTitle.nutritionist.male' : 'therapist.jobTitle.nutritionist.female'
  }

  if (therapyPathType === 'MYSELF_PSYCHIATRY') {
    return 'therapist.jobTitle.psychiatry'
  }

  if (therapyPathType === 'PATH_COACHING') {
    return 'therapist.jobTitle.coach'
  }

  return sex === 1 ? 'therapist.jobTitle.male' : 'therapist.jobTitle.female'
}
