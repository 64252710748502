import { gql, useQuery } from '@apollo/client'
import { ClinicalTestAnswerQuery, ClinicalTestAnswerQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query clinicalTestAnswer($answerId: String!) {
    clinicalTestAnswer(answerId: $answerId) {
      id
      clinicalTestId
      code
      questions {
        id
      }
      createdAt
      answers {
        answer
        questionId
      }
      therapyId
    }
  }
`

export const useClinicalTestAnswer = (answerId: string) => {
  const { data, error, loading } = useQuery<ClinicalTestAnswerQuery, ClinicalTestAnswerQueryVariables>(QUERY, {
    variables: {
      answerId,
    },
  })

  return {
    clinicalTestAnswer: data?.clinicalTestAnswer,
    error: !!error,
    loading,
  }
}
