import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { FormValues } from '../types/formValues'
import { getDeleteForTherapistEmergencyRoute } from '../utils/getDeleteForTherapistEmergencyRoute'
import { StepMessage } from './StepMessage'
import { StepPaymentWarning } from './StepPaymentWarning'
import { StepReason } from './StepReason'
import { StepWarning } from './StepWarning'

type Props = Pick<TherapistAgendaTherapySession, 'id' | 'free' | 'startAt' | 'patient' | 'status' | 'typeCost'>

const initialEntries = [getDeleteForTherapistEmergencyRoute('/warning')]

export const DeleteForTherapistEmergencyModal = ({ id, free, patient, startAt, status, typeCost }: Props) => {
  const form = useReactHookForm<FormValues>({
    defaultValues: {
      message: '',
      therapistEmergencyReason: null,
    },
  })

  return (
    <ReactHookFormProvider {...form}>
      <MemoryRouter initialEntries={initialEntries}>
        <Switch>
          <Route path={getDeleteForTherapistEmergencyRoute('/warning')}>
            <StepWarning free={free} patient={patient} startAt={startAt} status={status} typeCost={typeCost} />
          </Route>
          <Route path={getDeleteForTherapistEmergencyRoute('/payment-warning')}>
            <StepPaymentWarning patient={patient} startAt={startAt} status={status} typeCost={typeCost} />
          </Route>
          <Route path={getDeleteForTherapistEmergencyRoute('/reason')}>
            <StepReason startAt={startAt} status={status} typeCost={typeCost} />
          </Route>
          <Route path={getDeleteForTherapistEmergencyRoute('/message')}>
            <StepMessage id={id} patient={patient} />
          </Route>
        </Switch>
      </MemoryRouter>
    </ReactHookFormProvider>
  )
}
