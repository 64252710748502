import { useEffect } from 'react'
import { useScrollRestore } from '~/hooks/useScrollRestore'

export const PageScrollEffect = () => {
  const { scrollTo } = useScrollRestore()

  useEffect(() => {
    scrollTo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
