import { useEffect } from 'react'
import { useNavigation } from '../hooks/useNavigation'

const useNavigationHideAppBarEffect = () => {
  const { hideAppBar } = useNavigation()

  useEffect(() => {
    hideAppBar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const NavigationHideAppBarEffect = () => {
  useNavigationHideAppBarEffect()

  return null
}
