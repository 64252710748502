import { useEffect } from 'react'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { upsertAnonymousFingerprint } from '~/utils/upsertAnonymousFingerprint'

export const useUpdateIntercomFingerprint = () => {
  const { update } = useIntercom()

  const fingerprintId = upsertAnonymousFingerprint()

  useEffect(() => {
    update({
      customAttributes: {
        sync_fingerprint_id: fingerprintId,
      },
    })
  }, [fingerprintId, update])

  return null
}
