import { useChatUnreadCount } from '~/domains/chat/hooks/useChatUnreadCount'
import { NavigationLink } from '../types'
import { NavLink } from './NavLink'

type SideBarLinksProps = {
  extended: boolean
  links: NavigationLink[]
  onLinkClick?: () => void
}

export const SideBarLinks = ({ extended, links, onLinkClick }: SideBarLinksProps) => {
  const { unread } = useChatUnreadCount()

  return links.map((link) => (
    <NavLink
      key={link.id}
      extended={extended}
      link={link}
      onLinkClick={onLinkClick}
      unread={link.id === 'chat' ? unread : undefined}
    />
  ))
}
