import { SessionStorageItemValue, SessionStorageKey } from './types'

export const setSessionStorage = <T extends SessionStorageItemValue>(key: SessionStorageKey, value: T): T | null => {
  try {
    window.sessionStorage.setItem(`srns-${key}`, typeof value === 'string' ? value : String(value))

    return value
  } catch (error) {
    return null
  }
}
