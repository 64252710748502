import { useMemo } from 'react'
import { Text } from 'ui'
import { Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { PatientAgendaTherapySession } from '~/domains/agenda/types'
import { Modal, ModalId } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { EditTherapySessionContactButton } from './EditTherapySessionContactButton'

type Props = Pick<PatientAgendaTherapySession, 'id' | 'therapistId'>

export const EditTherapySessionModal = ({ id, therapistId }: Props) => {
  const { therapies } = usePatientTherapies()

  const therapy = useMemo(
    () => therapies.find((therapy) => therapy?.therapist?.id === therapistId),
    [therapistId, therapies],
  )

  const modalId: Extract<ModalId, `editTherapySession:${string}`> = `editTherapySession:${id}`

  return (
    <Modal id={modalId}>
      <Flex grow={1} shrink={1}>
        <ModalCloseButton id={modalId} />

        <OverflowAuto>
          <Text fontWeight="600" kind="h3">
            <Translation id="therapySession.lessThan24Hours.edit.modal.title" />
          </Text>

          <TranslationMarkdown
            colorName="black"
            id="therapySession.lessThan24Hours.edit.modal.subtitle"
            kind="paragraph"
            pt={24}
            values={{ therapistFullName: String(therapy?.therapist?.fullName) }}
          />
        </OverflowAuto>

        <Flex pt={16}>
          <EditTherapySessionContactButton modalId={modalId} />
        </Flex>
      </Flex>
    </Modal>
  )
}
