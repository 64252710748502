import { Flex } from 'cdk'
import styled from 'styled-components'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'

const Container = styled(Flex)`
  @keyframes slide-thx-page-description {
    to {
      opacity: 1;
    }
  }

  opacity: 0;
  animation: slide-thx-page-description 1.5s ease forwards;
  animation-delay: 0.6s;
`

export const TherapistCardDescription = ({ fullName, titleId }: any) => (
  <Container $grow={1} $shrink={1}>
    <Text kind="paragraph">
      <TranslationMarkdown id="thankYouPage.therapist-card.therapistWith" values={{ therapistName: fullName }} />
    </Text>
    <Text kind="paragraph">
      <Translation id={titleId} />
    </Text>
  </Container>
)
