type base64ToBlobProps = {
  base64: string
  mimeType: string
}

export const convertBase64ToBlob = ({ base64, mimeType = 'text/plain' }: base64ToBlobProps): Blob => {
  const byteCharacters = atob(base64)
  const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0))
  const byteArray = new Uint8Array(byteNumbers)

  return new Blob([byteArray], { type: mimeType })
}
