import { pipe } from 'fp-ts/function'
import { Text, TextProps } from 'ui'
import { getTherapySessionDayAndMonthLong } from '~/utils/dates/getTherapySessionDayAndMonth'
import { getTherapySessionDurationRange } from '~/utils/dates/getTherapySessionDurationRange'
import { Translation } from './Translation'

type Props = {
  endAt: Date
  startAt: Date
  textColor?: TextProps['colorName']
}

export const DateTime = ({ endAt, startAt, textColor = 'black' }: Props) => (
  <Text colorName={textColor} fontWeight="600" kind="paragraph">
    <Translation
      id="generic.fullDateAndHour.2"
      values={{
        dateWithDayAndMonth: pipe(startAt, getTherapySessionDayAndMonthLong),
        hoursAndMinutes: getTherapySessionDurationRange({ endAt, startAt }),
      }}
    />
  </Text>
)
