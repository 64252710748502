import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'

export const ServiceInfoTitle = () => (
  <Flex pt={32}>
    <Text fontWeight="600" kind="h2">
      <Translation id="home.patient.serviceInfo.title" />
    </Text>
  </Flex>
)
