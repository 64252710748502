import { Flex } from 'cdk'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { ReactHookFormTextArea } from '~/domains/react-hook-form'

export const ClinicalTestMessageRoute = () => {
  const trackClick = useTrackEventClick()

  return (
    <Flex $gap={8}>
      <Text kind="h3">
        <Translation id="clinicalTests.message.title" />
      </Text>
      <Flex $gap={12}>
        <ReactHookFormTextArea minHeight="130px" name="message" />
        <Flex $direction="row" $justify="flex-end">
          <Flex $basis="128px">
            <Button kind="primary" onClick={() => trackClick('clinical-test.send-to-patient-chat.send')} type="submit">
              <Translation id="actions.send" />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
