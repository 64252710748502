import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatientOrTherapist } from '~/domains/auth/components/AssertPatientOrTherapist'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { TherapistTherapiesProvider } from '~/domains/therapies/TherapistTherapiesProvider'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

const LazyChat = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const ChatPage: Page = {
  exact: false,
  id: 'chat',
  paths: ['/chat'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'chat',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatientOrTherapist>{children}</AssertPatientOrTherapist>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => {
    const role = useUserActingAsGroup()

    if (role === 'therapist') {
      return <TherapistTherapiesProvider>{children}</TherapistTherapiesProvider>
    }

    return <PatientTherapiesProvider>{children} </PatientTherapiesProvider>
  },
  PageView: () => <LazyChat />,
}
