import { isSameDay } from 'date-fns/fp'
import { Icon } from 'icons'
import { useMemo } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link, Text } from 'ui'
import { Calendar, Emoji, Flex, PageLayout, Sticky } from 'ui-deprecated'
import { TherapySessionCardSkeleton } from '~/components/TherapySessionCardSkeleton'
import { TherapySessionCardTherapist } from '~/components/TherapySessionCardTherapist'
import { Translation } from '~/components/Translation'
import { useTherapistAgenda } from '~/domains/agenda/hooks/useTherapistAgenda'
import { PushNotificationRequest } from '~/domains/appNative/components/PushNotificationRequest'
import { AvailabilitiesInsertionReminder } from '~/domains/availabilities/reminders'
import { IntercomTrackEvent } from '~/domains/intercom/components/IntercomTrackEvent'
import { TherapistProfileImage } from '~/domains/therapist/components/TherapistProfileImage'
import { useTherapySessionsTherapistAgenda } from '~/domains/therapy-session/hooks/useTherapySessionsTherapistAgenda'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useCurrentUserProfessionTypeValue } from '~/hooks/useCurrentUserProfessionTypeValue'
import { useLanguage } from '~/i18n/hooks/useLanguage'
import { useIsSaasTherapist } from '../auth/hooks/useIsSaasTherapist'
import { useShowAvailabilitiesInsertionReminder } from '../availabilities/reminders/hooks/useShowAvailabilitiesInsertionReminder'
import { useFeatureFlagsByUserId } from '../featureFlags'
import { PatientsFiltersRecap } from '../patientsManagement/components/PatientsFiltersRecap'
import { SaasActivateGesto } from '../saas/components/SaasActivateGesto'
import { AgendaPollingEffect } from './AgendaPollingEffect'

export const TherapistView = () => {
  const { disabledBeforeDate, onSelectDay, selectedDate } = useTherapistAgenda()
  const { error, events, items, loading, startPolling, stopPolling } = useTherapySessionsTherapistAgenda()
  const { firstName } = useCurrentUser()
  const showAvailabilitiesInsertionReminder = useShowAvailabilitiesInsertionReminder()
  const { language } = useLanguage()
  const isSaasTherapist = useIsSaasTherapist()
  const { isPsychiatrist } = useCurrentUserProfessionTypeValue()

  const { isVariant1 } = useFeatureFlagsByUserId()

  const trackIntercomEvent = useMemo(
    () => !!items.filter(({ churn, status }) => status === 'ENDED' && !churn).length,
    [items],
  )

  return (
    <>
      <PageLayout maxWidth="1024px" pb={88}>
        {!loading && <PushNotificationRequest />}

        <AgendaPollingEffect startPolling={startPolling} stopPolling={stopPolling} />

        {showAvailabilitiesInsertionReminder && (
          <Flex pb={24}>
            <AvailabilitiesInsertionReminder />
          </Flex>
        )}

        {isVariant1('ff_saas_onboarding_internal') && !isSaasTherapist && !isPsychiatrist && (
          <Flex pb={24}>
            <SaasActivateGesto />
          </Flex>
        )}

        <Flex direction="column" grow={1} justify="space-between" mdDirection="row" shrink={1}>
          <Sticky mdBasis="40%">
            <Flex align="center" direction="row" pb={32}>
              <TherapistProfileImage size="sm" />

              <Flex align="center" data-test-id="welcome-message" direction="row" pl={16} wrap="wrap">
                <Text fontWeight="600" kind="h3">
                  <Translation id="agenda.welcomeBack" values={{ firstName }} />
                </Text>

                <Emoji style={{ fontSize: 32, paddingLeft: 8 }}>😊</Emoji>
              </Flex>
            </Flex>
            <Flex background="white" borderColor="purple04" borderRadius={16} borderSize={1} py={16}>
              <Calendar
                disabledBeforeDate={disabledBeforeDate}
                events={events}
                language={language}
                onChange={onSelectDay}
                sundaysEnabled
              />

              <Flex align="center" direction="row" justify="flex-end" px={24}>
                <Link as={ReactRouterLink} data-test-id="go-to-calendar-link" to="/availabilities/calendar">
                  <Text kind="caption" textDecoration="underline">
                    <Translation id="actions.goToCalendar" />
                  </Text>
                </Link>

                <Flex pl={8}>
                  <Icon colorName="primary" name="open-in-window" size={16} />
                </Flex>
              </Flex>
            </Flex>
            <Flex pt={24}>
              <PatientsFiltersRecap />
            </Flex>
          </Sticky>
          <Flex mdBasis="50%" mdPt={64} pt={48}>
            {error && (
              <Flex pt={16}>
                <Text colorName="red-80" kind="caption">
                  <Translation id="generic.errorOccurred.title" />
                </Text>
              </Flex>
            )}

            {loading && (
              <>
                <Flex pt={16}>
                  <TherapySessionCardSkeleton />
                </Flex>
                <Flex pt={16}>
                  <TherapySessionCardSkeleton />
                </Flex>
              </>
            )}

            {!loading && !items.length && (
              <Flex pt={16}>
                <Text fontWeight="600" kind="h3" textAlign="center">
                  <Translation id="agenda.noTherapySessions" />
                </Text>
              </Flex>
            )}

            {trackIntercomEvent && <IntercomTrackEvent name="banners.therapist-delete-reason" />}

            {!!items.length && (
              <Flex pt={16}>
                <Text fontWeight="400" kind="caption">
                  <Translation id="agenda.todayTherapySessions" />
                  <Text fontWeight="600" kind="caption">
                    {items.filter(({ startAt }) => isSameDay(startAt, selectedDate)).length}
                  </Text>
                </Text>
              </Flex>
            )}

            {!loading &&
              !error &&
              items
                .filter(({ startAt }) => isSameDay(selectedDate, startAt))
                .map(
                  ({
                    bonus,
                    churn,
                    duration,
                    endAt,
                    free,
                    id,
                    notes,
                    patient,
                    provider,
                    recurrency,
                    repeatEveryWeek,
                    startAt,
                    status,
                    therapist,
                    therapyPathType,
                    typeCost,
                    url,
                  }) => (
                    <Flex key={id} pt={16}>
                      <TherapySessionCardTherapist
                        bonus={bonus}
                        churn={churn}
                        duration={duration}
                        endAt={endAt}
                        free={free}
                        id={id}
                        notes={notes}
                        patient={patient}
                        provider={provider}
                        recurrency={recurrency}
                        repeatEveryWeek={repeatEveryWeek}
                        startAt={startAt}
                        status={status}
                        therapist={therapist}
                        therapyPathType={therapyPathType}
                        typeCost={typeCost}
                        url={url}
                      />
                    </Flex>
                  ),
                )}
          </Flex>
        </Flex>
      </PageLayout>
    </>
  )
}
