import { Flex, MountOn } from 'cdk'
import { memo } from 'react'
import { UserTherapist } from '~/domains/therapist/types'
import { MultipleSuggestionAvailabilitiesMedium } from './MultipleSuggestionAvailabilitiesMedium'
import { MultipleSuggestionAvailabilitiesSmall } from './MultipleSuggestionAvailabilitiesSmall'

type Props = {
  therapists: UserTherapist[]
  onTimeSlotSelected: (timeSlot: Date, therapistId: string) => void
  filterByTimeInterval: boolean
}

export const MultipleSuggestionAvailabilities = memo(
  ({ filterByTimeInterval, therapists, onTimeSlotSelected }: Props) => (
    <Flex $grow={1} $pt={16}>
      <MountOn mediaQuery="sm">
        <MultipleSuggestionAvailabilitiesSmall
          filterByTimeInterval={filterByTimeInterval}
          onTimeSlotSelected={onTimeSlotSelected}
          therapists={therapists}
        />
      </MountOn>

      <MountOn mediaQuery="gt-sm">
        <MultipleSuggestionAvailabilitiesMedium
          filterByTimeInterval={filterByTimeInterval}
          onTimeSlotSelected={onTimeSlotSelected}
          therapists={therapists}
        />
      </MountOn>
    </Flex>
  ),
)
