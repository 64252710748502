import { matchPath } from 'react-router-dom'
import { FormFlowResponseType } from '~/types/graphql'
import { Route, getRoute } from '~/utils/getRoute'

export const getFormFlowTypeFromPathname = (pathname: Route): FormFlowResponseType => {
  switch (true) {
    case !!matchPath(pathname, { path: getRoute('/change-therapist/:therapistId/preferences'), exact: true }):
      return 'PREFERENCES_CHANGE_THERAPIST'
    case !!matchPath(pathname, { path: getRoute('/change-therapist/:therapistId'), exact: true }):
      return 'COMPLETE_CHANGE_THERAPIST'
    case !!matchPath(pathname, { path: getRoute('/start'), exact: true }):
    default:
      return 'COMPLETE'
  }
}
