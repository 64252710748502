import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { getRoute } from '~/utils/getRoute'
import { HeartHug } from './HeartHug'

export const StickerModuleModal = () => {
  const history = useHistory()

  const { close } = useModals()

  const handleOnClick = useCallback(() => {
    history.push(getRoute('/stickers'))

    close('stickerModule')
  }, [close, history])

  return (
    <Modal id="stickerModule">
      <ModalCloseButton id="stickerModule" />
      <OverflowAuto>
        <Flex basis="140px">
          <HeartHug />
        </Flex>
        <Flex align="center" grow={1} justify="flex-start" mdAlign="flex-start" pt={24} px={24}>
          <Text fontWeight="600" kind="h2">
            <Translation id="stickerModule.modal.title" />
          </Text>
          <Flex pt={16}>
            <Text fontWeight="400" kind="paragraph">
              <Translation id="stickerModule.modal.body" />
            </Text>
          </Flex>
          <Flex pt={24}>
            <Text fontWeight="400" kind="paragraph">
              <Translation id="stickerModule.modal.note" />
            </Text>
          </Flex>
        </Flex>
      </OverflowAuto>
      <Button kind="primary" onClick={handleOnClick}>
        <Translation id="stickerModule.modal.cta" />
      </Button>
    </Modal>
  )
}
