import { Svg } from 'ui-deprecated'

export const JournalingLogScoreIllustration2 = ({ size }: { size: number }) => (
  <Svg fill="none" height={size} style={{ maxWidth: '100%' }} viewBox="0 0 64 64" width={size}>
    <circle cx="32" cy="32" fill="#DFDAF6" r="21.33" />
    <path d="M38.4 38.19a6.4 6.4 0 0 0-12.8 0" stroke="#000" strokeWidth="1.28" />
    <circle cx="24.96" cy="30.93" fill="#141323" r=".64" />
    <circle cx="39.04" cy="30.93" fill="#141323" r=".64" />
    <path
      d="M10.91 30.3c-3.43 0-6.22 2.46-6.22 5.5 0 3.03 3.19 5.46 6.94 6.13 7.65 1.38 16.02 0 26.43-3.7 2.67-.95 8.13-2.96 9.09 0"
      stroke="#B5B2EA"
      strokeLinecap="round"
      strokeWidth="2.56"
    />
    <path
      d="M53.12 30.3c3.42 0 6.19 2.46 6.19 5.5 0 3.03-3.17 5.46-6.9 6.13-7.62 1.38-15.95 0-26.3-3.7-2.66-.95-8.1-2.96-9.04 0"
      stroke="#B5B2EA"
      strokeLinecap="round"
      strokeWidth="2.56"
    />
  </Svg>
)
