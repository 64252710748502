import { PaymentElement, PaymentElementProps } from '@stripe/react-stripe-js'

const options: PaymentElementProps['options'] = {
  fields: {
    billingDetails: 'auto',
  },
  layout: {
    type: 'accordion',
    radios: true,
  },
  terms: {
    card: 'never',
  },
  wallets: {
    applePay: 'auto',
    googlePay: 'auto',
  },
  paymentMethodOrder: ['card', 'apple_pay', 'google_pay', 'paypal'],
}

export const StripePaymentElement = () => <PaymentElement options={options} />
