import { Flex } from 'cdk'
import { COLOR_PRIMARY_50, SPACING_XS } from 'design-tokens'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { usePartnership } from '~/hooks/usePartnership'
import { ReactHookFormCheckboxField } from '../components'
import { ReactHookFormCheckboxFieldVariant } from '../components/ReactHookFormCheckboxFieldVariant'

export const FieldTermsAndConditions = () => {
  const { formState, watch } = useFormContext()
  const termsAndConditions = watch('termsAndConditions')
  const partnership = usePartnership()

  return (
    <ReactHookFormCheckboxField name="termsAndConditions" rules={{ required: true }}>
      <Flex $pt={2} as="label" htmlFor="termsAndConditions">
        <TranslationMarkdown
          colorName={formState.isSubmitted && !termsAndConditions ? 'red-80' : 'black'}
          colorNameBold={formState.isSubmitted && !termsAndConditions ? 'red-80' : 'primary'}
          id={
            partnership === 'MY_SECRET_CASE' ? 'forms.labels.termsAndConditionsMysc' : 'forms.labels.termsAndConditions'
          }
          kind="caption"
          target="_blank"
        />
      </Flex>
    </ReactHookFormCheckboxField>
  )
}

// NOTE This is a temporary solution. All future form fields will be handled within the CheckboxButton component
const FlexPointer = styled(Flex)`
  cursor: pointer;

  &:hover p {
    color: ${COLOR_PRIMARY_50};
  }
`

export const FieldTermsAndConditionsVariant = () => {
  const { formState, watch } = useFormContext()
  const termsAndConditions = watch('termsAndConditions')
  const partnership = usePartnership()

  return (
    <Flex $align="center" $direction="row" $gap={SPACING_XS}>
      <ReactHookFormCheckboxFieldVariant name="termsAndConditions" rules={{ required: true }} />
      <FlexPointer $shrink={1} as="label" htmlFor="termsAndConditions">
        <TranslationMarkdown
          colorName={formState.isSubmitted && !termsAndConditions ? 'red-80' : 'black'}
          colorNameBold={formState.isSubmitted && !termsAndConditions ? 'red-80' : 'primary'}
          id={
            partnership === 'MY_SECRET_CASE' ? 'forms.labels.termsAndConditionsMysc' : 'forms.labels.termsAndConditions'
          }
          kind="caption"
          target="_blank"
        />
      </FlexPointer>
    </Flex>
  )
}
