import { COLOR_GREY_30, COLOR_LIGHTER } from 'design-tokens'
import { TextAreaStyleProps } from '../types'

export const getBackgroundColorFromProps = ({ disabled }: Pick<TextAreaStyleProps, 'disabled'>) => {
  if (disabled) {
    return COLOR_GREY_30
  }

  return COLOR_LIGHTER
}
