import { useCallback, useMemo } from 'react'
import { getAvailabilityHours } from '~/domains/availabilities/utils/slots'
import { ReactHookFormSelect } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'

type Props = {
  isPatientAvailable?: boolean
  name: 'endAtHours' | 'startAtHours'
  restrictHoursRange: boolean
}

export const FieldAvailabilityHours = ({ isPatientAvailable, name, restrictHoursRange }: Props) => {
  const {
    errors: { required, patientAvailability },
    labels,
    placeholders,
  } = useFormTranslations()

  const { isAvailabiliyRange } = useFormValidation()

  const sliced = useMemo(() => {
    const availabilityHours = getAvailabilityHours()

    if (name === 'endAtHours') {
      return restrictHoursRange ? availabilityHours.slice(1, -1) : availabilityHours.slice(1)
    }

    return restrictHoursRange ? availabilityHours.slice(1, -2) : availabilityHours.slice(0, -1)
  }, [name, restrictHoursRange])

  const validatePatientAvailability = useCallback(
    () => isPatientAvailable !== false || patientAvailability,
    [patientAvailability, isPatientAvailable],
  )

  return (
    <ReactHookFormSelect
      hideError
      label={labels.availability[name]}
      name={name}
      rules={{
        required,
        validate: {
          isAvailabiliyRange,
          validatePatientAvailability,
        },
      }}
    >
      <option value="">{placeholders.availability[name]}</option>

      {sliced.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </ReactHookFormSelect>
  )
}
