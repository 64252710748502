const customStateFailureErrors = [
  'external-provider-disabled',
  'missing-email-attribute',
  'unprocessable-entity',
] as const

type CustomStateFailureError = (typeof customStateFailureErrors)[number]

const regexp = new RegExp(`(${customStateFailureErrors.join('|')})`)

export const parseCustomStateFailureError = (message: string): CustomStateFailureError | null => {
  const [, customStateFailureError] = message.match(regexp) || []

  if (customStateFailureError) {
    return customStateFailureError as CustomStateFailureError
  }

  return null
}
