import { Translation } from '~/components/Translation'

type ClinicalTestResultProps = {
  value: number
}

export const ClinicalTestResultTranslation = ({ value }: ClinicalTestResultProps) => {
  if (value <= 4) {
    return <Translation id="clinicalTests.results.0.name" />
  }

  if (value <= 9) {
    return <Translation id="clinicalTests.results.1.name" />
  }

  if (value <= 14) {
    return <Translation id="clinicalTests.results.2.name" />
  }

  if (value <= 21) {
    return <Translation id="clinicalTests.results.3.name" />
  }

  if (value <= 27) {
    return <Translation id="clinicalTests.results.4.name" />
  }

  return null
}
