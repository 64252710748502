import { gql, useQuery } from '@apollo/client'
import { FindTherapyPathTypeByIdQuery, FindTherapyPathTypeByIdQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query findTherapyPathTypeById($id: String) {
    findTherapyPathById(id: $id) {
      type
    }
  }
`

export const useTherapyPathTypeById = (therapyPathId: string) => {
  const { data, loading } = useQuery<FindTherapyPathTypeByIdQuery, FindTherapyPathTypeByIdQueryVariables>(QUERY, {
    variables: {
      id: therapyPathId,
    },
  })

  return {
    loading,
    therapyPath: data?.findTherapyPathById.type ?? null,
  }
}
