import { Svg } from 'ui-deprecated'

export const JournalingLogScoreIllustration1 = ({ size }: { size: number }) => (
  <Svg fill="none" height={size} style={{ maxWidth: '100%' }} viewBox="0 0 64 64" width={size}>
    <circle cx="32" cy="32" fill="#DFDAF6" r="21.33" />
    <path d="M39.68 41.39a7.68 7.68 0 1 0-15.36 0" stroke="#000" strokeWidth="1.28" />
    <circle cx="24.96" cy="31.57" fill="#141323" r=".64" />
    <circle cx="39.04" cy="31.57" fill="#141323" r=".64" />
    <path d="M49.6 14.3v5.86c0 1.18-.95 2.13-2.13 2.13h-1.39" stroke="#000" strokeWidth="1.28" />
    <path
      d="M51.08 33.66a9.17 9.17 0 0 0-2.36-12.75M13.09 33.7a9.17 9.17 0 0 0 2.36 12.76"
      stroke="#B5B2EA"
      strokeLinecap="round"
      strokeWidth="2.56"
    />
    <path
      d="M49.5 5.33c-5.75 0-10.48 5.13-11.04 11.7-.03.35.42.48.6.17l.57-1.02a2.84 2.84 0 0 1 4.93 0c.55.95 1.93.95 2.47 0a2.84 2.84 0 0 1 4.93 0c.54.95 1.92.95 2.46 0a2.84 2.84 0 0 1 4.93 0l.58 1.02c.18.3.63.18.6-.18-.56-6.56-5.29-11.69-11.04-11.69Z"
      fill="#B5B2EA"
    />
  </Svg>
)
