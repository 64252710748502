import { useIsAvailabilityRange } from './useIsAvailabilityRange'
import { useIsEmail, useIsEmailTypo } from './useIsEmail'
import { useIsMobilePhone } from './useIsMobilePhone'
import { useIsOlderThan14AndYoungerThan18 } from './useIsOlderThan14AndYoungerThan18'
import { useIsPassword } from './useIsPassword'
import { useIsPostalCode } from './useIsPostalCode'
import { useIsProvince } from './useIsProvince'
import { useIsTaxCode } from './useIsTaxCode'
import { useIsTimeZone } from './useIsTimeZone'

export const useFormValidation = () => ({
  isAvailabiliyRange: useIsAvailabilityRange(),
  isEmail: useIsEmail(),
  isEmailTypo: useIsEmailTypo(),
  isMobilePhone: useIsMobilePhone(),
  isOlderThan14AndYoungerThan18: useIsOlderThan14AndYoungerThan18(),
  isPassword: useIsPassword(),
  isPostalCode: useIsPostalCode(),
  isProvince: useIsProvince(),
  isTaxCode: useIsTaxCode(),
  isTimeZone: useIsTimeZone(),
})
