import { Flex, MaxWidth640px } from 'cdk'
import { useCallback } from 'react'
import { Button, Text } from 'ui'
import { PageRoute } from '~/components/PageRoute'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { StartTherapyPathIcon } from '~/domains/agenda/components/StartTherapyPath/components/StartTherapyPathIcon'
import { ChangeTherapistModal } from '~/domains/changeTherapist/components/ChangeTherapistModal'
import { useModals } from '~/domains/modals'
import { Head } from '~/domains/reservation/components/Head'
import { useRootHistory } from '~/hooks/useRootHistory'
import { PageScrollEffect } from '~/routes/PageScrollEffect'
import { TherapyTherapyPathType } from '~/types/graphql'
import { getRoute } from '~/utils/getRoute'
import { mapTherapyAlreadyOngoingType } from '../utils/mapTherapyAlreadyOngoingType'

type Props = {
  therapyPathType?: TherapyTherapyPathType
  therapistFullName?: string
  therapistId?: string
  therapyId?: string
}

export const TherapyAlreadyOngoing = ({ therapyPathType, therapistFullName, therapistId, therapyId }: Props) => {
  const { open } = useModals()
  const rootHistory = useRootHistory()

  const onGoToChat = useCallback(() => {
    if (!therapistId) {
      return null
    }

    rootHistory.push(getRoute(`/chat/${therapistId}`))
  }, [therapistId, rootHistory])

  const therapyPathIsCoaching = therapyPathType === 'PATH_COACHING'
  const therapyPathIsPsichiatry = therapyPathType === 'MYSELF_PSYCHIATRY'

  const onChangeTherapist = useCallback(() => {
    if (!therapyId || therapyPathIsPsichiatry) {
      return
    }

    open(`changeTherapist:${therapyId}`)
  }, [open, therapyId, therapyPathIsPsichiatry])

  if (!therapyPathType || !therapyId) {
    return null
  }

  return (
    <>
      <PageScrollEffect />

      <Head translationId="reservation.therapyAlreadyOngoing" />

      <PageRoute id="therapy-already-ongoing">
        <MaxWidth640px $align="stretch" $grow={1} $justify="center" $p={24}>
          <Flex $align="center" $pb={8}>
            <StartTherapyPathIcon
              size={80}
              therapyPathType={mapTherapyAlreadyOngoingType[therapyPathType].illustrationTherapyPath}
            />
          </Flex>

          <Text fontWeight="600" kind="h1" textAlign="center">
            <Translation id={mapTherapyAlreadyOngoingType[therapyPathType].title} />
          </Text>

          <Flex $pt={16}>
            {therapistFullName ? (
              <TranslationMarkdown
                id="reservation.therapyAlreadyOngoing.description.1"
                kind="paragraph"
                textAlign="center"
                values={{ therapistFullName }}
              />
            ) : (
              <Text kind="paragraph" textAlign="center">
                <Translation id="reservation.therapyAlreadyOngoing.description.1.withoutTherapistFullName" />
              </Text>
            )}

            {!therapyPathIsCoaching && !therapyPathIsPsichiatry && (
              <Flex $pt={32}>
                <Text kind="paragraph" textAlign="center">
                  <Translation id="reservation.therapyAlreadyOngoing.description.2" />
                </Text>
              </Flex>
            )}
          </Flex>

          <Flex $gap={8} $pt={24}>
            <Button kind="primary" onClick={onGoToChat} type="submit">
              <Translation id="actions.goToChat" />
            </Button>

            {!therapyPathIsCoaching && !therapyPathIsPsichiatry && (
              <Button isGhost kind="secondary" onClick={onChangeTherapist} type="button">
                <Translation id="reservation.therapyAlreadyOngoing.changeTherapist.cta" />
              </Button>
            )}
          </Flex>
        </MaxWidth640px>
      </PageRoute>

      {!therapyPathIsCoaching && !therapyPathIsPsichiatry && <ChangeTherapistModal therapyId={therapyId} />}
    </>
  )
}
