import { useChatUnreadCount } from '~/domains/chat/hooks/useChatUnreadCount'
import { NavigationLink } from '../types'
import { NavLink } from './NavLink'

type TabBarLinksProps = {
  isMenuOpen: boolean
  links: NavigationLink[]
}

export const BottomBarLinks = ({ isMenuOpen, links }: TabBarLinksProps) => {
  const { unread } = useChatUnreadCount()

  return links.map((link) => (
    <NavLink
      key={link.id}
      extended={false}
      isMenuOpen={isMenuOpen}
      isMobile
      link={link}
      unread={link.id === 'chat' ? unread : undefined}
    />
  ))
}
