import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Agenda } from '~/domains/agenda'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { TherapistTherapiesProvider } from '~/domains/therapies/TherapistTherapiesProvider'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { TherapistFetchProvider } from '~/hooks/useTherapistFetch'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'
import { getRoute } from '~/utils/getRoute'
import { JournalingProvider } from '../journaling/hooks/useJournalingContext'

export const HomePage: Page = {
  exact: true,
  id: 'home',
  paths: ['/'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'home',
    title: null,
  },
  PageGuard: AssertAuthenticatedRedirect,
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => {
    const { id } = useCurrentUser()
    const group = useUserActingAsGroup()
    const history = useHistory()

    const onError = useCallback(() => {
      history.push(getRoute('/booking'))
    }, [history])

    if (group === 'therapist') {
      return (
        <TherapistFetchProvider id={id} onError={onError}>
          <TherapistTherapiesProvider>{children}</TherapistTherapiesProvider>
        </TherapistFetchProvider>
      )
    }

    return (
      <PatientTherapiesProvider>
        <JournalingProvider>{children}</JournalingProvider>
      </PatientTherapiesProvider>
    )
  },
  PageView: Agenda,
}
