import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { usePartnerFindLatest } from '~/domains/partner/hooks/usePartnerFindLatest'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { getRoute } from '~/utils/getRoute'
import { CreatePartnerSuggestion } from './CreatePartnerSuggestion'

export const CreatePartnerSuggestionBanner = () => {
  const { partner, loading } = usePartnerFindLatest()
  const history = useHistory()
  const { therapies } = usePatientTherapies()

  const coupleTherapyId = therapies.find(
    ({ ongoing, therapyPath }) => ongoing && therapyPath?.type === 'COUPLES_PSYCHOTHERAPY',
  )?.id

  const onClick = useCallback(() => {
    if (!coupleTherapyId) {
      history.push(getRoute('/'))

      return
    }

    history.push(getRoute(`/therapies/${coupleTherapyId}/details`))
  }, [history, coupleTherapyId])

  if (loading || partner || !coupleTherapyId) {
    return null
  }

  return <CreatePartnerSuggestion onClick={onClick} />
}
