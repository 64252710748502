import { Space } from 'cdk'
import { useCallback } from 'react'
import { Link as ReactRouterLink, useLocation } from 'react-router-dom'
import { Link, Text } from 'ui'
import { Button, CardBox, Flex, Form } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { FieldEmailTextFieldNew } from '~/domains/react-hook-form/fields/FieldEmailTextFieldNew'
import { FieldPasswordTextFieldNew } from '~/domains/react-hook-form/fields/FieldPasswordTextFieldNew'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useLoadingState } from '~/hooks/useLoadingState'
import { useToasts } from '~/hooks/useToasts'
import { useAuthMethods } from '../hooks/useAuthMethods'

type LocationState = {
  email?: string
}

type LoginFormProps = {
  email: string
  password: string
}

export const LoginForm = () => {
  const { state } = useLocation<LocationState>()
  const { addToast } = useToasts()
  const { loading, loadingKey, startLoading, stopLoading } = useLoadingState()
  const { labels } = useFormTranslations()
  const { signIn } = useAuthMethods()
  const form = useReactHookForm<LoginFormProps>({
    defaultValues: {
      email: state?.email ?? '',
    },
  })
  const localLoading = form.formState.isSubmitting || loadingKey === 'local'

  const handleSubmit = useCallback(
    async ({ email, password }: LoginFormProps) => {
      startLoading('local')

      try {
        await signIn(email, password)
      } catch (error) {
        addToast({ translationId: 'generic.errorOccurred.title', type: 'alert' })
      } finally {
        stopLoading()
      }
    },
    [addToast, signIn, startLoading, stopLoading],
  )

  return (
    <ReactHookFormProvider {...form}>
      <Form grow={1} mdGrow={0} mdShrink={0} onSubmit={form.handleSubmit(handleSubmit)} shrink={1}>
        <CardBox background="white" borderSize={0} boxShadow grow={1} mdGrow={0} mdShrink={0} shrink={1}>
          <FieldEmailTextFieldNew />

          <Flex pt={16}>
            <FieldPasswordTextFieldNew autoComplete="current-password" label={labels.password} name="password" />
          </Flex>

          <Flex pt={16}>
            <Link as={ReactRouterLink} to="/forgot-password">
              <Text fontWeight="600" kind="caption" textDecoration="underline">
                <Translation id="actions.passwordForgotten" />
              </Text>
            </Link>
          </Flex>

          <Flex pt={32}>
            <Button disabled={loading} id="login-button" kind="primary" loading={localLoading} type="submit">
              <Translation id="actions.signin" />
            </Button>

            <Flex align="center" pt={24}>
              <Text colorName="grey-80" fontWeight="400" kind="caption">
                <Text colorName="grey-80" fontWeight="400" kind="caption">
                  <Translation id="generic.dontHaveAnAccountYet" />
                </Text>
                <Space />
                <Link as={ReactRouterLink} to="/start">
                  <Text fontWeight="600" kind="caption" textAlign="center" textDecoration="underline">
                    <Translation id="actions.missingAccount" />
                  </Text>
                </Link>
              </Text>
            </Flex>
          </Flex>
        </CardBox>
      </Form>
    </ReactHookFormProvider>
  )
}
