import { gql, useMutation } from '@apollo/client'
import { CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation createPaymentMethod($input: CreatePaymentMethodInput!) {
    createPaymentMethod(input: $input) {
      id
    }
  }
`

export const useCreatePaymentMethod = () =>
  useMutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(MUTATION)
