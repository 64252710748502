type ReservationRoute =
  | '/book-by-suggested-therapist'
  | '/check-verification-code'
  | '/insert-billing-information'
  | '/insert-payment-method'
  | '/no-matched-therapists'
  | '/send-verification-code'
  | '/skip-booking'
  | '/skip-booking/coaching'
  | '/skip-booking/psychiatry'
  | '/submit'
  | '/thank-you-page'
  | '/therapy-already-ongoing'

export const getReservationRoutes = (route: ReservationRoute): string => route
