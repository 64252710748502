import { useMemo } from 'react'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { TranslationId } from '~/i18n/types'
import { TherapyTherapyPathType } from '~/types/graphql'

type Props = {
  therapyPathType: TherapyTherapyPathType | null
}

type TherapySessionTypeTranslationId = Extract<
  TranslationId,
  `patientAgenda.therapySessionCard.therapySessionType.${string}`
>

export const TherapySessionCardType = ({ therapyPathType }: Props) => {
  const therapySessionTypeTranslationId = useMemo((): TherapySessionTypeTranslationId => {
    if (therapyPathType === 'PATH_ASSERTIVE_COMMUNICATION') {
      return 'patientAgenda.therapySessionCard.therapySessionType.path.assertiveCommunication'
    }

    if (therapyPathType === 'PATH_COACHING') {
      return 'patientAgenda.therapySessionCard.therapySessionType.path.coaching'
    }

    if (therapyPathType === 'PATH_SLEEP') {
      return 'patientAgenda.therapySessionCard.therapySessionType.path.sleep'
    }

    if (therapyPathType === 'COUPLES_PSYCHOTHERAPY') {
      return 'patientAgenda.therapySessionCard.therapySessionType.couples'
    }

    if (therapyPathType === 'UNDERAGE_PSYCHOTHERAPY') {
      return 'patientAgenda.therapySessionCard.therapySessionType.underage'
    }

    if (therapyPathType === 'MYSELF_PSYCHIATRY') {
      return 'patientAgenda.therapySessionCard.therapySessionType.psychiatry'
    }

    return 'patientAgenda.therapySessionCard.therapySessionType.individual'
  }, [therapyPathType])

  return (
    <Text fontWeight="400" kind="paragraph">
      <Translation id={therapySessionTypeTranslationId} />
    </Text>
  )
}
