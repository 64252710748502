import { gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import {
  GetConfirmablePaymentIntentByTransactionIdQuery,
  GetConfirmablePaymentIntentByTransactionIdQueryVariables,
} from '~/types/graphql'

type Param = {
  transactionId: string
}

const QUERY = gql`
  query getConfirmablePaymentIntentByTransactionId($transactionId: String!) {
    getConfirmablePaymentIntentByTransactionId(id: $transactionId) {
      amount
      clientSecret
      paymentIntentId
      paymentMethodId
    }
  }
`

export const useGetConfirmablePaymentIntentByTransactionId = () => {
  const { transactionId } = useParams<Param>()

  const { data, error, loading } = useQuery<
    GetConfirmablePaymentIntentByTransactionIdQuery,
    GetConfirmablePaymentIntentByTransactionIdQueryVariables
  >(QUERY, {
    variables: {
      transactionId,
    },
  })

  return {
    error: !!error,
    loading,
    paymentIntent: data?.getConfirmablePaymentIntentByTransactionId || null,
  }
}
