import { Flex, Pressable } from 'cdk'
import { BORDER_RADIUS_XS, COLOR_GREY_20, SPACING_SM, SPACING_XS } from 'design-tokens'
import { Icon } from 'icons'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Avatar, Text } from 'ui'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { FlexWithTransition } from './common/FlexWithTransition'

type SideBarFooterProps = {
  extended: boolean
  $showIcon?: boolean
  onIconClick?: () => void
  onAvatarClick?: () => void
}

const IconPressable = styled(Pressable)`
  &:hover {
    border-radius: ${BORDER_RADIUS_XS};
    background-color: ${COLOR_GREY_20};
  }
`

const IconTriggerContainer = styled(FlexWithTransition)<{ $showIcon?: boolean }>`
  ${({ $showIcon = false }) => css`
    opacity: ${$showIcon ? 1 : 0};
    visibility: ${$showIcon ? 'visible' : 'hidden'};
  `};
`

const IconTrigger = ({ extended, onIconClick }: SideBarFooterProps) => (
  <Flex $align={extended ? 'flex-end' : 'center'} $justify="center">
    <IconPressable onClick={onIconClick}>
      <Flex $align="center">
        <Icon colorName="grey-80" name={extended ? 'arrow-left-to-line' : 'arrow-right-to-line'} size={32} />
      </Flex>
    </IconPressable>
  </Flex>
)

export const SideBarFooter = ({ extended, $showIcon, onIconClick, onAvatarClick }: SideBarFooterProps) => {
  const user = useCurrentUser()

  return (
    <Flex $direction="column" $gap={SPACING_XS}>
      <IconTriggerContainer $showIcon={$showIcon}>
        <IconTrigger extended={extended} onIconClick={onIconClick} />
      </IconTriggerContainer>
      <Flex $direction="row" $gap={SPACING_SM}>
        <Link onClick={onAvatarClick} to="/settings/account">
          <Avatar name={user.fullName} size="sm" />
        </Link>
        {extended && (
          <Flex $justify="center">
            <Text kind="paragraph"> {user.fullName} </Text>
            <Text colorName="grey-80" kind="footnote">
              {user.email}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
