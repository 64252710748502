import { Icon } from 'icons'
import { useCallback } from 'react'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useIsNativeAppAndroid } from '~/domains/appNative/hooks/useIsNativeAppAndroid'
import { useIsNativeAppIOS } from '~/domains/appNative/hooks/useIsNativeAppIOS'
import { MenuAction } from '~/domains/navigation/TabNavigation/components/MenuAction'
import { getEnv } from '~/utils/getEnv'

export const MenuItemReview = () => {
  const isNativeAppAndroid = useIsNativeAppAndroid()
  const isNativeAppIOS = useIsNativeAppIOS()

  const reviewURL = isNativeAppAndroid
    ? `${getEnv('SERENIS_PLAY_STORE')}&showAllReviews=true`
    : isNativeAppIOS
      ? `${getEnv('SERENIS_APP_STORE')}?action=write-review`
      : getEnv('SERENIS_TRUST_PILOT')

  const onClick = useCallback(() => {
    window.open(reviewURL, '_blank')
  }, [reviewURL])

  return (
    <MenuAction onClick={onClick}>
      <Flex pr={12}>
        <Icon colorName="grey-60" fillColorName="white" name="star" size={24} />
      </Flex>

      <Flex align="flex-start" grow={1} shrink={1}>
        <Text fontWeight="400" kind="paragraph">
          <Translation id="menu.review" />
        </Text>
      </Flex>
    </MenuAction>
  )
}

MenuItemReview.displayName = 'MenuItemReview'
