import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useFormFlowResponse } from '~/domains/formFlow/hooks/useFormFlowResponse'
import { Intent } from '../types/intent'

const isIntent = (value: string): value is Intent =>
  [
    'call',
    'coaching',
    'dca',
    'journaling',
    'nutritionist',
    'psychiatry',
    'sexology',
    'therapyCouple',
    'therapyMyself',
  ].includes(value)

export const signupIntentShouldRedirectToHome = (
  value: Intent,
): value is Extract<Intent, 'call' | 'dca' | 'journaling' | 'nutritionist'> =>
  ['call', 'dca', 'journaling', 'nutritionist'].includes(value)

export const signupIntentHasFirstTherapySessionFree = (
  value: Intent,
): value is Extract<Intent, 'call' | 'therapyMyself' | 'therapyCouple' | 'coaching' | 'journaling' | 'sexology'> =>
  ['call', 'therapyMyself', 'therapyCouple', 'coaching', 'journaling', 'sexology'].includes(value)

export const getSignupIntent = (value: Intent): Intent => value

export const useSignupIntent = (): Intent => {
  const { search } = useLocation()

  const { intent } = queryString.parse(search)

  const response = useFormFlowResponse()

  if (!intent || Array.isArray(intent) || !isIntent(intent)) {
    const forWhom = response.formFlowResponse?.answers
      ?.find(({ step: { name } }) => name === 'FOR_WHOM')
      ?.valueChoice?.at(0)?.name

    return forWhom === 'GENERIC_FOR_WHOM_COUPLES' ? 'therapyCouple' : 'therapyMyself'
  }

  return intent
}
