import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

const LazyTherapySessionEdit = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const TherapySessionEditPage: Page = {
  exact: true,
  id: 'therapy-sessions.edit',
  paths: ['/therapy-session/:therapySessionId/edit'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: false,
    seoKey: 'therapy-session.edit',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient>{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyTherapySessionEdit />,
}
