import styled from 'styled-components'
import { Flex } from 'ui-deprecated'

type Props = {
  opacity: number
}

export const Opacity = styled(Flex)<Props>`
  opacity: ${({ opacity }) => opacity};
`
