import { useCallback, useMemo } from 'react'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { useDeleteRecurrency } from '~/components/TherapySessionCardTherapist/hooks/useDeleteRecurrency'
import { Translation } from '~/components/Translation'
import { PatientAgendaTherapySession } from '~/domains/agenda/types'
import { useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { ReactHookFormTextArea } from '~/domains/react-hook-form/components/ReactHookFormTextArea'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { useRootHistory } from '~/hooks/useRootHistory'
import { useToasts } from '~/hooks/useToasts'
import { getRoute } from '~/utils/getRoute'
import { useDeleteTherapySessionOnSubmit } from '../hooks/useDeleteTherapySessionOnSubmit'
import { FormValues } from '../types/formValues'

const max = 600

type Props = Pick<PatientAgendaTherapySession, 'id' | 'therapistId'>

export const StepMessage = ({ id, therapistId }: Props) => {
  const { loading: deleteTherapySessionLoading, onSubmit: deleteTherapySessionOnSubmit } =
    useDeleteTherapySessionOnSubmit(therapistId)
  const [deleteRecurrency, { loading: deleteRecurrencyLoading }] = useDeleteRecurrency()
  const form = useReactHookFormContext<FormValues>()
  const { addToast } = useToasts()
  const { close } = useModals()
  const { refetch: refetchPatientAgenda } = usePatientAgenda()
  const history = useRootHistory()

  const { therapies } = usePatientTherapies()

  const therapy = useMemo(
    () => therapies.find((therapy) => therapy.therapist?.id === therapistId),
    [therapies, therapistId],
  )

  const modalId = `deleteTherapySession:${id}` as const

  const { watch } = useReactHookFormContext<FormValues>()

  const deletedType = watch('deletedType')

  const {
    errors: { required },
    placeholders: { deleteTherapySession },
  } = useFormTranslations()

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (deletedType === 'DELETE_SINGLE_THERAPY_SESSION') {
        await deleteTherapySessionOnSubmit(id, values)

        return
      }

      try {
        await deleteRecurrency({
          variables: {
            input: {
              id,
              message: values.message,
            },
          },
        })

        close(modalId)

        addToast({ translationId: 'therapySession.deleteRecurrency.success', type: 'success' })

        refetchPatientAgenda()

        if (therapistId) {
          history.push(getRoute(`/chat/${therapistId}`))

          return
        }

        history.push(getRoute('/chat'))
      } catch (error) {
        addToast({ translationId: 'therapySession.deleteRecurrency.error', type: 'alert' })
      }
    },
    [
      addToast,
      close,
      deletedType,
      deleteRecurrency,
      deleteTherapySessionOnSubmit,
      history,
      id,
      modalId,
      refetchPatientAgenda,
      therapistId,
    ],
  )

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <ModalCloseButton id={modalId} />

      <OverflowAuto>
        <Text fontWeight="600" kind="h2">
          <Translation
            id="therapySession.delete.message.title"
            values={{ therapistFullName: String(therapy?.therapist?.fullName) }}
          />
        </Text>

        <Flex pt={16}>
          <Text fontWeight="400" kind="paragraph">
            <Translation
              id={
                deletedType === 'DELETE_SINGLE_THERAPY_SESSION'
                  ? 'therapySession.delete.therapySession.message.subtitle'
                  : 'therapySession.delete.therapySessions.message.subtitle'
              }
            />
          </Text>
        </Flex>

        <Flex pt={32}>
          <ReactHookFormTextArea
            max={max}
            minHeight="200px"
            name="message"
            placeholder={deleteTherapySession}
            rules={{ max, required }}
          />
        </Flex>
      </OverflowAuto>

      <Button
        disabled={!form.formState.isValid}
        kind="danger"
        loading={
          deletedType === 'DELETE_SINGLE_THERAPY_SESSION' ? deleteTherapySessionLoading : deleteRecurrencyLoading
        }
        type="submit"
      >
        <Translation
          id={
            deletedType === 'DELETE_SINGLE_THERAPY_SESSION'
              ? 'therapySession.delete.therapySession'
              : 'therapySession.delete.therapySessions'
          }
        />
      </Button>
    </Form>
  )
}
