import { Flex, Pressable } from 'ui-deprecated'
import { useTabNavigationMenu } from '~/domains/navigation/TabNavigation/hooks/useTabNavigationMenu'

export const MenuVerticalWrapperOverlay = () => {
  const { closeMenu } = useTabNavigationMenu()

  return (
    <Flex background="overlay" grow={1} shrink={1}>
      <Pressable grow={1} onClick={closeMenu} shrink={1} />
    </Flex>
  )
}
