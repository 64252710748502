import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'
import { JournalingProvider } from './hooks/useJournalingContext'

const LazyJournalingPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.JournalingPageView,
})

export const JournalingPage: Page = {
  exact: true,
  id: 'journaling',
  paths: ['/journaling'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'journaling',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: JournalingProvider,
  PageView: () => <LazyJournalingPageView />,
}
