import { getTime } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'

export const upsertSentencesTutorialViewedAt = () => {
  const timestamp = getLocalStorage('sentences-tutorial-viewed-at')

  if (timestamp) {
    return timestamp
  }

  const sentencesTutorialViewedAt = pipe(nowInMilliseconds(), getTime, String)

  setLocalStorage('sentences-tutorial-viewed-at', sentencesTutorialViewedAt)

  return sentencesTutorialViewedAt
}
