import { Flex, MountOn, Space } from 'cdk'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Button, Link, Text } from 'ui'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { Translation } from '~/components/Translation'
import { useLoadingState } from '~/hooks/useLoadingState'
import { useFormContext } from 'react-hook-form'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'

export const SignupFormCTAs = () => {
  const { isVariant1, isVariant2 } = useFeatureFlagsByFingerprint()
  const isSignupMultivariantVariant1Or2 = isVariant1('ff_signup_multivariant') || isVariant2('ff_signup_multivariant')

  const { formState } = useFormContext()
  const { loadingKey } = useLoadingState()
  const localLoading = formState.isSubmitting || loadingKey === 'local'

  return (
    <>
      <Button disabled={localLoading} id="signup-button" kind="primary" size="lg" type="submit">
        <Translation id="actions.createAccount" />
      </Button>

      {isSignupMultivariantVariant1Or2 && (
        <MountOn mediaQuery="lt-lg">
          <Flex $align="center" $pt={16}>
            <TranslationMarkdown colorName="darker" id="generic.signup.notASubscription" kind="paragraph" />
          </Flex>
        </MountOn>
      )}

      <MountOn mediaQuery="gt-md">
        <Flex $align="center" $pt={16}>
          <Text colorName="black" kind="caption">
            <Translation id="generic.doYouAnAccountAlready" />
            <Space />
            <Link as={ReactRouterLink} to="/login">
              <Text colorName="primary" fontWeight="600" kind="paragraph" textDecoration="underline">
                <Translation id="actions.signin" />
              </Text>
            </Link>
          </Text>
        </Flex>
      </MountOn>
    </>
  )
}
