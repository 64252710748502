import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertTherapist } from '~/domains/auth/components/AssertTherapist'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

const LazyProfileActivitiesPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.ProfileActivitiesPageView,
})

export const ProfileActivitiesPage: Page = {
  exact: true,
  id: 'settings.activities',
  paths: ['/profile/activities'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: true,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'settings.activities',
    title: 'settings.activities.title',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertTherapist target="/">{children}</AssertTherapist>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyProfileActivitiesPageView />,
}
