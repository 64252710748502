import { useCallback } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useTherapyIdLocationParam } from '~/domains/changeTherapist/hooks/useTherapyIdLocationParam'
import { useCreateChildFormFlow } from '~/domains/formFlow/hooks/useCreateChildFormFlow'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { useRootHistory, useRootLocation } from '~/hooks/useRootHistory'
import { useToasts } from '~/hooks/useToasts'
import { getRoute } from '~/utils/getRoute'
import { useReservationFlow } from '../hooks/useReservationFlow'
import { getReservationRoutes } from '../utils/getReservationRoutes'

export const NoMoreTherapistsModal = () => {
  const rootHistory = useRootHistory()

  const history = useHistory()

  const [createChildFormFlow] = useCreateChildFormFlow()

  const { addToast } = useToasts()

  const { dismiss } = useModals()

  const { suggestedTherapistsProfiles } = useReservationFlow()

  const suggestedTherapistsProfilesLength = suggestedTherapistsProfiles?.length ?? 0

  const { pathname } = useRootLocation()

  const therapyId = useTherapyIdLocationParam()

  const isChangingTherapist = !!matchPath(pathname, { path: getRoute('/change-therapist/:therapyId/booking') })

  const onRepeatFormFlow = useCallback(async () => {
    try {
      const result = await createChildFormFlow()

      if (!result.data?.createChildFormFlowResponse.ok) {
        throw new Error('createChildFormFlow')
      }

      dismiss('noMoreTherapists')

      rootHistory.push(getRoute(isChangingTherapist ? `/change-therapist/${therapyId}` : '/start'))
    } catch (error) {
      addToast({
        translationId: 'generic.errorOccurred.title',
        type: 'alert',
      })
    }
  }, [addToast, createChildFormFlow, dismiss, isChangingTherapist, rootHistory, therapyId])

  const onBackClicked = useCallback(() => {
    dismiss('noMoreTherapists')
  }, [dismiss])

  const onContinueWithoutReservation = useCallback(() => {
    dismiss('noMoreTherapists')

    history.push(getReservationRoutes('/skip-booking'))
  }, [dismiss, history])

  return (
    <Modal id="noMoreTherapists">
      <ModalCloseButton id="noMoreTherapists" />
      <OverflowAuto>
        <Text colorName="black" fontWeight="600" kind="h2">
          <Translation
            id={
              suggestedTherapistsProfilesLength >= 3
                ? 'reservation.noMoreTherapistsModal.title.1'
                : 'reservation.noMoreTherapistsModal.title.2'
            }
          />
        </Text>
        <TranslationMarkdown
          id={
            suggestedTherapistsProfilesLength >= 3
              ? 'reservation.noMoreTherapistsModal.body.1'
              : 'reservation.noMoreTherapistsModal.body.2'
          }
          kind="paragraph"
          pt={8}
        />
      </OverflowAuto>
      <Flex>
        <Flex pt={32}>
          <Button kind="secondary" onClick={onRepeatFormFlow}>
            <Translation id="reservation.noMoreTherapistsModal.repeatFormFlow" />
          </Button>
        </Flex>
        <Flex pt={16}>
          <Button kind="primary" onClick={onBackClicked}>
            <Translation id="reservation.noMoreTherapistsModal.back" />
          </Button>
        </Flex>
        <Flex align="center" pt={32}>
          <Text colorName="black" kind="paragraph">
            <Translation id="reservation.noMoreTherapistsModal.noReservation" />
          </Text>
        </Flex>
        <Flex align="center" pt={8}>
          <Pressable data-test-id="proceed-without-booking" onClick={onContinueWithoutReservation}>
            <Text colorName="primary" fontWeight="600" kind="paragraph" textDecoration="underline">
              <Translation id="reservation.noMoreTherapistsModal.noReservation.continue" />
            </Text>
          </Pressable>
        </Flex>
      </Flex>
    </Modal>
  )
}
