import { Flex } from 'cdk'
import { SerenisLogoHorizontal, SerenisMySecretCaseLogoHorizontal } from 'illustrations'
import { usePartnership } from '~/hooks/usePartnership'

export const AppBarLogo = () => {
  const partnership = usePartnership()

  return (
    <Flex>
      {partnership === 'MY_SECRET_CASE' ? (
        <SerenisMySecretCaseLogoHorizontal height={40} />
      ) : (
        <SerenisLogoHorizontal height={28} primaryColorName="primary" secondaryColorName="darker" />
      )}
    </Flex>
  )
}
