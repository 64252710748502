import { useContext } from 'react'
import { FeatureFlagsByUserIdContext } from '~/domains/featureFlags/components/FeatureFlagsByUserId'
import { useFeatureFlagsClient } from '~/domains/featureFlags/hooks/useFeatureFlagsClient'

export const useFeatureFlagsByUserId = () => {
  const context = useContext(FeatureFlagsByUserIdContext)

  const client = useFeatureFlagsClient(context)

  return client
}
