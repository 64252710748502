import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { parseQueryParams } from 'url-parser'
import { getSessionStorageQueryParams } from '~/utils/getSessionStorageQueryParams'

export const useSessionStorageParams = () => {
  const { asPath } = useRouter()

  return useMemo(
    () => (getSessionStorageQueryParams() || parseQueryParams(asPath) || {}) as Record<string, unknown>,
    [asPath],
  )
}
