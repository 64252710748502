import { Flex } from 'cdk'
import { SPACING_SM, SPACING_XS } from 'design-tokens'
import { SerenisLogoIcon, SerenisLogoText } from 'illustrations'
import { Link } from 'react-router-dom'
import { getRoute } from '~/utils/getRoute'
import { FlexWithTransition } from './common/FlexWithTransition'

export type SideBarHeaderProps = {
  extended: boolean
}

export const SideBarHeader = ({ extended }: SideBarHeaderProps) => (
  <Flex $align="flex-start" $direction="row" $gap={SPACING_XS} $justify="space-between" $pl={4}>
    <FlexWithTransition $align="flex-start">
      <Link aria-label="Serenis Health" to={getRoute('/')}>
        <Flex $direction="row" $gap={SPACING_SM} $justify="center">
          <SerenisLogoIcon />
          <FlexWithTransition $opacity={extended ? 1 : 0}>
            <SerenisLogoText />
          </FlexWithTransition>
        </Flex>
      </Link>
    </FlexWithTransition>
  </Flex>
)
