import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Link, Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { ReactHookFormProvider, useReactHookForm } from '~/domains/react-hook-form'
import { FieldEmailWithConfirmation, FormValues } from '~/domains/react-hook-form/fields/FieldEmailWithConfirmation'
import { useEmailVerification } from '../hooks/useEmailVerification'
import { getEmailVerificationRoute } from '../utils/getEmailVerificationRoute'

export const EmailVerificationModalEditRoute = () => {
  const { requestVerification, loading } = useEmailVerification()
  const form = useReactHookForm<FormValues>()
  const history = useHistory()

  const onBack = useCallback(async () => {
    history.push(getEmailVerificationRoute('/'))
  }, [history])

  const onSubmit = useCallback(
    async ({ email }: FormValues) => {
      const result = await requestVerification(email)
      if (!result) {
        return
      }

      history.push(getEmailVerificationRoute('/'))
    },
    [requestVerification, history],
  )

  return (
    <ReactHookFormProvider {...form}>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <OverflowAuto>
          <Text fontWeight="600" kind="h2">
            <Translation id="emailVerification.edit.title" />
          </Text>

          <Flex pt={16}>
            <Text fontWeight="400" kind="paragraph">
              <Translation id="emailVerification.edit.subtitle" />
            </Text>
          </Flex>

          <Flex pt={32}>
            <FieldEmailWithConfirmation />
          </Flex>
        </OverflowAuto>

        <Flex pt={32}>
          <Button
            disabled={!form.formState.isValid}
            kind="primary"
            loading={form.formState.isSubmitting || loading}
            type="submit"
          >
            <Translation id="emailVerification.edit.confirm" />
          </Button>
        </Flex>

        <Flex align="center" pt={16}>
          <Link onClick={onBack}>
            <Text kind="paragraph" textDecoration="underline">
              <Translation id="emailVerification.edit.back" />
            </Text>
          </Link>
        </Flex>
      </Form>
    </ReactHookFormProvider>
  )
}
