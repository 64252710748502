import { Text } from 'ui'
import { Emoji, Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'

type Props = {
  firstName: string
}

export const WelcomeHeader = ({ firstName }: Props) => (
  <Flex align="center" direction="row">
    <Flex align="center" data-test-id="welcome-message" direction="row" wrap="wrap">
      <Text fontWeight="600" kind="h1">
        <Translation id="agenda.welcomeBack" values={{ firstName }} />
      </Text>

      <Emoji style={{ fontSize: 32, paddingLeft: 8 }}>🙂</Emoji>
    </Flex>
  </Flex>
)
