import { Flex, media, MountOn } from 'cdk'
import { useSignupIntent } from '../hooks/useSignupIntent'
import { Translation } from '~/components/Translation'
import { Text } from 'ui'
import styled from 'styled-components'
import { TrustpilotWidget } from './TrustpilotWidget'
import { Icon } from 'icons'
import { TranslationId } from '~/i18n/types'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { Intent } from '../types/intent'

type Variant = 'variant1_2' | 'variant3_4'

const titleByIntentVariant: {
  [key in Intent]: { [key in Variant]: TranslationId } | null
} = {
  call: null,
  dca: null,
  journaling: null,
  nutritionist: null,
  coaching: {
    variant1_2: 'signup.variant1_2.title',
    variant3_4: 'signup.variant3_4.title',
  },
  psychiatry: {
    variant1_2: 'signup.variant.title.psychiatry',
    variant3_4: 'signup.variant.title.psychiatry',
  },
  sexology: {
    variant1_2: 'signup.variant1_2.title',
    variant3_4: 'signup.variant3_4.title',
  },
  therapyMyself: {
    variant1_2: 'signup.variant1_2.title',
    variant3_4: 'signup.variant3_4.title',
  },
  therapyCouple: {
    variant1_2: 'signup.variant1_2.title',
    variant3_4: 'signup.variant3_4.title',
  },
}

const descriptionByIntentVariant: {
  [key in Intent]: { [key in Variant]: TranslationId } | null
} = {
  call: null,
  dca: null,
  journaling: null,
  nutritionist: null,
  coaching: {
    variant1_2: 'generic.signup.notASubscription',
    variant3_4: 'signup.variant3_4.description.coaching',
  },
  psychiatry: {
    variant1_2: 'generic.signup.notASubscription',
    variant3_4: 'generic.signup.notASubscription',
  },
  sexology: {
    variant1_2: 'generic.signup.notASubscription',
    variant3_4: 'signup.variant3_4.description',
  },
  therapyMyself: {
    variant1_2: 'generic.signup.notASubscription',
    variant3_4: 'signup.variant3_4.description',
  },
  therapyCouple: {
    variant1_2: 'generic.signup.notASubscription',
    variant3_4: 'signup.variant3_4.description.therapyCouple',
  },
}

const itemsByIntentVariation: {
  [key in Intent]: { [key in Variant]: TranslationId[] } | null
} = {
  call: null,
  dca: null,
  journaling: null,
  nutritionist: null,
  coaching: {
    variant1_2: [
      'signup.hero.variant1_2.item1',
      'signup.hero.variant1_2.item2',
      'signup.hero.variant1_2.item3.coaching',
    ],
    variant3_4: ['signup.hero.variant3_4.item1', 'signup.hero.variant3_4.item2'],
  },
  psychiatry: {
    variant1_2: [
      'signup.hero.variant.item1.psychiatry',
      'signup.hero.variant.item2.psychiatry',
      'signup.hero.variant.item3.psychiatry',
    ],
    variant3_4: [
      'signup.hero.variant.item1.psychiatry',
      'signup.hero.variant.item2.psychiatry',
      'signup.hero.variant.item3.psychiatry',
    ],
  },
  sexology: {
    variant1_2: [
      'signup.hero.variant1_2.item1',
      'signup.hero.variant1_2.item2',
      'signup.hero.variant1_2.item3.sexology',
    ],
    variant3_4: ['signup.hero.variant3_4.item1', 'signup.hero.variant3_4.item2'],
  },
  therapyMyself: {
    variant1_2: ['signup.hero.variant1_2.item1', 'signup.hero.variant1_2.item2', 'signup.hero.variant1_2.item3'],
    variant3_4: ['signup.hero.variant3_4.item1', 'signup.hero.variant3_4.item2'],
  },
  therapyCouple: {
    variant1_2: [
      'signup.hero.variant1_2.item1',
      'signup.hero.variant1_2.item2.therapyCouple',
      'signup.hero.variant1_2.item3',
    ],
    variant3_4: ['signup.hero.variant3_4.item1', 'signup.hero.variant3_4.item2'],
  },
}

const FlexWithoutMargin = styled(Flex)`
  margin: 0;
`

const PriceFirstSession = styled(Text)`
  font-size: 72px;
  line-height: 0.75;

  ${media.gtMd`
    font-size: 160px;
  `}
`

const PriceFirstSessionSymbol = styled(Text)`
  font-size: 24px;
  line-height: 1;

  ${media.gtMd`
  font-size: 48px;
  `}
`

const ListItem = ({ translationId }: { translationId: TranslationId }) => (
  <Flex $align="center" $direction="row" $gap={8} $grow={1} $shrink={0}>
    <Flex $shrink={0}>
      <Icon colorName="white" name="check" size={20} />
    </Flex>

    <Flex $grow={1} $mdShrink={0} $shrink={1}>
      <TranslationMarkdown colorName="lighter" id={translationId} kind="public-h5" />
    </Flex>
  </Flex>
)

export const SignupHeroContentVariant = () => {
  const intent = useSignupIntent()
  const { isVariant1, isVariant2, isVariant3, isVariant4 } = useFeatureFlagsByFingerprint()

  const isVariant1Or2 = isVariant1('ff_signup_multivariant') || isVariant2('ff_signup_multivariant')
  const isVariant3Or4 = isVariant3('ff_signup_multivariant') || isVariant4('ff_signup_multivariant')

  const variant = isVariant1Or2 ? 'variant1_2' : 'variant3_4'

  const titleTranslationId = (titleByIntentVariant[intent] ?? titleByIntentVariant.therapyMyself!)[variant]
  const descriptionTranslationId = (descriptionByIntentVariant[intent] ?? descriptionByIntentVariant.therapyMyself!)[
    variant
  ]
  const items = (itemsByIntentVariation[intent] ?? itemsByIntentVariation.therapyMyself!)[variant]

  return (
    <Flex $lgBasis="50%">
      <Flex $gap={16} $grow={1} $lgJustify="space-between" $lgPx={64} $lgPy={112} $px={16} $py={24} $shrink={1}>
        <Flex $gap={16} $lgGap={isVariant1Or2 ? 32 : 24}>
          <FlexWithoutMargin $justify="flex-start">
            <Text colorName="lighter" fontWeight="600" kind="public-h3">
              <Translation id={titleTranslationId} />
            </Text>
          </FlexWithoutMargin>

          <Flex
            $align="flex-start"
            $direction="row"
            $gap={16}
            $grow={1}
            $lgAlign="center"
            $lgGap={24}
            $lgPb={0}
            $pb={isVariant1Or2 || intent === 'psychiatry' ? 16 : 0}
            $shrink={1}
          >
            {isVariant3Or4 && intent !== 'psychiatry' && (
              <Flex $align="baseline" $direction="row">
                <PriceFirstSession colorName="lighter" fontWeight="600">
                  0
                </PriceFirstSession>
                <PriceFirstSessionSymbol colorName="lighter" fontWeight="600">
                  €
                </PriceFirstSessionSymbol>
              </Flex>
            )}
            <Flex $align="flex-start" $gap={4} $grow={1} $lgGap={16} $shrink={1}>
              {items.map((translationId) => (
                <ListItem key={translationId} translationId={translationId} />
              ))}
            </Flex>
          </Flex>

          {isVariant3Or4 && intent !== 'psychiatry' && (
            <Flex>
              <TranslationMarkdown colorName="lighter" id={descriptionTranslationId} kind="public-h5" />
            </Flex>
          )}

          {isVariant1Or2 && (
            <MountOn mediaQuery="gt-md">
              <Flex>
                <TranslationMarkdown colorName="lighter" id={descriptionTranslationId} kind="public-h5" />
              </Flex>
            </MountOn>
          )}
        </Flex>

        <MountOn mediaQuery="gt-md">
          <Flex $pt={24}>
            <TrustpilotWidget />
          </Flex>
        </MountOn>
      </Flex>
    </Flex>
  )
}
