import { useBreakpoints } from '~/hooks/useBreakpoints'
import { TabNavigationMenuProvider } from '../TabNavigation/hooks/useTabNavigationMenu'
import { NavigationProps } from '../TabNavigation/types'
import { BottomBar } from './components/BottomBar'
import { SideBar } from './components/SideBar'

export const NewNavigation = ({ direction }: NavigationProps) => {
  const { isMedium, isSmallOnly } = useBreakpoints()
  const horizontal = isSmallOnly && direction === 'horizontal'
  const vertical = isMedium && direction === 'vertical'

  return (
    <TabNavigationMenuProvider>
      {horizontal && <BottomBar />}
      {vertical && <SideBar />}
    </TabNavigationMenuProvider>
  )
}
