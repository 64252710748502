import { noop } from 'functions'
import { useCallback, useMemo } from 'react'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { useTherapySessionFindManyFutureForTherapistByPatientId } from '~/domains/patientsManagement/hooks/useTherapySessionFindManyFutureForTherapistByPatientId'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { ReactHookFormTextArea } from '~/domains/react-hook-form/components/ReactHookFormTextArea'
import { useTherapySessionsTherapistAgenda } from '~/domains/therapy-session/hooks/useTherapySessionsTherapistAgenda'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useTherapistAgendaByPatientId } from '~/hooks/useTherapistAgendaByPatientId'
import { useTherapistTherapiesFetch } from '~/hooks/useTherapistTherapiesFetch'
import { TranslationId } from '~/i18n/types'
import { useDeleteForTherapistEmergencyOnSubmit } from '../hooks/useDeleteForTherapistEmergencyOnSubmit'
import { FormValues } from '../types/formValues'

const max = 600

type Props = Pick<TherapistAgendaTherapySession, 'patient'> & {
  id: string
}

export const StepMessage = ({ id, patient }: Props) => {
  const { loading, onSubmit: deleteForTherapistEmergencyOnSubmit } = useDeleteForTherapistEmergencyOnSubmit()
  const { refetch: refetchUserTherapySessionsTherapistAgenda } = useTherapySessionsTherapistAgenda('cache-only')
  const { refetch: refetchTherapistAgendaByPatientId } = useTherapistAgendaByPatientId(patient.id, 'cache-only')
  const { refetch: refetchTherapySessionFindManyFutureForTherapistByPatientId } =
    useTherapySessionFindManyFutureForTherapistByPatientId(patient.id)
  const { refetch: refetchTherapistTherapies } = useTherapistTherapiesFetch()
  const form = useReactHookFormContext<FormValues>()

  const therapistEmergencyReason = form.watch('therapistEmergencyReason')

  const {
    errors: { required },
    placeholders: { deleteForTherapistEmergencyMessage },
  } = useFormTranslations()

  const onSubmit = useCallback(
    async (values: FormValues) => {
      await deleteForTherapistEmergencyOnSubmit(id, values)

      refetchUserTherapySessionsTherapistAgenda().catch(noop)

      refetchTherapistAgendaByPatientId().catch(noop)

      await refetchTherapySessionFindManyFutureForTherapistByPatientId().catch(noop)

      await refetchTherapistTherapies().catch(noop)
    },
    [
      deleteForTherapistEmergencyOnSubmit,
      id,
      refetchTherapistAgendaByPatientId,
      refetchTherapistTherapies,
      refetchTherapySessionFindManyFutureForTherapistByPatientId,
      refetchUserTherapySessionsTherapistAgenda,
    ],
  )

  const paragraphTranslationId = useMemo((): TranslationId => {
    if (therapistEmergencyReason === 'FAMILY_ISSUE' || therapistEmergencyReason === 'HEALTH_ISSUE') {
      return 'therapySession.deleteForTherapistEmergency.step.message.therapistEmergency.paragraph'
    }

    if (therapistEmergencyReason === 'PATIENT_REQUEST') {
      return 'therapySession.deleteForTherapistEmergency.step.message.patientRequest.paragraph'
    }

    return 'therapySession.deleteForTherapistEmergency.step.message.body'
  }, [therapistEmergencyReason])

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <OverflowAuto>
        <Text fontWeight="600" kind="h2">
          <Translation
            id="therapySession.deleteForTherapistEmergency.step.message.title"
            values={{ patientFirstName: patient.firstName }}
          />
        </Text>

        <Flex pt={16}>
          <Text fontWeight="400" kind="paragraph">
            <Translation id={paragraphTranslationId} />
          </Text>
        </Flex>

        <Flex pt={32}>
          <ReactHookFormTextArea
            max={max}
            minHeight="200px"
            name="message"
            placeholder={deleteForTherapistEmergencyMessage}
            rules={{ max, required }}
          />
        </Flex>
      </OverflowAuto>

      <Button disabled={!form.formState.isValid} kind="danger" loading={loading} type="submit">
        <Translation id="therapySession.deleteForTherapistEmergency.step.message.submit" />
      </Button>
    </Form>
  )
}
