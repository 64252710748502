import { useCurrentUser } from '~/hooks/useCurrentUser'
import { usePatientAgenda } from '~/hooks/usePatientAgenda'
import { useUserCustomer } from '~/hooks/useUserCustomer'
import { ServiceInfoActiveConventionCard } from './ServiceInfoActiveConventionCard'
import { ServiceInfoBillingCard } from './ServiceInfoBillingCard'
import { ServiceInfoCompanyReferral } from './ServiceInfoCompanyReferral'
import { ServiceInfoSkeleton } from './components/ServiceInfoSkeleton'
import { ServiceInfoTitle } from './components/ServiceInfoTitle'

export const ServiceInfo = () => {
  const { activeReferral, company, loading: currentUserLoading, referralCode } = useCurrentUser()
  const { agenda, loading: patientAgendaLoading } = usePatientAgenda()
  const { customer, loading: userCustomerLoading } = useUserCustomer()

  const loading = currentUserLoading || userCustomerLoading || patientAgendaLoading

  if (loading) {
    return <ServiceInfoSkeleton />
  }

  const b2b = !!company?.id

  const shouldShowBillingInfo = agenda?.nextTherapySessions?.some(({ free }) => !free) && !customer

  if (activeReferral || shouldShowBillingInfo || (!!referralCode && !b2b)) {
    return (
      <>
        <ServiceInfoTitle />
        {!!referralCode && !b2b && <ServiceInfoCompanyReferral />}
        {activeReferral && <ServiceInfoActiveConventionCard />}
        {shouldShowBillingInfo && <ServiceInfoBillingCard />}
      </>
    )
  }

  return null
}
