import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { Modal, useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { useRootHistory } from '~/hooks/useRootHistory'
import { getRoute } from '~/utils/getRoute'

export const JournalingLogShareModalNoTherapist = () => {
  const { close } = useModals()

  const history = useRootHistory()

  const onFindTherapist = useCallback(() => {
    history.push(getRoute('/booking'))
  }, [history])

  const onCloseClick = useCallback(() => {
    close('journalingLogShareNoTherapist')
  }, [close])

  return (
    <Modal id="journalingLogShareNoTherapist">
      <ModalCloseButton id="journalingLogShareNoTherapist" />

      <OverflowAuto>
        <Text fontWeight="600" kind="h2">
          <Translation id="journaling.journalingLogShare.noTherapist.title" />
        </Text>

        <TranslationMarkdown
          colorName="black"
          id="journaling.journalingLogShare.noTherapist.text"
          kind="paragraph"
          pt={16}
        />
      </OverflowAuto>

      <Flex pt={16}>
        <Button kind="primary" onClick={onFindTherapist}>
          <Translation id="journaling.journalingLogShare.noTherapist.cta.find" />
        </Button>
        <Flex pt={8}>
          <Button kind="secondary" onClick={onCloseClick}>
            <Translation id="journaling.journalingLogShare.noTherapist.cta.goBack" />
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}
