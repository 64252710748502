import {
  registerDecorator,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator'

export const isAccepted = (value: unknown) => {
  if (typeof value === 'string') {
    return value === 'true'
  }

  if (['function', 'object'].includes(typeof value)) {
    return false
  }

  return Boolean(value)
}

@ValidatorConstraint({ name: 'accepted', async: false })
export class IsAcceptedConstraint implements ValidatorConstraintInterface {
  validate = isAccepted

  defaultMessage = () => 'Input must be accepted'
}

export function IsAccepted<T extends object>(validationOptions?: ValidationOptions) {
  return function (object: T, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsAcceptedConstraint,
    })
  }
}
