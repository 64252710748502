import { gql, useLazyQuery } from '@apollo/client'
import { GetChatTokenByUserIdQuery, GetChatTokenByUserIdQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query getChatTokenByUserId {
    getChatTokenByUserId {
      token
    }
  }
`

export const useChatToken = () => {
  const [getToken, { data, loading }] = useLazyQuery<GetChatTokenByUserIdQuery, GetChatTokenByUserIdQueryVariables>(
    QUERY,
  )

  return {
    getToken,
    token: data?.getChatTokenByUserId?.token,
    loading,
  }
}
