import { ReactNode } from 'react'
import { Redirect } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { getRoute, Route } from '~/utils/getRoute'

type Props = {
  children: ReactNode
  target?: Route
}

export const AssertUserTherapy = ({ children, target = '/booking' }: Props) => {
  const { loading, therapies } = usePatientTherapies()

  if (loading) {
    return <CenteredLoader />
  }

  if (!therapies.length) {
    return <Redirect to={getRoute(target)} />
  }

  return <>{children}</>
}
