import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

const LazyBundlePurchaseFlow = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const BundlePurchaseFlowPage: Page = {
  exact: false,
  id: 'bundles',
  paths: ['/bundles'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'bundle',
    title: 'seo.bundle.title',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient>{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PatientTherapiesProvider,
  PageView: () => <LazyBundlePurchaseFlow />,
}
