import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useUserRedirectEffect } from '~/hooks/useUserRedirectEffect'
import { Route } from '~/utils/getRoute'

type Props = {
  children: ReactNode
  target?: Route
}

type LocationState = {
  referrer?: Route
}

export const AssertAnonymous = ({ children, target }: Props) => {
  const { isAuthenticated } = useAuthState()
  const { state } = useLocation<LocationState>()

  useUserRedirectEffect(isAuthenticated, state?.referrer ?? target)

  if (isAuthenticated) {
    return null
  }

  return <>{children}</>
}
