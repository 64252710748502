import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PatientTherapiesProvider } from '~/domains/patient/hooks/usePatientTherapies'
import { StripeElementsProvider } from '~/domains/stripe'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

const SettingsPaymentsAdd = loadable(() => import('./View'), {
  resolveComponent: (components) => components.View,
})

export const SettingsPaymentsAddPage: Page = {
  exact: true,
  id: 'settings.payments.add',
  paths: ['/settings/payments/add'],
  options: {
    isVisibleAppBar: false,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: false,
    seoKey: 'settings.payments.add',
    title: 'settings.payments.addPaymentMethod',
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => (
    <PatientTherapiesProvider>
      <StripeElementsProvider>{children}</StripeElementsProvider>
    </PatientTherapiesProvider>
  ),
  PageView: () => <SettingsPaymentsAdd />,
}
