import { Redirect } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { getRoute } from '~/utils/getRoute'

export const OnboardingPageView = () => {
  const { loading, therapies } = usePatientTherapies()

  if (loading) {
    return <CenteredLoader />
  }

  const isUnderagePatient = therapies.some(
    ({ ongoing, therapyPath }) => ongoing && therapyPath?.type === 'UNDERAGE_PSYCHOTHERAPY',
  )

  if (isUnderagePatient) {
    return <Redirect to={getRoute('/onboarding/guardian')} />
  }

  const hasCoupleTherapy = therapies.some(
    ({ ongoing, therapyPath }) => ongoing && therapyPath?.type === 'COUPLES_PSYCHOTHERAPY',
  )

  if (hasCoupleTherapy) {
    return <Redirect to={getRoute('/onboarding/couples')} />
  }

  return <Redirect to={getRoute('/')} />
}
