import { Flex, MaxWidth1260px, MountOn } from 'cdk'
import { Icon } from 'icons'
import { GestoLogoIcon } from 'illustrations'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Button, Text } from 'ui'
import { useAppBarContent } from '~/components/AppBar/hooks/useAppBarContent'
import { TertiaryButtonIconLeft } from '~/components/TertiaryButton/TertiaryButtonIconLeft'
import { TertiaryButtonIconOnly } from '~/components/TertiaryButton/TertiaryButtonIconOnly'
import { Translation } from '~/components/Translation'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useModals } from '~/domains/modals'
import { useNavigation } from '~/domains/navigation/hooks/useNavigation'
import { Inbox, InboxIcon } from '~/domains/notifications/Inbox'
import { NotificationsProvider } from '~/domains/notifications/NotificationsProvider'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { getEnv } from '~/utils/getEnv'
import { getRoute } from '~/utils/getRoute'
import { APP_BAR_HEIGHT } from '../constants'
import { AppBarLogo } from './AppBarLogo'
import { AppBarTitle } from './AppBarTitle'

export const AppBarContent = () => {
  const isTherapist = useUserActingAsGroup() === 'therapist'
  const {
    goBackOrToFallbackPreviousRoute,
    isVisibleBackButton,
    leaveChangeTherapistFlow,
    shouldShowCancelButton,
    shouldShowGestoButton,
    shouldShowShareReferralButton,
    shouldShowLeaveChangeTherapistButton,
    title,
  } = useAppBarContent()
  const { referralCode } = useCurrentUserNullable()
  const { open } = useModals()
  const { isVisibleNotificationsInbox } = useNavigation()

  const openReferralModal = useCallback(() => {
    open('referralShare')
  }, [open])

  const goToGesto = useCallback(() => {
    window.open(getEnv('GESTO_APP_URL'), '_blank')
  }, [])

  return (
    <Flex $backgroundColorName="white" $basis={APP_BAR_HEIGHT} $justify="center" $px={16} as="header">
      <MaxWidth1260px $align="center" $direction="row" $justify="space-between">
        {!isVisibleBackButton && (
          <Link aria-label="Serenis Health" to={getRoute('/')}>
            <AppBarLogo />
          </Link>
        )}

        {isVisibleBackButton && (
          <Flex $basis="52px">
            <TertiaryButtonIconLeft iconName="arrow-left" onClick={goBackOrToFallbackPreviousRoute}>
              <Flex $hide $mdHide={false}>
                <Text fontWeight="600" kind="paragraph">
                  <Translation id="actions.back" />
                </Text>
              </Flex>
            </TertiaryButtonIconLeft>
          </Flex>
        )}

        {title && <AppBarTitle>{title}</AppBarTitle>}

        <Flex $align="center" $direction="row">
          {shouldShowGestoButton && (
            <Flex>
              <Button kind="primary" onClick={goToGesto}>
                <Translation id="actions.saas.openGesto" />

                <GestoLogoIcon />
              </Button>
            </Flex>
          )}

          <Flex $pl={12} data-test-id="appbar-close-button">
            {shouldShowCancelButton && (
              <TertiaryButtonIconOnly onClick={goBackOrToFallbackPreviousRoute}>
                <Icon name="xmark" size={24} />
              </TertiaryButtonIconOnly>
            )}

            {shouldShowLeaveChangeTherapistButton && (
              <TertiaryButtonIconOnly onClick={leaveChangeTherapistFlow}>
                <Icon name="xmark" size={24} />
              </TertiaryButtonIconOnly>
            )}

            {!isTherapist && referralCode && shouldShowShareReferralButton && (
              <TertiaryButtonIconOnly onClick={openReferralModal}>
                <Icon name="gift" size={24} />
              </TertiaryButtonIconOnly>
            )}

            {isTherapist && isVisibleNotificationsInbox && !shouldShowCancelButton && (
              <NotificationsProvider>
                <MountOn mediaQuery="sm">
                  <Link to={getRoute('/communications')}>
                    <InboxIcon />
                  </Link>
                </MountOn>
                <MountOn mediaQuery="gt-sm">
                  <Inbox />
                </MountOn>
              </NotificationsProvider>
            )}
          </Flex>
        </Flex>
      </MaxWidth1260px>
    </Flex>
  )
}
