import { Svg } from 'ui-deprecated'

export const JournalingLogScoreIllustration5 = ({ size }: { size: number }) => (
  <Svg fill="none" height={size} style={{ maxWidth: '100%' }} viewBox="0 0 64 64" width={size}>
    <circle cx="32" cy="32" fill="#DFDAF6" r="21.33" />
    <path d="M43.73 23.04a11.73 11.73 0 0 1-23.46 0" stroke="#000" strokeWidth="1.28" />
    <path
      d="M28.59 19.41a2.13 2.13 0 1 1-4.27 0M39.9 19.41a2.13 2.13 0 1 1-4.27 0"
      stroke="#141323"
      strokeWidth="1.28"
    />
    <path
      d="M25.81 26.03a5.55 5.55 0 0 0 5.15-3.47c.44-1.09-.56-2.08-1.73-2.08H22.4c-1.18 0-2.17.99-1.73 2.08a5.55 5.55 0 0 0 5.14 3.47ZM38.19 26.03a5.55 5.55 0 0 0 5.14-3.47c.44-1.09-.55-2.08-1.73-2.08h-6.83c-1.18 0-2.17.99-1.73 2.08a5.55 5.55 0 0 0 5.15 3.47Z"
      fill="#141323"
    />
    <path
      d="m48.85 19.41 4.23.59a4.27 4.27 0 0 1 3.26 6.08l-4.6 9.55M15.15 19.41l-4.23.59a4.27 4.27 0 0 0-3.26 6.08l4.6 9.55"
      stroke="#B5B2EA"
      strokeLinecap="round"
      strokeWidth="2.56"
    />
  </Svg>
)
