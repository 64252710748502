import {
  BORDER_RADIUS_0,
  BORDER_RADIUS_MD,
  BORDER_RADIUS_XS,
  BORDER_WIDTH_1,
  COLOR_DARKER,
  COLOR_GREEN_40,
  COLOR_GREEN_80,
  COLOR_GREY_30,
  COLOR_LIGHTER,
  COLOR_NEUTRAL_100,
  COLOR_PRIMARY,
  COLOR_PRIMARY_10,
  COLOR_PRIMARY_40,
  COLOR_RED_40,
  COLOR_RED_60,
  COLOR_RED_80,
  COLOR_TRANSPARENT,
  COLOR_WARNING,
  COLOR_YELLOW_20,
  COLOR_YELLOW_40,
  COLOR_YELLOW_80,
  cssvarTypography,
  ELEVATION_SM,
  SPACING_0,
  SPACING_2XS,
  SPACING_3XS,
  SPACING_MD,
  SPACING_XL,
  SPACING_XS,
} from 'design-tokens'
import { createGlobalStyle } from 'styled-components'

export const VideocallStyles = createGlobalStyle`
  /* stylelint-disable custom-property-pattern, selector-class-pattern, no-duplicate-selectors */
  .str-video.str-video {
    --str-video__primary-color: ${COLOR_PRIMARY};
    --str-video__secondary-color: ${COLOR_PRIMARY_40};
    --str-video__composite-button__button-group--background-color: ${COLOR_DARKER};
    --str-video__danger-color: ${COLOR_RED_60};
    --str-video__danger-color--accent: ${COLOR_RED_80};
    --str-video__composite-button__button-group-active--background-color: ${COLOR_RED_60};
    --str-video__button-primary-hover: ${COLOR_RED_60};
    --str-video__button-primary-base: ${COLOR_TRANSPARENT};
    --str-video__button-default-hover: ${COLOR_TRANSPARENT};
    --str-video__composite-button__button-group--color: ${COLOR_DARKER};

    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
    justify-content: center;
    max-height: 100%;

    .str-video__video-preview-container {
      width: initial;
      height: initial;
      border: 0;
      background-color: ${COLOR_NEUTRAL_100};
    }

    .str-video__participant-view--speaking {
      outline-width: ${SPACING_2XS};
    }

    .str-video__video.str-video__video--not-playing {
      position: absolute;
      background-color: ${COLOR_TRANSPARENT};
    }

    .str-video__participant-view {
      width: 100%;
      height: 100%;
      aspect-ratio: initial;
    }

    .str-video__participant-view,
    .str-video__participant-view--angular-host {
      --str-video__border-radius-sm: ${BORDER_RADIUS_MD};

      overflow: hidden;
      background-color: rgb(255 255 255 / 20%);

      .str-video__participant-details__name--dominant_speaker,
      .str-video__call-controls__button {
        display: none;
      }
    }

    .str-video__participant-details {
      bottom: ${SPACING_MD};
      left: ${SPACING_MD};
      border-radius: ${BORDER_RADIUS_XS};
      background-color: rgb(20 19 35 / 50%);

      .str-video__participant-details__name {
        padding: ${SPACING_3XS} ${SPACING_XS};
        color: ${COLOR_LIGHTER};
        font-weight: 600;
      }
    }

    .str-video__participant-details__connection-quality {
      right: ${SPACING_MD};
      bottom: ${SPACING_MD};
      padding: ${SPACING_3XS} ${SPACING_XS};
      border-radius: ${BORDER_RADIUS_XS};
      background-color: ${COLOR_TRANSPARENT};
    }

    .srns-screen-share-button,
    .srns-microphone-button,
    .srns-camera-button,
    .srns-audio-output-button,
    .srns-background-filter-button {
      .str-video__composite-button__button-group {
        position: relative;
        height: 42px;
        border: ${BORDER_WIDTH_1} solid ${COLOR_PRIMARY_40};
      }
    }

    .srns-close-videocall-button .str-video__composite-button__button-group {
      position: relative;
      height: 42px;
      border: ${BORDER_WIDTH_1} solid ${COLOR_RED_60};
    }

    .srns-permissions-denied-control .str-video__composite-button__button-group {
      background-color: ${COLOR_WARNING};
    }

    .str-video__composite-button__button {
      &:disabled {
        cursor: auto;
      }
    }

    .srns-screen-share-button,
    .srns-background-filter-button {
      --str-video__composite-button__button-group-active--background-color: ${COLOR_PRIMARY};
      --str-video__button-primary-hover: ${COLOR_PRIMARY};
    }

    .str-video__composite-button--menu {
      .str-video__composite-button__button {
        width: 40px;
        margin: ${SPACING_0} ${SPACING_XL} ${SPACING_0} ${SPACING_0};
        padding: ${SPACING_0};
        border-right: ${BORDER_WIDTH_1} solid ${COLOR_LIGHTER};
      }
    }

    .str-video__screen-share-overlay {
      .str-video__screen-share-overlay__button {
        border: 0;
        cursor: pointer;
      }

      .str-video__screen-share-overlay__title {
        color: ${COLOR_LIGHTER};
      }
    }

    .str-video__menu-container {
      width: max-content;
      margin: calc(${SPACING_XS} * -1);
      border-radius: ${BORDER_RADIUS_MD};
      background-color: ${COLOR_LIGHTER};
      box-shadow: ${ELEVATION_SM};
    }

    .str-video__device-settings__device-kind {
      padding: ${SPACING_0};

      .str-video__device-settings__option {
        margin: 0;
        padding: ${SPACING_XS} ${SPACING_MD};
        border-radius: ${BORDER_RADIUS_0};
        ${cssvarTypography('caption')}

        &.str-video__device-settings__option--selected {
          background-color: ${COLOR_PRIMARY_10};
          color: ${COLOR_PRIMARY};
          font-weight: 600;
        }

        input {
          display: none;
        }
      }
    }

    .str-video__notification {
      --str-video__background-color1: ${COLOR_YELLOW_20};
      --str-video__text-color1: ${COLOR_DARKER};
    }

    .str-video__video--screen-share {
      object-fit: contain;
    }
  }

  .str-video__call-stats.str-video__call-stats {
    .str-video__call-stats__heading {
      font-weight: 600;

      .str-video__icon {
        display: none;
      }
    }

    .str-video__call-stats__description {
      ${cssvarTypography('caption')}
      font-weight: 400;
    }

    .str-video__call-stats__card-container {
      --gap: ${SPACING_XS};
    }

    .str-video__call-stats__card {
      border-radius: ${BORDER_RADIUS_XS};
      background-color: ${COLOR_GREY_30};

      .str-video__call-stats__card-label {
        font-weight: 600;
      }

      .str-video__call-stats__card-value {
        ${cssvarTypography('footnote')}
      }
    }

    .str-video__call-stats__tag {
      ${cssvarTypography('caption')}
      align-self: baseline;
      height: auto;
      padding: ${SPACING_3XS};
      border-radius: ${BORDER_RADIUS_XS};
      font-weight: 600;

      &.str-video__call-stats__tag--good {
        background-color: ${COLOR_GREEN_40};
        color: ${COLOR_GREEN_80};
      }

      &.str-video__call-stats__tag--ok {
        background-color: ${COLOR_YELLOW_40};
        color: ${COLOR_YELLOW_80};
      }

      &.str-video__call-stats__tag--bad {
        background-color: ${COLOR_RED_40};
        color: ${COLOR_RED_80};
      }
    }
  }
`
