import { Text } from 'ui'
import { Flex, Pressable, SuggestionBox } from 'ui-deprecated'
import { Translation } from '~/components/Translation'

type Props = {
  onClick: () => void
}

export const CreatePartnerSuggestion = ({ onClick }: Props) => (
  <Flex pb={32}>
    <SuggestionBox kind="warning">
      <Flex mdAlign="center" mdDirection="row" mdJustify="space-between">
        <Text colorName="black" kind="caption">
          <Translation id="onboardingCouplesBanner.title" />
        </Text>

        <Flex mdPl={16} mdPt={0} pt={8}>
          <Pressable align="flex-start" onClick={onClick}>
            <Text colorName="primary" fontWeight="600" kind="caption" textDecoration="underline">
              <Translation id="onboardingCouplesBanner.banner.cta" />
            </Text>
          </Pressable>
        </Flex>
      </Flex>
    </SuggestionBox>
  </Flex>
)
