import { Chip, ChipProps } from 'ui'
import { ClinicalTestAnswerTranslation } from './ClinicalTestAnswerTranslation'

const chipKindByAnswerValue: Record<number, ChipProps['kind']> = {
  0: 'success',
  1: 'grey',
  2: 'warning',
  3: 'error',
}

type ClinicalTestAnswerChipProps = {
  answer: number
}

export const ClinicalTestAnswerChipForTherapist = ({ answer }: ClinicalTestAnswerChipProps) => (
  <Chip kind={chipKindByAnswerValue[answer]}>
    {answer} - <ClinicalTestAnswerTranslation answer={answer} />
  </Chip>
)

export const ClinicalTestAnswerChipForPatient = ({ answer }: ClinicalTestAnswerChipProps) => (
  <Chip kind="info">
    <ClinicalTestAnswerTranslation answer={answer} />
  </Chip>
)
