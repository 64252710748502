import { Chip } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { AppointmentDetails } from '../components/AppointmentDetails'
import { ConnectToLiveButton } from '../components/ConnectToLiveButton'
import { ContentSkeleton } from '../components/ContentSkeleton'
import { PayNowButton } from '../components/PayNowButton'
import { Price } from '../components/Price'

type Props = {
  isVeryNextTherapySession?: boolean
}

export const BookedWithPaymentMethodContent = ({ isVeryNextTherapySession }: Props) => (
  <ContentSkeleton
    bottomChildren={
      <>
        <ConnectToLiveButton isVeryNextTherapySession={isVeryNextTherapySession} />
        <Flex pt={8}>
          <PayNowButton />
        </Flex>
      </>
    }
    topEndChildren={<Price />}
    topStartChildren={
      <Chip kind="warning">
        <Translation id="therapySession.patient.scheduled.statusValue" />
      </Chip>
    }
  >
    <AppointmentDetails canManage />
  </ContentSkeleton>
)
