import { Flex, PositionRelative } from 'cdk'
import { OPACITY_24 } from 'design-tokens'
import { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'

const PositionRelativeWithShadow = styled(PositionRelative)<{ $active: boolean }>`
  ${({ $active }) =>
    $active &&
    css`
      box-shadow: 0 0.5px 0 0 rgb(from var(--srns-color-primary) r g b / ${OPACITY_24}) inset;
    `}
`

export const FlowNavigationBar = ({ children }: PropsWithChildren) => {
  const { isVariant1 } = useFeatureFlagsByFingerprint()

  const isRestyleSurvey = isVariant1('ff_restyle_survey')

  return (
    <PositionRelativeWithShadow $active={isRestyleSurvey} $backgroundColorName="white" $py={16}>
      <Flex $maxWidth={isRestyleSurvey ? 464 : 640} $px={24}>
        {children}
      </Flex>
    </PositionRelativeWithShadow>
  )
}
