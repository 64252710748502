import { Flex } from 'ui-deprecated'
import { modalContainerDesktop, modalContainerMedium, modalContainerMobile } from '../constants/containers'

export const Modals = () => (
  <>
    <Flex hide id={modalContainerDesktop} mdHide={false} />

    <Flex id={modalContainerMobile} mdHide />

    <Flex id={modalContainerMedium} lgHide />
  </>
)
