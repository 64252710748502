import { ColorName } from 'design-tokens'
import { Icon } from 'icons'
import { useMemo } from 'react'
import { components, MultiValueRemoveProps } from 'react-select'
import { SelectOption } from '~/components/SelectNew/types'

export const MultiValueRemove = (props: MultiValueRemoveProps<SelectOption>) => {
  const colorName: ColorName = useMemo(() => {
    if (props.selectProps.isDisabled || props.selectProps.isLoading) {
      return 'grey-60'
    }

    return 'primary'
  }, [props])

  return (
    <components.MultiValueRemove {...props}>
      <Icon colorName={colorName} name="xmark" size={16} />
    </components.MultiValueRemove>
  )
}
