import { ComponentProps } from 'react'
import { ReactHookFormTextField } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useTranslation } from '~/i18n/hooks/useTranslation'

const max = 4

type Props = {
  textAlign?: ComponentProps<typeof ReactHookFormTextField>['textAlign']
}

export const FieldPhoneVerificationCode = ({ textAlign = 'center' }: Props) => {
  const {
    errors: { required, patternDigits },
    placeholders,
  } = useFormTranslations()

  const maxLengthMessage = useTranslation('forms.errors.maxLength', { count: max })

  return (
    <ReactHookFormTextField
      autoComplete="one-time-code"
      inputMode="numeric"
      name="phoneVerificationCode"
      placeholder={placeholders.phoneVerificationCode}
      rules={{
        required,
        maxLength: { message: maxLengthMessage, value: max },
        pattern: { message: patternDigits, value: /[0-9]{4}/ },
      }}
      textAlign={textAlign}
      type="text"
    />
  )
}
