import styled from 'styled-components'
import { Text } from 'ui'

/* TODO (amin-khayam)
 * - use proper <Text kind> when typography is updated
 */

export const TextSize18 = styled(Text)`
  font-size: 18px;
`
