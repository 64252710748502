import { useProductUserFeedbackCompleted } from '~/domains/productUserFeedback/hooks/useProductUserFeedbackCompleted'
import { AskForReviewTrigger } from '~/domains/reviews/components/AskForReviewTrigger'
import { useSetupReviewAskAtEffect } from '~/domains/reviews/hooks/useSetupReviewAskAtEffect'
import { shouldAskForReview } from '~/domains/reviews/utils/shouldAskForReview'
import { useReviewExtra } from '../hooks/useReviewExtra'

export const AskForReviewHandler = () => {
  useSetupReviewAskAtEffect()

  const extra = useReviewExtra()

  const { completed, error, loading } = useProductUserFeedbackCompleted('APP_REVIEW', extra)

  if (completed || error || loading) {
    return null
  }

  if (shouldAskForReview()) {
    return <AskForReviewTrigger />
  }

  return null
}
