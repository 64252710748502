import { Flex, MountOn, OverflowAuto } from 'cdk'
import { Text } from 'ui'
import { AppBarLogo } from '~/components/AppBar/components/AppBarLogo'
import { Translation } from '~/components/Translation'
import { NavigationHideAppBarEffect } from '~/domains/navigation/components/NavigationHideAppBarEffect'
import { NavigationShowAppBarEffect } from '~/domains/navigation/components/NavigationShowAppBarEffect'
import { SignupForm } from '../components/SignupForm'
import { useSignupIntent } from '../hooks/useSignupIntent'
import { SignupHeroContent } from '../components/SignupHeroContent'
import { useFeatureFlagsByFingerprint } from '~/domains/featureFlags'
import { TranslationId } from '~/i18n/types'
import loadable from '@loadable/component'

const LazySignupHeroBackground = loadable(() => import('../components/SignupHeroBackground.lazy'), {
  resolveComponent: (components) => components.SignupHeroBackground,
})

export const SignupPage = () => {
  const intent = useSignupIntent()
  const { isVariant2, isVariant4 } = useFeatureFlagsByFingerprint()
  const isVariant2Or4 = isVariant2('ff_signup_multivariant') || isVariant4('ff_signup_multivariant')
  const shouldIgnoreIntent = ['call', 'dca', 'journaling', 'nutritionist'].includes(intent)

  // Copy for the above intents are temporary missing, force type assertion
  const formTitle = (
    isVariant2Or4 && !shouldIgnoreIntent ? `generic.signup.${intent}.title.variant` : `generic.signup.${intent}.title`
  ) as TranslationId

  return (
    <OverflowAuto $pb={0}>
      <LazySignupHeroBackground>
        <MountOn mediaQuery="lt-lg">
          <NavigationShowAppBarEffect />
        </MountOn>

        <MountOn mediaQuery="gt-md">
          <NavigationHideAppBarEffect />
        </MountOn>

        <SignupHeroContent />

        <Flex $backgroundColorName="lighter" $grow={1} $shrink={1}>
          <MountOn mediaQuery="gt-md">
            <Flex $align="flex-start" $pl={64} $pt={32}>
              <AppBarLogo />
            </Flex>
          </MountOn>

          <Flex $gap={16} $grow={1} $lgPx={64} $lgPy={64} $px={16} $py={24} $shrink={1} $xlPx={128}>
            <Text fontWeight="600" kind="h2">
              <Translation id={formTitle} />
            </Text>
            <SignupForm />
          </Flex>
        </Flex>
      </LazySignupHeroBackground>
    </OverflowAuto>
  )
}
