import { noop } from 'functions'
import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { useDeleteRecurrencyForTherapistEmergency } from '~/components/TherapySessionCardTherapist/hooks/useDeleteRecurrencyForTherapistEmergency'
import { Translation } from '~/components/Translation'
import { TherapistAgendaTherapySession } from '~/domains/agenda/types'
import { useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { useTherapySessionFindManyFutureForTherapistByPatientId } from '~/domains/patientsManagement/hooks/useTherapySessionFindManyFutureForTherapistByPatientId'
import { ReactHookFormTextArea, useReactHookFormContext } from '~/domains/react-hook-form'
import { useTherapySessionsTherapistAgenda } from '~/domains/therapy-session/hooks/useTherapySessionsTherapistAgenda'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useTherapistAgendaByPatientId } from '~/hooks/useTherapistAgendaByPatientId'
import { useTherapistTherapiesFetch } from '~/hooks/useTherapistTherapiesFetch'
import { useToasts } from '~/hooks/useToasts'
import { FormValues } from '../types/formValues'

const max = 600

type Props = { onClose?: () => void } & Pick<TherapistAgendaTherapySession, 'id' | 'patient'>

export const StepMessage = ({ id, onClose = noop, patient }: Props) => {
  const [deleteRecurrencyForTherapistEmergency] = useDeleteRecurrencyForTherapistEmergency()
  const { addToast } = useToasts()
  const { close } = useModals()
  const { refetch: refetchTherapySessionsTherapistAgenda } = useTherapySessionsTherapistAgenda()
  const { refetch: refetchTherapistAgendaByPatientId } = useTherapistAgendaByPatientId(patient.id, 'cache-only')
  const { refetch: refetchTherapySessionFindManyFutureForTherapistByPatientId } =
    useTherapySessionFindManyFutureForTherapistByPatientId(patient.id)
  const { refetch: refetchTherapistTherapies } = useTherapistTherapiesFetch()

  const form = useReactHookFormContext<FormValues>()

  const {
    errors: { required },
    placeholders: { deleteBatchRecurrency },
  } = useFormTranslations()

  const modalId = `deleteBatchRecurrency:${id}` as const

  const handleClose = useCallback(async () => {
    close(modalId)

    onClose()
  }, [close, onClose, modalId])

  const onSubmit = useCallback(
    async ({ message, therapistEmergencyReason }: FormValues) => {
      if (!therapistEmergencyReason) {
        return
      }

      try {
        await deleteRecurrencyForTherapistEmergency({
          variables: {
            input: {
              id,
              message,
              therapistEmergencyReason,
            },
          },
        })

        refetchTherapySessionsTherapistAgenda().catch(noop)

        refetchTherapistAgendaByPatientId().catch(noop)

        await refetchTherapySessionFindManyFutureForTherapistByPatientId().catch(noop)

        await refetchTherapistTherapies().catch(noop)

        handleClose()

        addToast({ translationId: 'therapySession.deleteRecurrency.success', type: 'success' })
      } catch (error) {
        addToast({ translationId: 'therapySession.deleteRecurrency.error', type: 'alert' })
      }
    },
    [
      addToast,
      deleteRecurrencyForTherapistEmergency,
      handleClose,
      id,
      refetchTherapistAgendaByPatientId,
      refetchTherapistTherapies,
      refetchTherapySessionFindManyFutureForTherapistByPatientId,
      refetchTherapySessionsTherapistAgenda,
    ],
  )

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <ModalCloseButton id={modalId} />

      <OverflowAuto>
        <Text fontWeight="600" kind="h3">
          <Translation id="therapySession.deleteBatchRecurrency.title" />
        </Text>
        <Flex pt={4}>
          <Text fontWeight="400" kind="paragraph">
            <Translation
              id={`therapySession.deleteBatchRecurrency.${patient.firstName ? '1' : '2'}.description`}
              values={{ patientFirstName: patient.firstName || '' }}
            />
          </Text>
        </Flex>
        <Flex pt={24}>
          <ReactHookFormTextArea
            max={max}
            minHeight="200px"
            name="message"
            placeholder={deleteBatchRecurrency}
            rules={{ max, required }}
          />
        </Flex>
      </OverflowAuto>

      <Flex pt={16}>
        <Button disabled={!form.formState.isValid} kind="danger" loading={form.formState.isSubmitting} type="submit">
          <Translation id="actions.confirm" />
        </Button>
      </Flex>
    </Form>
  )
}
