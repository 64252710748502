import { Link } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, PageLayout } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { getRoute } from '~/utils/getRoute'

export const LoginCallbackError = () => (
  <PageLayout maxWidth="640px">
    <Flex grow={1} mdGrow={0}>
      <Text fontWeight="600" kind="h3">
        <Translation id="generic.authError.title" />
      </Text>

      <Flex pt={32}>
        <TranslationMarkdown id="generic.authError.description" kind="paragraph" pt={12} />
      </Flex>

      {/* IDP_REMOVAL */}
    </Flex>

    <Flex align="center" pt={32}>
      <Link replace to={getRoute('/login')}>
        <Button kind="secondary">
          <Translation id="actions.signin" />
        </Button>
      </Link>
    </Flex>
  </PageLayout>
)
