import { Flex } from 'ui-deprecated'
import { TherapistTabMenu } from './TherapistTabMenu'
import { MenuHorizontalWrapper } from './components/MenuHorizontalWrapper'
import { useTabNavigationMenu } from './hooks/useTabNavigationMenu'
import { RouteAgendaMenuItem } from './routes/AgendaMenuItem'
import { RouteCalendarMenuItem } from './routes/CalendarMenuItem'
import { RouteChatMenuItem } from './routes/ChatMenuItem'
import { RoutePatientManagementMenuItem } from './routes/PatientManagementMenuItem'
import { RouteSettingsMenuItem } from './routes/SettingsMenuItem'

export const TherapistTabNavigationHorizontal = () => {
  const { open } = useTabNavigationMenu()

  return (
    <>
      <Flex background="purple08" basis="78px" direction="row" justify="center">
        <RouteAgendaMenuItem direction="horizontal" />

        <RouteCalendarMenuItem direction="horizontal" />

        <RouteChatMenuItem direction="horizontal" />

        <RoutePatientManagementMenuItem direction="horizontal" />

        <RouteSettingsMenuItem direction="horizontal" />
      </Flex>

      {open && (
        <MenuHorizontalWrapper background="white">
          <TherapistTabMenu />
        </MenuHorizontalWrapper>
      )}
    </>
  )
}
