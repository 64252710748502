import { Flex } from 'cdk'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { NavigationProps } from '~/domains/navigation/TabNavigation/types'
import { NewNavigation } from './NewNavigation'
import { TabNavigation } from './TabNavigation/TabNavigation'

export const Navigation = ({ direction }: NavigationProps) => {
  const { isAuthenticated } = useAuthState()

  const { isVariant1 } = useFeatureFlagsByUserId()
  const useNewNavigation = isVariant1('ff_new_navigation')

  if (!isAuthenticated) {
    return null
  }

  if (useNewNavigation) {
    return (
      <Flex $zIndex={2}>
        <NewNavigation direction={direction} />
      </Flex>
    )
  }

  return <TabNavigation direction={direction} />
}
