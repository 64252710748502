import { memo, useEffect } from 'react'
import { useTrackEvent } from '../hooks/useTrackEvent'

type Props = {
  name: string
}

export const TrackModalView = memo(({ name }: Props) => {
  const trackEvent = useTrackEvent('MODAL')

  useEffect(() => {
    trackEvent({ name: `${name}.open` })
  }, [name, trackEvent])

  return null
})
