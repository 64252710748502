import { useCallback } from 'react'
import { matchPath } from 'react-router-dom'
import { useRootLocation } from '~/hooks/useRootHistory'
import { getRoute } from '~/utils/getRoute'
import { useBookAndAssign } from './useBookAndAssign'
import { useReassignTherapySession } from './useReassignTherapySession'

type Param = {
  startAt: Date
  therapistId: string
}

export const useReservationRouteStrategy = () => {
  const { pathname } = useRootLocation()

  const isReassign = !!matchPath(pathname, { path: [getRoute('/change-therapist/:therapyId/booking')] })

  const reassignTherapySession = useReassignTherapySession()
  const bookAndAssign = useBookAndAssign()

  return useCallback(
    (param: Param) => {
      if (isReassign) {
        return reassignTherapySession(param)
      }

      return bookAndAssign(param)
    },
    [bookAndAssign, isReassign, reassignTherapySession],
  )
}
