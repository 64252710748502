import { useCurrentUser } from '~/hooks/useCurrentUser'
import { Modal } from '~/domains/modals'
import { ReferralActivationModalSuccess } from './ReferralActivationModalSuccess'
import { ReferralActivationModalError } from './ReferralActivationModalError'

const ReferralActivationModalView = () => {
  const { activeReferral } = useCurrentUser()

  return activeReferral ? <ReferralActivationModalSuccess /> : <ReferralActivationModalError />
}

export const ReferralActivationModal = () => (
  <Modal closeOnClickOutside={false} id="referralActivation">
    <ReferralActivationModalView />
  </Modal>
)
