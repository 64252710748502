import { fold } from 'fp-ts/boolean'
import { pipe } from 'fp-ts/function'
import { useCallback } from 'react'
import { isTaxCode } from 'validation'
import { useFormTranslations } from './useFormTranslations'

export const useIsTaxCode = () => {
  const {
    errors: { taxCode: label },
  } = useFormTranslations()

  return useCallback(
    (value: unknown) =>
      pipe(
        isTaxCode(value),
        fold<boolean | string>(
          () => label,
          () => true,
        ),
      ),
    [label],
  )
}
