import { Icon, IconProps } from 'icons'
import { memo } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { MenuHorizontalItem } from '~/domains/navigation/TabNavigation/components/MenuHorizontalItem'
import { MenuVerticalItem } from '~/domains/navigation/TabNavigation/components/MenuVerticalItem'
import { useTabNavigationMenu } from '~/domains/navigation/TabNavigation/hooks/useTabNavigationMenu'
import { NavigationProps } from '~/domains/navigation/TabNavigation/types'
import { getRoute } from '~/utils/getRoute'

export const RouteAgendaMenuItem = memo(({ direction }: NavigationProps) => {
  const { pathname } = useLocation()
  const group = useUserActingAsGroup()
  const { open } = useTabNavigationMenu()

  const active = !!matchPath(pathname, {
    path: [
      getRoute('/'),
      getRoute('/therapy-session/:therapyId/schedule'),
      getRoute('/therapy-session/:therapySessionId/edit'),
      getRoute('/therapy-session/booking/:therapyId'),
    ],
    exact: true,
  })

  const iconName: IconProps['name'] = group === 'therapist' ? 'google-docs' : 'calendar'
  const Item = direction === 'horizontal' ? MenuHorizontalItem : MenuVerticalItem
  const itemActive = direction === 'horizontal' ? active && !open : active

  return (
    <Link to={getRoute('/')}>
      <Item active={itemActive}>
        <Flex basis="32px" pb={8}>
          <Icon colorName="white" name={iconName} size={24} />
        </Flex>

        <Text fontWeight={active ? '600' : '400'} kind="footnote">
          <Translation id="menu.agenda.yourBookings" />
        </Text>
      </Item>
    </Link>
  )
})

RouteAgendaMenuItem.displayName = 'RouteAgendaMenuItem'
