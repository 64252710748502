import { Text } from 'ui'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { useFeatureFlagsByUserId } from '~/domains/featureFlags'
import { useTherapist } from '~/hooks/useTherapist'

export const ThankYouPageDescription = () => {
  const { fullName } = useTherapist()

  const { isVariant1, isVariant2 } = useFeatureFlagsByUserId()

  const addToCalendar = isVariant1('ff_thank_you_page')
  const openChat = isVariant2('ff_thank_you_page')

  const descriptionId = openChat
    ? 'thankYouPage.therapist-card.description.variant2'
    : addToCalendar
      ? 'thankYouPage.therapist-card.description.variant1'
      : 'thankYouPage.therapist-card.description.variant3'

  return (
    <Text kind="paragraph">
      <TranslationMarkdown id={descriptionId} values={{ therapistName: fullName }} />
    </Text>
  )
}
