import { TranslationId } from '~/i18n/types'
import { TherapyTherapyPathType } from '~/types/graphql'

type Props = {
  pathType?: TherapyTherapyPathType | null
  sex: number
}

type Translation = Extract<
  TranslationId,
  `therapist.jobTitle.${string}` | `therapist.presentationCard.certifications.sexology.title.${string}`
>

export const useValidTherapistProfessionTypeValue = ({
  pathType = 'MYSELF_PSYCHOTHERAPY',
  sex,
}: Props): Translation => {
  switch (pathType) {
    case 'PATH_COACHING':
      return 'therapist.jobTitle.coach'

    case 'PATH_SEXOLOGY':
      return sex === 1
        ? 'therapist.presentationCard.certifications.sexology.title.male'
        : 'therapist.presentationCard.certifications.sexology.title.female'

    case 'MYSELF_PSYCHIATRY':
      return 'therapist.jobTitle.psychiatry'

    case 'PATH_NUTRITION_DCA':
    case 'PATH_NUTRITION_WEIGHT_LOSS':
      return sex === 1 ? 'therapist.jobTitle.nutritionist.male' : 'therapist.jobTitle.nutritionist.female'

    default:
      return sex === 1 ? 'therapist.jobTitle.male' : 'therapist.jobTitle.female'
  }
}
