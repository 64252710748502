import loadable from '@loadable/component'
import { noop } from 'functions'
import { useCallback } from 'react'
import { MemoryRouter, Route, Switch } from 'react-router-dom'
import { Modal, useModals } from '~/domains/modals'
import { modalId } from './constants'
import { getJournalingTutorialRoute } from './utils/getJournalingTutorialRoute'

const LazyStepDetails = loadable(() => import('./components/StepDetails.lazy'), {
  resolveComponent: (components) => components.StepDetails,
})

const LazyStepIntro = loadable(() => import('./components/StepIntro.lazy'), {
  resolveComponent: (components) => components.StepIntro,
})

const LazyStepNeeds = loadable(() => import('./components/StepNeeds.lazy'), {
  resolveComponent: (components) => components.StepNeeds,
})

const LazyStepTryIt = loadable(() => import('./components/StepTryIt.lazy'), {
  resolveComponent: (components) => components.StepTryIt,
})

const initialEntries = [getJournalingTutorialRoute('/')]

export const JournalingTutorialModal = () => {
  const { close } = useModals()

  const onClose = useCallback(() => {
    close(modalId)
  }, [close])

  return (
    <Modal id="journalingTutorial">
      <MemoryRouter initialEntries={initialEntries}>
        <Switch>
          <Route exact path={getJournalingTutorialRoute('/')}>
            <LazyStepIntro onDismiss={noop} />
          </Route>

          <Route exact path={getJournalingTutorialRoute('/needs')}>
            <LazyStepNeeds onDismiss={noop} />
          </Route>

          <Route exact path={getJournalingTutorialRoute('/details')}>
            <LazyStepDetails onDismiss={noop} />
          </Route>

          <Route exact path={getJournalingTutorialRoute('/try-it')}>
            <LazyStepTryIt onClose={onClose} />
          </Route>
        </Switch>
      </MemoryRouter>
    </Modal>
  )
}
