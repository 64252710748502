import { AvailabilitiesInsertionReminderBanner } from './components/AvailabilitiesInsertionReminderBanner'
import { AvailabilitiesInsertionReminderModal } from './components/AvailabilitiesInsertionReminderModal'

export const AvailabilitiesInsertionReminder = () => (
  <>
    <AvailabilitiesInsertionReminderBanner />

    <AvailabilitiesInsertionReminderModal />
  </>
)
