import { useEffect } from 'react'
import { useTrackEventModal } from '~/domains/analytics/hooks/useTrackEventModal'
import { ModalId } from '~/domains/modals'

type Props = {
  id: ModalId
}

export const ModalView = ({ id }: Props) => {
  const trackEventModal = useTrackEventModal('open')

  useEffect(() => {
    trackEventModal(id)
  }, [id, trackEventModal])

  return null
}
