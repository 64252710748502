import { Flex, FlexProps } from 'cdk'
import styled from 'styled-components'

type Props = Omit<
  FlexProps,
  | 'borderRadius'
  | 'borderRadiusBottomLeft'
  | 'borderRadiusBottomRight'
  | 'borderRadiusTopLeft'
  | 'borderRadiusTopRight'
  | 'mdBorderRadius'
  | 'mdBorderRadiusBottomLeft'
  | 'mdBorderRadiusBottomRight'
  | 'mdBorderRadiusTopLeft'
  | 'mdBorderRadiusTopRight'
  | 'lgBorderRadius'
  | 'lgBorderRadiusBottomLeft'
  | 'lgBorderRadiusBottomRight'
  | 'lgBorderRadiusTopLeft'
  | 'lgBorderRadiusTopRight'
>

const defaultProps: Partial<FlexProps> = {
  $backgroundColorName: 'white',
  $p: 16,
}

const fixedProps: Pick<FlexProps, '$borderRadiusTopLeft' | '$borderRadiusTopRight'> = {
  $borderRadiusTopLeft: 16,
  $borderRadiusTopRight: 16,
}

export const Drawer = styled(Flex).attrs<Props>((props) => ({
  ...defaultProps,
  ...props,
  ...fixedProps,
}))`
  height: 100%;
`

Drawer.displayName = 'Drawer'
