import { ipv4 } from 'ipify2'
import { useCallback } from 'react'

export const useIpAddress = () => {
  const getIp = useCallback(() => ipv4().catch(() => '127.0.0.1'), [])

  return {
    getIp,
  }
}
