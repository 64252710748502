import { pipe } from 'fp-ts/function'
import { TherapyTherapyPathType } from '~/types/graphql'
import { getEnv } from '~/utils/getEnv'

const multiplyBySlots = (slots: number) => (seconds: number) => {
  switch (slots) {
    case 2:
      return seconds * slots
    default:
      return seconds
  }
}

type Param = {
  duration: number
  firstTherapySession: boolean
  therapyPathType: TherapyTherapyPathType | null
}

export const getTherapySessionMeetingDurationInSeconds = ({
  duration,
  firstTherapySession,
  therapyPathType,
}: Param) => {
  switch (therapyPathType) {
    case 'COUPLES_PSYCHOTHERAPY':
      return pipe('SERENIS_THERAPY_SESSION_MEETING_DURATION_COUPLES', getEnv, Number, multiplyBySlots(duration))
    case 'MYSELF_PSYCHIATRY':
      return firstTherapySession
        ? pipe('SERENIS_THERAPY_SESSION_MEETING_DURATION_MYSELF', getEnv, Number, multiplyBySlots(duration))
        : pipe('SERENIS_THERAPY_SESSION_MEETING_DURATION_PSYCHIATRY', getEnv, Number, multiplyBySlots(duration))
    default:
      return pipe('SERENIS_THERAPY_SESSION_MEETING_DURATION_MYSELF', getEnv, Number, multiplyBySlots(duration))
  }
}
