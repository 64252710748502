import loadable from '@loadable/component'
import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { AssertFeatureFlagVariant1Redirect } from '~/domains/featureFlags/components/AssertFeatureFlagVariant1Redirect'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

const LazyDiariesPageView = loadable(() => import('./View'), {
  resolveComponent: (components) => components.DiariesPageView,
})

export const DiariesPage: Page = {
  exact: true,
  id: 'diaries',
  paths: ['/diaries'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: false,
    isVisibleTabNavigation: true,
    seoKey: 'diaries',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertFeatureFlagVariant1Redirect name="ff_nutrition">
        <AssertPatient>{children}</AssertPatient>
      </AssertFeatureFlagVariant1Redirect>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: PageNoop,
  PageView: () => <LazyDiariesPageView />,
}
