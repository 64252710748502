import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { Modal } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { useRootHistory } from '~/hooks/useRootHistory'
import { getRoute } from '~/utils/getRoute'

export const JournalingLogShareModalNotBooked = () => {
  const history = useRootHistory()

  const { therapy } = usePatientTherapies()

  const onFindTherapist = useCallback(() => {
    history.push(getRoute('/booking'))
  }, [history])

  const therapistFullName = String(therapy?.therapist?.fullName)

  return (
    <Modal id="journalingLogShareNotBooked">
      <ModalCloseButton id="journalingLogShareNotBooked" />

      <OverflowAuto>
        <Text fontWeight="600" kind="h2">
          <Translation id="journaling.journalingLogShare.notBooked.title" />
        </Text>

        <TranslationMarkdown
          colorName="black"
          id="journaling.journalingLogShare.notBooked.text"
          kind="paragraph"
          pt={16}
          values={{ therapistFullName }}
        />
      </OverflowAuto>
      <Flex pt={16}>
        <Button kind="primary" onClick={onFindTherapist}>
          <Translation id="journaling.journalingLogShare.notBooked.cta" />
        </Button>
      </Flex>
    </Modal>
  )
}
