import styled from 'styled-components'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'

const AnimatedText = styled(Text)`
  @keyframes slide-thx-page-free {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  opacity: 0;
  transform: translateX(30%);
  animation: slide-thx-page-free 1.5s ease forwards;
  animation-delay: 0.6s;
`

export const TherapistCardFree = () => (
  <AnimatedText kind="caption">
    <Translation id="thankYouPage.therapist-card.isFree" />
  </AnimatedText>
)
