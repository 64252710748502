import { gql, useQuery } from '@apollo/client'
import { ReactNode, createContext, useMemo } from 'react'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useIsSaasTherapist } from '~/domains/auth/hooks/useIsSaasTherapist'
import { GenericErrorContent } from '~/domains/error/GenericError'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { SaasTherapistQuery, SaasTherapistQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query saasTherapist($userId: String!) {
    saasTherapist(userId: $userId) {
      id
    }
  }
`

type SaasTherapistContext = {
  saasTherapist: SaasTherapistQuery['saasTherapist']
  updateQuery: ReturnType<typeof useQuery<SaasTherapistQuery, SaasTherapistQueryVariables>>['updateQuery']
}

const SaasTherapistContext = createContext<SaasTherapistContext | null>(null)

type Props = {
  children: ReactNode
}

const SaasTherapistRetriever = ({ children }: Props) => {
  const { id: userId } = useCurrentUser()

  const { data, error, loading, updateQuery } = useQuery<SaasTherapistQuery, SaasTherapistQueryVariables>(QUERY, {
    variables: {
      userId,
    },
  })

  const value = useMemo(
    (): SaasTherapistContext => ({
      saasTherapist: data?.saasTherapist || null,
      updateQuery,
    }),
    [data, updateQuery],
  )

  if (loading) {
    return <CenteredLoader />
  }

  if (error || !data?.saasTherapist) {
    return <GenericErrorContent />
  }

  return <SaasTherapistContext.Provider value={value}>{children}</SaasTherapistContext.Provider>
}

export const SaasTherapistProvider = ({ children }: Props) => {
  const isSaasTherapist = useIsSaasTherapist()

  if (!isSaasTherapist) {
    return children
  }

  return <SaasTherapistRetriever>{children}</SaasTherapistRetriever>
}
