import {
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
  registerDecorator,
} from 'class-validator'
import { checkVAT, countries } from 'jsvat'

const vatCodeCountryRegExp = /[a-z]{2,3}/i

export const isVatCode = (value: unknown) => {
  if (typeof value !== 'string') {
    return false
  }

  const maybeVatCode = vatCodeCountryRegExp.test(value) ? value : `IT${value}`

  return checkVAT(maybeVatCode.trim(), countries).isValid
}

@ValidatorConstraint({ name: 'vatCode', async: false })
export class IsVatCodeConstraint implements ValidatorConstraintInterface {
  validate = isVatCode

  defaultMessage = () => 'Input is not a valid vat code'
}

export function IsVatCode<T extends object>(validationOptions?: ValidationOptions) {
  return function (object: T, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsVatCodeConstraint,
    })
  }
}
