import { useEffect } from 'react'
import { useNavigation } from '../hooks/useNavigation'

const useNavigationShowTabNavigationEffect = () => {
  const { showTabNavigation } = useNavigation()

  useEffect(() => {
    showTabNavigation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const NavigationShowTabNavigationEffect = () => {
  useNavigationShowTabNavigationEffect()

  return null
}
