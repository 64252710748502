import { CenteredLoader } from '~/components/CenteredLoader'
import { useJournalingContext } from '../hooks/useJournalingContext'
import { JournalingLogCard } from './JournalingLogCard'
import { TipsOfLoveCard } from './TipsOfLoveCard'

export const JournalingCard = () => {
  const {
    journalingLogToday: [journalingLogToday, { loading }],
  } = useJournalingContext()

  if (loading) {
    return <CenteredLoader />
  }

  if (!journalingLogToday) {
    return <TipsOfLoveCard />
  }
  return <JournalingLogCard {...journalingLogToday} />
}
