import { Flex, MaxWidth1260px, MaxWidth640px, MountOn, OverflowAuto, PositionRelative, PositionSticky } from 'cdk'
import { LoginForm } from '../components/LoginForm'
import { LoginMenuTitle } from '../components/LoginMenuTitle'

type LoginForm = {
  email: string
  password: string
}

export const LoginPage = () => (
  <OverflowAuto $pb={0}>
    <MountOn mediaQuery="sm">
      <PositionSticky $px={16} $py={32} $top={0} $zIndex={0}>
        <LoginMenuTitle />
      </PositionSticky>

      <PositionRelative $grow={1} $zIndex={1}>
        <LoginForm />
      </PositionRelative>
    </MountOn>

    <MountOn mediaQuery="gt-sm">
      <MaxWidth1260px $lgDirection="row" $lgGrow={1} $lgShrink={1} $px={24} $py={32}>
        <Flex $lgBasis="50%" $lgJustify="center" $lgPr={48} $lgPt={0} $mdPt={48}>
          <MaxWidth640px>
            <LoginMenuTitle />
          </MaxWidth640px>
        </Flex>
        <Flex $lgAlign="stretch" $lgBasis="50%" $lgJustify="center" $lgPt={24} $mdAlign="center" $mdPt={48}>
          <MaxWidth640px>
            <LoginForm />
          </MaxWidth640px>
        </Flex>
      </MaxWidth1260px>
    </MountOn>
  </OverflowAuto>
)
