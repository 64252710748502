import { Icon } from 'icons'
import { memo } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { MenuHorizontalItem } from '~/domains/navigation/TabNavigation/components/MenuHorizontalItem'
import { MenuVerticalItem } from '~/domains/navigation/TabNavigation/components/MenuVerticalItem'
import { useTabNavigationMenu } from '~/domains/navigation/TabNavigation/hooks/useTabNavigationMenu'
import { NavigationProps } from '~/domains/navigation/TabNavigation/types'
import { getRoute } from '~/utils/getRoute'

export const RouteTherapiesMenuItem = memo(({ direction }: NavigationProps) => {
  const { pathname } = useLocation()
  const { open } = useTabNavigationMenu()

  const active = !!matchPath(pathname, {
    path: [getRoute('/therapies')],
  })

  const Item = direction === 'horizontal' ? MenuHorizontalItem : MenuVerticalItem
  const itemActive = direction === 'horizontal' ? active && !open : active

  return (
    <Link data-test-id="tab-item-therapies" to={getRoute('/therapies')}>
      <Item active={itemActive}>
        <Flex basis="32px" pb={8}>
          <Icon colorName="white" name="report-columns" size={24} />
        </Flex>

        <Text fontWeight={active ? '600' : '400'} kind="footnote">
          <Translation id="menu.therapies" />
        </Text>
      </Item>
    </Link>
  )
})

RouteTherapiesMenuItem.displayName = 'RouteTherapiesMenuItem'
