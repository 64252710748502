import { ReactHookFormTextFieldNew } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useFormValidation } from '~/hooks/useFormValidation'

type Props = {
  required?: boolean
}

export const FieldTaxCode = ({ required = true }: Props) => {
  const { errors, labels, placeholders } = useFormTranslations()

  const { isTaxCode } = useFormValidation()

  return (
    <ReactHookFormTextFieldNew
      label={labels.taxCode}
      name="taxCode"
      placeholder={placeholders.taxCode}
      rules={{
        ...(required && { required: errors.required }),
        validate: (value) => {
          if (!required && !value.length) {
            return true
          }

          return isTaxCode(value)
        },
      }}
      type="text"
      validateOnSubmit={false}
    />
  )
}
