import { Flex } from 'cdk'
import { useCallback, useEffect } from 'react'
import { useLanguage } from '~/i18n/hooks/useLanguage'

const scriptId = 'trustpilot-script'
const widgetClass = 'trustpilot-widget'

const widgetId = '5419b732fbfb950b10de65e5'

export const TrustpilotWidgetFloating = () => {
  const { language } = useLanguage()

  const appendTrustpilotScript = useCallback(() => {
    const script = document.createElement('script')

    script.id = scriptId
    script.type = 'text/javascript'
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    script.async = true

    document.head.appendChild(script)

    script.onload = function () {
      const element = document.querySelector(`.${widgetClass}`)

      if (element && window.Trustpilot) {
        window.Trustpilot.loadFromElement(element)
      }
    }
  }, [])

  useEffect(() => {
    const trustPilotScript = document.getElementById(scriptId)

    if (!!trustPilotScript?.parentElement) {
      trustPilotScript.parentElement.removeChild(trustPilotScript)
    }

    appendTrustpilotScript()
  }, [appendTrustpilotScript])

  return (
    <Flex $align="center" $justify="center" $pb={8} $pt={16}>
      <div
        className="trustpilot-widget"
        data-businessunit-id="612cc718df8a85001c7f3815"
        data-locale={language}
        data-style-height="24px"
        data-style-width="100%"
        data-template-id={widgetId}
        style={{ pointerEvents: 'none' }}
      />
    </Flex>
  )
}
