import { isNeitherNullNorUndefined } from 'functions'
import { useEffect } from 'react'
import { Chat } from '~/clients/chat'
import { useChatUnreadCount } from '~/domains/chat/hooks/useChatUnreadCount'

export const useChatUnreadCountListenerEffect = () => {
  const { setUnread } = useChatUnreadCount()

  useEffect(() => {
    const listener = Chat.on((event) => {
      if (!isNeitherNullNorUndefined(event.total_unread_count)) {
        return
      }

      setUnread(event.total_unread_count)
    })

    return listener.unsubscribe
  }, [setUnread])

  return null
}
