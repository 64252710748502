import { useEffect } from 'react'
import { useNavigation } from '../hooks/useNavigation'

const useNavigationHideTabNavigationEffect = () => {
  const { hideTabNavigation } = useNavigation()

  useEffect(() => {
    hideTabNavigation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const NavigationHideTabNavigationEffect = () => {
  useNavigationHideTabNavigationEffect()

  return null
}
