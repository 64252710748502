import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { LoginImage } from './LoginImage'

export const LoginMenuTitle = () => (
  <>
    <Flex hide lgHide={false}>
      <LoginImage />
    </Flex>
    <Text fontWeight="600" kind="h1">
      <Translation id="generic.login.title.variant" />
    </Text>
    <Flex pt={16}>
      <Text fontWeight="400" kind="paragraph">
        <Translation id="generic.login.subtitle" />
      </Text>
    </Flex>
  </>
)
