import { Flex } from 'ui-deprecated'
import { PatientTabMenu } from '~/domains/navigation/TabNavigation/PatientTabMenu'
import { MenuHorizontalWrapper } from '~/domains/navigation/TabNavigation/components/MenuHorizontalWrapper'
import { useTabNavigationMenu } from './hooks/useTabNavigationMenu'
import { RouteChatMenuItem } from './routes/ChatMenuItem'
import { RouteHomeMenuItem } from './routes/HomeMenuItem'
import { RouteJournalingMenuItem } from './routes/JournalingMenuItem'
import { RouteSettingsMenuItem } from './routes/SettingsMenuItem'
import { RouteTherapiesMenuItem } from './routes/TherapiesMenuItem'

export const PatientTabNavigationHorizontal = () => {
  const { open } = useTabNavigationMenu()

  return (
    <>
      <Flex background="purple08" basis="78px" direction="row" justify="center">
        <RouteHomeMenuItem direction="horizontal" />

        <RouteTherapiesMenuItem direction="horizontal" />

        <RouteChatMenuItem direction="horizontal" />

        <RouteJournalingMenuItem direction="horizontal" />

        <RouteSettingsMenuItem direction="horizontal" />
      </Flex>

      {open && (
        <MenuHorizontalWrapper background="white">
          <PatientTabMenu />
        </MenuHorizontalWrapper>
      )}
    </>
  )
}
