import { gql, useMutation } from '@apollo/client'
import { JournalingRewardMutation, JournalingRewardMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation journalingReward($input: JournalingRewardInput!) {
    journalingReward(input: $input) {
      rewardType
    }
  }
`

export const useJournalingReward = () =>
  useMutation<JournalingRewardMutation, JournalingRewardMutationVariables>(MUTATION)
