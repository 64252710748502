export const padSlotsByDayAndTherapist = (
  slotsByDayAndTherapist: [day: string, slotsByTherapist: [therapistId: string, slot: (Date | 'PLACEHOLDER')[]][]][],
  therapistIds: string[],
): [day: string, slotsByTherapist: [therapistId: string, slot: (Date | 'PLACEHOLDER')[]][]][] => {
  if (therapistIds.length === 1) {
    return slotsByDayAndTherapist.map(([day, slotsByTherapist]) => [
      day,
      slotsByTherapist.map(([therapistId, slots]) => [
        therapistId,
        slots.length % 2 === 0 ? slots : [...slots, 'PLACEHOLDER'],
      ]),
    ])
  }

  return slotsByDayAndTherapist.map(([day, slotsByTherapist]) => [
    day,
    therapistIds.map((id) => {
      const maxLength = slotsByTherapist.reduce((max, [, dates]) => Math.max(max, dates.length), 0)
      const slots: [string, (Date | 'PLACEHOLDER')[]] = slotsByTherapist.find(
        ([therapistId]) => therapistId === id,
      ) ?? [id, []]

      while (slots[1].length < maxLength) {
        slots[1].push('PLACEHOLDER')
      }

      return slots
    }),
  ])
}
