import { useCallback } from 'react'
import { Text } from 'ui'
import { Button, Flex, OverflowAuto } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { ActivateConventionErrorIcon } from '~/domains/convention/components/ActivateConventionErrorIcon'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { removeLocalStorage } from '~/utils/localStorage/removeLocalStorage'

export const ReferralActivationModalError = () => {
  const { close } = useModals()
  const { open } = useIntercom()

  const cleanupLocalStorage = useCallback(() => {
    removeLocalStorage('referral-code-name')
  }, [])

  const handlePress = useCallback(() => {
    cleanupLocalStorage()

    close('referralActivation')

    open()
  }, [cleanupLocalStorage, close, open])

  return (
    <>
      <ModalCloseButton id="referralActivation" onClose={cleanupLocalStorage} />

      <OverflowAuto>
        <Flex align="center" pt={16}>
          <ActivateConventionErrorIcon />
        </Flex>

        <Flex pt={32}>
          <Text colorName="red-60" fontWeight="600" kind="h3" textAlign="center">
            <Translation id="referralCode.activation.error.title" />
          </Text>
        </Flex>

        <Flex pt={16}>
          <TranslationMarkdown id="referralCode.activation.error.body" kind="paragraph" pt={16} textAlign="center" />
        </Flex>
      </OverflowAuto>

      <Flex pt={32}>
        <Button kind="primary" onClick={handlePress}>
          <Translation id="actions.writeInChat" />
        </Button>
      </Flex>
    </>
  )
}
