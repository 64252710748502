import { useEffect, useRef } from 'react'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'

type Props = {
  metadata?: Record<string, unknown>
  name: 'banners.therapist-delete-reason'
}

export const IntercomTrackEvent = ({ metadata, name }: Props) => {
  const ref = useRef<string | null>(null)
  const { trackEvent } = useIntercom()

  useEffect(() => {
    if (ref?.current) {
      return
    }

    ref.current = name

    trackEvent(name, metadata)
  }, [name, metadata, ref, trackEvent])

  return null
}
