import { useEffect } from 'react'
import { useNavigation } from '../hooks/useNavigation'

const useNavigationShowBackButtonEffect = () => {
  const { showBackButton } = useNavigation()

  useEffect(() => {
    showBackButton()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const NavigationShowBackButtonEffect = () => {
  useNavigationShowBackButtonEffect()

  return null
}
