import { isEqual } from 'date-fns/fp'
import { FormatDateEnum, format } from 'dates'
import { pipe } from 'fp-ts/function'
import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { ReservationFormValues } from '../../types'

const StyledSlot = styled(Flex).attrs({
  borderRadius: 8,
  borderSize: 1,
  borderColor: 'purple06',
  grow: 1,
})`
  height: 48px;
`

export const EmptySlot = styled(StyledSlot)`
  border-style: dotted;
`

export const Slot = ({
  slot,
  therapistId,
  onSelected,
}: {
  slot: Date
  therapistId: string
  onSelected: (slot: Date, therapistId: string) => void
}) => {
  const handleClick = useCallback(() => {
    onSelected(slot, therapistId)
  }, [onSelected, slot, therapistId])

  const form = useReactHookFormContext<ReservationFormValues>()

  const selectedTimeSlot = form.watch('selectedTimeSlot')
  const selectedTherapistId = form.watch('therapistId')

  const selected = useMemo(
    () => selectedTimeSlot && isEqual(selectedTimeSlot, slot) && therapistId === selectedTherapistId,
    [selectedTherapistId, selectedTimeSlot, slot, therapistId],
  )

  return (
    <Pressable autoWidth data-test-id="time-slot-item-single-suggestion" grow={1} onClick={handleClick}>
      <StyledSlot
        align="center"
        background={selected ? 'purple04' : 'white'}
        backgroundHover={selected ? 'purple04' : 'purple02'}
        justify="center"
      >
        <Flex align="center" grow={1} p={16}>
          <Text fontWeight="600" kind="caption" textAlign="left">
            {pipe(slot, format(FormatDateEnum.HOURS_MINUTES))}
          </Text>
        </Flex>
      </StyledSlot>
    </Pressable>
  )
}
