import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'ui'
import { Button, Flex, Form, OverflowAuto } from 'ui-deprecated'
import { useDeleteForTherapistEmergencyOnSubmit } from '~/components/TherapySessionCardTherapist/components/DeleteForTherapistEmergency/hooks/useDeleteForTherapistEmergencyOnSubmit'
import { Translation } from '~/components/Translation'
import { useModals } from '~/domains/modals'
import { ModalCloseButton } from '~/domains/modals/components/ModalCloseButton'
import { ReactHookFormRadioField, useReactHookFormContext } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { TranslationId } from '~/i18n/types'
import { FormValues } from '../types/formValues'
import { getDeleteTherapySessionRoute } from '../utils/getDeleteTherapySessionRoute'

type Props = {
  id: string
}

type OptionTranslationId = Extract<TranslationId, `therapySession.delete.${string}`>

type Option = {
  id: OptionTranslationId
  value: string
}

const deleteOptions: Option[] = [
  { id: 'therapySession.delete.singleTherapySession', value: 'DELETE_SINGLE_THERAPY_SESSION' },
  { id: 'therapySession.delete.batchRecurrencyTherapySessions', value: 'DELETE_BATCH_RECURRENCY_THERAPY_SESSIONS' },
]

export const StepOptions = ({ id }: Props) => {
  const { loading } = useDeleteForTherapistEmergencyOnSubmit()
  const history = useHistory()
  const form = useReactHookFormContext<FormValues>()
  const { close } = useModals()
  const modalId = `deleteTherapySession:${id}` as const

  const {
    errors: { required },
  } = useFormTranslations()

  const onSubmit = useCallback(() => {
    history.replace(getDeleteTherapySessionRoute('/reason'))
  }, [history])

  const handleGoBack = useCallback(() => {
    close(modalId)

    history.goBack()
  }, [close, history, modalId])

  return (
    <Form grow={1} onSubmit={form.handleSubmit(onSubmit)} shrink={1}>
      <ModalCloseButton id={modalId} />

      <OverflowAuto>
        <Text fontWeight="600" kind="h3">
          <Translation id="therapySession.delete.optionsModal.message.title" />
        </Text>

        <Flex pt={32}>
          {deleteOptions.map(({ id, value }) => (
            <Flex key={id} pb={16}>
              <Flex as="label" borderColor="purple04" borderRadius={16} borderSize={1} id={id} p={16}>
                <ReactHookFormRadioField name="deletedType" rules={{ required }} value={value}>
                  <Flex pt={2}>
                    <Text colorName="primary" fontWeight="600" kind="caption">
                      <Translation id={id} />
                    </Text>
                  </Flex>
                </ReactHookFormRadioField>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </OverflowAuto>

      <Button kind="primary" loading={loading} type="submit">
        <Translation id="therapySession.delete.proceed" />
      </Button>
      <Flex pt={8}>
        <Button kind="secondary" loading={loading} onClick={handleGoBack} type="button">
          <Translation id="therapySession.delete.goBack" />
        </Button>
      </Flex>
    </Form>
  )
}
