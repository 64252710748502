import { useCallback, useMemo, useState } from 'react'

export const useShowMoreButton = <T>(items: T[], maxItemsToShowInFirstRun: number) => {
  const [maxItemsToShow, setMaxItemsToShow] = useState(maxItemsToShowInFirstRun)

  const visibleItems = useMemo(() => items.slice(0, maxItemsToShow), [items, maxItemsToShow])

  const areAllItemsVisible = useMemo(() => visibleItems.length === items.length, [items, visibleItems])

  const showAllItems = useCallback(() => {
    setMaxItemsToShow(items.length)
  }, [items])

  const showMoreItems = useCallback(
    (numberOfItemsToAdd: number) => {
      setMaxItemsToShow(visibleItems.length + numberOfItemsToAdd)
    },
    [visibleItems.length],
  )

  return {
    areAllItemsVisible,
    showAllItems,
    showMoreItems,
    visibleItems,
  }
}
