import { COLOR_LIGHTER } from 'design-tokens'
import styled from 'styled-components'
import { shouldForwardProp } from 'ui'
import { Flex, FlexProps } from 'ui-deprecated'

type Attrs = {
  active: boolean
  open?: boolean
}

type Props = FlexProps & Attrs

export const MenuVerticalItem = styled(Flex)
  .withConfig({ shouldForwardProp })
  .attrs<Props>(({ active, open = false }) => ({
    align: 'center',
    background: active || open ? 'purple10' : 'purple08',
    backgroundHover: 'purple10',
    p: 16,
  }))<Props>`
  height: 78px;
  border-right: 2px solid ${({ active, open = false }) => (active && !open ? COLOR_LIGHTER : 'transparent')};
  color: ${COLOR_LIGHTER};
  cursor: pointer;
`

MenuVerticalItem.displayName = 'MenuVerticalItem'
