import { ReactNode } from 'react'
import { Popover, PopoverContent, PopoverTrigger } from '../FloatingUI/Popover'
import { DropdownMenuTrigger } from './Trigger'

type Props = {
  children: ReactNode
  withPadding?: boolean
}

export const DropdownMenu = ({ children, withPadding = true }: Props) => (
  <Popover placement="left">
    <PopoverTrigger>
      <DropdownMenuTrigger />
    </PopoverTrigger>
    <PopoverContent withPadding={withPadding}>{children}</PopoverContent>
  </Popover>
)
