import { FormFlowForWhomType, TherapyTherapyPathType } from '~/types/graphql'

export const getFormWhomTypeFromTherapy = (therapyPathType?: TherapyTherapyPathType | null): FormFlowForWhomType => {
  if (therapyPathType === 'MYSELF_PSYCHIATRY') {
    return 'PSYCHIATRY'
  }

  if (therapyPathType === 'COUPLES_PSYCHOTHERAPY') {
    return 'COUPLES'
  }

  if (therapyPathType === 'PATH_ASSERTIVE_COMMUNICATION') {
    return 'PATH_ASSERTIVE_COMMUNICATION'
  }

  if (therapyPathType === 'PATH_SLEEP') {
    return 'PATH_SLEEP'
  }

  if (therapyPathType === 'PATH_COACHING') {
    return 'PATH_COACHING'
  }

  if (therapyPathType === 'UNDERAGE_PSYCHOTHERAPY') {
    return 'UNDERAGE'
  }

  return 'MYSELF'
}
