import { some } from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'
import { AuthenticatedUser, AuthenticatedUserGroups } from '../types'

export const authenticatedUserHasGroup =
  <G extends AuthenticatedUserGroups>(groups: G) =>
  (user: Pick<AuthenticatedUser, 'groups'>) =>
    pipe(
      groups,
      some((group) => user.groups.includes(group)),
    )
