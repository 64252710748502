import { useStripe } from '@stripe/react-stripe-js'
import { Flex, Form, MountOn } from 'cdk'
import { getTime } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { noop } from 'lodash-es'
import { useCallback, useState } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { Button, Text } from 'ui'
import { CardBox } from 'ui-deprecated'
import { TertiaryButtonIconLeft } from '~/components/TertiaryButton/TertiaryButtonIconLeft'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'
import { AboutAddingPaymentInfoDuringSignup } from '~/domains/bundlePurchaseFlow/components/AboutAddingPaymentInfoDuringSignup'
import { StepBottomBarButton } from '~/domains/navigation/step/StepBottomBarButton'
import { StepOverflowAuto } from '~/domains/navigation/step/StepOverflowAuto'
import { KeepYourPhoneClose } from '~/domains/payments/components/KeepYourPhoneClose'
import { PaymentSecurity } from '~/domains/payments/components/PaymentSecurity'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { useTherapyPathById } from '~/domains/reservation/hooks/useTherapyPathById'
import { StripePaymentElement, useConfirmSetup } from '~/domains/stripe'
import { useRootLocation } from '~/hooks/useRootHistory'
import { getRoute } from '~/utils/getRoute'
import { ReservationFormValues } from '../types'
import { getReservationRoutes } from '../utils/getReservationRoutes'

export const StepPaymentAdd = () => {
  const form = useReactHookFormContext<ReservationFormValues>()

  const { selectedTimeSlot, therapistId } = form.getValues()

  const [loading, setLoading] = useState(false)
  const stripe = useStripe()

  const history = useHistory()

  const location = useRootLocation()

  const { therapyPath } = useTherapyPathById()

  const isPsychiatryPath = therapyPath.type === 'MYSELF_PSYCHIATRY'

  const isFirstBooking = !!matchPath(location.pathname, {
    path: [getRoute('/booking/:therapyPathId'), getRoute('/booking')],
  })

  const { confirmSetup } = useConfirmSetup({
    returnRoute: !!selectedTimeSlot
      ? `${getRoute(`/booking/${therapyPath.id}`)}?selectedTimeSlot=${pipe(selectedTimeSlot, getTime)}&therapistId=${therapistId}`
      : getRoute(`/booking/${therapyPath.id}`),
  })

  const onSubmit = useCallback(() => {
    setLoading(true)

    confirmSetup()
      .then(async ({ ok }) => {
        if (!ok) {
          return
        }

        history.push(getReservationRoutes('/submit'))
      })
      .catch(noop)
      .finally(() => {
        setLoading(false)
      })
  }, [confirmSetup, history])

  return (
    <Form $grow={1} $shrink={1} onSubmit={form.handleSubmit(onSubmit)}>
      <StepOverflowAuto>
        <Flex $align="center" $direction="row" $pb={16}>
          <Text fontWeight="600" kind="h1">
            <Translation id="settings.payments.addPaymentMethod" />
          </Text>

          <MountOn mediaQuery="gt-sm">
            <Flex $pl={8}>
              <KeepYourPhoneClose />
            </Flex>
          </MountOn>
        </Flex>

        {isFirstBooking && (
          <Flex $pb={16} $pt={16}>
            <Text fontWeight="600" kind="h3">
              <Translation id="reservation.paymentMethod.firstBooking.title" />
            </Text>

            <TranslationMarkdown
              id={
                isPsychiatryPath
                  ? 'reservation.paymentMethod.firstBooking.psychiatry.description'
                  : 'reservation.paymentMethod.firstBooking.psychotherapy.description'
              }
              kind="paragraph"
              pt={16}
            />
          </Flex>
        )}
        <CardBox>
          <StripePaymentElement />
          <Flex $pt={16}>
            <PaymentSecurity />
          </Flex>
        </CardBox>

        <Flex $pt={16}>
          <AboutAddingPaymentInfoDuringSignup therapyPathType={therapyPath.type} />
        </Flex>
      </StepOverflowAuto>
      <StepBottomBarButton>
        <Flex $direction="row" $justify="space-between">
          <TertiaryButtonIconLeft iconName="arrow-left" onClick={history.goBack} size="sm" tabIndex={-1}>
            <Translation id="actions.goBack" />
          </TertiaryButtonIconLeft>

          <Button disabled={!stripe} isLoading={loading} kind="primary" type="submit">
            <Translation id="actions.confirm" />
          </Button>
        </Flex>
      </StepBottomBarButton>
    </Form>
  )
}
