import { AssertAuthenticatedRedirect } from '~/domains/auth/components/AssertAuthenticatedRedirect'
import { AssertPatient } from '~/domains/auth/components/AssertPatient'
import { PaymentsConfirm } from '~/domains/payments/pages/PaymentsConfirm'
import { StripeElementsProvider } from '~/domains/stripe'
import { PageNoop } from '~/routes/PageNoop'
import { Page } from '~/routes/types'

export const PaymentsConfirmPage: Page = {
  exact: true,
  id: 'payments.confirm',
  paths: ['/payments/:transactionId/confirm'],
  options: {
    isVisibleAppBar: true,
    isVisibleBackButton: false,
    isVisibleNotificationsInbox: true,
    isVisibleTabNavigation: true,
    seoKey: 'payments.confirm',
    title: null,
  },
  PageGuard: ({ children }) => (
    <AssertAuthenticatedRedirect>
      <AssertPatient target="/">{children}</AssertPatient>
    </AssertAuthenticatedRedirect>
  ),
  PageLoaded: PageNoop,
  PageProvider: ({ children }) => <StripeElementsProvider>{children}</StripeElementsProvider>,
  PageView: PaymentsConfirm,
}
