import { ReactHookFormTextFieldNew } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'

type Props = {
  validateOnSubmit?: boolean
}

export const FieldName = ({ validateOnSubmit = true }: Props) => {
  const {
    errors: { required },
    labels,
    placeholders,
  } = useFormTranslations()

  return (
    <ReactHookFormTextFieldNew
      autoComplete="name"
      label={labels.name}
      name="name"
      placeholder={placeholders.name}
      rules={{ required }}
      type="text"
      validateOnSubmit={validateOnSubmit}
    />
  )
}
