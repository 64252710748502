import { parseISO } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { CalendarAvailability } from '../types'

export const mapCalendarAvailabilityToCalendarEvent = ({
  available,
  endAt,
  id,
  startAt,
  therapySession,
  type,
}: CalendarAvailability) => ({
  end: pipe(new Date(endAt).toISOString(), parseISO),
  resource: {
    available,
    id,
    therapySession,
    type,
  },
  start: pipe(new Date(startAt).toISOString(), parseISO),
})
