import { Icon } from 'icons'
import { useCallback, useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link, Text } from 'ui'
import { CardBox, Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'
import { TranslationMarkdown } from '~/components/TranslationMarkdown'

type Props = {
  accordionAlways?: boolean
}

export const HowItWorksInvoice = ({ accordionAlways = false }: Props) => {
  const [hidden, setHidden] = useState(true)

  const onClick = useCallback(() => {
    setHidden((value) => !value)
  }, [])

  return (
    <CardBox>
      <Pressable
        align="center"
        colorName="purple08"
        colorNameHover="purple10"
        direction="row"
        justify="space-between"
        mdHide={!accordionAlways}
        onClick={onClick}
      >
        <Text fontWeight="600" kind="paragraph">
          <Translation id="payments.howItWorks.settings.invoices.title" />
        </Text>

        <Flex align="flex-end" pl={8}>
          {hidden && <Icon colorName="primary" name="nav-arrow-down" size={24} />}
          {!hidden && <Icon colorName="primary" name="nav-arrow-up" size={24} />}
        </Flex>
      </Pressable>

      <Flex hide mdHide={accordionAlways}>
        <Text colorName="primary" fontWeight="600" kind="paragraph">
          <Translation id="payments.howItWorks.settings.invoices.title" />
        </Text>
      </Flex>

      <Flex hide={hidden} mdHide={accordionAlways ? hidden : accordionAlways}>
        <Flex direction="row" pt={20}>
          <Flex pr={16}>
            <Icon colorName="grey-60" name="page-flip" size={24} />
          </Flex>

          <Flex grow={1} shrink={1}>
            <TranslationMarkdown
              colorName="black"
              id="payments.howItWorks.settings.invoices.description"
              kind="paragraph"
            />

            <Flex pt={8}>
              <Link as={ReactRouterLink} to="/settings/billing">
                <Text kind="paragraph" textDecoration="underline">
                  <Translation id="actions.addYourBillingInfo" />
                </Text>
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </CardBox>
  )
}
