import { isBefore } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { CalendarRangeInterval } from '../types'
import { parseEndAndStartFromCalendar } from './parseEndAndStartFromCalendar'

export const isCalendarEventPast = (range: CalendarRangeInterval) => {
  const { end, start } = parseEndAndStartFromCalendar(range)

  return pipe(end, isBefore(nowInMilliseconds())) || pipe(start, isBefore(nowInMilliseconds()))
}
