import { Translation } from '~/components/Translation'
import { TranslationId } from '~/i18n/types'

const translationsByAnswerValue: Record<number, Extract<TranslationId, `clinicalTests.tests.answers.${number}`>> = {
  0: 'clinicalTests.tests.answers.0',
  1: 'clinicalTests.tests.answers.1',
  2: 'clinicalTests.tests.answers.2',
  3: 'clinicalTests.tests.answers.3',
}

type ClinicalTestAnswerTranslationProps = {
  answer: number
}

export const ClinicalTestAnswerTranslation = ({ answer }: ClinicalTestAnswerTranslationProps) => {
  const translationId = translationsByAnswerValue[answer]

  return translationId ? <Translation id={translationId} /> : null
}
