import { useMergeRefs } from '@floating-ui/react'
import { Flex, Pressable } from 'cdk'
import { cloneElement, forwardRef, HTMLProps, isValidElement, ReactNode } from 'react'
import { useTooltipContext } from './TooltipContext'

type Props = {
  children: ReactNode
}

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement> & Props>(
  ({ children, ...props }, propRef) => {
    const { open, getReferenceProps, refs } = useTooltipContext()
    const childrenRef = (children as any).ref
    const ref = useMergeRefs([refs.setReference, propRef, childrenRef])

    if (isValidElement(children)) {
      return cloneElement(
        <Flex role="button">{children}</Flex>,
        getReferenceProps({
          ref,
          ...props,
          ...children.props,
        }),
      )
    }

    return (
      <Pressable ref={ref} onClick={open} {...getReferenceProps(props)}>
        {children}
      </Pressable>
    )
  },
)
