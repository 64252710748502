import { FetchPolicy, gql, useQuery } from '@apollo/client'
import { GetLegalGuardiansQuery } from '~/types/graphql'

const QUERY = gql`
  query getLegalGuardians {
    getLegalGuardians {
      email
      firstName
      id
      lastName
    }
  }
`

export const useGetLegalGuardians = (fetchPolicy: FetchPolicy = 'cache-first') => {
  const { data, error, loading, refetch } = useQuery<GetLegalGuardiansQuery>(QUERY, { fetchPolicy })

  return {
    legalGuardians: data?.getLegalGuardians,
    loading,
    error: !!error,
    refetch,
  }
}
