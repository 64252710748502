import { COLOR_PRIMARY_30 } from 'design-tokens'
import { useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import { Avatar, Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { CenteredLoader } from '~/components/CenteredLoader'
import { UserTherapist } from '~/domains/therapist/types'
import { useAvailableTimeSlotsByDay } from '../../hooks/useTimeSlots'
import { getAvailabilityDayHeaderId } from '../../utils/getAvailabilityDayHeaderId'
import { AvailabilityDayHeader } from './AvailabilityDayHeader'
import { SlotsByDay } from './SlotsByDay'

type Props = {
  therapists: UserTherapist[]
  onTimeSlotSelected: (timeSlot: Date, therapistId: string) => void
  filterByTimeInterval: boolean
}

const PositionSticky = styled(Flex)<{ stuck: boolean }>`
  position: sticky;
  top: 55px;
  margin: 0 -32px;
  padding: 12px 32px;
  ${({ stuck }) =>
    stuck &&
    css`
      border-bottom: 1px solid ${COLOR_PRIMARY_30};
    `}
`

const StickyTherapistItem = ({
  userTherapist,
  slots,
  onTimeSlotSelected,
}: {
  userTherapist: UserTherapist
  slots: (Date | 'PLACEHOLDER')[]
  onTimeSlotSelected: (timeSlot: Date, therapistId: string) => void
}) => {
  const { ref, inView } = useInView({
    rootMargin: '-120px 0px 0px 0px',
    threshold: 0,
  })

  return (
    <Flex>
      <Flex ref={ref} basis="1px" />
      <PositionSticky align="center" background="white" direction="row" stuck={!inView}>
        <Avatar image={userTherapist.therapist.profileImage?.s} name={userTherapist.fullName} size="xs" />
        <Flex pl={8} shrink={1}>
          <Text fontWeight="600" kind="caption" textAlign="left">
            {userTherapist.fullName}
          </Text>
        </Flex>
      </PositionSticky>
      <Flex pb={16}>
        <SlotsByDay
          key={userTherapist.id}
          onTimeSlotSelected={onTimeSlotSelected}
          slots={slots}
          therapistId={userTherapist.id}
        />
      </Flex>
    </Flex>
  )
}

export const MultipleSuggestionAvailabilitiesSmall = ({
  filterByTimeInterval,
  therapists,
  onTimeSlotSelected,
}: Props) => {
  const { loading, slotsByDay, slotsByDayFilteredByTimeInterval } = useAvailableTimeSlotsByDay()

  const selectedSlots = useMemo(
    () => (filterByTimeInterval ? slotsByDayFilteredByTimeInterval : slotsByDay),
    [filterByTimeInterval, slotsByDay, slotsByDayFilteredByTimeInterval],
  )

  if (loading) {
    return (
      <Flex pt={32}>
        <CenteredLoader />
      </Flex>
    )
  }

  return selectedSlots.map(([day, slotsByTherapist]) => (
    <Flex key={day} id={getAvailabilityDayHeaderId(day)}>
      <AvailabilityDayHeader day={day} pt={16} />
      {slotsByTherapist.map(([therapistId, slots]) => {
        if (!slots.length) {
          return null
        }

        const userTherapist = therapists.find(({ id }) => id === therapistId)
        if (!userTherapist?.therapist) {
          return null
        }

        return (
          <StickyTherapistItem
            key={therapistId}
            onTimeSlotSelected={onTimeSlotSelected}
            slots={slots}
            userTherapist={userTherapist}
          />
        )
      })}
    </Flex>
  ))
}
