import { useEffect } from 'react'
import { useNavigation } from '../hooks/useNavigation'

const useNavigationShowAppBarEffect = () => {
  const { showAppBar } = useNavigation()

  useEffect(() => {
    showAppBar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const NavigationShowAppBarEffect = () => {
  useNavigationShowAppBarEffect()

  return null
}
