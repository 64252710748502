import { gql, useMutation } from '@apollo/client'
import { PartnerUpsertMutation, PartnerUpsertMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation partnerUpsert($input: PartnerUpsertInput!) {
    partnerUpsert(input: $input) {
      ok
    }
  }
`

export const usePartnerUpsert = () => useMutation<PartnerUpsertMutation, PartnerUpsertMutationVariables>(MUTATION)
