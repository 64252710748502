import { getTherapySessionEndAt } from '~/utils/dates/getTherapySessionEndAt'
import { getTherapySessionStartAt } from '~/utils/dates/getTherapySessionStartAt'

type Param = {
  endAt: Date
  startAt: Date
}

export const getTherapySessionDurationRange = ({ endAt, startAt }: Param) =>
  [getTherapySessionStartAt(startAt), getTherapySessionEndAt(endAt)].join(' - ')
