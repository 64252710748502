import { ReactHookFormTextFieldNew } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useTranslation } from '~/i18n/hooks/useTranslation'

const max = 4

export const FieldPhoneVerificationCodeNew = () => {
  const {
    errors: { required, patternDigits },
    placeholders,
  } = useFormTranslations()

  const maxLengthMessage = useTranslation('forms.errors.maxLength', { count: max })

  return (
    <ReactHookFormTextFieldNew
      autoComplete="one-time-code"
      inputMode="numeric"
      name="phoneVerificationCode"
      placeholder={placeholders.phoneVerificationCode}
      rules={{
        required,
        maxLength: { message: maxLengthMessage, value: max },
        pattern: { message: patternDigits, value: /[0-9]{4}/ },
      }}
      type="text"
    />
  )
}
