import { ReactNode, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { CenteredLoader } from '~/components/CenteredLoader'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { getRoute } from '~/utils/getRoute'

type Props = {
  children: ReactNode
}

export const AssertAuthenticatedRedirect = ({ children }: Props) => {
  const { isAuthenticated, isAuthenticating } = useAuthState()
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    if (isAuthenticating) {
      return
    }

    if (isAuthenticated) {
      return
    }

    history.replace(getRoute('/login'), { referrer: pathname })
  }, [history, isAuthenticated, isAuthenticating, pathname])

  if (isAuthenticating || !isAuthenticated) {
    return <CenteredLoader />
  }

  return <>{children}</>
}
