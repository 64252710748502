import { Modal } from '~/domains/modals'
import { TriggerPaymentFailure } from './TriggerPaymentFailure'
import { TriggerPaymentLoading } from './TriggerPaymentLoading'
import { TriggerPaymentSuccess } from './TriggerPaymentSuccess'

type Props = {
  therapySessionId: string
  loading: boolean
  loopCompleted: boolean
  success: boolean | null
  onChangePaymentMethod: () => void
  onLoopCompleted: () => void
  triggerPaymentCallback: () => void
  onClose: () => void
}

export const TriggerPaymentModal = ({
  therapySessionId,
  loading,
  loopCompleted,
  success,
  onChangePaymentMethod,
  onLoopCompleted,
  triggerPaymentCallback,
  onClose,
}: Props) => (
  <Modal closeOnClickOutside={false} id={`triggerPayment:${therapySessionId}`}>
    {(loading || !loopCompleted) && <TriggerPaymentLoading onLoopCompleted={onLoopCompleted} />}
    {!loading && loopCompleted && success && <TriggerPaymentSuccess onClose={onClose} />}
    {!loading && loopCompleted && !success && (
      <TriggerPaymentFailure onChangePaymentMethod={onChangePaymentMethod} onRetryClicked={triggerPaymentCallback} />
    )}
  </Modal>
)
