import { ReactHookFormTextFieldNew } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'

export const FieldTaxCodeForeign = () => {
  const {
    errors: { required },
    labels,
    placeholders,
  } = useFormTranslations()

  return (
    <ReactHookFormTextFieldNew
      label={labels.taxCode}
      name="taxCodeForeign"
      placeholder={placeholders.taxCode}
      rules={{ required }}
      type="text"
      validateOnSubmit={false}
    />
  )
}
