import { Flex, MaxWidth400px } from 'cdk'
import { FormatDateEnum, format } from 'dates'
import { pipe } from 'fp-ts/function'
import { useLocation } from 'react-router-dom'
import { capitalize } from 'strings'
import { Text } from 'ui'
import { FlowNavigationBar } from '~/components/Flow/FlowNavigationBar'
import { useReactHookFormContext } from '~/domains/react-hook-form'
import { useCurrentUser } from '~/hooks/useCurrentUser'
import { useReservationFlow } from '../hooks/useReservationFlow'
import { ReservationFormValues } from '../types'
import { getReservationRoutes } from '../utils/getReservationRoutes'
import { BookButton } from './BookButton'
import { GoNextButton } from './GoNextButton'
import { GoPreviousButton } from './GoPreviousButton'
import { GoRootButton } from './GoRootButton'
import { SkipBookingButton } from './SkipBookingButton'
import { StartButton } from './StartButton'

const SelectedSlot = () => {
  const form = useReactHookFormContext<ReservationFormValues>()

  const selectedSlot = form.watch('selectedTimeSlot')
  const selectedTherapistId = form.watch('therapistId')

  const { suggestedTherapistsProfiles } = useReservationFlow()

  const therapistProfile = suggestedTherapistsProfiles.find(({ id }) => id === selectedTherapistId)

  if (!selectedSlot || !therapistProfile) {
    return null
  }

  const formattedDate = pipe(selectedSlot, format(FormatDateEnum.LONG_DAY_DATE_MONTH_NAME), capitalize)
  const formattedHours = pipe(selectedSlot, format(FormatDateEnum.HOURS_MINUTES))

  return (
    <Text
      colorName="grey-80"
      data-test-id="reservation-footer-booking-recap"
      fontWeight="600"
      kind="caption"
      textAlign="center"
    >{`${formattedDate}, ${formattedHours}, ${therapistProfile?.fullName}`}</Text>
  )
}

export const ReservationFooter = () => {
  const location = useLocation()
  const { emailVerified } = useCurrentUser()

  if (!emailVerified) {
    return null
  }

  if (location.pathname.includes(getReservationRoutes('/book-by-suggested-therapist'))) {
    return (
      <FlowNavigationBar>
        <MaxWidth400px>
          <Flex $pb={8}>
            <SelectedSlot />
          </Flex>
          <StartButton />
        </MaxWidth400px>
      </FlowNavigationBar>
    )
  }

  if (location.pathname.includes(getReservationRoutes('/send-verification-code'))) {
    return (
      <FlowNavigationBar>
        <Flex $direction="row" $justify="space-between">
          <GoPreviousButton />
          <GoNextButton />
        </Flex>
      </FlowNavigationBar>
    )
  }

  if (location.pathname.includes(getReservationRoutes('/check-verification-code'))) {
    return (
      <FlowNavigationBar>
        <Flex $direction="row" $justify="space-between">
          <GoPreviousButton />
          <GoNextButton />
        </Flex>
      </FlowNavigationBar>
    )
  }

  if (location.pathname.includes(getReservationRoutes('/submit'))) {
    return (
      <FlowNavigationBar>
        <Flex $direction="row" $justify="space-between">
          <GoRootButton />
          <BookButton />
        </Flex>
      </FlowNavigationBar>
    )
  }

  if (location.pathname.includes(getReservationRoutes('/skip-booking'))) {
    return (
      <FlowNavigationBar>
        <Flex $direction="row" $justify="space-between">
          <GoPreviousButton />
          <SkipBookingButton />
        </Flex>
      </FlowNavigationBar>
    )
  }

  return null
}
