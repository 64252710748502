import { Flex } from 'cdk'
import styled from 'styled-components'
import { Avatar } from 'ui'

const Container = styled(Flex)`
  @keyframes slide-thx-page-avatar {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  opacity: 0;
  transform: translateX(30%);
  animation: slide-thx-page-avatar 1.5s ease forwards;
  animation-delay: 0.9s;
`

export const TherapistCardAvatar = ({ image, name }: { image: string | undefined; name: string }) => (
  <Container $justify="flex-end">
    <Avatar image={image} name={name} size="sm" />
  </Container>
)
