/**
 * (amin-khayam)
 * For additional details and implementation reference, see the mobile detection logic at:
 * https://github.com/haldarmahesh/use-mobile-detect-hook/blob/master/src/index.ts
 */

export const useIsAppleDevice = () => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent

  return Boolean(userAgent.match(/Apple|iPhone|iPad|iPod|Mac OS X|Macintosh/i))
}
