import { authenticatedUserHasGroup } from 'acl'
import { pipe } from 'fp-ts/function'
import { useAuthState } from '../components/AuthStateProvider'

export const useIsSaasTherapist = (): boolean => {
  const { accessTokenPayload } = useAuthState()

  if (!accessTokenPayload) {
    return false
  }

  return pipe(accessTokenPayload, authenticatedUserHasGroup(['saas-therapist']))
}
