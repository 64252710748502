import { useFormFlowResponse } from '~/domains/formFlow/hooks/useFormFlowResponse'

export const useSelectedTimeInterval = () => {
  const { formFlowResponse } = useFormFlowResponse()

  const answer = formFlowResponse?.answers?.find((answer) => answer.step.name === 'TIME_PREFERENCES_HOURS_SLOT')

  return (
    answer?.valueChoice
      ?.flatMap((value) => value.references.map((reference) => reference.value))
      .filter((value) => !value.includes('OTHER')) ?? null
  )
}
