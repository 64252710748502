import { Flex } from 'cdk'
import { SPACING_XS } from 'design-tokens'
import { Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useStepNavigation } from './useStepNavigation'

export const StepProgressCounter = () => {
  const { currentIndex, entries } = useStepNavigation()
  const total = entries.filter(({ isInfoStep }) => !isInfoStep).length
  const stepsToSkip = entries.slice(0, currentIndex + 1).filter(({ isInfoStep }) => isInfoStep).length
  const current = currentIndex - stepsToSkip + 1
  const isCurrentStepInfoStep = entries[currentIndex].isInfoStep

  if (isCurrentStepInfoStep) {
    return null
  }

  return (
    <Flex $gap={SPACING_XS}>
      <Text kind="caption">
        <Translation id="generic.outOf" values={{ value: current, max: total }} />
      </Text>
    </Flex>
  )
}
