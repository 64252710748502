import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getRoute, Route } from '~/utils/getRoute'

export const useUserRedirectEffect = (redirect: boolean, target: Route = '/') => {
  const history = useHistory()

  useEffect(() => {
    if (redirect) {
      history.replace(getRoute(target))
    }
  }, [history, redirect, target])
}
