import { useEffect } from 'react'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { upsertAnonymousFingerprint } from '~/utils/upsertAnonymousFingerprint'

export const useClarityIdentifyEffect = () => {
  const { id } = useCurrentUserNullable()
  const fingerprintId = upsertAnonymousFingerprint()

  useEffect(() => {
    if (!id || !window?.clarity) {
      return
    }

    try {
      window?.clarity('identify', id, fingerprintId)
    } catch (error) {}
  }, [fingerprintId, id])

  return null
}
