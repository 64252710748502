import { gql, useQuery } from '@apollo/client'
import { ClinicalTestAnswersQuery, ClinicalTestAnswersQueryVariables } from '~/types/graphql'

const QUERY = gql`
  query clinicalTestAnswers($therapyId: String!) {
    clinicalTestAnswers(therapyId: $therapyId) {
      id
      clinicalTestId
      code
      questions {
        id
      }
      createdAt
      answers {
        answer
        questionId
      }
    }
  }
`

export const useClinicalTestAnswersByTherapyId = (therapyId: string) => {
  const { data, error, loading } = useQuery<ClinicalTestAnswersQuery, ClinicalTestAnswersQueryVariables>(QUERY, {
    variables: {
      therapyId,
    },
  })

  return {
    clinicalTests: data?.clinicalTestAnswers,
    error: !!error,
    loading,
  }
}
