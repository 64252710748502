import { MaxWidth320px } from 'ui-deprecated'
import { usePatientTherapies } from '~/domains/patient/hooks/usePatientTherapies'
import { AgendaPatientEmptyStateCard } from './AgendaPatientEmptyStateCard'
import { BookTherapySessionFromTherapy } from './BookTherapySessionFromTherapy'
import { ChooseTherapistBeforeBookingModal } from './ChooseTherapistBeforeBookingModal'

export const AgendaPatientEmptyState = () => {
  const { therapies } = usePatientTherapies()

  const hasSingleTherapy = therapies.length === 1

  const singleTherapy = therapies.at(0)

  return (
    <AgendaPatientEmptyStateCard>
      <MaxWidth320px pt={16}>
        {hasSingleTherapy && singleTherapy ? (
          <BookTherapySessionFromTherapy therapyId={singleTherapy.id} />
        ) : (
          <ChooseTherapistBeforeBookingModal />
        )}
      </MaxWidth320px>
    </AgendaPatientEmptyStateCard>
  )
}
