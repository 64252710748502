import { COLOR_LIGHTER } from 'design-tokens'
import styled from 'styled-components'
import { shouldForwardProp } from 'ui'
import { Flex, media } from 'ui-deprecated'

type Attrs = {
  active: boolean
  open?: boolean
}

export const MenuHorizontalItem = styled(Flex)
  .withConfig({
    shouldForwardProp,
  })
  .attrs({
    align: 'center',
    mdPx: 24,
    px: 16,
    py: 16,
  })<Attrs>`
  width: 78px;
  border-top: 2px solid ${({ active }) => (active ? COLOR_LIGHTER : 'transparent')};
  color: ${COLOR_LIGHTER};
  cursor: pointer;

  ${media.smallOnly`
    width: 66px;
  `}
`

MenuHorizontalItem.displayName = 'MenuHorizontalItem'
