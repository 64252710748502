import { ReactHookFormSelect } from '~/domains/react-hook-form'
import { useFormTranslations } from '~/hooks/useFormTranslations'
import { useTranslate } from '~/i18n/hooks/useTranslation'
import { TranslationId } from '~/i18n/types'
import { ChurnReason } from '~/types/graphql'

type OptionTranslationId = Extract<
  TranslationId,
  | 'therapySession.churnReason.patient.economic'
  | 'therapySession.churnReason.patient.other'
  | 'therapySession.churnReason.patient.schedulingIssue'
  | 'therapySession.churnReason.patient.solved'
  | 'therapySession.churnReason.patient.therapyInPerson'
  | 'therapySession.churnReason.patient.therpistIssue'
>

type Option = {
  id: OptionTranslationId
  value: ChurnReason
}

const churnReasons: Option[] = [
  { id: 'therapySession.churnReason.patient.solved', value: 'SOLVED' },
  { id: 'therapySession.churnReason.patient.economic', value: 'ECONOMIC' },
  { id: 'therapySession.churnReason.patient.therapyInPerson', value: 'THERAPY_IN_PERSON' },
  { id: 'therapySession.churnReason.patient.therpistIssue', value: 'THERAPIST_ISSUE' },
  { id: 'therapySession.churnReason.patient.schedulingIssue', value: 'SCHEDULING_ISSUE' },
  { id: 'therapySession.churnReason.patient.other', value: 'OTHER' },
]

export const DeleteTherapySessionChurnReasonField = () => {
  const {
    errors: { required },
    placeholders,
  } = useFormTranslations()

  const translate = useTranslate()

  return (
    <ReactHookFormSelect name="churnReason" rules={{ required }}>
      <option disabled selected value="">
        {placeholders.churnReason}
      </option>

      {churnReasons.map(({ id, value }) => (
        <option key={value} value={value}>
          {translate(id)}
        </option>
      ))}
    </ReactHookFormSelect>
  )
}
