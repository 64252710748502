import { Icon } from 'icons'
import { Text } from 'ui'
import { Flex, Pressable } from 'ui-deprecated'
import { Translation } from '~/components/Translation'

type Props = {
  onClick: () => void
}

export const DeleteRecurrencyButton = ({ onClick }: Props) => (
  <Pressable autoWidth colorName="black" colorNameHover="purple08" onClick={onClick}>
    <Flex align="center" direction="row" justify="flex-start">
      <Flex basis="28px" pr={8}>
        <Icon name="timer-off" size={20} />
      </Flex>

      <Text fontWeight="400" kind="caption" textAlign="left">
        <Translation id="actions.deleteRecurrency" />
      </Text>
    </Flex>
  </Pressable>
)
