import { gql, useMutation } from '@apollo/client'
import { JournalingLogUpdateMutation, JournalingLogUpdateMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation journalingLogUpdate($input: JournalingLogUpdateInput!) {
    journalingLogUpdate(input: $input) {
      ok
    }
  }
`

export const useJournalingLogUpdate = () =>
  useMutation<JournalingLogUpdateMutation, JournalingLogUpdateMutationVariables>(MUTATION)
