import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { useSeoMeta } from '~/hooks/useSeoMeta'
import { Page } from '~/routes/types'

type Props = {
  id: NonNullable<Page['options']['seoKey']>
}

export const PageHead = ({ id }: Props) => {
  const { description, title } = useSeoMeta(id)
  const { asPath } = useRouter()

  return (
    <NextHead>
      <title key="title">{title}</title>
      <meta key="og:title" content={title} property="og:title" />
      <meta key="twitter:title" content={title} property="twitter:title" />

      <meta key="description" content={description} name="description" />
      <meta key="og:description" content={description} property="og:description" />
      <meta key="twitter:description" content={description} property="twitter:description" />

      <link key="canonical" href={asPath} rel="canonical" />
      <meta key="og:url" content={asPath} property="og:url" />
    </NextHead>
  )
}
