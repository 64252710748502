import { Text } from 'ui'
import { Flex } from 'ui-deprecated'
import { Markdown } from '~/components/Markdown'
import { Translation } from '~/components/Translation'
import { useTherapist } from '~/hooks/useTherapist'

export const TherapistAboutCoach = () => {
  const {
    therapist: { about, aboutCoach },
  } = useTherapist()

  return (
    <>
      <Text fontWeight="600" kind="paragraph">
        <Translation id="therapist.presentationCard.about.title" />
      </Text>
      <Flex pt={16}>
        <Markdown colorName="black" kind="paragraph">{`*“${aboutCoach || about}”*`}</Markdown>
      </Flex>
    </>
  )
}
