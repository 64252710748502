import { NavigationLink } from '../types'

export const therapistNavigationLinks: NavigationLink[] = [
  {
    id: 'agenda',
    route: '/',
    translation: 'menu.agenda.yourBookings',
    iconName: 'srns-agenda',
    iconNameActive: 'srns-agenda-solid',
    pathMatches: [
      '/',
      '/therapy-session/:therapyId/schedule',
      '/therapy-session/:therapySessionId/edit',
      '/therapy-session/booking/:therapy',
    ],
    exact: true,
  },
  {
    id: 'calendar',
    route: '/availabilities/calendar',
    translation: 'menu.availabilities.calendar',
    iconName: 'srns-calendar',
    iconNameActive: 'srns-calendar-solid',
    pathMatches: ['/availabilities/calendar', '/availabilities/weekly'],
    exact: false,
  },
  {
    id: 'chat',
    dataTestId: 'tab-item-chat',
    route: '/chat',
    translation: 'menu.chat',
    iconName: 'srns-chat',
    iconNameActive: 'srns-chat-solid',
    pathMatches: ['/chat'],
    exact: false,
  },
  {
    id: 'patients',
    dataTestId: 'tab-item-patients',
    route: '/patients',
    translation: 'menu.patients',
    iconName: 'srns-patients',
    iconNameActive: 'srns-patients-solid',
    pathMatches: ['/patients'],
    exact: false,
  },
]
