import { Flex } from 'cdk'
import { Icon, isIconName } from 'icons'
import styled from 'styled-components'
import { Button, Text } from 'ui'
import { Translation } from '~/components/Translation'
import { useShowMoreButton } from '~/hooks/useShowMoreButton'
import { useTherapist } from '~/hooks/useTherapist'

type CertificationIconProps = {
  icon: string
}

const CertificationIconContainer = styled(Flex)`
  width: 24px;
  height: 24px;
`

const CertificationIcon = ({ icon }: CertificationIconProps) => (
  <CertificationIconContainer $align="center" $justify="center">
    {isIconName(icon) && <Icon colorName="grey-80" name={icon} size={24} />}
  </CertificationIconContainer>
)

export const TherapistCertifications = () => {
  const {
    therapist: { therapistCertifications },
  } = useTherapist()

  const { areAllItemsVisible, visibleItems, showAllItems } = useShowMoreButton(therapistCertifications ?? [], 3)

  return (
    <>
      <Text fontWeight="600" kind="paragraph">
        <Translation id="therapist.presentationCard.certifications.title" />
      </Text>
      <Flex $gap={16} $pt={16}>
        {visibleItems.map(({ certification }) => (
          <Flex key={certification.id} $align="flex-start" $direction="row">
            <Flex>
              <CertificationIcon icon={certification.icon} />
            </Flex>
            <Flex $grow={1} $justify="center" $pl={16} $shrink={1}>
              <Text colorName="grey-80" fontWeight="600" kind="paragraph">
                {certification.name}
              </Text>
              <Flex $pt={4}>
                <Text colorName="black" kind="caption">
                  {certification.description}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        ))}
        {!areAllItemsVisible && (
          <Flex $align="flex-start" $pt={16}>
            <Button kind="secondary" onClick={showAllItems} size="sm">
              <Translation id="actions.learnMore" />
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  )
}
