import loadable from '@loadable/component'
import { Flex, Pressable } from 'cdk'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Text } from 'ui'
import { CardBoxNoStroke } from '~/components/CardBoxNoStroke'
import { TertiaryButtonIcon } from '~/components/TertiaryButton/TertiaryButtonIcon'
import { Translation } from '~/components/Translation'
import { getRoute } from '~/utils/getRoute'

const Fallback = styled.div`
  width: 78px;
  height: 78px;
`

const LazyWritingIsGoodCardIllustration = loadable(() => import('./WritingIsGoodCardIllustration.lazy'), {
  resolveComponent: (components) => components.WritingIsGoodCardIllustration,
  fallback: <Fallback />,
})

export const WritingIsGoodCardSmall = () => {
  const history = useHistory()

  const handleOnPress = useCallback(() => {
    history.push(getRoute('/journaling/benefits'))
  }, [history])

  return (
    <Pressable onClick={handleOnPress}>
      <CardBoxNoStroke
        $align="center"
        $backgroundColorName="lighter"
        $direction="row"
        $justify="space-between"
        $px={16}
        $py={8}
      >
        <LazyWritingIsGoodCardIllustration />
        <Flex $align="flex-start" $direction="row" $grow={1} $justify="center" $pl={8} $shrink={1}>
          <Flex $grow={1} $shrink={1}>
            <Text fontWeight="600" kind="paragraph" textAlign="left">
              <Translation id="journaling.writingIsGoodCard.title" />
            </Text>
            <Flex $pt={4}>
              <Text fontWeight="400" kind="paragraph" textAlign="left">
                <Translation id="journaling.writingIsGoodCard.description" />
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex $pl={12}>
          <TertiaryButtonIcon colorName="primary" iconName="arrow-right" />
        </Flex>
      </CardBoxNoStroke>
    </Pressable>
  )
}
