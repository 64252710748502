import { useEffect } from 'react'
import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'

export const useUpdateIntercom = () => {
  const { update } = useIntercom()

  const { email, fullName, hash, id, phoneNumber, professionTypeValues } = useCurrentUserNullable()
  const group = useUserActingAsGroup()

  useEffect(() => {
    if (!email || !fullName || !id || !hash) {
      return
    }

    update({
      customAttributes: {
        'User Type': group === 'therapist' ? 'Therapist' : 'Patient',
        'Therapist Profession Types': professionTypeValues?.join(','),
      },
      email,
      name: fullName,
      userHash: hash,
      userId: id,
      ...(phoneNumber && { phone: phoneNumber }),
    })
  }, [email, fullName, group, hash, id, phoneNumber, professionTypeValues, update])

  return null
}
