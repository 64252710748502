export const HeartHug = () => (
  <svg fill="none" viewBox="0 0 83 71" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M82.2 21.8A26.34 26.34 0 0 0 68.67 3.28C62.22-.23 54.74-.98 49.14 1.3a22.23 22.23 0 0 0-9.79 7.76 22.2 22.2 0 0 0-3.14 6.54 22.16 22.16 0 0 0-6.38-3.46 22.22 22.22 0 0 0-12.48-.56c-5.88 1.43-11.5 6.42-14.68 13.04a26.33 26.33 0 0 0-.1 22.93C4.37 51.4 8.17 55 13.85 58.3c4.39 2.54 9.9 4.92 16.38 7.07 6.48 2.14 12.26 3.5 15.14 4.11 4.35.93 6.4 1.19 7.47 1.19a3 3 0 0 0 1.13-.17c.65-.18 1.7-.68 6.38-5.87 1.97-2.18 5.86-6.66 9.86-12.2 4-5.54 7.06-10.7 9.13-15.33 2.68-6 3.64-11.15 2.86-15.3ZM50.45 68.65a2.76 2.76 0 0 1 1.44-3.3l-.13.1a2.01 2.01 0 0 1 1.05-.34h-.17a2.76 2.76 0 0 1 3.1 1.83l-5.29 1.71Zm15.9-20.4c-5.98 8.46-12.23 15.07-14.14 16.82-2.57-.3-11.5-2-21.3-5.34C18.24 55.4 9.96 50.25 7.6 45.2a20.88 20.88 0 0 1 .09-18.16c2.43-5.08 6.74-9.02 10.97-10.04a16.4 16.4 0 0 1 9.36.4c3.04 1.05 5.76 3.01 7.86 5.67l4.65 5.88.31-7.48c.15-3.39 1.2-6.57 3.06-9.2a16.66 16.66 0 0 1 7.34-5.82c4.03-1.65 9.83-.98 14.78 1.71 3.3 1.8 9.1 6.1 10.71 14.67 1.04 5.48-2.65 14.5-10.37 25.42Z"
      fill="#FFDBDE"
    />
    <path
      d="M75.95 13.57c-5.68 3.84-10.3 6.47-10.3 6.47-2.28 4.19 2.27 3 2.27 3-2.74 3.57 1.51 3.62 1.51 3.62-2.7 3.8 1.51 3.6 1.51 3.6a66 66 0 0 0 8.72-4.6c.06-1.17 0-2.29-.2-3.34a23.74 23.74 0 0 0-3.5-8.75ZM63.26 31.1s2.36-3.57-2.1-3.3c0 0 3.54-3.12-1.16-3.6 0 0-8.85 4.42-18.51 7.72l-3.42-10.57A19.41 19.41 0 0 0 18 14.3C8.09 16.7-1.83 31.69 5.08 46.39a15.43 15.43 0 0 0 3 4.16 164.8 164.8 0 0 0 22.99-3.61c4.5 1.5.37 3.76.37 3.76 4.42.73 1.33 3.67 1.33 3.67 4.56.95 1.34 3.68 1.34 3.68-3 1.08-6.06 1.89-9.04 2.5 13.38 5.28 28.2 7.81 28.04 7.28l-7.96-24.6c6.07-1.72 13.45-4.48 20.2-8.83 0 0 2.54-3.4-2.1-3.3Z"
      fill="#FF535B"
    />
    <path
      d="M31.07 46.94s-11.73 2.8-22.98 3.61c3.91 4 10.26 7.34 16.98 10 2.98-.61 6.03-1.42 9.04-2.5 0 0 3.22-2.73-1.34-3.68 0 0 3.09-2.94-1.34-3.67 0 0 4.14-2.25-.36-3.76ZM69.43 26.66s-4.25-.05-1.5-3.61c0 0-4.55 1.18-2.28-3 0 0 4.62-2.64 10.3-6.48C69.75 4.15 57.66.83 50.2 3.88a19.41 19.41 0 0 0-12.12 17.47l3.42 10.57C51.16 28.62 60 24.2 60 24.2c4.7.47 1.17 3.6 1.17 3.6 4.45-.28 2.09 3.3 2.09 3.3 3.23-.07 2.97 1.56 2.51 2.55-5.8 3.96-13.9 7.25-19.91 9.37l-.7.2v.05c-4.35 1.51-7.43 2.37-7.43 2.37-3.13 3.56 1.58 3.43 1.58 3.43-3.45 2.85.7 3.85.7 3.85-3.45 3.1.7 3.84.7 3.84a64.48 64.48 0 0 0 8.16-2.05l4.24 13.1c.28.84 25.64-25.65 26.55-42.16a65.87 65.87 0 0 1-8.72 4.6s-4.2.2-1.5-3.6Z"
      fill="#FFACB4"
    />
    <path
      d="M50.31 21.11c-2.64 0-4-1.57-4.05-1.63l.68-.58-.34.3.34-.3a4.53 4.53 0 0 0 3.37 1.32h.04c2.15-.01 5.75-1.27 10.7-7.18l.67.57c-4.15 4.97-7.99 7.5-11.4 7.5ZM47.27 15a3.47 3.47 0 0 1-3.3-2.4l.85-.28a2.58 2.58 0 0 0 4.9-1.59l.85-.27a3.47 3.47 0 0 1-3.3 4.54ZM56.5 12.01a3.47 3.47 0 0 1-3.3-2.4l.84-.27a2.58 2.58 0 0 0 4.9-1.59l.85-.27a3.44 3.44 0 0 1-2.23 4.36c-.36.12-.71.17-1.07.17ZM22.37 29.79c-2.11 0-4.57-.36-7.36-1.07l.22-.86c4.14 1.05 9.73 1.83 12.9-.46 1.81-1.3 1.96-3.03 1.96-3.04l.89.06c0 .09-.18 2.15-2.33 3.7-1.54 1.11-3.64 1.67-6.28 1.67ZM25.5 22.04a3.44 3.44 0 0 1-3.3-2.4l.85-.27a2.56 2.56 0 0 0 3.25 1.65 2.58 2.58 0 0 0 1.66-3.24l.84-.27a3.47 3.47 0 0 1-3.3 4.53ZM16.29 25.02a3.47 3.47 0 0 1-3.3-2.4l.84-.27a2.58 2.58 0 0 0 4.9-1.59l.85-.27a3.47 3.47 0 0 1-3.3 4.53Z"
      fill="#000001"
    />
  </svg>
)
