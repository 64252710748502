import { secondsToHours } from 'date-fns/fp'
import { pipe } from 'fp-ts/function'
import { useTherapistAvailabilitiesGivenCount } from './useTherapistAvailabilitiesGivenCount'

export const useShowAvailabilitiesInsertionReminder = () => {
  const { data, error, loading } = useTherapistAvailabilitiesGivenCount()

  if (
    error ||
    loading ||
    !data?.findTherapistProfileById?.therapist ||
    !data.findTherapistProfileById?.weeklyAvailabilitiesGivenCount ||
    !data.findTherapistProfileById?.monthlyAvailabilitiesGivenCount
  ) {
    return false
  }

  const {
    findTherapistProfileById: {
      therapist: { availableHoursPerWeek },
      monthlyAvailabilitiesGivenCount,
      weeklyAvailabilitiesGivenCount,
    },
  } = data

  return (
    [weeklyAvailabilitiesGivenCount.current.available, weeklyAvailabilitiesGivenCount.next.available].some(
      (available) => pipe(available, secondsToHours) < availableHoursPerWeek,
    ) ||
    [monthlyAvailabilitiesGivenCount.current.available].some(
      (available) => pipe(available, secondsToHours) < availableHoursPerWeek * 4,
    )
  )
}
