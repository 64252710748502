import { useIsNativeApp } from '~/domains/appNative/hooks/useIsNativeApp'
import { AskForReviewHandler } from '~/domains/reviews/components/AskForReviewHandler'

export const AskForReview = () => {
  const isNativeApp = useIsNativeApp()

  if (!isNativeApp) {
    return null
  }

  return <AskForReviewHandler />
}
