import { useUserActingAsGroup } from '~/domains/auth/hooks/useUserActingAsGroup'
import { PatientView } from './PatientView'
import { TherapistView } from './TherapistView'

export const Agenda = () => {
  const group = useUserActingAsGroup()

  if (group === 'therapist') {
    return <TherapistView />
  }

  return <PatientView />
}
