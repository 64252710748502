import { useCurrentUserNullable } from '~/hooks/useCurrentUser'

export const useCurrentUserProfessionTypeValue = () => {
  const { professionTypeValues } = useCurrentUserNullable()

  return {
    isCoach: !!professionTypeValues?.includes('COACH'),
    isPsychiatrist: !!professionTypeValues?.includes('PSYCHIATRIST'),
    isPsychotherapist: !!professionTypeValues?.includes('PSYCHOTHERAPIST'),
    isSexologist: !!professionTypeValues?.includes('SEXOLOGIST'),
  }
}
